<template>
  <div>
    <v-checkbox v-model="isInvoiced" label="Pending review / do not bill" @click="save" v-if="isReady"/>
  </div>
</template>

<script>

export default {
  name: "InvoicedCheckbox",
  permissions: ["Prepare Invoices"],
  props: ["jobId", "invoicePeriod"],
  methods: {
    load() {
      if (this.jobId && this.invoicePeriod) {
        const query = {job_id: this.jobId, month_str: this.invoicePeriod}
        this.$BwfApi.get("invoice/task-review", query).then(response => {
          this.isInvoiced = response.is_invoiced === 1
          this.isReady=true
        })
      }
    },
    save() {
      const data = {
        job_id: this.jobId,
        month_str: this.invoicePeriod,
        is_invoiced: this.isInvoiced ? 1 : 0,
      }
      this.$BwfApi.post("invoice/task-review", data).then(() => {
        this.$notify.toast("Saved")
      })
    }
  },
  mounted() {
    this.load()
  },
  data() {
    return {
      isInvoiced: false,
      isReady: false,
    }
  }
}

</script>


<style scoped>

</style>