<template>
  <div>
    <v-chart class="chart" :option="chartOptions" @click="chartClick" autoresize/>
    <v-dialog v-model="showPopup" width="auto" v-if="showPopup">
      <CapacityMonthPopup @close="showPopup = false" :serviceIds="serviceIds" :monthStr="selectedMonth"
                          :highlightedJobId="jobId" :maxCapacity="selectedCapacity"></CapacityMonthPopup>
    </v-dialog>
  </div>
</template>

<script>
import {use} from "echarts/core"
import {CanvasRenderer} from "echarts/renderers"
import {BarChart, LineChart} from "echarts/charts"
import {
  LegendComponent,
  GridComponent,
  MarkAreaComponent,
  TooltipComponent,
  TitleComponent
} from "echarts/components"
import VChart from "vue-echarts"
import {defineComponent} from "vue"
import CapacityMonthPopup from "@/components/capacity/CapacityMonthPopup.vue"
import {formatMonth} from "@/lib/dateTimeUtilities"

use([
  CanvasRenderer,
  BarChart,
  LineChart,
  LegendComponent,
  GridComponent,
  MarkAreaComponent,
  TooltipComponent,
  TitleComponent,
])
export default defineComponent({
  name: "CapacitySummaryGraph",
  props: ["jobId", "services", "capacity",
    "plannedThisJob", "plannedOverall",
    "scheduledThisJob", "scheduledOverall",
    "timeUsedThisJob", "timeUsedOverall"
  ],
  permissions: [],
  components: {
    CapacityMonthPopup,
    VChart,
  },
  computed: {
    serviceIds: function () {
      const ids = []
      for (const service of this.services) {
        ids.push(service.service_id)
      }
      return ids
    },
    chartOptions: function () {
      // this.plannedThisJob contains all months from the project start date till the end of planning
      const relevantMonthStrings = this.plannedThisJob ? Object.keys(this.plannedThisJob) : Object.keys(this.plannedOverall)
      const xAxis = []

      for (const monthStr of relevantMonthStrings) {
        xAxis.push(formatMonth(monthStr))
      }

      // This Job Planned
      const plannedThisJob = []
      if (this.plannedThisJob) {
        for (const monthStr of relevantMonthStrings) {
          let total = 0
          for (const serviceIdx in this.services) {
            const service = this.services[serviceIdx]
            if (monthStr in this.plannedThisJob && service.service_id in this.plannedThisJob[monthStr]) {
              total += this.plannedThisJob[monthStr][service.service_id]
            }
          }
          plannedThisJob.push({value: Math.round(total), custom: {monthStr: monthStr}})
        }
      }

      // This Job Scheduled
      const scheduledThisJob = []
      if (this.scheduledThisJob) {
        for (const monthStr of relevantMonthStrings) {
          let total = 0
          for (const serviceIdx in this.services) {
            const service = this.services[serviceIdx]
            if (monthStr in this.scheduledThisJob && service.service_id in this.scheduledThisJob[monthStr]) {
              total += this.scheduledThisJob[monthStr][service.service_id]
            }
          }
          scheduledThisJob.push({value: Math.round(total), custom: {monthStr: monthStr}})
        }
      }

      // All Jobs Planned
      const plannedOverall = [] // array items are one per month
      let totalPlannedGoingForward = 0
      for (const monthStr of relevantMonthStrings) {
        let total = 0
        for (const serviceIdx in this.services) {
          const service = this.services[serviceIdx]

          if (monthStr in this.plannedOverall && service.service_id in this.plannedOverall[monthStr]) {
            total += this.plannedOverall[monthStr][service.service_id]
          }
        }

        if (!this.isHistorical(monthStr)) {
          totalPlannedGoingForward += total
        }

        // subtract 'this' job time from the overall to give the "other" jobs time
        if (this.plannedThisJob) {
          total -= plannedThisJob.find(item => item.custom.monthStr === monthStr).value
        }
        plannedOverall.push({value: Math.round(total), custom: {monthStr: monthStr}})
      }


      // All Jobs Scheduled
      const scheduledOverall = [] // array items are one per month
      for (const monthStr of relevantMonthStrings) {
        let total = 0
        for (const serviceIdx in this.services) {
          const service = this.services[serviceIdx]

          if (monthStr in this.scheduledOverall && service.service_id in this.scheduledOverall[monthStr]) {
            total += this.scheduledOverall[monthStr][service.service_id]
          }
        }

        // subtract 'this' job time from the overall to give the "other" jobs time
        if (this.scheduledThisJob) {
          total -= scheduledThisJob.find(item => item.custom.monthStr === monthStr).value
        }
        scheduledOverall.push({value: Math.round(total), custom: {monthStr: monthStr}})
      }


      // This Job Time Used
      const timeUsedThisJob = []

      if (this.timeUsedThisJob) {
        for (const monthStr of relevantMonthStrings) {
          let total = 0
          if (monthStr in this.timeUsedThisJob) {
            const timeUsed = this.timeUsedThisJob[monthStr]

            for (const serviceIdx in this.services) {
              const service = this.services[serviceIdx]
              if (service.service_id in timeUsed) {
                total += timeUsed[service.service_id]
              }
            }
          }
          if (!this.isHistorical(monthStr)) {
            totalPlannedGoingForward += total
          }

          timeUsedThisJob.push({value: Math.round(total), custom: {monthStr: monthStr}})
        }
      }

      // All Jobs Time Used
      const timeUsedOverall = []
      for (const monthStr of relevantMonthStrings) {
        let total = 0
        if (monthStr in this.timeUsedOverall) {
          const timeUsed = this.timeUsedOverall[monthStr]
          for (const serviceIdx in this.services) {
            const service = this.services[serviceIdx]
            if (service.service_id in timeUsed) {
              total += timeUsed[service.service_id]
            }
          }

        }

        // subtract 'this' job time from the overall to give the "other" jobs time
        if (this.timeUsedThisJob) {
          const nextId = timeUsedOverall.length
          total -= timeUsedThisJob[nextId].value
        }
        timeUsedOverall.push({value: Math.round(total), custom: {monthStr: monthStr}})
      }
      const legend = [null, null, null, null, null, null, null, null] // place things in the legend in the right place as needed then filter out the nulls
      const plannedAll = (this.plannedThisJob) ? "Planned all other jobs" : "Planned"
      const scheduledAll = (this.scheduledThisJob) ? "Scheduled all other jobs" : "Scheduled"
      const usedAll = (this.timeUsedThisJob) ? "Actual all other jobs" : "Actual"
      legend[1] = plannedAll
      legend[3] = scheduledAll
      legend[5] = usedAll

      const series = [{
        name: plannedAll,
        type: "bar",
        data: plannedOverall,
        stack: "stack1",
        itemStyle: {
          color: "#FEC7A8"
        }
      },
        {
          name: scheduledAll,
          type: "bar",
          data: scheduledOverall,
          stack: "stack3",
          itemStyle: {
            color: "#99ee99"
          }
        },
        {
          name: usedAll,
          type: "bar",
          data: timeUsedOverall,
          stack: "stack2",
          itemStyle: {
            color: "#92C6FF"
          }
        }
      ]

      if (this.plannedThisJob) {
        series.push({
              name: "Planned this Job",
              type: "bar",
              data: plannedThisJob,
              stack: "stack1",
              itemStyle: {
                color: "#FF8138"
              },
            },
            {
              name: "Scheduled this Job",
              type: "bar",
              data: scheduledThisJob,
              stack: "stack3",
              itemStyle: {
                color: "#50e250"
              }
            },
            {
              name: "Actual this Job",
              type: "bar",
              data: timeUsedThisJob,
              stack: "stack2",
              itemStyle: {
                color: "#1D19E7"
              }

            })
        legend[0] = "Planned this Job"
        legend[2] = "Scheduled this Job"
        legend[4] = "Actual this Job"
      }

      // Capacity
      const capacityDataUpper = []
      const capacityDataLower = []
      const capacityMonthStrings = Object.keys(this.capacity)

      for (const monthStr of relevantMonthStrings) {
        if (capacityMonthStrings.includes(monthStr)) {
          const total = this.calculateUpperCapacity(monthStr)
          const reservedCapacity = (100 - this.capacity[monthStr].reserved) / 100
          capacityDataUpper.push(Math.round(total))
          capacityDataLower.push(Math.round(total * reservedCapacity))
        } else {
          capacityDataUpper.push(null)
          capacityDataLower.push(null)
        }
      }

      series.push(
          {
            name: "Maximum Capacity",
            type: "line",
            data: capacityDataUpper,
            step: "middle",
            showSymbol: false,
            lineStyle: {
              color: "#f00"
            },
            itemStyle: {
              color: "#f00"
            },
          },
          {
            name: "Capacity",
            type: "line",
            data: capacityDataLower,
            step: "middle",
            showSymbol: false,
            lineStyle: {
              color: "#77aa00"
            },
            itemStyle: {
              color: "#77aa00"
            },
          })

      legend[6] = "Capacity"
      legend[7] = "Maximum Capacity"

      // grey historical
      let startMarkArea = ""
      let endMarkArea = ""

      for (const monthStr of relevantMonthStrings) {
        if (this.isHistorical(monthStr)) {
          if (!startMarkArea) {
            startMarkArea = formatMonth(monthStr)
          }
          endMarkArea = formatMonth(monthStr)
        }
      }
      series[0].markArea = {
        silent: true,
        itemStyle: {
          color: "#eee",
          opacity: 0.5
        },
        data: [[
          {xAxis: startMarkArea},
          {xAxis: endMarkArea}
        ]]
      }

      return {
        legend: {
          data: legend.filter(function (el) {
            return el != null
          }),
          top: "30"
        },
        title: {
          text: `Planned time: ${Math.round(totalPlannedGoingForward)}hours`,
          left: "center"
        },
        grid: {
          top: "10%",
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow"
          }
        },
        xAxis: {
          type: "category",
          data: xAxis,
          axisLabel: {
            interval: 0,
            rotate: 30
          }
        },
        yAxis: {
          type: "value",
          //   min: 0,
        },
        series: series
      }
    },
  },
  methods: {
    chartClick(evt) {
      if (evt.data.custom) {
        this.showPopup = true
        this.selectedMonth = evt.data.custom.monthStr
        this.selectedCapacity = this.calculateUpperCapacity(this.selectedMonth)
      }
    },
    calculateUpperCapacity(monthStr) {
      let total = 0
      const capacityMonthStrings = Object.keys(this.capacity)
      if (capacityMonthStrings.includes(monthStr)) {
        for (const serviceIdx in this.services) {
          const service = this.services[serviceIdx]
          if (service.service_id in this.capacity[monthStr].capacity) {
            total += this.capacity[monthStr].capacity[service.service_id]
          }
        }
      }
      return total
    },
    isHistorical(monthStr) {
      const parts = monthStr.split("-")
      const dataYear = parseInt(parts[0])
      const dataMonth = parseInt(parts[1])
      const now = new Date()
      const currentYear = now.getFullYear()
      const currentMonth = now.getMonth() + 1
      const c = currentYear * 12 + currentMonth
      const d = dataYear * 12 + dataMonth
      return d < c
    },
  },
  data() {
    return {
      showPopup: false,
      selectedServices: [],
      selectedMonth: "",
      selectedCapacity: 0
    }
  },
})

</script>

<style scoped>
.chart {
  height: 600px;
}

</style>