<template>
  <BfwComponent :isReady="isReady" :title="title" :items-per-page="15" :showTitle="showTitle" :editable="editable" :hideCard="true" loaderType="list-item">

    <v-btn @click="loadStatementLines" color="primary" fab dark class="mt-9" top right absolute>
      <v-icon>mdi-refresh</v-icon>
    </v-btn>

    <v-progress-circular indeterminate size="50" width="10" color="primary" v-if="downloading"></v-progress-circular>

    <v-data-table :headers="headers" :items="statementLines" :items-per-page="100" dense :sort-by="['date']" :sort-desc="true"
                  :expanded.sync="expanded" item-key="id" show-expand expand-icon="mdi-expand-all">

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="expansion">
          <v-row class="headings">
            <v-col>Description</v-col>
            <v-col>Job</v-col>
            <v-col>Amount</v-col>
            <v-col>Markup</v-col>
            <v-col>Price</v-col>
            <v-col>Profit</v-col>
            <v-col>Cost Code</v-col>
          </v-row>
          <div v-for="cost in item.costs" v-bind:key="cost.id" class="background">
            <v-row>
              <v-col>{{ cost.description }}</v-col>
              <v-col>{{ jobs[cost.job] }}</v-col>
              <v-col>{{ cost.amount|formatCurrency(2) }}</v-col>
              <v-col>{{ cost.markup }}%</v-col>
              <v-col>{{ cost.price|formatCurrency(2) }}</v-col>
              <v-col>{{ cost.profit|formatCurrency(2) }}</v-col>
              <v-col>{{ wfmCostOptions[cost.wfm_cost_code] }}</v-col>

            </v-row>
          </div>
        </td>
      </template>

      <template v-slot:item.amount="{ item }">
        {{ item.chargeable_amount|formatCurrency(2) }}
        <div v-if="item.includes_gst">+GST</div>
      </template>

      <template v-slot:item.staff="{ item }">
        {{ staff[item.staff] }}
      </template>


      <template v-slot:item.date="{ item }">
        <div class="text-no-wrap">{{ new Date(item.date)|formatDate }}</div>
      </template>

      <template v-slot:item.supplier="{ item }">
        {{ suppliers[item.supplier] }}
      </template>

      <template v-slot:item.actions="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn @click="viewUpload(item.id, $event)" fab v-bind="attrs" v-on="on" x-small color="grey" class="ma-1">
              <v-icon>
                mdi-paperclip
              </v-icon>
            </v-btn>

          </template>
          <span>View Upload</span>
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn :href="`https://go.xero.com/AccountsPayable/View.aspx?InvoiceID=${item.xero_link}`"
                   target="_blank" fab v-bind="attrs" v-on="on" x-small color="blue" class="ma-1"
                   v-if="item.xero_link">
              <v-icon>
                mdi-calculator
              </v-icon>
            </v-btn>

          </template>
          <span>View in Xero</span>
        </v-tooltip>
      </template>

      <template v-slot:item.audit="{ item }">
        <v-chip color="primary" v-if="item.audit === 1">OK</v-chip>
        <v-chip color="grey" v-else-if="item.audit === 0">Not yet audited</v-chip>
        <v-chip color="accent" v-else-if="item.audit === -1">Audit failed</v-chip>
      </template>

    </v-data-table>

    <v-dialog v-model="viewUploadDialog">
      <v-card class="text-center iframe-wrapper">
        <v-toolbar dark color="primary">
          <v-spacer></v-spacer>
          <v-btn icon dark @click="viewUploadDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <iframe v-if="src" :src="src" class="iframe"/>
        <div v-else>
          <v-progress-circular indeterminate size="100" color="primary"></v-progress-circular>
        </div>
      </v-card>
    </v-dialog>

  </BfwComponent>
</template>

<script>
import BfwComponent from "../BwfComponent"
import {toLocalString} from "@/lib/dateTimeUtilities"

export default {
  name: "CostTransactionReconciled",
  permissions: ["Read Job", "Read Staff", "Read Supplier", "Read Cost", "Modify Job Cost"],
  props: ["jobId", "value"],
  components: {
    BfwComponent,
  },
  methods: {
    viewUpload(costId, event) {
      if (event.ctrlKey === false) this.viewUploadDialog = true //open dialog before loading but only if ctrl is not held down
      this.src = null
      this.downloading = true
      this.$BwfApi.get(`cost/${costId}/view-upload`, {}, {responseType: "blob"})
          .then(response => {
            const objectUrl = window.URL.createObjectURL(response)
            if (event.ctrlKey) { // download
              const anchor = document.createElement("a")
              document.body.appendChild(anchor)
              anchor.target = "_blank"
              anchor.href = objectUrl
              anchor.click()

            } else {
              this.src = objectUrl
            }
            setTimeout(() => window.URL.revokeObjectURL(objectUrl), 100)
            this.downloading = false
          })
    },
    loadStatementLines() {
      this.$BwfApi.get("costs/statement/load", {status: 2}).then(response => {
        this.statementLines = response
      })
    },
    loadJobs() {
      const endDate = new Date()
      const startDate = new Date()
      startDate.setFullYear(startDate.getFullYear() - this.$WfmApi.lookback)
      const start = toLocalString(startDate).substr(0, 10).replace(/-/g, "")
      const end = toLocalString(endDate).substr(0, 10).replace(/-/g, "")
      const query = {"from": start, "to": end, detailed: true}

      this.$WfmApi.get("job.api/list", query).then(response => this.processLoadJobsResponse(response))
    },
    processLoadJobsResponse(response) {
      this.jobs = {}

      const jobs = this.$ensureArray(response.Jobs.Job)
      for (const job of jobs) {
        this.jobs[job.ID] = `[${job.ID}]  ${job.Client.Name} - ${job.Name}`
      }
    },
    loadSuppliers() {
      this.$WfmApi.get("supplier.api/list").then(response => this.processSuppliersResponse(response))
    },
    processSuppliersResponse(response) {
      this.suppliers = {}
      const suppliers = this.$ensureArray(response.Suppliers.Supplier)
      for (const supplier of suppliers) {
        this.suppliers[supplier.UUID] = supplier.Name
      }
    },
    loadStaff() {
      this.$WfmApi.get("staff.api/list").then(response => this.processStaffResponse(response))
    },
    processStaffResponse(response) {
      this.staff = {}
      const staffList = this.$ensureArray(response.StaffList.Staff)
      for (const staff of staffList) {
        this.staff[staff.UUID] = staff.Name
      }
    },
    loadCostOptions() {
      this.$WfmApi.get("cost.api/list", {page: 1}).then(response => {
        this.$BwfApi.get("cost/codes").then(response2 => {
          const enabledCostOptions = response2
          this.wfmCostOptions = []
          const costOptions = this.$ensureArray(response.Costs.Cost)
          for (const costOption of costOptions) {
            if (costOption.Code && enabledCostOptions.includes(costOption.Code)) {
              this.wfmCostOptions[costOption.Code] = `${costOption.Code} - ${costOption.Description}`
            }
          }
        })
      })
    },
  },

  mounted() {
    this.loadStatementLines()
    this.loadJobs()
    this.loadSuppliers()
    this.loadStaff()
    this.loadCostOptions()
  },
  data() {
    return {
      title: "Cost Allocation Table",
      showTitle: false,
      editable: false,
      isReady: true,
      valid: true,
      headers: [
        {text: "Date", value: "date"},
        {text: "Bank Statement ID", value: "id"},
        {text: "Description", value: "description"},
        {text: "Amount", value: "amount"},
        {text: "Supplier", value: "supplier"},
        {text: "Staff", value: "staff"},
        {text: "Notes", value: "notes"},
        {text: "Audit", value: "audit"},
        {text: "", value: "actions"},
      ],
      statementLines: [],
      expanded: [],
      jobs: {},
      staff: {},
      suppliers: {},
      wfmCostOptions: {},
      src: null,
      viewUploadDialog: false,
      downloading: false
    }
  },
}

</script>

<style scoped>

.headings {
  font-weight: bold;
}


.expansion {
  padding: 0 0 8px 0 !important;
}

.background:nth-child(even) {
  background-color: #EFF7DE;
}

.background:nth-child(odd) {
  background-color: #D1E6A1;
}

.iframe-wrapper {
  width: 100%;
  height: 80vh;
  overflow: hidden;
  padding: 0 !important;
}

.iframe {
  width: 100%;
  height: 90%;
  border: 0;
}
</style>