<template>
  <v-container fluid>
    <h1>Jobs Time Planner</h1>
    <v-row>
      <v-col cols="12">
        <JobsNavigator @jobChanged="jobChanged"></JobsNavigator>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <JobsTimePlanner :jobId="selectedJob" @updatedTasks="tasks=$event"></JobsTimePlanner>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import JobsNavigator from "../components/jobs/JobsNavigator"
import JobsTimePlanner from "../components/job/JobTimePlanner"

export default {
  name: "JobTimePlannerPage",
  permissions: [...JobsTimePlanner.permissions, ...JobsNavigator.permissions],
  components: {
    JobsTimePlanner, JobsNavigator
  },
  methods: {
    jobChanged: function (jobId) {
      this.selectedJob = jobId
    }
  },
  mounted() {
  },
  data() {
    return {
      selectedJob: "",
      tasks: []
    }
  },
}

</script>

<style scoped>

</style>