<template>
  <v-container fluid>
    <h1>Cost Importer</h1>
    <p>This tool makes it easy to convert bank statement lines into entries which can be reconciled against with Workflow Max and Xero.</p>
    <p>Note: holding down the ctrl key while viewing an invoice will cause it to open in a new tab</p>
    
    <v-tabs v-model="tab" background-color="primary" centered dark icons-and-text @change="reloadAll">
      <v-tab>1. Bank Statement
        <v-icon>mdi-bank</v-icon>
      </v-tab>
      <v-tab>2. Match Transactions
        <v-icon>mdi-compare-horizontal</v-icon>
      </v-tab>
      <v-tab>3. Reconcile
        <v-icon>mdi-alpha-w</v-icon>
      </v-tab>
      <v-tab>4. Reconciled
        <v-icon>mdi-check</v-icon>
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item key="bank-statement">
        <v-card class="pa-2 mb-5">
          <h2>Bank Statement</h2>
          <p>Select a bank statement excel file from Xero which contains unreconciled transactions then choose the bank statement lines
            which need to be matched to invoices.</p>


          <v-dialog v-model="dialog" width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark v-bind="attrs" v-on="on">
                Import Bank Statement
              </v-btn>
            </template>

            <v-card>
              <v-card-title class="text-h5 grey lighten-2">
                Bank Statement Importer
              </v-card-title>

              <v-card-text>
                <CostImporterBankStatementImport @imported="reloadAll" ref="bankStatement"/>
              </v-card-text>

            </v-card>
          </v-dialog>
          <h2>Unmatched Bank Statement Lines</h2>
          <CostImporterBankStatementList ref="bankStatementList"></CostImporterBankStatementList>
        </v-card>
      </v-tab-item>

      <v-tab-item key="matchTransactions">
        <v-card class="pa-2">
          <h2>Match Transactions</h2>
          <p>Match transactions with actual invoices.</p>
          <CostTransactionMatching ref="matchTransactions"/>
        </v-card>
      </v-tab-item>

      <v-tab-item key="wfm">
        <v-card class="pa-2">
          <h2>Reconcile</h2>
          <p>Review transaction matching details and reconcile to WorkFlow Max.</p>
          <CostImporterReconcile ref="reconcile"/>
        </v-card>
      </v-tab-item>

      <v-tab-item key="done">
        <v-card class="pa-2">
          <h2>Reconciled</h2>
          <p>Transactions which have been sent to WorkFlow Max.</p>
          <CostTransactionReconciled ref="reconciled"/>
        </v-card>
      </v-tab-item>
    </v-tabs-items>


  </v-container>
</template>

<script>
import CostImporterBankStatementImport from "@/components/costs/CostImporterBankStatementImport.vue"
import CostImporterReconcile from "@/components/costs/CostImporterReconcile.vue"
import CostTransactionMatching from "@/components/costs/CostTransactionMatching.vue"
import CostImporterBankStatementList from "@/components/costs/CostImporterBankStatementList.vue"
import CostTransactionReconciled from "@/components/costs/CostTransactionReconciled.vue"

export default {
  name: "CostImporterAdministrator",
  permissions: [...CostImporterBankStatementImport.permissions, ...CostTransactionMatching.permissions, ...CostImporterReconcile.permissions],
  components: {
    CostTransactionReconciled,
    CostImporterBankStatementList,
    CostTransactionMatching,
    CostImporterReconcile,
    CostImporterBankStatementImport,
  },
  data() {
    return {
      tab: "bank-statement",
      dialog: false
    }
  },
  methods: {
    reloadAll() {
      this.dialog = false
      if (this.$refs.matchTransactions) {
        this.$refs.matchTransactions.loadStatementLines() // refresh list
      }
      if (this.$refs.bankStatementList) {
        this.$refs.bankStatementList.loadCosts() // refresh list
      }
      if (this.$refs.reconcile) {
        this.$refs.reconcile.loadStatementLines() // refresh list
      }
      if (this.$refs.reconciled) {
        this.$refs.reconciled.loadStatementLines() // refresh list
      }

    }
  }
}

</script>

<style scoped>

</style>