<template>
  <BfwComponent :isReady="isReady" :title="title" :showTitle="showTitle" :editable="editable" :hideCard="false"
                loaderType="list-item">

    <v-select label="Snapshot" :items="snapshots" v-model="selectedSnapshot" @change="loadSnapshot(selectedSnapshot)"></v-select>

    <div v-if="selectedSnapshot">
      <v-simple-table>
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-left">
            </th>
            <th class="text-right">
              Quoted
            </th>
            <th class="text-right">
              Invoiced
            </th>
            <th class="text-right">
              Estimated Billing
            </th>
          </tr>
          </thead>
          <tbody>

          <template v-for="(inner1,jobType)  in data">
            <tr :key="jobType">
              <th class="level-1">{{ jobType }}</th>
              <td class="text-right"></td>
              <td class="text-right"></td>
              <td class="text-right"></td>
            </tr>

            <template v-for="(inner2,billingType)  in inner1">
              <tr :key="billingType+jobType">
                <th class="">{{ billingType }}</th>
                <td class="text-right">{{ calculateBillingType(billingType, jobType, "quoted") | formatCurrency }}</td>
                <td class="text-right">{{ calculateBillingType(billingType, jobType, "invoiced") | formatCurrency }}</td>
                <td class="text-right">{{ calculateBillingType(billingType, jobType, "estimated_billings") | formatCurrency }}</td>
              </tr>
            </template>
            <tr :key="jobType+'Total'">
              <td class="text-left bold level-2">{{ jobType }} Total</td>
              <td class="text-right bold">{{ calculateJobType(jobType, "quoted") | formatCurrency }}</td>
              <td class="text-right bold">{{ calculateJobType(jobType, "invoiced") | formatCurrency }}</td>
              <td class="text-right bold">{{ calculateJobType(jobType, "estimated_billings") | formatCurrency }}</td>
            </tr>
          </template>
          <tr>
            <th class="level-1">Grand Total</th>
            <td class="text-right bold">{{ calculateAll("quoted") | formatCurrency }}</td>
            <td class="text-right bold">{{ calculateAll("invoiced") | formatCurrency }}</td>
            <td class="text-right bold">{{ calculateAll("estimated_billings") | formatCurrency }}</td>
          </tr>
          </tbody>
        </template>

      </v-simple-table>
      <p>Note: Estimated billings is Quoted minus Invoiced (except for jobs where Invoiced > Quoted)</p>
    </div>
    <v-switch v-model="detail" label="Show Detail"></v-switch>

    <v-data-table :headers="headers" :items="detailSummary" v-if="selectedSnapshot && detail">
      <template v-slot:item.quoted="{item}">
        {{ item.quoted | formatCurrency }}
      </template>
      <template v-slot:item.invoiced="{item}">
        {{ item.invoiced | formatCurrency }}
      </template>
      <template v-slot:item.estimatedBillings="{item}">
        {{ item.estimatedBillings | formatCurrency }}
      </template>
    </v-data-table>
  </BfwComponent>
</template>

<script>
import BfwComponent from "../BwfComponent"
import {defineComponent} from "vue"


export default defineComponent({
  name: "EstimatedBilling",
  permissions: ["Administer BetaWorkflow"],
  props: ["months"],
  components: {
    BfwComponent
  },
  computed: {
    detailSummary() {
      const rows = []
      for (const jobType in this.data) {
        for (const billingType in this.data[jobType]) {
          for (const item in this.data[jobType][billingType]) {
            rows.push({
              jobName: this.getJobName(this.data[jobType][billingType][item]["job_id"]),
              jobType: jobType,
              billingType: billingType,
              quoted: this.data[jobType][billingType][item]["quoted"],
              invoiced: this.data[jobType][billingType][item]["invoiced"],
              estimatedBillings: this.data[jobType][billingType][item]["estimated_billings"]
            })
          }
        }
      }
      return rows
    }
  },
  mounted() {
    Promise.all([
      this.loadSnapshots(),
      this.loadJobs()
    ]).then(() => {
      this.isReady = true
    })
  },
  methods: {
    calculateBillingType(billingType, jobType, field) {
      if (this.data[jobType] && this.data[jobType][billingType]) {
        let total = 0
        for (const row of this.data[jobType][billingType]) {
          if (row[field] > 0) { // ignore negative values
            total += row[field]
          }
        }
        return total
      }
      return 0
    },
    calculateJobType(jobType, field) {
      if (this.data[jobType]) {
        let total = 0
        console.log(jobType, this.data[jobType])
        for (const billingType in this.data[jobType]) {
          for (const row of this.data[jobType][billingType]) {
            if (row[field] > 0) { // ignore negative values
              total += row[field]
            }
          }
        }
        return total
      }
      return 0
    },
    calculateAll(field) {
      let total = 0
      for (const jobType in this.data) {
        for (const billingType in this.data[jobType]) {
          for (const row of this.data[jobType][billingType]) {
            if (row[field] > 0) { // ignore negative values
              total += row[field]
            }
          }
        }
      }
      return total
    },
    loadJobs() {
      return this.$WfmApi.get("job.api/current").then(response => {
        this.jobs = this.$ensureArray(response.Jobs.Job)
        this.isReady = true
      })
    },
    getJobName(jobId) {
      for (const job of this.jobs) {
        if (job.ID === jobId) {
          return `[${job.ID}] ${job.Client.Name} - ${job.Name}`
        }
      }
      return jobId
    },
    loadSnapshots() {
      return this.$BwfApi.get("estimated-billings/snapshots").then(response => {
        const snapshots = []
        for (const snapshot of response) {
          snapshots.push({
            text: snapshot.substring(0, 16),
            value: snapshot
          })
        }
        this.snapshots = snapshots
      })
    },
    loadSnapshot(snapshot) {
      this.$BwfApi.get("estimated-billings/snapshot", {snapshot: snapshot}).then(response => {
        const result = {} // {job_type: {billing_type: row}}}
        for (const row of response) {
          const jobType = row.job_type ? row.job_type : "Unknown"
          const billingType = row.billing_type ? row.billing_type : "Unknown"
          if (!result[jobType]) {
            result[jobType] = {}
          }
          if (!result[jobType][billingType]) {
            result[jobType][billingType] = []
          }
          result[jobType][billingType].push(row)
        }
        this.data = result
      })
    }
  },
  data() {
    return {
      title: "Estimated Billing Report",
      showTitle: true,
      editable: false,
      isReady: false,
      selectedSnapshot: undefined,
      snapshots: [],
      detail: false,
      data: {},
      headers: [
        {text: "Job", value: "jobName"},
        {text: "Job Type", value: "jobType"},
        {text: "Billing Type", value: "billingType"},
        {text: "Quoted", value: "quoted"},
        {text: "Invoiced", value: "invoiced"},
        {text: "Estimated Billing", value: "estimatedBillings"},
      ]
    }
  },
})

</script>

<style scoped>
.level-1 {
  font-weight: bold;
  font-size: 1.2em !important;
  padding-left: 0 !important;
}

.level-2 {
  font-weight: bold;
  font-size: 1em !important;
  padding-left: 0.5em !important;
}

.bold {
  font-weight: bold;
}

.review-table th {
  padding: 0 10px 0 10px;
  position: sticky;
  text-align: right;
  top: 65px;
  border-bottom: 1px #ccc solid;
}
</style>