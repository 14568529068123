<template>
  <BfwComponent :isReady="isReady" :title="title" :showTitle="showTitle" :editable="editable">
    <div class="name">Costs</div>
    <CopyButton>
      <div>
        {{invoiceData.toInvoiceCostsDollars|formatCurrency}}
      </div>
    </CopyButton>
    <br/>
    <CopyButton>
      <div>
        <div v-for="(item, idx) in invoiceData.costDescriptions" v-bind:key="idx">
          <div class="log">{{ item }}</div>
        </div>
      </div>
    </CopyButton>
  </BfwComponent>
</template>

<script>
import BfwComponent from "../BwfComponent"
import CopyButton from "@/components/toolkit/CopyButton"

export default {
  name: "JobsInvoiceCostsSummary",
  permissions: [],
  props: ["invoiceData"],
  components: {
    CopyButton,
    BfwComponent,
  },
  methods: {

  },
  mounted() {
  },
  computed: {},
  data() {
    return {
      title: "Job Invoice Cost Summary",
      showTitle: false,
      editable: false,
      job: {},
      quote: {},
      logs: [],
      isReady: true,
      costsAmount:0
    }
  }
}

</script>

<style scoped>
.name {
  font-weight: bold
}
</style>

