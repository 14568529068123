<template>
  <v-container fluid>
    <h1>Job Forecast</h1>

    <v-row>
      <v-col cols="6">
        <JobsNavigator v-model="jobId" @jobChanged="jobChanged"></JobsNavigator>
        <div v-if="isReady">
          <div>
            <span class="font-weight-bold">Original Due Date: </span>
            <CustomField type="job" :typeUUID="job.ID" :fieldUUID="settings.originalDueDateUUID" :edit="false"
                         :editIfEmtpy="true" :view="true" :key="job.ID"/>
          </div>

          <div>
            <span class="font-weight-bold">Expected Due Date: </span>
            <span v-if="job">
        {{ (new Date(job.DueDate))  | dateFormat("D MMM YYYY") }}
          </span>
          </div>

          <div>
            <span class="font-weight-bold">Last Updated: </span>
            <span v-if="bwfJob">
        {{ (new Date(bwfJob.capacity_updated))  | dateFormat("D MMM YYYY") }}
          </span>
            <span v-else>
                Never
              </span>
          </div>
        </div>

      </v-col>

      <v-col cols="6">
        <JobBasicInfo :jobId="jobId"></JobBasicInfo>

        <div class="save-area mt-2">
          <v-alert type="info" v-if="isChanged" class="text-right">
            <p>Changes have been made</p>
            <v-btn color="accent" @click="save" class="ma-3">Save now</v-btn>
            <v-btn color="warning" @click="reset" class="ma-3">Reset all</v-btn>
          </v-alert>
        </div>
      </v-col>
    </v-row>


    <v-row v-if="isReady">
      <v-col>
        <h3>{{ soFarThisMonth }} hours used so far this month</h3>

        <BarScroll :key="job.ID" :jobId="job.ID" :services="jobServices" v-model="jobPlannedCapacityData"
                   :timeUsedThisJob="timeUsedThisJob"
                   :expectedDueDate="shortenDate(job.DueDate)" :originalDueDate="shortenDate(originalDueDate)"
                   @changed="barScrollChanged"
                   @forecastChanged="updateForecast" :forecastData="jobPlannedForecastData" ref="barScroll"
                   :basic="false"></BarScroll>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col class="text-center pa-4">
        <v-progress-circular indeterminate size="150" width="10" color="primary">Loading</v-progress-circular>
      </v-col>
    </v-row>

    <div v-if="isReady &&  jobServices.length > 0">
      <v-row>
        <v-col>
          <h2 class="text-center">Overall</h2>
          <CapacitySummaryGraph :key="job.ID" :services="services" :capacity="capacity"
                                :plannedOverall="allPlannedCapacityData" :plannedThisJob="jobPlannedCapacityData"
                                :scheduledOverall="allScheduledData" :scheduledThisJob="jobScheduledData"
                                :timeUsedOverall="timeUsedOverall" :timeUsedThisJob="timeUsedThisJob"/>
        </v-col>
      </v-row>

      <v-row v-for="service in jobServices" v-bind:key="service.service_id">
        <v-col>
          <h2 class="text-center">{{ service.name }}</h2>
          <CapacitySummaryGraph :key="job.ID" :services="[service]" :capacity="capacity" :jobId="job.ID"
                                :plannedOverall="allPlannedCapacityData" :plannedThisJob="jobPlannedCapacityData"
                                :scheduledOverall="allScheduledData" :scheduledThisJob="jobScheduledData"
                                :timeUsedOverall="timeUsedOverall" :timeUsedThisJob="timeUsedThisJob"/>
        </v-col>
      </v-row>
    </div>

  </v-container>
</template>

<script>


import BarScroll from "@/components/toolkit/BarScroll.vue"
import JobsNavigator from "@/components/jobs/JobsNavigator.vue"
import CapacitySummaryGraph from "@/components/capacity/CapacitySummaryGraph.vue"
import CustomField from "@/components/toolkit/CustomField.vue"
import JobBasicInfo from "@/components/job/JobBasicInfo.vue"
import {formatDate, getDateOfISOWeek, getWeekFraction, getWeekNumber, toLocalString} from "@/lib/dateTimeUtilities"
import {getAllTimeEntries} from "@/lib/lookup"

export default {
  name: "JobCapacityPage",
  permissions: [...CustomField.permissions, ...JobsNavigator.permissions, ...BarScroll.permissions, ...CapacitySummaryGraph.permissions,
    "Read Services", "Modify Capacity Plan", "Read Job", "Read Capacity", "Modify Job", "Read Custom Field",
    "Read Capacity Plan", "Read Time"],
  components: {
    JobBasicInfo,
    CustomField,
    CapacitySummaryGraph,
    JobsNavigator,
    BarScroll,
  },
  mounted() {
    const d = new Date()
    d.setMonth(d.getMonth() - 12) // only look back 12 months
    this.startYear = d.getFullYear()
    this.startMonth = d.getMonth() + 1
    const d2 = new Date()
    d2.setMonth(d.getMonth() + 12) // only look forward 12 months
    this.endYear = d2.getFullYear()
    this.endMonth = d2.getMonth() + 1

    this.loadJobs()
    this.loadTimeUsed()
    this.$BwfApi.get("services", {enabled: true}).then(response => {
      this.services = response
    })
  },
  computed: {
    isReady: function () {
      return this.jobsLoaded && this.jobLoaded && this.bwfJobLoaded && this.originalDueDateLoaded &&
          this.jobCapacityLoaded && this.allPlannedCapacityDataLoaded &&
          this.jobScheduledLoaded && this.allScheduledDataLoaded &&
          this.jobTimeUsedLoaded && this.allTimeUsedLoaded
    },
    soFarThisMonth() {
      const now = new Date()
      const monthStr = now.getFullYear() + "-" + (now.getMonth() + 1).toString().padStart(2, "0")
      let total = 0
      if (monthStr in this.timeUsedThisJob) {
        for (const serviceId in this.timeUsedThisJob[monthStr]) {
          total += this.timeUsedThisJob[monthStr][serviceId]
        }
      }
      return Math.round(total)
    },
  },
  methods: {
    reset() {
      this.$refs.barScroll.reset()
      this.isChanged = false
    },
    isHistorical(monthStr) {
      const parts = monthStr.split("-")
      const dataYear = parseInt(parts[0])
      const dataMonth = parseInt(parts[1])
      const now = new Date()
      const currentYear = now.getFullYear()
      const currentMonth = now.getMonth() + 1
      const c = currentYear * 12 + currentMonth
      const d = dataYear * 12 + dataMonth
      return d < c
    },
    updateForecast(forecastData) {
      this.jobPlannedForecastData = forecastData
    },
    save() {
      const toSaveLabour = {}
      for (const monthStr in this.jobPlannedCapacityData) {
        if (!this.isHistorical(monthStr)) {
          toSaveLabour[monthStr] = this.jobPlannedCapacityData[monthStr]
        }
      }

      const toSaveForecast = {}
      for (const monthStr in this.jobPlannedForecastData) {
        if (!this.isHistorical(monthStr)) {
          toSaveForecast[monthStr] = this.jobPlannedForecastData[monthStr]
        }
      }

      this.$BwfApi.post("capacity-plan/job", {
        job_id: this.job.ID,
        plan: toSaveLabour,
        forecast: toSaveForecast
      }).then(() => {
        this.isChanged = false
        this.saveJob()
        this.$notify.toast("Saved")
      })
    },
    saveJob: function () {
      const data = {
        Job: {
          ID: this.job.ID,
          Name: this.job.Name,
          StartDate: this.job.StartDate.substr(0, 10).replace(/-/g, ""),
          DueDate: this.job.DueDate.substr(0, 10).replace(/-/g, ""),
          ClientNumber: this.job.ClientOrderNumber
        }
      }
      this.$WfmApi.put("job.api/update", data).then(() => {
        this.$notify.toast("Success")
      })
    }
    ,
    loadBwfJob(jobId) {
      this.bwfJobLoaded = false
      this.$BwfApi.get("job", {job_id: jobId}).then((response) => {
        this.bwfJob = response
        this.bwfJobLoaded = true

      })
    },
    loadJobs() {
      this.jobsLoaded = false
      const endDate = new Date()
      const startDate = new Date()
      startDate.setFullYear(startDate.getFullYear() - this.$WfmApi.lookback)
      const start = toLocalString(startDate).substr(0, 10).replace(/-/g, "")
      const end = toLocalString(endDate).substr(0, 10).replace(/-/g, "")
      const query = {"from": start, "to": end, detailed: true}

      this.$WfmApi.get("job.api/list", query).then(response => {
        this.jobs = this.$ensureArray(response.Jobs.Job)
        this.jobsLoaded = true
      })
    },
    loadOriginalDueDate() {
      this.originalDueDateLoaded = false
      this.originalDueDate = null
      this.$WfmApi.get("job.api/get/" + this.jobId + "/customfield", {}).then((response) => {
        this.originalDueDateLoaded = true
        if (response.CustomFields) {
          const customFields = this.$ensureArray(response.CustomFields.CustomField)
          customFields.forEach(field => {
            if (field.UUID === this.settings.originalDueDateUUID) {
              this.originalDueDate = field.Date
            }
          })
        }
      })
    },
    initializeChartData() {
      // services template
      const servicesTemplate = {}
      for (const service of this.jobServices) {
        servicesTemplate[service.service_id] = 0
      }

      // build obj of months
      const startYear = parseInt(this.job.StartDate.substring(0, 4))
      const startMonth = parseInt(this.job.StartDate.substring(5, 7))
      const start = startYear * 12 + startMonth

      const endYear = parseInt(this.job.DueDate.substring(0, 4))
      const endMonth = parseInt(this.job.DueDate.substring(5, 7))
      const end = endYear * 12 + endMonth

      const labourMonths = {}
      const forecastMonths = {}
      let month = startMonth
      let year = startYear
      for (let i = start; i <= end; i++) {
        if (month === 13) {
          month = 1
          year += 1
        }
        const monthStr = year.toString() + "-" + month.toString().padStart(2, "0")
        labourMonths[monthStr] = servicesTemplate
        forecastMonths[monthStr] = {labour: 0, materials: 0}
        month += 1
      }

      this.jobPlannedCapacityData = labourMonths
      this.jobPlannedForecastData = forecastMonths
    },
    loadJobCapacityData() {
      this.jobCapacityLoaded = false
      this.$BwfApi.get("capacity-plan/job", {job_id: this.job.ID}).then(response => {
        for (const monthString in response.labour) {
          for (const serviceId in response.labour[monthString]) {
            const month = {...this.jobPlannedCapacityData[monthString]}
            month[serviceId] = response.labour[monthString][serviceId]
            this.$set(this.jobPlannedCapacityData, monthString, month)
          }
        }

        for (const monthString in response.forecast) {
          const month = {...this.jobPlannedForecastData[monthString]}
          month['labour'] = response.forecast[monthString]['labour']
          month['materials'] = response.forecast[monthString]['materials']
          this.$set(this.jobPlannedForecastData, monthString, month)
        }
        this.jobCapacityLoaded = true
      })
    },
    loadAllPlannedCapacityData() {
      this.allPlannedCapacityDataLoaded = false
      this.$BwfApi.get("capacity-plan/all", {startMonth: this.startMonth, startYear: this.startYear}).then(response => {
        this.allPlannedCapacityDataLoaded = true
        for (const monthString in response) {
          for (const serviceId in response[monthString]) {
            const month = {...this.allPlannedCapacityData[monthString]}
            month[serviceId] = response[monthString][serviceId]
            this.$set(this.allPlannedCapacityData, monthString, month)
          }
        }
      })
    },
    ////
    loadJobScheduledData() {
      this.jobScheduledLoaded = false
      this.$BwfApi.get("staff-plan/job", {job_id: this.job.ID}).then(response => {
        const result = {}
        for (const task of response) {
          if (task.service_id === -1) { // Other jobs
            continue
          }
          const date = getDateOfISOWeek(task.week, task.year)
          const month = date.getMonth() + 1
          const fraction = getWeekFraction(month, task.week, task.year)
          const monthStr = task.year + "-" + month.toString().padStart(2, "0")
          if (!result[monthStr]) {
            result[monthStr] = {}
          }
          if (!result[monthStr][task.service_id]) {
            result[monthStr][task.service_id] = 0
          }
          result[monthStr][task.service_id] += task.allocated_hours * fraction

          // if the week crosses a month boundary, add the fraction to the next month as well
          date.setDate(date.getDate() + 6)
          const eowMonth = date.getMonth() + 1
          if (eowMonth !== month) {
            const fraction = getWeekFraction(eowMonth, task.week, task.year)
            const monthStr = task.year + "-" + eowMonth.toString().padStart(2, "0")
            if (!result[monthStr]) {
              result[monthStr] = {}
            }
            if (!result[monthStr][task.service_id]) {
              result[monthStr][task.service_id] = 0
            }
            result[monthStr][task.service_id] += task.allocated_hours * fraction
          }

        }
        this.jobScheduledData = result
        this.jobScheduledLoaded = true
      })
    },
    loadAllScheduledData() {
      this.allScheduledDataLoaded = false
      const periods = []
      const d = new Date(this.startYear, this.startMonth - 1, 1)
      const end = new Date(this.endYear, this.endMonth, 1).getTime()
      while (d.getTime() < end) {
        const {week, year} = getWeekNumber(d)
        const period = year + "-" + week.toString().padStart(2, "0")
        periods.push(period)
        d.setDate(d.getDate() + 7)
      }

      this.$BwfApi.get("staff-plan", {staff_uuid: "all", periods: periods.join(",")}).then(response => {
        this.allScheduledDataLoaded = true
        const result = {}
        for (const task of response) {
          if (task.service_id === -1) { // Other jobs
            continue
          }
          const date = getDateOfISOWeek(task.week, task.year)
          const month = date.getMonth() + 1
          const fraction = getWeekFraction(month, task.week, task.year)
          const monthStr = task.year + "-" + month.toString().padStart(2, "0")
          if (!result[monthStr]) {
            result[monthStr] = {}
          }
          if (!result[monthStr][task.service_id]) {
            result[monthStr][task.service_id] = 0
          }
          result[monthStr][task.service_id] += task.allocated_hours * fraction

          // if the week crosses a month boundary, add the fraction to the next month as well
          date.setDate(date.getDate() + 6)
          const eowMonth = date.getMonth() + 1
          if (eowMonth !== month) {
            const fraction = getWeekFraction(eowMonth, task.week, task.year)
            const monthStr = task.year + "-" + eowMonth.toString().padStart(2, "0")
            if (!result[monthStr]) {
              result[monthStr] = {}
            }
            if (!result[monthStr][task.service_id]) {
              result[monthStr][task.service_id] = 0
            }
            result[monthStr][task.service_id] += task.allocated_hours * fraction
          }

        }

        this.allScheduledData = result
      })
    },
    ////
    loadTimeUsed() { // load all time entries
      this.allTimeUsedLoaded = false
      const to = new Date()
      const from = new Date()
      from.setYear(from.getFullYear() - 2) // only look back 2 year for time entries

      getAllTimeEntries(this, from, to).then(timeEntries => {
        const timeUsedOverall = {}
        for (const item of timeEntries) {
          const hours = parseInt(item.Minutes) / 60
          const monthStr = this.shortenDate(item.Date)
          const taskUuid = this.findTaskTypeUuid(item.Job.ID, item.Task.UUID)
          if (!taskUuid) continue

          const service = this.findService(taskUuid)
          if (!service) continue

          if (!(monthStr in timeUsedOverall)) {
            timeUsedOverall[monthStr] = {}
          }
          if (!(service.service_id in timeUsedOverall[monthStr])) {
            timeUsedOverall[monthStr][service.service_id] = 0
          }
          timeUsedOverall[monthStr][service.service_id] += hours
        }

        this.timeUsedOverall = timeUsedOverall
        this.allTimeUsedLoaded = true
      })
    },
    loadJobTimeUsed() { // load all time entries
      this.jobTimeUsedLoaded = false
      const now = new Date()
      const to = formatDate(now)
      now.setYear(now.getFullYear() - this.$WfmApi.lookback) // only look back 1 year for time entries
      const from = formatDate(now)
      this.$WfmApi.get("time.api/job/" + this.job.ID, {from: from, to: to}).then(response => {
        const timeUsedThisJob = {}
        if (response.Times) {
          const timeEntries = this.$ensureArray(response.Times.Time)
          for (const item of timeEntries) {
            const hours = parseInt(item.Minutes) / 60
            const monthStr = this.shortenDate(item.Date)
            const taskTypeUuid = this.findTaskTypeUuid(item.Job.ID, item.Task.UUID)
            if (!taskTypeUuid) {
              continue
            }

            const service = this.findService(taskTypeUuid)
            if (!service) {
              continue
            }

            if (!(monthStr in timeUsedThisJob)) {
              timeUsedThisJob[monthStr] = {}
            }
            if (!(service.service_id in timeUsedThisJob[monthStr])) {
              timeUsedThisJob[monthStr][service.service_id] = 0
            }
            timeUsedThisJob[monthStr][service.service_id] += hours
          }
        }
        this.timeUsedThisJob = timeUsedThisJob
        this.jobTimeUsedLoaded = true
      })
    },
    shortenDate(dateStr) {
      if (!dateStr) return ""
      return dateStr.substring(0, 7)
    },
    barScrollChanged(evt) {
      if (evt && evt.month) {
        //evt.month will be 1 or -1
        const d = new Date(this.job.DueDate)
        d.setMonth(d.getMonth() + evt.month)
        this.job.DueDate = formatDate(d, "-")
      }
      this.isChanged = true
    },
    jobChanged(jobId) {
      this.jobLoaded = false
      this.$WfmApi.get("job.api/get/" + jobId).then(response => {
        this.isChanged = false
        this.jobLoaded = true
        this.job = response.Job
        this.jobId = response.Job.ID
        this.prepareServices(this.job)

        this.loadBwfJob(response.Job.ID)
        this.loadJobCapacityData()
        this.loadJobTimeUsed()
        this.loadOriginalDueDate()
        this.initializeChartData()
        this.loadCapacity()
        this.loadAllPlannedCapacityData()
        this.loadJobScheduledData()
        this.loadAllScheduledData()
      })
    },
    loadCapacity() {
      this.$BwfApi.get("capacity").then(response => {
        const capacity = {}

        response.forEach((row) => {
          const monthStr = row.year.toString() + "-" + row.month.toString().padStart(2, "0")
          const cap = {}
          for (const service_id in row.capacity) {
            const item = row.capacity[service_id]
            cap[item.service_id] = item.capacity
          }
          capacity[monthStr] = {
            reserved: row.reserved_capacity,
            capacity: cap
          }
        })
        this.capacity = capacity
      })
    },
    prepareServices(job) {
      const services = {}
      if (job.Tasks) {
        const tasks = this.$ensureArray(job.Tasks.Task)
        tasks.forEach((task) => {
          const service = this.findService(task.TaskUUID)
          if (service) {
            if (!(task.TaskUUID in services)) {
              services[task.TaskUUID] = {
                name: service.name,
                service_id: service.service_id,
                color_idx: service.sequence,
                estimated: parseInt(task.EstimatedMinutes) / 60
              }
            } else {
              services[task.TaskUUID].estimated += parseInt(task.EstimatedMinutes) / 60
            }
          }
        })
      }

      this.jobServices = Object.values(services)
      this.jobServices.sort((a, b) => {
        return a.color_idx - b.color_idx
      })
    },
    findService(taskTypeUUID) {
      for (const service of this.services) {
        if (service.uuid === taskTypeUUID) {
          return service
        }
      }
      return false
    },
    findTaskTypeUuid(jobId, taskUUID) {
      const job = this.findJob(jobId)
      if (!job) return false
      if (!("Tasks" in job)) return false

      const tasks = this.$ensureArray(job.Tasks.Task)
      for (const task of tasks) {
        if (task.UUID === taskUUID) {
          return task.TaskUUID
        }
      }
      return false
    },
    findJob(JobId) {
      if (!this.jobs) return false

      for (const job of this.jobs) {
        if (job.ID === JobId) {
          return job
        }
      }
      return false
    }
  },
  data() {
    return {
      jobId: "",
      job: null,
      bwfJob: null,
      services: [],
      jobServices: [],
      jobPlannedCapacityData: {},
      allPlannedCapacityData: {},
      jobScheduledData: {},
      allScheduledData: {},
      capacity: {},
      timeUsedOverall: {},
      timeUsedThisJob: {},
      startYear: 0,
      startMonth: 0,
      endYear: 0,
      endMonth: 0,
      isChanged: false,
      originalDueDate: null,
      settings: {
        originalDueDateUUID: "9c483d6b-3c50-44a4-9bfe-904880c11677",
      },
      jobCapacityLoaded: false,
      jobScheduledLoaded: false,
      jobTimeUsedLoaded: false,
      jobsLoaded: false,
      jobLoaded: false,
      originalDueDateLoaded: false,
      allPlannedCapacityDataLoaded: false,
      allScheduledDataLoaded: false,
      allTimeUsedLoaded: false,
      bwfJobLoaded: false,
      jobPlannedForecastData: {},

    }
  },
}

</script>

<style scoped>
.vertical-align {
  line-height: 36px;
  display: inline-block;
}

.jobLink {
  text-decoration: none;
}

.jobHeading {
  display: inline-block;
}

.save-area {
  min-height: 150px;
}
</style>