<template>
  <Field @save="save" :editable="editable">
    <template v-slot:view>
      {{ dueDate | dateFormat("D MMM YYYY") }}
    </template>
    <template v-slot:editModal>
      <v-date-picker v-model="dueDateString"></v-date-picker>
    </template>
  </Field>
</template>

<script>
import Field from "../../Field"
import {toLocalString} from "@/lib/dateTimeUtilities"

export default {
  name: "JobFieldDueDate",
  permissions: ["Modify Job"],
  props: {"job":{}, "editable":{default:true}},
  components: {
    Field
  },
  methods: {
    save: function () {
      const data = {
        Job: {
          ID: this.job2.ID,
          Name: this.job2.Name,
          StartDate: this.job2.StartDate.substr(0, 10).replace(/-/g, ""),
          DueDate: this.dueDateString.replace(/-/g, ""),
          ClientNumber:this.job2.ClientOrderNumber
        }
      }
      this.$WfmApi.put("job.api/update", data).then((response) => this.processResponse(response))
    },
    process() {
      this.dueDate = new Date(this.job2.DueDate)
      this.dueDateString = toLocalString(this.dueDate).substr(0, 10)
    },
    processResponse(response) {
      this.job2 = response.Job
      this.process()
      this.$emit("updated", new Date(this.job2.DueDate))
    }
  },
  mounted() {
    this.job2 = this.$clone(this.job)
    this.process()
  },
  data() {
    return {
      dueDate: new Date(),
      dueDateString: ""
    }
  }
}
</script>