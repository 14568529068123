<template>
  <BfwComponent :isReady="isReady" :title="title" :showTitle="showTitle" :editable="editable" :hideCard="true"
                loaderType="list-item">
    <v-row>
      <v-col cols="12" md="12" class="pb-0 pt-0" v-if="showStaffNavigator">
        <StaffNavigator @staffMemberChanged="updateStaff"></StaffNavigator>
      </v-col>
      <v-col cols="12" md="12" class="pb-0 pt-0">
        <WeekNavigator @input="updateWeek"></WeekNavigator>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-tooltip bottom v-if="currentYear === period.year && period.week >= currentWeekNumber">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" color="primary" @click="copyPreviousWeek" class="mr-2">
              <v-icon>mdi-clipboard-outline</v-icon>
            </v-btn>
          </template>
          <span>Copy incomplete tasks from last week</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" color="primary" @click="breakdownDialog=true" class="mr-2">
              <v-icon>mdi-chart-pie</v-icon>
            </v-btn>
          </template>
          <span>Show work breakdown</span>
        </v-tooltip>

        <div class="total ml-3">
          Total this week: {{ currentTotalAll }}hr
        </div>
      </v-col>
    </v-row>

    <!-- Current -->
    <v-row no-gutter v-if="periodLoaded(period.period)">
      <v-col cols="12" sm="4" md="3" lg="2" v-for="priority in priorities" v-bind:key="priority">
        <div :class="'mb-5 plan '+priorityClasses[priority]">
          <h2>{{ priorityNames[priority] }} ({{ totals[period.period][priority] }}hr)</h2>
          <v-btn fab top right small icon @click="openDialog(priority, period)" color="accent" class="add-button">
            <v-icon>mdi-plus-circle</v-icon>
          </v-btn>
          <draggable :list="taskLists[period.period][priority]" class="draggable" @start="dragStart" @change="dragChange"
                     :clone="dragClone" :group="{ name: 'common', pull: pullFunction }" handle=".handle">

            <div v-for="item in taskLists[period.period][priority]" :key="item.id">
              <WeeklyPlanItem :value="item" mode="edit" :key="item.id" @remove="remove" @input="updateTask"></WeeklyPlanItem>
            </div>
          </draggable>
        </div>
      </v-col>

      <!-- Future -->
      <v-col cols="12" sm="4" md="3" lg="2" v-for="futurePeriod in futurePeriods" v-bind:key="futurePeriod.period">
        <div class="mb-5 plan">
          <h2 v-if="periodLoaded(futurePeriod.period)">
            {{ formatWeekName(futurePeriod.week, futurePeriod.year) }} ({{ totals[futurePeriod.period][2] }}hr)
          </h2>

          <v-btn fab top right small icon @click="openDialog(2, futurePeriod)" color="accent" class="add-button">
            <v-icon>mdi-plus-circle</v-icon>
          </v-btn>
          <draggable :list="taskLists[futurePeriod.period][2]" class="draggable" @start="dragStart" @change="dragChange"
                     :clone="dragClone" :group="{ name: 'common', pull: pullFunction }"
                     v-if="periodLoaded(futurePeriod.period)" handle=".handle">
            <div v-for="item in taskLists[futurePeriod.period][2]" :key="item.id" >
              <WeeklyPlanItem :value="item" mode="edit" :key="item.id" @remove="remove" @input="updateTask"></WeeklyPlanItem>
            </div>
          </draggable>
        </div>
      </v-col>
    </v-row>

    <v-dialog v-model="addItemDialog" max-width="500" v-if="addItemDialog">
      <WeeklyPlanAddItem :newTaskMetadata="newTaskMetadata" @input="newTaskCreated"></WeeklyPlanAddItem>
    </v-dialog>

    <v-dialog v-model="breakdownDialog" v-if="breakdownDialog" fullscreen hide-overlay>
      <v-card style="width:100%">
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="breakdownDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <WeeklyPlanStaffGraph :taskLists="taskLists"></WeeklyPlanStaffGraph>
      </v-card>
    </v-dialog>

  </BfwComponent>
</template>

<script>
import BfwComponent from "../BwfComponent"
import WeeklyPlanItem from "@/components/capacity/WeeklyPlanItem.vue"
import draggable from "vuedraggable"
import WeekNavigator from "@/components/toolkit/WeekNavigator.vue"
import StaffNavigator from "@/components/staff/StaffNavigator.vue"
import WeeklyPlanAddItem from "@/components/capacity/WeeklyPlanAddItem.vue"
import {monthNames} from "@/lib/constants"
import WeeklyPlanStaffGraph from "@/components/capacity/WeeklyPlanStaffGraph.vue"
import {getDateOfISOWeek, getWeekNumber, toLocalString} from "@/lib/dateTimeUtilities"

export default {
  name: "WeeklyPlan",
  permissions: ["Read Staff Plan", "Modify Staff Plan", "Read Services", "Read Job"],
  props: ["showStaffNavigator"],
  components: {
    WeeklyPlanStaffGraph,
    WeeklyPlanAddItem,
    StaffNavigator,
    WeekNavigator,
    WeeklyPlanItem,
    BfwComponent,
    draggable
  },
  computed: {
    currentTotalAll() {
      if (this.totals[this.period.period]) {
        return this.totals[this.period.period][1] + this.totals[this.period.period][2] + this.totals[this.period.period][3]
      }
      return 0
    }
  },
  mounted() {
    const res = getWeekNumber(new Date())
    this.currentWeekNumber = res["week"]
    this.currentYear = res["year"]

    const endDate = new Date()
    const startDate = new Date()
    startDate.setFullYear(startDate.getFullYear() - this.$WfmApi.lookback)
    const start = toLocalString(startDate).substr(0, 10).replace(/-/g, "")
    const end = toLocalString(endDate).substr(0, 10).replace(/-/g, "")
    const query = {"from": start, "to": end}

    Promise.all([
      this.$BwfApi.get("services", {enabled: true}).then(response => this.processServices(response)),
      this.$WfmApi.get("job.api/list", query).then(response => this.processJobsLoad(response)),
    ]).then(() => {
      this.isReady=true
      this.loadPlan(this.period)
    })

  },
  methods: {
    formatWeekName(week, year) {
      const d = getDateOfISOWeek(week, year)
      const startOfWeek = d.getDate()
      const month = monthNames[d.getMonth()]
      let suffix = "th"
      if (startOfWeek === 1 || startOfWeek === 21 || startOfWeek === 31) {
        suffix = "st"
      }
      if (startOfWeek === 2 || startOfWeek === 22) {
        suffix = "nd"
      }
      return `Week of ${startOfWeek}${suffix} ${month}`
    },
    copyPreviousWeek() {
      // ISO weeks can have 52 or 53 weeks so need to calculate this properly to wrap between years
      const d = getDateOfISOWeek(this.currentWeekNumber, this.currentYear)
      d.setDate(d.getDate() - 7)
      const {week, year} = getWeekNumber(d)
      const period = year + "-" + week.toString().padStart(2, "0")
      this.$BwfApi.get("staff-plan", {staff_uuid: this.staff_uuid, periods: period}).then(response => this.processPlanLoad(response, true))

    },
    dragStart({originalEvent}) {
      this.altKeyOnDragStart = originalEvent.altKey
    },
    dragChange(evt) {
      // Triggered on every "add" event. A move event is a combination of remove and add
      let task
      if (evt.added) {
        task = evt.added.element
      } else if (evt.moved) {
        task = evt.moved.element
      } else {
        return
      }

      const {period, priority, task_order} = this.findTask(task.id) // period and priority come from the place in the TaskLists array
      task.period = period // set the task period and priority based on the position in the array
      task.year = period.split("-")[0]
      task.week = period.split("-")[1]
      task.priority = priority
      task.task_order = task_order

      if (this.altKeyOnDragStart) { // If the alt key was held down to create a copy, this is where the database reference is removed
        task.staff_plan_id = null // saved in the DB
        task.editing = false
      }
      this.updateTask(task)
    },
    pullFunction() {
      // Used to determine if the task should be moved or cloned
      return this.altKeyOnDragStart ? "clone" : true
    },
    dragClone(task) {
      // clones for every drag. This will change the internal ID of the task and create a copy.
      // The old one is removed automatically if required if the pullFunction is set to true
      // Clone is called before start so cannot use the altKeyOnDragStart property
      const task2 = {...task} // Create a copy and give it a new Internal ID
      task2.id = Math.round(10000 * Math.random()) // used internally to identify tasks
      return task2
    },
    processJobsLoad(response) {
      this.jobs = this.$ensureArray(response.Jobs.Job)
    },
    processJob(job) {
      this.job = job
      this.availableServiceIds = this.findAvailableServiceIds()
    },
    processServices(response) {
      this.services = response
    },
    periodLoaded(period) {
      return period in this.taskLists
    },
    findAvailableServiceIds() {
      const tasks = new Set()
      if (this.job && this.job.Tasks) {
        for (const task of this.job.Tasks.Task) {
          tasks.add(task.TaskUUID)
        }
      }
      const availableServiceIds = []
      if (this.services) {
        for (const service of this.services) {
          if (tasks.has(service.uuid)) {
            availableServiceIds.push(service.service_id)
          }
        }
      }
      return availableServiceIds
    },
    findService(serviceId) {
      for (const service of this.services) {
        if (service.service_id === serviceId) {
          return service
        }
      }
      return undefined
    },
    calculateUsedTime(serviceUUID) {
      let total = 0
      for (const entry of this.timeEntries) {
        if (entry.Task.UUID === serviceUUID) {
          total += parseInt(entry.Minutes)
        }
      }
      return Math.round(total / 60)
    },
    openDialog(priority, period) {
      this.newTaskMetadata = {
        priority: priority,
        period: period.period,
        year: period.year,
        week: period.week,
        staff_uuid: this.staff_uuid,
      }
      this.addItemDialog = true
    },
    newTaskCreated(task) {
      this.taskLists[task.period][task.priority].push(task)
      const len = this.taskLists[task.period][task.priority].length
      task.task_order = len > 0 ? this.taskLists[task.period][task.priority][len - 1].task_order + 1 : 0
      this.addItemDialog = false
      this.updateTask(task)
      this.calculateTotal()
    },
    findTask(internalId) {
      for (const period in this.taskLists) {
        for (const priority in this.taskLists[period]) {
          for (const idx in this.taskLists[period][priority]) {
            if (this.taskLists[period][priority][idx].id === internalId) {
              const idxA = parseInt(idx) - 1
              const idxB = parseInt(idx) + 1 // don't know idx is a string
              const a = this.taskLists[period][priority][idxA] ? this.taskLists[period][priority][idxA].task_order : 0
              const b = this.taskLists[period][priority][idxB] ? this.taskLists[period][priority][idxB].task_order : 100
              const task_order = (a + b) / 2
              return {period, priority, idx, task_order}
            }
          }
        }
      }
    },
    updateWeek(response) {
      this.period = response
      this.updateFuturePeriods()
      if (this.isReady) {
        this.loadPlan()
      }
    },
    updateFuturePeriods() {
      // future periods
      const futurePeriods = []
      const weekStartDate = getDateOfISOWeek(this.period.week, this.period.year)
      weekStartDate.setDate(weekStartDate.getDate() - weekStartDate.getDay() + 1)
      for (let i = 1; i <= 3; i++) {
        weekStartDate.setDate(weekStartDate.getDate() + 7)
        const {week, year} = getWeekNumber(weekStartDate)
        const period = {
          week: week,
          year: year,
          period: year + "-" + week.toString().padStart(2, "0")
        }
        futurePeriods.push(period)
      }
      this.futurePeriods = futurePeriods
    },
    updateStaff(response) {
      this.staff_uuid = response
      this.loadPlan()
    },
    findJob(jobId) {
      for (const job of this.jobs) {
        if (job.ID === jobId) {
          return job
        }
      }
    },
    loadPlan() {
      const periodsToLoad = []
      periodsToLoad.push(this.period.period)

      for (const period of this.futurePeriods) {
        periodsToLoad.push(period.period)
      }

      this.$BwfApi.get("staff-plan", {staff_uuid: this.staff_uuid, periods: periodsToLoad.join(",")}).then(response => this.processPlanLoad(response))
    },
    processPlanLoad(response, merge = false) {
      let taskLists
      let data
      if (merge) {
        data = []
        taskLists = JSON.parse(JSON.stringify(this.taskLists)) // deep clone
        // adjust the period of the tasks to this week before continuing and filtering to done only
        let taskOrder = 0.01
        for (const item of response) {
          if (!item.done) {
            item.year = this.currentYear
            item.week = this.currentWeekNumber
            item.task_order = taskOrder
            taskOrder += 0.01 // put at the top of the list (or near it)
            data.push(item)
            this.saveTask(item)
          }
        }
      } else {
        data = response
        taskLists = {}
        taskLists[this.period.period] = {1: [], 2: [], 3: []}
        for (const period of this.futurePeriods) {
          taskLists[period.period] = {2: []}
        }
      }

      for (const item of data) {
        const service = this.findService(item.service_id)
        const job = this.findJob(item.job_id)
        const serviceName = (service) ? service.name : ""
        const jobName = (job) ? `${item.job_id} - ${job.Name}` : "Other"

        const task = {
          id: item.staff_plan_id, // internal use only
          staff_plan_id: item.staff_plan_id,
          staff_uuid: item.staff_uuid,
          job_id: item.job_id,
          service_id: item.service_id,
          done: item.done,
          year: item.year,
          week: item.week,
          period: item.year + "-" + item.week.toString().padStart(2, "0"),
          priority: item.priority,
          allocated_hours: item.allocated_hours,
          project: jobName,
          service: serviceName,
          notes: item.notes,
          task_order: item.task_order,
          editing: false,
        }

        taskLists[task.period] = taskLists[task.period] || {}
        taskLists[task.period][task.priority] = taskLists[task.period][task.priority] || []
        taskLists[task.period][task.priority].push(task)
      }

      this.taskLists = taskLists
      this.sortAll()
      this.calculateTotal()
    },
    updateTask(task) {
      const {period, priority, idx} = this.findTask(task.id)
      this.taskLists[period][priority][idx] = task
      this.calculateTotal()
      this.saveTask(task)
    },
    saveTask(task) {
      this.$BwfApi.post("staff-plan/task", task).then(response => {
        task.id = response.staff_plan_id
        task.staff_plan_id = response.staff_plan_id
      })
    },
    remove(task) {
      const {idx} = this.findTask(task.id)
      this.taskLists[task.period][task.priority].splice(idx, 1)
      if (task.staff_plan_id) {
        this.$BwfApi.delete("staff-plan/task", {params: {id: task.staff_plan_id}})
      }
      this.calculateTotal()
    },
    sortAll() {
      for (const period in this.taskLists) {
        for (const priority in this.taskLists[period]) {
          this.taskLists[period][priority].sort((a, b) => {
            return a.task_order - b.task_order
          })
        }
      }
    },
    calculateTotal() {
      const totals = {}
      totals[this.period.period] = {1: 0, 2: 0, 3: 0}
      for (const period of this.futurePeriods) {
        totals[period.period] = {2: 0}
      }
      for (const period in this.taskLists) {
        for (const priority in this.taskLists[period]) {
          for (const idx in this.taskLists[period][priority]) {
            totals[period][priority] += this.taskLists[period][priority][idx].allocated_hours
          }
        }
      }
      this.totals = totals
    },
  }
  ,
  data() {
    return {
      title: "Weekly Plan",
      showTitle: false,
      editable: false,
      isReady: false,
      valid: true,
      jobId: null,
      currentWeekNumber: 0,
      currentYear: 0,
      staff_uuid: this.$access.wfm_uuid,
      period: {year: 0, week: 0, period: "00-00"},
      futurePeriods: [],
      services: [],
      selectedServiceId: undefined,
      capacityData: {},
      job: {},
      jobs: [],
      availableServiceIds: [],
      priorities: [1, 2, 3],
      priorityNames: {1: "Must do", 2: "Normal", 3: "Sometime soon"},
      futureNames: ["Next week", "Week after", "3 Weeks"],
      priorityClasses: {1: "must-do", 2: "normal", 3: "soon"},
      taskLists: {},
      totals: {},
      drag: false,
      addItemDialog: false,
      newTaskMetadata: {},
      altKeyOnDragStart: true,
      breakdownDialog: false
    }
  }
  ,
}

</script>

<style scoped>

.plan {
  display: inline-block;
  width: 100%;
  vertical-align: top;
  padding: 10px;
  background: #eee;
  border-radius: 10px;
  position: relative;
}

.plan h2 {
  text-align: center;
  background: #fff;
  border-radius: 10px;
  margin-bottom: 10px;
}

.total {
  font-weight: bold;
  font-size: larger;
  display: inline-block;
}

.must-do {
  background: #ff000088
}

.normal {
  background: #00800088;
}

.soon {
  background: #FFA50088;
}

.draggable {
  min-height: 100px;
  height: 100%;
}

.add-button {
  position: absolute;
  top: 7px;
  right: 10px;
}

</style>