<template>
  <div>
    <v-card class="mb-3" :class="'card '+doneClass">
      <v-card-text :class="'card-inner overflow-hidden ' + (task.editing ? '' : 'handle')">
        <div v-if="task.editing">

          <div>
            <div class="project-name">{{ task.project }}</div>
            <div class="project-service">{{ task.service }}</div>
            <v-textarea outlined v-model="task.notes" class="notes" rows="3" dense ></v-textarea>
          </div>
          <div>
            <v-text-field label="This week" v-model.number="task.allocated_hours"
                          suffix="hrs" dense style="width:80px; display: inline-block;"></v-text-field>
          </div>

          <div>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn fab top right x-small icon @click="emitSave" class="save-button" v-bind="attrs" v-on="on">
                  <v-icon>mdi-content-save</v-icon>
                </v-btn>
              </template>
              <span>Save</span>
            </v-tooltip>

            <v-tooltip bottom v-if="value.job_id !== '-1'">
              <template v-slot:activator="{ on, attrs }">
                <v-btn fab top right x-small icon @click="dialog=true" class="detail-button" v-bind="attrs" v-on="on">
                  <v-icon>mdi-human-capacity-decrease</v-icon>
                </v-btn>
              </template>
              <span>Detail</span>
            </v-tooltip>

          </div>

          <div v-if="mode!=='add'">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn fab bottom right x-small icon @click="emitDone"
                       class="done-button" :color="task.done ? 'green': ''" v-bind="attrs" v-on="on">
                  <v-icon>mdi-thumb-up</v-icon>
                </v-btn>
              </template>
              <span>{{ task.done ? "Complete" : "Mark complete" }}</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn fab bottom right x-small icon @click="$emit('remove', task); "
                       class="delete-button" color="red" v-bind="attrs" v-on="on">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
              <span>Delete</span>
            </v-tooltip>
          </div>
        </div>

        <div v-else>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn fab top right x-small icon @click="$set(task, 'editing', true)" class="edit-button" v-bind="attrs" v-on="on">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>

            </template>
            <span>Edit</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn fab top right x-small icon @click="dialog=true" class="detail-button" v-bind="attrs" v-on="on"
                     v-if="value.job_id !== '-1'">
                <v-icon>mdi-human-capacity-decrease</v-icon>
              </v-btn>
            </template>
            <span>Detail</span>
          </v-tooltip>

          <div class="project-name">{{ task.project }}</div>
          <div class="project-service">{{ task.service }}</div>

          <div class="allocation">
            <span class="planned-heading">This week:</span>
            <span class="planned-text"> {{ task.allocated_hours }}hrs</span>

          </div>
          <hr class="hr"/>
          <div class="project-notes">{{ task.notes }}</div>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn fab bottom right x-small icon @click="emitDone"
                     class="done-button" :color="task.done ? 'green': ''" v-bind="attrs" v-on="on">
                <v-icon>mdi-thumb-up</v-icon>
              </v-btn>
            </template>
            <span>{{ task.done ? "Complete" : "Mark complete" }}</span>
          </v-tooltip>

        </div>
      </v-card-text>
    </v-card>
    <v-dialog v-model="dialog" max-width="800">
      <v-card>
        <JobMonthProgress :jobId="task.job_id" :monthStr="monthStr"></JobMonthProgress>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>


import JobMonthProgress from "@/components/capacity/JobMonthProgress.vue"

export default {
  name: "WeeklyPlanItem",
  permissions: [],//["Read Task List"],
  props: ["value", "mode"],
  components: {JobMonthProgress},
  computed: {
    doneClass: function () {
      return this.task.done ? "done" : ""
    },
    monthStr() {
      if (this.task.year) {
        return this.task.year.toString() + "-" + '00'
      } else {
        return "00-00" // @todo need this to work on the prorata stuff
      }
    }
  },
  mounted() {
    this.task = {...this.value}
  },
  watch: {
    value: function () {
      this.task = {...this.value}
    }
  },
  methods: {
    emitSave() {
      this.task.editing = false
      this.$emit("input", this.task)
    },
    emitDone() {
      this.task.done = !this.task.done
      this.$emit("input", this.task)
    },
  },
  data() {
    return {
      dialog: false,
      task: {},
    }
  },

}

</script>

<style>
.card {
  border-radius: 10px !important;
}

.handle {
  cursor: grab;
}

.card-inner {
  padding: 5px 5px 5px 10px !important;
  min-height: 120px;
}

.project-name {
  font-weight: bold;
  line-height: 1em;
  font-size: 16px;
}

.project-notes {
}

.project-service {
  font-weight: bold;
  font-size: 14px;
  line-height: 0.95em;
}

.allocation {
  line-height: 0.95em;
}

.hr {
  margin: 5px 40px 5px 40px;
  border: 1px solid #eee;
}

/** Buttons **/

.done-button {
  position: absolute;
  top: 1px;
  right: 1px;
}

.edit-button {
  position: absolute;
  top: 30px;
  right: 1px;
}

.detail-button {
  position: absolute;
  top: 60px;
  right: 1px;
}

.copy-button {
  position: absolute;
  top: 90px;
  right: 1px;
}

.delete-button {
  position: absolute;
  bottom: 35px;
  right: 1px;
}

.save-button {
  position: absolute;
  bottom: 1px;
  right: 1px;
}


.notes {
  padding-right: 30px !important;
}

.planned-heading {
  font-size: 14px;
  font-weight: bold;

}

.planned-text {
  font-size: 14px;

}

.v-label-active {
  left: 0;
  right: auto;
  position: absolute;
  transform: translateY(-18px) scale(0.75);
  transform-origin: top left;
  color: rgba(0, 0, 0, 0.6);
  top: 5px;
}

.done {
  background: #edffed !important;
}


</style>