<template>
  <div class="graph-wrapper">
    <div class="used" :style="usedWidth"></div>
    <div class="budget" :style="budgetWidth"></div>
    <div class="overage" :style="overageWidth"></div>
  </div>
</template>

<script>
  export default {
    name: "OneLineGraph",
    props: {budget: {type:Number, required: true},
            used: {type:Number, required: true},
    },
    components: {

    },
    computed: {
      usedWidth: function() {
        // Used
        return 'width:' + (100 * Math.min(this.used, this.budget) / Math.max(this.used, this.budget)) +'%'
      },
      budgetWidth: function() {
        // Remaining budget
        return 'width:' + (100 * Math.max(0, this.budget - this.used) / Math.max(this.used, this.budget)) +'%'
      },
      overageWidth: function() {
        // Overage
        return 'width:' + (100 * Math.max(0, this.used - this.budget) / Math.max(this.used, this.budget)) +'%'
      },

    },
    mounted() {
    },
    data() {
      return {

      }
    },
  }

</script>

<style scoped>

.graph-wrapper {
  border-radius: 15px;
  border: 1px solid white;
  overflow: hidden;
  min-width: 100px;
  height: 22px;
}

.budget {
  background: grey;
  display: inline-block;
  height: 100%;
}

.used {
  background: green;
  display: inline-block;
  height: 100%;
}

.overage {
  background: red;
  display: inline-block;
  height: 100%;
}
</style>