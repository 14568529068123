<template>
  <v-alert v-model="alert" type="error" dismissible v-if="isReady">
    <ul>
      <li v-if="!quote">Quote not found.</li>
      <li v-if="!manager">Manager not set up.</li>
      <li v-if="!jobType">Job Type not set up.</li>
      <li v-if="!capacity">Capacity not set up.</li>
      <li v-if="!billingType">Billing Type not set up.</li>
      <li v-if="!originalDueDate">Original Due Date not set up.</li>
      <li v-if="!costsDeleted">There are still quoted costs on the job that need to be deleted.</li>
    </ul>
  </v-alert>
</template>

<script>
// Check that the Original Due Date is set, Billing Type is set, Capacity Planning is set, and there is a quote
// And that there are no costs which are dated the date the day the job was created
export default {
  name: "JobSetupCheck",
  permissions: ["Read Job"],
  props: ["jobId"],
  components: {},
  computed: {
    alert: function () {
      return !(this.quote && this.manager && this.jobType && this.capacity && this.billingType && this.originalDueDate && this.costsDeleted)
    }
  },
  methods: {
    reset() {
      this.isReady = false
      this.capacity = false
      this.jobType = false
      this.manager = false
      this.quote = false
      this.billingType = false
      this.originalDueDate = false
    },
    checkCosts() {
      return this.$WfmApi.get("job.api/costs/" + this.jobId).then(response => {
        let costToDelete = false
        if (response.Costs) {
          const costs = this.$ensureArray(response.Costs.Cost)
          for (const cost of costs) {
            if (this.job.DateCreatedUtc.substr(0,10) === cost.Date.substr(0,10)) {
              costToDelete = true
              break
            }
          }
          this.costsDeleted = ! costToDelete
        }
        else {
          this.costsDeleted = true
        }
      })
    }
  },
  mounted() {
  },
  watch: {
    jobId: function () {
      if (this.jobId) {
        this.reset()
        Promise.all([
          this.$WfmApi.get("job.api/get/" + this.jobId).then(response => {
            const job = response.Job
            this.job = job
            if (job.ApprovedQuoteID) {
              this.quote = true
            }
            if (job.Manager) {
              this.manager = true
            }
            if (job.Type) {
              this.jobType = true
            }
          }),

          this.$BwfApi.get("job", {job_id: this.jobId}).then(response => {
            if (response && response.capacity_updated) {
              this.capacity = true
            }
          }),

          this.$WfmApi.get("job.api/get/" + this.jobId + "/customfield", {}).then((response) => {
            if (response.CustomFields) {
              const customFields = this.$ensureArray(response.CustomFields.CustomField)
              for (const field of customFields) {
                if (field.UUID === "9c483d6b-411b-4633-8edc-43e0535a2350") {
                  this.billingType = true
                }
                if (field.UUID === "9c483d6b-3c50-44a4-9bfe-904880c11677") {
                  this.originalDueDate = true
                }
              }
            }
          }),


        ]).then(() => {
          this.checkCosts().then(() => {
            this.isReady = true
          })
        })
      }
    }
  },

  data() {

    return {
      isReady: false,
      job: {},
      capacity: false,
      jobType: false,
      manager: false,
      quote: false,
      billingType: false,
      originalDueDate: false,
      costsDeleted: false,

    }
  }
}

</script>

<style scoped>

</style>