<template>
  <v-container fluid>
    <h1>Service Schedule Summary</h1>
    <ReportServiceScheduleSummary></ReportServiceScheduleSummary>
  </v-container>
</template>

<script>


import ReportServiceScheduleSummary from "@/components/capacity/ReportServiceScheduleSummary.vue"

export default {
  name: "ReportServiceScheduleSummaryPage",
  permissions: [],
  components: {ReportServiceScheduleSummary},
  data() {
    return {}
  },
  mounted() {

  },
}

</script>

<style scoped>

</style>