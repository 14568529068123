<template>
  <Field @save="save" :editable="!!edit || (editIfEmtpy && value==='')">
    <template v-slot:view>
      <div v-if="view && loaded" :class="'value '+errorClass">{{ displayValue }}</div>
      <!--      <div v-if="edit" class="placeholder"></div>-->
    </template>
    <template v-slot:editModal>
      <CustomFields :type="type" :typeUUID="typeUUID" :restrict="fieldUUID" ref="customFieldsEdit" @saved="update"/>
    </template>
  </Field>
</template>

<script>
import Field from "../Field"
import CustomFields from "./CustomFields"
import {toLocalString} from "@/lib/dateTimeUtilities"

export default {
  name: "CustomField",
  permissions: [],
  props: ["type", "typeUUID", "fieldUUID", "view", "edit", "editIfEmtpy"],  //eg. type='client' and typeUUID = the clientUUID
  components: {
    Field, CustomFields
  },
  computed: {
    errorClass: function () {
      if (!this.value && this.fieldType !== "Checkbox") {
        return "red--text"
      } else {
        return ""
      }
    },
    fieldType() {
      for (const field of this.customFieldDefinitions) {
        if (field.UUID === this.fieldUUID) {
          return field.Type
        }
      }
      return null
    },
    displayValue: function () {
      if (this.fieldType === "Date" && this.value) {
        return (new Date(this.value)).toLocaleString("en-GB", {month: "short", year: "numeric", day: "numeric"})
      } else if (this.fieldType === "Checkbox") {
        return this.value === "true" ? "Yes" : "No"
      } else if (this.value) {
        return this.value
      } else {
        return "Not Set"
      }
    }
  },
  methods: {
    save: function () {
      if (this.$refs.customFieldsEdit) {
        this.$refs.customFieldsEdit.save()
      }
    },
    update: function (customFields) {
      let value = ""
      customFields.forEach(field => {
        if (field.UUID === this.fieldUUID) {
          if (field.Date) {
            const year = parseInt(field.Date.substr(0, 4))
            const monthIdx = parseInt(field.Date.substr(4, 2)) - 1
            const day = parseInt(field.Date.substr(6, 2))
            value = toLocalString(new Date(year, monthIdx, day)).substr(0, 10)
          } else {
            value = field.Text
                || field.Number
                || field.Decimal
                || field.Boolean
                || ""
          }
        }
      })
      this.value = value
      this.$emit("saved", value)
    },
    load() {
      this.$WfmApi.get("customfield.api/definition", {}).then((response) => {
        this.customFieldDefinitions = this.$ensureArray(response.CustomFieldDefinitions.CustomFieldDefinition)
      })

      if (this.type === "client") {
        this.$WfmApi.get("client.api/get/" + this.typeUUID + "/customfield", {})
            .then((response) => this.processValuesResponse(response))
      } else if (this.type === "contact") {
        this.$WfmApi.get("client.api/contact/" + this.typeUUID + "/customfield", {})
            .then((response) => this.processValuesResponse(response))
      } else if (this.type === "supplier") {
        this.$WfmApi.get("supplier.api/get/" + this.typeUUID + "/customfield", {})
            .then((response) => this.processValuesResponse(response))
      } else if (this.type === "supplier-contact") {
        this.$WfmApi.get("supplier.api/contact/" + this.typeUUID + "/customfield", {})
            .then((response) => this.processValuesResponse(response))
      } else if (this.type === "job") {
        this.$WfmApi.get("job.api/get/" + this.typeUUID + "/customfield", {})
            .then((response) => this.processValuesResponse(response))
      } else if (this.type === "task") {
        this.$WfmApi.get("job.api/task/" + this.typeUUID + "/customfield", {})
            .then((response) => this.processValuesResponse(response))
      } else if (this.type === "cost") {
        this.$WfmApi.get("job.api/cost/" + this.typeUUID + "/customfield", {})
            .then((response) => this.processValuesResponse(response))
      } else if (this.type === "time") {
        this.$WfmApi.get("time.api/get/" + this.typeUUID + "/customfield", {})
            .then((response) => this.processValuesResponse(response))
      } else if (this.type === "lead") {
        this.$WfmApi.get("lead.api/get/" + this.typeUUID + "/customfield", {})
            .then((response) => this.processValuesResponse(response))
      } else {
        console.log("Unknown custom fields source type")
      }
    },
    processValuesResponse(res) {
      const _this = this
      this.loaded = true
      res.CustomFields = res.CustomFields || {} // make sure there is a default
      const defaultSettings = this.$ensureArray(res.CustomFields.CustomField)
      for (let i = 0; i < defaultSettings.length; i++) {
        if (this.fieldUUID === defaultSettings[i].UUID) {
          _this.value = defaultSettings[i].Text
              || defaultSettings[i].Decimal
              || defaultSettings[i].Number
              || defaultSettings[i].Boolean
              || toLocalString(new Date(defaultSettings[i].Date)).substr(0, 10)
              || ""
        }
      }
    }
  },
  mounted() {
    if (this.view) { // only load if needed
      this.load()
    }
  },
  data() {
    return {
      customFieldDefinitions: [],
      value: "",
      loaded: false
    }
  }
}
</script>

<style scoped>
.placeholder {
  width: 17px;
  height: 17px;
  display: inline-block;
}

.value {
  display: inline-block;
}
</style>
