<template>
<div>
  <v-btn color="accent"  dark fab small  @click="dialog=true">
    <v-icon>mdi-calendar-check</v-icon>
  </v-btn>

  <v-dialog v-model="dialog" v-if="dialog" width="500">
    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        Add Task
      </v-card-title>
      <v-card-text>
        <StaffNavigator @staffMemberChanged="newTaskMetadata.staff_uuid = $event"></StaffNavigator>
        <WeeklyPlanAddItem :newTaskMetadata="newTaskMetadata" :defaultJobId="jobId" @input="save"></WeeklyPlanAddItem>
      </v-card-text>
    </v-card>
  </v-dialog>
</div>
</template>

<script>
import WeeklyPlanAddItem from "@/components/capacity/WeeklyPlanAddItem.vue"
import StaffNavigator from "@/components/staff/StaffNavigator.vue"
import {getWeekNumber} from "@/lib/dateTimeUtilities"

export default {
  name: "WeeklyPlanAddItemPopup",
  permissions: [],
  props: ["jobId"],
  components: {
    StaffNavigator,
    WeeklyPlanAddItem,
  },
  methods: {
    save(task) {
      this.$BwfApi.post("staff-plan/task", task).then(() => {
        this.$notify.toast("Task saved")
        this.dialog = false
      })
    },
  },
  data() {
    const now = new Date()
    const {year, week} = getWeekNumber(now)
    const period = year.toString() + "-" + week.toString().padStart(2, "0")
    return {
      dialog: false,
      newTaskMetadata: {
        job_id: this.jobId,
        staff_uuid: null,
        year: year,
        week: week,
        period: period,
        priority: 2
      }
    }
  },
}

</script>

<style scoped>

</style>