<template>
  <BfwComponent :isReady="this.jobs.length > 0" :title="title" :showTitle="showTitle" :editable="editable" :hideCard="true"
                loaderType="list-item">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-row>
        <v-col cols="1">
          <v-btn fab @click="more = !more" color="accent" dark small>
            <v-icon>mdi-dots-horizontal</v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-row v-if="more">
        <v-col cols="12" class="mt-0 ma-0">
          <div class="frameset"><label class="v-label theme--light">Filter</label>
            <v-select dense :items="clients" label="Client" v-model="selectedClients" multiple @input="changeFilter"></v-select>
            <v-select dense :items="jobManagers" label="Job Manager" v-model="selectedManagers" multiple @input="changeFilter" :disabled="!jobManagersFilter"></v-select>
            <v-select dense :items="jobStates" label="Job State" v-model="selectedJobStates" multiple @input="changeFilter" :disabled="!jobStatesFilter"></v-select>
            <v-select dense :items="jobTypes" label="Job Type" v-model="selectedJobTypes" multiple @input="changeFilter" :disabled="!jobTypesFilter"></v-select>
            <v-checkbox v-model="myJobs" label="Only my jobs" dense @click="changeFilter"></v-checkbox>
            <v-checkbox v-model="hasWip" :label="`Has WIP (updated: ${wipUpdated})`" dense @click="changeFilter" v-if="this.accessWIP"></v-checkbox>
          </div>
        </v-col>
      </v-row>
    </v-form>
  </BfwComponent>
</template>

<script>
import BfwComponent from "../BwfComponent"
import {defaultJobTypes, jobStates, defaultJobStates} from "@/lib/constants"
import {getStaffList} from "@/lib/lookup"

export default {
  name: "JobsFilter",
  permissions: ["Read Job"],
  components: {
    BfwComponent
  },
  props: ["value", "state"],
  computed: {
    jobStatesFilter() {
      return !this.hasWip
    },
    jobTypesFilter() {
      return !this.hasWip
    },
    jobManagersFilter() {
      return !this.myJobs
    },
    clients() {
      const clients = this.jobs.map((job) => {
        return job.Client ? {value: job.Client.UUID, text: job.Client.Name} : undefined
      })
      return [...new Set(clients)]
    },
    jobTypes() {
      const jobTypes = this.jobs.map((job) => {
        return job.Type ? {value: job.Type, text: job.Type} : undefined
      })
      return [...new Set(jobTypes)]
    },
    filteredJobs: function () {
      for (const i in this.jobs) {
        const job = this.jobs[i]
        job.exclude = false
        const selectedJobTypes = [...this.selectedJobTypes]
        const index = selectedJobTypes.indexOf("None")
        if (index !== -1) {
          selectedJobTypes.splice(index, 1, undefined)
        }

        if (this.jobTypesFilter) {
          // include if there are no selected job types or the job type is one of those selected, otherwise exclude
          if (!(selectedJobTypes.length === 0 || selectedJobTypes.includes(job.Type))) {
            job.exclude = true
          }
        }

        if (this.jobManagersFilter) {
          // include if there are no selected managers or the manager is one of those selected, otherwise exclude
          if (!(this.selectedManagers.length === 0 || (job.Manager && this.selectedManagers.includes(job.Manager.UUID)))) {
            job.exclude = true
          }
        }

        if (this.myJobs) {
          if (!(job.Manager && job.Manager.UUID === this.$access.wfm_uuid)) {
            job.exclude = true
          }
        }

        if (this.jobStatesFilter) {
          if (!(this.selectedJobStates.length === 0 || this.selectedJobStates.includes(job.State))) {
            job.exclude = true
          }
        }

        if (this.hasWip) {
          if (!this.includesWip(job.ID)) {
            job.exclude = true
          }
        }
      }

      return this.jobs.filter(job => !job.exclude).map(job => job.ID)
    }
  },
  methods: {
    loadJobManagers() {
      this.jobManagers = []
      getStaffList(this, (res) => {
        for (const staff of res) {
          this.jobManagers.push({value: staff.wfm_userid, text: staff.name})
        }
      })
    },
    includesWip(jobId) {
      for (const item of this.wip) {
        if (item.jobId === jobId) return true
      }
      return false
    },
    changeFilter() {
      this.$emit("filterChanged", this.filteredJobs)
    },
  },
  mounted() {
    this.jobs = this.value // link by reference
    this.loadJobManagers()
    if (this.accessWIP) {
      this.$BwfApi.get("wip").then(response => {
        this.wipUpdated = (new Date(response.updated)).toString()
        this.wip = response.data
        this.changeFilter()
      })
    } else {
      this.changeFilter() // trigger initial event
    }
  },
  data() {
    return {
      title: "Job Filter",
      showTitle: false,
      editable: false,
      isReady: true,
      valid: true,
      jobs: [],
      more: (this.state === "open") ? true : false,
      jobManagers:[],
      selectedManagers: [],
      selectedClients: [],
      selectedJobStates: [...defaultJobStates],
      selectedJobTypes: [...defaultJobTypes],
      myJobs: false,
      hasWip: false,
      jobStates: jobStates,
      wipUpdated: "",
      accessWIP: this.$access.permissions.includes("Read WIP"),
    }
  },
}

</script>

<style scoped>
.frameset {
  border: 1px solid lightgrey;
  border-radius: 10px;
  padding: 20px 10px 10px 10px;
  position: relative;
  margin-bottom: 10px;
}

.frameset label {
  top: -10px;
  left: 10px;
  background: white;
  padding: 5px;
  position: absolute;
}
</style>