<template>
  <BfwComponent :isReady="isReady" :title="title" :showTitle="showTitle" :editable="editable" :hideCard="true">
    <v-form ref="form" v-model="valid" lazy-validation :isReady="isReady">
      <v-text-field v-model="name" label="Name" class="required"></v-text-field>
      <v-text-field v-model="website" label="Website"></v-text-field>
      <v-select v-model="region" label="Region" :items="regions"></v-select>
      <v-combobox v-model="referralSource" :items="referralSources" label="Referral Source" class="full-width"></v-combobox>
      <CustomFields type="client" :typeUUID="clientUUID" ref="customFields"/>
      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header>Address</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-textarea v-model="address" label="Address"></v-textarea>
            <v-text-field v-model="city" label="City"></v-text-field>
            <v-select v-model="region" :items="regions" label="Region"></v-select>
            <v-text-field v-model="postCode" label="Post Code"></v-text-field>
            <v-text-field v-model="country" label="Country"></v-text-field>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>Postal Address</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-textarea v-model="postalAddress" label="Postal Address"></v-textarea>
            <v-text-field v-model="postalCity" label="Postal City"></v-text-field>
            <v-select v-model="postalRegion" label="Postal Region" :items="regions"></v-select>
            <v-text-field v-model="postalPostCode" label="Postal Post Code"></v-text-field>
            <v-text-field v-model="postalCountry" label="Postal Country"></v-text-field>
          </v-expansion-panel-content>
        </v-expansion-panel>

      </v-expansion-panels>
    </v-form>
  </BfwComponent>
</template>

<script>
import BfwComponent from "../BwfComponent"
import CustomFields from "../toolkit/CustomFields"
import {leadRegions} from "@/lib/constants"

export default {
  name: "ClientEditor",
  permissions: ["Read Client", "Read Contact", ...CustomFields.permissions],
  props: {
    "value": {type: Object},
  },
  components: {
    BfwComponent, CustomFields,
  },
  methods: {
    saveCustomFields(clientUuid) {
      this.$refs.customFields.save(clientUuid)
    },
    processSourceListResponse(response) {
      // Build the referral source list from existing entries
      const referralSources = new Set()
      const clients = this.$ensureArray(response.Clients.Client)
      for (let i = 0; i < clients.length; i++) {
        if (clients[i].Contacts) {
          clients[i].Contacts.Contact = this.$ensureArray(clients[i].Contacts.Contact)
        } else {
          clients[i].Contacts = {}
          clients[i].Contacts.Contact = []
        }

        if (clients[i].ReferralSource) {
          referralSources.add(clients[i].ReferralSource)
        }
      }
      this.referralSources = Array.from(referralSources)
    },
    getClient: function () { // called by ref to get the data
      const client = {
        uuid: this.UUID,
        name: this.name,
        referralSource: this.referralSource,
        isProspect: this.isProspect ? "Yes" : "No",
        address: this.address,
        city: this.city,
        region: this.region,
        postCode: this.postCode,
        country: this.country,
        postalAddress: this.postalAddress,
        postalCity: this.postalCity,
        postalRegion: this.postalRegion,
        postalPostCode: this.postalPostCode,
        postalCountry: this.postalCountry,
        website: this.website,
      }
      this.$emit("input", client)
      return client
    }
  },
  mounted() {
    this.$WfmApi.get("client.api/list", {detailed: true}).then(response => this.processSourceListResponse(response))
  },
  watch: {
    value: {
      handler: function (val) {
        this.name = val.name
        this.address = val.address
        this.city = val.city
        this.region = val.region
        this.postCode = val.postCode
        this.country = val.country
        this.postalAddress = val.postalAddress
        this.postalCity = val.postalCity
        this.postalRegion = val.postalRegion
        this.postalPostCode = val.postalPostCode
        this.postalCountry = val.postalCountry
        this.website = val.website
        this.isProspect = val.isProspect === "Yes"
        this.referralSource = val.referralSource
      },
      immediate: true
    },
  },
  data() {
    return {
      regions: leadRegions,
      title: "Client",
      showTitle: true,
      editable: false,
      isReady: true,
      valid: true,
      clientUUID: "",
      settings: {},
      referralSources: [],
      name: "",
      address: "",
      city: "",
      region: "",
      postCode: "",
      country: "",
      postalAddress: "",
      postalCity: "",
      postalRegion: "",
      postalPostCode: "",
      postalCountry: "",
      website: "",
      isProspect: true,
      referralSource: "",
    }
  },
}

</script>

<style scoped>
.full-width {
  width: 100%;
  padding: 0;
}
</style>