<template>
  <v-container fluid>
    <h1>Jobs Audit Report</h1>
    <v-row>
      <v-col cols="12">
        <JobsAuditReport></JobsAuditReport>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import JobsAuditReport from "../components/jobs/JobsAuditReport"

export default {
  name: "JobsAuditReportPage",
  permissions: [...JobsAuditReport.permissions],
  components: {
    JobsAuditReport
  },
  methods: {},
  mounted() {
  },
  data() {
    return {}
  },
}

</script>

<style scoped>

</style>