<template>
  <v-container fluid>
    <h1>Users</h1>
    <v-data-table :headers="headers" :items="staff" dense disable-pagination hide-default-footer>
      <template v-slot:item.is_working="{ item }">
        <v-checkbox v-model="item.is_working"></v-checkbox>
      </template>

      <template v-slot:item.roles="{ item }">
        <v-select v-model="item.roles" :items="roles" multiple item-text="name" item-value="role_id" :disabled="!item.bwf_userid" persistent-hint
                  :hint="(!item.bwf_userid) ? 'Staff member has not yet logged into BetaWorkflow so cannot be allocated a role':''"></v-select>
      </template>
    </v-data-table>

    <v-btn @click="save" color="primary" class="ma-1">
      <v-icon>
        mdi-content-save
      </v-icon>
      Save
    </v-btn>
  </v-container>
</template>

<script>

export default {
  name: "AdminUsers",
  permissions: ["Administer Betaworkflow"],
  components: {},

  data() {
    return {
      title: "Admin - Users",
      roles: [],
      headers: [
        {text: "Is Working", value: "is_working"},
        {text: "User", value: "Name"},
        {text: "Roles", value: "roles"},
      ],
      staff: [],
    }
  },
  methods: {
    save() {
      const data = this.staff.filter(item => {
        return !!item.bwf_userid
      }).map(item => {
        return {
          bwf_userid: item.bwf_userid,
          roles: item.roles,
          wfm_uuid: item.UUID,
          is_working: item.is_working || false
        }
      })
      this.$BwfApi.post("admin/users", data).then(() => {
        this.$notify.toast("Saved")
      })
    }
  },
  mounted() {
    this.$BwfApi.get("admin/roles").then(response => {
      this.roles = response

      this.$WfmApi.get("staff.api/list").then(response => {
        this.staff = this.$ensureArray(response.StaffList.Staff)
        this.$BwfApi.get("admin/users").then(response => {
          for (const staffIdx in this.staff) {
            const staff = this.staff[staffIdx]
            for (const UserIdx in response) {
              const user = response[UserIdx]
              if (user.email === staff.Email) {
                staff.roles = user.roles
                staff.bwf_userid = user.bwf_userid
                staff.is_working = user.is_working
                this.$set(this.staff, staffIdx, staff)
              }
            }
          }
        })
      })
    })
  },
}

</script>

<style scoped>

</style>