<template>
  <div>
    <v-textarea label="Email from contact form" v-model="emailContents"/>
    <v-btn @click="processEmail">Import</v-btn>
  </div>
</template>

<script>
import CustomFields from "@/components/toolkit/CustomFields"
import ContactEditor from "@/components/contacts/ContactEditor"
import ContactsEditor from "@/components/contacts/ContactsEditor"

export default {
  name: "ClientImporter",
  permissions:[...CustomFields.permissions, ...ContactEditor.permissions, ...ContactsEditor.permissions],
  data() {
    return {
      emailContents: "",
    }
  },
  methods: {
    processEmail: function () {
      const regexName = /NAME\t(.*)$/gmi
      const regexCompany = /COMPANY\t(.*)$/gmi
      const regexPosition = /ROLE\/POSITION\t(.*)$/gmi
      const regexEmail = /EMAIL\t(.*)$/gmi
      const regexContactMethod = /HOW DID YOU HEAR ABOUT US\?\t(.*)$/gmi
      const regexPhone = /TELEPHONE\t(.*)$/gmi
      const regexCountry = /ADDRESS\t(.*)$/gmi
      const regexLocation = /REGION\/COUNTRY\t(.*)$/gmi

      const name = regexName.exec(this.emailContents)
      const company = regexCompany.exec(this.emailContents)
      const position = regexPosition.exec(this.emailContents)
      const email = regexEmail.exec(this.emailContents)
      const contactMethod = regexContactMethod.exec(this.emailContents)
      const phone = regexPhone.exec(this.emailContents)
      const country = regexCountry.exec(this.emailContents)
      const location = regexLocation.exec(this.emailContents)

      const result = {}
      result.name = (name && name.length === 2) ? name[1] : ""
      result.company = (company && company.length === 2) ? company[1] : ""
      result.position = (position && position.length === 2) ? position[1] : ""
      result.email = (email && email.length === 2) ? email[1] : ""
      result.contactMethod = (contactMethod && contactMethod.length === 2) ? contactMethod[1] : ""
      result.phone = (phone && phone.length === 2) ? phone[1] : ""
      result.country = (country && country.length === 2) ? country[1] : ""
      result.location = (location && location.length === 2) ? location[1] : ""

      this.$emit('import', result)
    }
  }
}
</script>

<style scoped>

</style>