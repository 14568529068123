<template>
  <v-container fluid>
    <h1>Leads Dashboard</h1>
    <p>The current state of the leads pipeline</p>
    <LeadsDashboard/>
    <h1>Leads KPIs</h1>
    <p>Current and Historical metrics of the leads pipeline</p>
    <LeadsKpiGraphs/>
  </v-container>
</template>

<script>

import LeadsDashboard from "@/components/leads/LeadsDashboard.vue"
import LeadsKpiGraphs from "@/components/leads/LeadsKpiGraphs.vue"

export default {
  name: "ReportKPIPage",
  permissions: [ ...LeadsKpiGraphs.permissions, ...LeadsDashboard.permissions],
  components: { LeadsKpiGraphs, LeadsDashboard},
  data() {
    return {}
  },
  mounted() {

  },
}

</script>

<style scoped>

</style>