<template>
  <v-container fluid>
    <h1>Workload Report</h1>
    <v-menu ref="menu" v-model="menu" :close-on-content-click="false"  transition="scale-transition"
            offset-y max-width="290px" min-width="auto">

      <template v-slot:activator="{ on, attrs }">
        <v-text-field v-model="months" label="Picker in menu" prepend-icon="mdi-calendar"
                      readonly v-bind="attrs" v-on="on"></v-text-field>
      </template>

      <v-date-picker v-model="months" type="month" no-title scrollable range>
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="menu = false"> Cancel</v-btn>
        <v-btn text color="primary" @click="months2 = [...months]; $refs.menu.save(months)"> OK</v-btn>
      </v-date-picker>
    </v-menu>

    <WorkloadReport :months="months2"></WorkloadReport>
  </v-container>
</template>

<script>


import WorkloadReport from "@/components/capacity/WorkloadReport.vue"

export default {
  name: "ReportWorkloadPage",
  permissions: [...WorkloadReport.permissions],
  components: {WorkloadReport},
  data() {
    return {
      months: [],
      months2:[],
      menu: false
    }
  },
  mounted() {
    const d = new Date()
    const end = d.getFullYear() + "-" + (d.getMonth() + 1).toString().padStart(2, "0")
    d.setMonth(d.getMonth() - 6)
    const start = d.getFullYear() + "-" + (d.getMonth() + 1).toString().padStart(2, "0")
    this.months = [start, end]
    this.months2 = [start, end]
  },
}

</script>

<style scoped>

</style>