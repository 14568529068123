<template>
  <BfwComponent :isReady="isReady" :title="title" :showTitle="showTitle" :editable="editable" :hideCard="true" loaderType="list-item">

    <v-btn @click="loadStatementLines" color="primary" fab dark class="mt-9" top right absolute>
      <v-icon>mdi-refresh</v-icon>
    </v-btn>

    <v-progress-circular indeterminate size="50" width="10" color="primary" v-if="downloading"></v-progress-circular>

    <v-data-table :headers="headers" :items="statementLines" :items-per-page="-1" dense :sort-by="['date']" sort-desc hide-default-footer
                  :single-expand="true" :expanded.sync="expanded" item-key="id" show-expand expand-icon="mdi-expand-all">

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="expansion">
          <div v-for="(cost, costIdx) in item.costs" v-bind:key="cost.id" class="background">
            <CostTransactionMatchingForm v-model="item.costs[costIdx]" :wfmCostOptions="wfmCostOptions" :xeroCostOptions="xeroCostOptions" :jobs="jobs"
                                         :statementLine="item" @input="calculateCostsTotal(item); checkComplete(item)"></CostTransactionMatchingForm>
          </div>
          <div class="background">
            <v-row>
              <v-col cols="4" class="text-right">Total</v-col>
              <v-col cols="2">
                NZ{{ item.calclulateTotal | formatCurrency(2) }}
              </v-col>
              <v-col>

                <div class="red--text" v-if="(Math.abs(item.calclulateTotal - item.chargeable_amount )) >= 0.01">
                  Total differs from the bank statement by {{ (item.calclulateTotal - item.chargeable_amount) |formatCurrency(2) }}
                </div>
              </v-col>
            </v-row>
          </div>
        </td>
      </template>

      <template v-slot:item.date="{ item }">
        <div class="text-no-wrap">{{ new Date(item.date)|formatDate }}</div>
      </template>

      <template v-slot:item.amount="{ item }">
        {{ item.chargeable_amount|formatCurrency(2) }}
        <div v-if="item.includes_gst">+GST</div>
      </template>

      <template v-slot:item.includes_gst="{ item }">
        <v-checkbox v-model="item.includes_gst" dense @click="updateChargeableAmount(item)"></v-checkbox>
      </template>

      <template v-slot:item.staff="{ item }">
        <v-autocomplete label="Staff" :items="staff" v-model="item.staff" dense :rules="[isNotEmpty]"></v-autocomplete>
      </template>

      <template v-slot:item.supplier="{ item }">
        <div style="display:flex">
          <v-autocomplete label="Supplier" :items="suppliers" v-model="item.supplier" dense class="inline" @input="checkComplete(item)" :rules="[isNotEmpty]"></v-autocomplete>
          <v-btn @click="supplierDialog=true" color="primary" fab dark x-small class="ma-1 inline">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </div>
      </template>

      <template v-slot:item.notes="{ item }">
        <v-textarea v-model="item.notes" dense @blur="checkComplete(item)"></v-textarea>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-file-input hide-input @change="handleFileUpload(item, $event)" class="file-picker" v-bind="attrs" v-on="on"
                          @mouseenter.native="on.mouseenter" @mouseleave.native="on.mouseleave"
                          prepend-icon="mdi-receipt-text-plus" dark :rules="[isNotEmpty]"/>
          </template>
          <span>Upload Invoice</span>
        </v-tooltip>

        <v-tooltip top v-if="item.file">
          <template v-slot:activator="{ on, attrs }">
            <v-btn @click="viewUpload(item.id,$event)" fab v-bind="attrs" v-on="on" x-small color="grey" class="ma-1">
              <v-icon>
                mdi-paperclip
              </v-icon>
            </v-btn>

          </template>
          <span>View Upload</span>
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn @click="split(item.id)" fab v-bind="attrs" v-on="on" dark x-small color="blue" class="ma-1">
              <v-icon>
                mdi-arrow-split-horizontal
              </v-icon>
            </v-btn>
          </template>
          <span>Split Transaction</span>
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn @click="save(item.id, 0)" fab v-bind="attrs" v-on="on" dark x-small color="orange" class="ma-1">
              <v-icon>
                mdi-content-save
              </v-icon>
            </v-btn>
          </template>
          <span>Save</span>
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn @click="save(item.id, 1)" fab v-bind="attrs" v-on="on" :dark="item.isComplete" x-small color="primary"
                   class="ma-1" :disabled="!item.isComplete">
              <v-icon>
                mdi-check
              </v-icon>
            </v-btn>
          </template>
          <span>Complete</span>
        </v-tooltip>

      </template>

    </v-data-table>

    <v-dialog v-model="supplierDialog" width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Add Supplier
        </v-card-title>

        <v-card-text>
          <SupplierEditor @saved="supplierAdded" ref="supplierRef"/>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="$refs.supplierRef.save()">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showSplitDialog" width="700" v-if="showSplitDialog">
      <CostSplitter :statementLine="selectedStatementLine" :jobs="jobs" :key="selectedStatementLine.id"
                    @split="processCostSplit"/>
    </v-dialog>

    <v-dialog v-model="saving" :scrim="false" persistent width="auto">
      <v-card color="primary">
        <v-card-title class="white--text">Saving</v-card-title>
        <v-card-text class="text-center">
          <v-progress-circular indeterminate color="white"></v-progress-circular>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="viewUploadDialog">
      <v-card class="text-center iframe-wrapper">
        <v-toolbar dark color="primary">
          <v-spacer></v-spacer>
          <v-btn icon dark @click="viewUploadDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <iframe v-if="src" :src="src" class="iframe"/>
        <div v-else>
          <v-progress-circular indeterminate size="100" color="primary"></v-progress-circular>
        </div>
      </v-card>
    </v-dialog>

  </BfwComponent>
</template>

<script>
import BfwComponent from "../BwfComponent"
import SupplierEditor from "@/components/supplier/SupplierEditor"
import CostSplitter from "@/components/costs/CostSplitter"
import CostTransactionMatchingForm from "@/components/costs/CostTransactionMatchingForm.vue"
import {isNotEmpty} from "@/lib/validationRules"
import {getStaffList} from "@/lib/lookup"

export default {
  name: "CostTransactionMatching",
  permissions: ["Read Job", "Read Staff", "Read Supplier", "Read Cost", "Modify Job Cost", ...SupplierEditor.permissions],
  props: ["jobId", "value"],
  components: {
    CostTransactionMatchingForm,
    CostSplitter,
    SupplierEditor,
    BfwComponent
  },
  methods: {
    isNotEmpty,
    viewUpload(costId, event) {
      if (event.ctrlKey === false) this.viewUploadDialog = true //open dialog before loading but only if ctrl is not held down
      this.src = null
      this.downloading = true
      this.$BwfApi.get(`cost/${costId}/view-upload`, {}, {responseType: "blob"})
          .then(response => {
            const objectUrl = window.URL.createObjectURL(response)
            if (event.ctrlKey) { // download
              const anchor = document.createElement("a")
              document.body.appendChild(anchor)
              anchor.target = "_blank"
              anchor.href = objectUrl
              anchor.click()

            } else {
              this.src = objectUrl
            }
            setTimeout(() => window.URL.revokeObjectURL(objectUrl), 100)
            this.downloading = false
          })
    },
    calculateCostsTotal(item) {
      let total = 0
      for (const cost of item.costs) {
        total += cost.amount
      }
      item.calclulateTotal = Math.round(100 * total) / 100 // round to 2dp
    },
    updateChargeableAmount(item) {
      item.chargeable_amount = (item.includes_gst) ? Math.round(100 * item.amount / 1.15) / 100 : item.amount
      this.$nextTick(() => {
        //  this.calculateCostsTotal(item)
      })
    },
    checkComplete(item) {
      let complete = true
      if (!item.supplier || !item.file) {
        complete = false
      }

      let total = 0
      for (const cost of item.costs) {
        if (!cost.description || !cost.amount) {
          complete = false
        }
        if (cost.is_project_cost && (!cost.wfm_cost_code || !cost.job)) {
          complete = false
        } else if (!cost.is_project_cost && !cost.xero_cost_code) {
          complete = false
        }

        total += cost.amount
      }

      if (Math.abs(total - item.chargeable_amount) > 0.01) {
        complete = false
      }

      item.isComplete = complete
    },
    processCostSplit(statementLineId, costs) {
      const {idx} = this.findStatementLine(statementLineId)
      this.statementLines[idx].costs = costs
      this.showSplitDialog = false
    },
    loadStatementLines() {
      this.$BwfApi.get("costs/statement/load", {status: 0}).then(response => {
        const statementLines = []
        for (const item of response) {
          if (item.costs.length === 0) {
            item.costs.push({
              id: 0,
              description: "",
              amount: item.chargeable_amount ? item.chargeable_amount : item.amount, // default to amount of chargeable amount not set
              job: "",
              wfm_cost_code: "",
              xero_cost_code: "",
              is_project_cost: true,
              calculatedTotal: 0,
              isComplete: false,
            })
          }
          this.calculateCostsTotal(item)
          this.checkComplete(item)
          statementLines.push(item)
        }
        this.statementLines = statementLines
      })
    },
    loadJobs() {
      this.$WfmApi.get("job.api/current").then(response => this.processLoadJobsResponse(response))
    },
    processLoadJobsResponse(response) {
      this.jobs = []

      const jobs = this.$ensureArray(response.Jobs.Job)
      for (const job of jobs) {
        if (this.settings.jobTypes.includes(job.Type)) {
          this.jobs.push({text: `[${job.ID}]  ${job.Client.Name} - ${job.Name}`, value: job.ID})
        }
      }
    },
    loadSuppliers() {
      this.$WfmApi.get("supplier.api/list").then(response => this.processSuppliersResponse(response))
    },
    processSuppliersResponse(response) {
      this.suppliers = []
      const suppliers = this.$ensureArray(response.Suppliers.Supplier)
      for (const supplier of suppliers) {
        this.suppliers.push({text: supplier.Name, value: supplier.UUID})
      }
    },
    loadStaff() {
      getStaffList(this, (res) => {
        this.staff = []
        for (const staff of res) {
          this.staff.push({text: staff.name, value: staff.wfm_userid})
        }
      })
    },
    loadWfmCostOptions() {
      this.$WfmApi.get("cost.api/list", {page: 1}).then(response => {
        this.$BwfApi.get("cost/codes").then(response2 => {
          const enabledCostOptions = response2
          this.wfmCostOptions = []
          const costOptions = this.$ensureArray(response.Costs.Cost)
          for (const costOption of costOptions) {
            if (costOption.Code && enabledCostOptions.includes(costOption.Code)) {
              this.wfmCostOptions.push({text: `${costOption.Code} - ${costOption.Description}`, value: costOption.Code})
            }
          }
        })
      })
    },
    loadXeroCostOptions() {
      this.$BwfApi.get("xero/enabled-account-codes").then(response => {
        const enabledCostOptions = response

        this.xeroCostOptions = []
        for (const costOption of enabledCostOptions) {
          this.xeroCostOptions.push({text: `${costOption.code} - ${costOption.name}`, value: costOption.code})
        }
      })
    },
    supplierAdded(supplier) {
      this.suppliers.push({text: supplier.Name, value: supplier.UUID})
      this.supplierDialog = false
    },
    handleFileUpload(item, file) {
      for (let i = 0; this.statementLines.length; i++) {
        const line = this.statementLines[i]
        if (line.id === item.id) {
          line.file = file
          this.$set(this.statementLines, i, line)
          break
        }
      }
      this.checkComplete(item)
    },
    save(costId, status) {
      this.saving = true
      const formData = new FormData()
      const {line} = this.findStatementLine(costId)
      const line2 = {...line}
      if (line2.file instanceof File) {
        formData.append("file", line2.file)
        line2.file = null
      }
      line2.status = status
      formData.append("data", JSON.stringify(line2))

      this.$BwfApi.post("costs/match", formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }).then(() => {
        this.$notify.toast("Successfully saved")
        this.saving = false
        this.loadStatementLines()
      })
    },
    split(statementLineId) {
      const {line} = this.findStatementLine(statementLineId)
      this.selectedStatementLine = line
      this.showSplitDialog = true
    },
    findStatementLine(statementLineId) {
      let statementLine = null
      let idx = 0
      for (idx = 0; this.statementLines.length; idx++) {
        const line = this.statementLines[idx]
        if (line.id === statementLineId) {
          statementLine = line
          break
        }
      }
      return {line: statementLine, idx: idx}
    },
  },

  mounted() {
    this.loadStatementLines()
    this.loadJobs()
    this.loadSuppliers()
    this.loadStaff()
    this.loadWfmCostOptions()
    this.loadXeroCostOptions()
  },
  data() {
    return {
      title: "Cost Allocation Table",
      showTitle: false,
      editable: false,
      isReady: true,
      valid: true,
      settings: {
        jobTypes: ["Consulting", "Production"],
      },
      headers: [
        {text: "Date", value: "date"},
        {text: "Bank Statement ID", value: "id"},
        {text: "Description", value: "description"},
        {text: "GST", value: "includes_gst"},
        {text: "Amount", value: "amount"},
        {text: "Staff", value: "staff"},
        {text: "Supplier", value: "supplier"},
        {text: "Notes", value: "notes"},
        {text: "Actions", value: "actions"},
      ],
      statementLines: [],
      expanded: [],
      costs: [],
      jobs: [],
      staff: [],
      suppliers: [],
      wfmCostOptions: [],
      xeroCostOptions: [],
      supplierDialog: false,
      selectedStatementLine: {},
      showSplitDialog: false,
      saving: false,
      viewUploadDialog: false,
      src: null,
      downloading: false
    }
  },
}

</script>

<style scoped>

.inline {
  display: flex;
}

.file-picker {
  display: inline-block;
  border-radius: 50%;
  background-color: #4a148c;
  color: white !important;
  width: 32px;
  height: 32px;
  font-size: 18px;
  padding: 0;
  margin: 0;
  padding-left: 4px;
}

.file-picker >>> .v-input__icon {
  margin: 0;
  height: 20px !important;
  width: 20px !important;
}

.file-picker >>> .v-icon {
  font-size: 18px;
}

.expansion {
  padding: 0 0 8px 0 !important;
}

.background {
  padding-left: 5px;
}

.background:nth-child(even) {
  background-color: #eee;
}

.background:nth-child(odd) {
  background-color: #eee;
}

.background:last-child {
  border-bottom: 2px solid #eee;
}

.iframe-wrapper {
  width: 100%;
  height: 80vh;
  overflow: hidden;
  padding: 0 !important;
}

.iframe {
  width: 100%;
  height: 90%;
  border: 0;
}

</style>