<template>
  <v-card class=" pa-5">
    <div class="available">
      <div class="item">
        <v-btn v-if="isJobTask" small class="service-selector-button-full mb-2" color="primary" @click="jobTaskButtonToggle">
          Job task
          <v-icon class="right">mdi-swap-horizontal</v-icon>
        </v-btn>
        <v-btn v-else small class="service-selector-button-full mb-2" color="accent" @click="jobTaskButtonToggle">
          Non-job-related task
          <v-icon class="right">mdi-swap-horizontal</v-icon>
        </v-btn>

        <JobsSelector v-model="jobId" @jobChanged="processJob" :urlNavigation="false" v-if="isJobTask"/>
        <v-btn-toggle v-model="newTask.service_id" color="primary" dense class="service-selector-group mt-3 mb-3" v-if="isJobTask"
                      @change="showAddTask">

          <v-btn :value="service.service_id" v-for="service in services" v-bind:key="service.service_id" small
                 class="service-selector-button" :disabled="!availableServiceIds.includes(service.service_id)">
            {{ service.name }}
          </v-btn>


        </v-btn-toggle>

        <div v-if="addTask">
          <WeeklyPlanItem v-model="newTask" mode="add" ref="weeklyPlanItem"></WeeklyPlanItem>
        </div>
      </div>
    </div>

    <v-btn @click="save" color="primary" class="full-width" :disabled="!newTask.job_id">Add Task</v-btn>

  </v-card>
</template>

<script>
import JobsSelector from "@/components/jobs/JobsSelector.vue"
import WeeklyPlanItem from "@/components/capacity/WeeklyPlanItem.vue"

export default {
  name: "WeeklyPlanAddItem",
  permissions: ["Read Staff Plan", "Modify Staff Plan", "Read Services", "Read Job"],
  props: ["newTaskMetadata", "defaultJobId"],
  components: {
    WeeklyPlanItem,
    JobsSelector,
  },
  computed: {},
  mounted() {
    this.initializeNewTask()
    Promise.all([
      this.$BwfApi.get("services", {enabled: true}).then(response => this.processServices(response)),
      this.$WfmApi.get("job.api/current").then(response => this.processJobsLoad(response)),
    ]).then(() => {

    })
  },
  methods: {
    jobTaskButtonToggle() {
      this.isJobTask = !this.isJobTask
      this.selectedServiceId = (this.isJobTask) ? 0 : -1
      this.showAddTask(this.selectedServiceId)
    },
    initializeNewTask() {
      this.newTask = {
        id: Math.round(100000 * Math.random()),
        staff_plan_id: null,
        staff_uuid: this.newTaskMetadata.staff_uuid,
        job_id: this.newTaskMetadata.job_id || 0,
        service_id: 0,
        done: false,
        year: this.newTaskMetadata.year,
        week: this.newTaskMetadata.week,
        period: this.newTaskMetadata.period,
        priority: this.newTaskMetadata.priority,
        task_order:0,
        allocated_hours: 0,
        project: "",
        service: "",
        notes: "",
        editing: true,
      }
    },
    save() {
      this.$refs.weeklyPlanItem.emitSave() // call save to ensure the model is updated
      this.$nextTick(() => { // run after the emitSave has been processed
        this.$emit("input", {...this.newTask})
        this.selectedServiceId = null
      })

    },
    processJobsLoad(response) {
      this.jobs = this.$ensureArray(response.Jobs.Job)
    },
    processJob(job) {
      this.$WfmApi.get(`job.api/get/${job.ID}?detailed=true`).then(response => {
        this.job = response.Job
        this.availableServiceIds = this.findAvailableServiceIds()
        this.addTask = false
      })
    },
    processServices(response) {
      this.services = response
    },
    showAddTask(selectedServiceId) {
      this.addTask = true

      if (selectedServiceId === -1) { // other
        this.initializeNewTask()
        this.newTask.job_id = "-1"
        this.newTask.service_id = -1
        this.newTask.project = "Other"
      } else {
        const service = this.findService(selectedServiceId)
        this.initializeNewTask()
        this.newTask.job_id = this.jobId
        this.newTask.project = `${this.jobId} - ${this.job.Name}`
        this.newTask.service_id = service.service_id
        this.newTask.service = service.name
      }
    },
    findAvailableServiceIds() {
      const tasks = new Set()
      if (this.job && "Tasks" in this.job && this.job.Tasks !== null) {
        const jobTasks = this.$ensureArray(this.job.Tasks.Task)
        for (const task of jobTasks) {
          tasks.add(task.TaskUUID)
        }
      }
      const availableServiceIds = []
      if (this.services) {
        for (const service of this.services) {
          if (tasks.has(service.uuid)) {
            availableServiceIds.push(service.service_id)
          }
        }
      }
      return availableServiceIds
    },
    findService(serviceId) {
      for (const service of this.services) {
        if (service.service_id === serviceId) {
          return service
        }
      }
      return undefined
    },
  },
  data() {
    return {
      services: [],
      jobId: this.defaultJobId ||"",
      jobs: [],
      addTask: false,
      newTask: {},
      availableServiceIds: [],
      isJobTask: true
    }
  },
}

</script>

<style scoped>
.background {
  background: #ccc;
}

.service-selector-group {
  flex-wrap: wrap;
}

.service-selector-button {
  width: 50%;
}

@media only screen and (max-width: 600px) {
  .service-selector-button {
    font-size: 65%;
  }
}

.service-selector-button-full {
  width: 100%;
}

.full-width {
  width: 100%
}

.right {
  position: absolute !important;
  right: 0;
}
</style>