<template>
  <div>
    <v-dialog v-model="dialog" width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" dark v-bind="attrs" v-on="on"> Import new WIP</v-btn>
      </template>

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Update WIP
        </v-card-title>

        <v-card-text>
          <p>Open the WIP list screen in WorkflowMax and copy and paste the whole screen into this text field.</p>
          <v-textarea label="WorkflowMax WIP" v-model="rawWip"/>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn href="https://app.workflowmax2.com/organizations/9c483c99-b3b2-4625-bdb2-081145336d38/wip" target="_blank" color="primary">Open WorkflowMax WIP</v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="processWip" color="primary">Update</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <p>WIP Last Updated: {{ updated }}</p>
    <v-data-table dense :headers="headers" :items="wip" :items-per-page="1000">
      <template v-slot:item.time="{ item }">
        <div>{{ item.time|formatCurrency(2) }}</div>
      </template>
      <template v-slot:item.costs="{ item }">
        <div>{{ item.costs|formatCurrency(2) }}</div>
      </template>
      <template v-slot:item.deposits="{ item }">
        <div>{{ item.deposits|formatCurrency(2) }}</div>
      </template>
      <template v-slot:item.wip="{ item }">
        <div>{{ item.wip|formatCurrency(2) }}</div>
      </template>
      <template v-slot:item.estBillings="{ item }">
        <div>{{ item.estBillings|formatCurrency(2) }}</div>
      </template>

    </v-data-table>

  </div>
</template>

<script>

import {toLocalString} from "@/lib/dateTimeUtilities"

export default {
  name: "WIPSetup",
  permissions: ["Read Job"],
  components: {},
  computed: {},
  methods: {
    processWip() {
      let capturing = false
      const lines = this.rawWip.split("\n")
      const rows = []

      // remove items from the top until we find the first "Client"
      while (capturing === false) {
        const item = lines[0]
        if (item === "Client") {
          capturing = true
        } else {
          lines.shift()
        }
      }

      //delete headings
      lines.splice(0, 12) // 12 lines of heading

      while (lines.length >= 2) {
        const resultLine = {}
        resultLine["client"] = lines.shift()
        const ln = lines.shift().split("\t")
        if (ln.length < 5) {
          console.log(resultLine)
          continue
        }
        const job = ln[0].split(" - ")

        resultLine["jobId"] = job[0]
        resultLine["job"] = job[1]
        resultLine["time"] = parseFloat(ln[1].replace(/,/g, ""))
        resultLine["costs"] = parseFloat(ln[2].replace(/,/g, ""))
        resultLine["deposits"] = parseFloat(ln[3].replace(/,/g, ""))
        resultLine["wip"] = parseFloat(ln[4].replace(/,/g, ""))
        resultLine["estBillings"] = 0

        rows.push(resultLine)
        lines.shift()
      }
      this.wip = rows
      this.dialog = false

      this.save()
    },
    save() {
      this.$BwfApi.post("wip", this.wip).then(() => {
        this.$notify.toast("Saved")
        this.loadWip()
      })
    },
    loadWip() {
      this.$BwfApi.get("wip").then(response => {
        this.wip = response.data
        this.updated = (new Date(response.updated)).toString()
        for (const item of this.wip) {
          const job = this.findJob(item.jobId)
          if (job) {
            item.job = job.Name
            item.client = job.Client.Name
          } else {
            item.job = item.jobId
            item.client = ""
          }
        }
      })
    },
    loadJobs() {
      const endDate = new Date()
      const startDate = new Date()
      startDate.setFullYear(startDate.getFullYear() - this.$WfmApi.lookback)
      const start = toLocalString(startDate).substr(0, 10).replace(/-/g, "")
      const end = toLocalString(endDate).substr(0, 10).replace(/-/g, "")
      const query = {"from": start, "to": end}

      this.$WfmApi.get("job.api/list", query).then(response => {
        this.jobs = this.$ensureArray(response.Jobs.Job)
        this.loadWip()
      })
    },
    findJob(JobId) {
      for (const job of this.jobs) {
        if (job.ID === JobId) {
          return job
        }
      }
      return false
    },
  },
  mounted() {
    this.loadJobs()
  },
  data() {
    return {
      dialog: false,
      jobs: [],
      rawWip: "",
      headers: [
        {text: "Client", value: "client"},
        {text: "Job Id", value: "jobId"},
        {text: "Job", value: "job"},
        {text: "Time", value: "time"},
        {text: "Costs", value: "costs"},
        {text: "Deposits", value: "deposits"},
        {text: "WIP", value: "wip"},
        {text: "Est Billings", value: "estBillings"},
      ],
      wip: [],
      updated: "",
      emptyRow: {
        client: "", jobId: "", job: "", time: 0, costs: 0, deposits: 0, wip: 0, estBillings: 0
      }
    }
  }
  ,
}

</script>


<style scoped>
.input {
  width: 100%;
}
</style>