<template>
  <div class="chart-area">
    <v-chart class="chart" :option="chartOptions(services(), 'Service Breakdown')"/>
    <v-chart class="chart" :option="chartOptions(projects(), 'Jobs Breakdown')"/>
  </div>
</template>

<script>
import {use} from "echarts/core"
import {CanvasRenderer} from "echarts/renderers"
import {PieChart} from "echarts/charts"
import {
  LegendComponent,
  GridComponent,
} from "echarts/components"
import VChart from "vue-echarts"
import {defineComponent} from "vue"

use([
  CanvasRenderer,
  PieChart,
  LegendComponent,
  GridComponent,
])
export default defineComponent({
  name: "WeeklyPlanStaffGraph",
  props: ["taskLists"],
  permissions: [],
  components: {
    VChart,
  },
  computed: {},
  methods: {
    services() {
      const services = {}
      for (const period in this.taskLists) {
        for (const priority in this.taskLists[period]) {
          for (const idx in this.taskLists[period][priority]) {
            const task = this.taskLists[period][priority][idx]
            const service = task.service !== "" ? task.service : "Other"
            services[service] = services[service] + task.allocated_hours || task.allocated_hours
          }
        }
      }
      return services
    },
    projects() {
      const projects = {}
      for (const period in this.taskLists) {
        for (const priority in this.taskLists[period]) {
          for (const idx in this.taskLists[period][priority]) {
            const task = this.taskLists[period][priority][idx]
            projects[task.project] = projects[task.project] + task.allocated_hours || task.allocated_hours
          }
        }
      }
      return projects
    },
    chartOptions(data, title) {
      const chartData = []
      for (const k in data) {
        chartData.push({value: data[k], name: k})
      }
      return {
        title: {
          text: title,
          left: "center",
        },
        tooltip: {
          trigger: "item"
        },
        legend: {
          orient: "vertical",
          left: "left"
        },
        series: [
          {
            type: "pie",
            radius: "50%",
            data: chartData,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)"
              }
            }
          }
        ]
      }
    },
  },
  mounted() {
  },
  data() {
    return {}
  },
})

</script>

<style scoped>
.chart-area {

}

.chart {
  height: 45vh;
  width: 90vw;
}
</style>