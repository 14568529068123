import {formatDate} from "@/lib/dateTimeUtilities"

export function getStaffList(vue, callback) {
  vue.$BwfApi.get("staff").then((response) => {
    callback(response)
  })
}

export function getStaffListAll(vue, callback) {
  vue.$BwfApi.get("staff", {current_only: false}).then((response) => {
    callback(response)
  })
}

export function getCategoriesList(vue, callback) {
  vue.$WfmApi.get("category.api/list").then((response) => {
    const categories = (response.Categories) ? vue.$ensureArray(response.Categories.Category) : []
    callback(categories)
  })
}

/**
 * Get all time entries between two dates in chunks of 300 days.
 * Can be used as a promise or with a callback function.
 *
 * @param vue - vue instance
 * @param from - start date (date object)
 * @param to - end date (date object)
 * @param callback - optional callback function to call with the entries
 */
export function getAllTimeEntries(vue, from, to, callback) {
  const chunkSize = 300
  let promise = new Promise((resolve, reject) => {
    const period = (to.getTime() - from.getTime()) / (1000 * 60 * 60 * 24) // days
    const chunks = Math.ceil(period / chunkSize)
    let start = from
    const chunkPromises = []
    for (let i = 0; i < chunks; i++) {
      let end = new Date(start.getTime() + (chunkSize * 24 * 60 * 60 * 1000))
      const _from = formatDate(start)
      const _to = formatDate(end)
      chunkPromises.push(vue.$WfmApi.get("time.api/list", {from: _from, to: _to}).catch(()=>reject()))
      start = end
    }

    Promise.all(chunkPromises).then(responses => {
      const entries = []
      responses.forEach(response => {
        if (response.Times) {
          entries.push(...vue.$ensureArray(response.Times.Time))
        }
      })
      resolve(entries)
    })
  })

  if (callback) {
    promise.then(callback)
  } else {
    return promise
  }
}