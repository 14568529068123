<template>
  <Field @save="save">
    <template v-slot:view>
      {{ job2.Manager ? job2.Manager.Name : "None" }}
    </template>
    <template v-slot:edit>
      <v-select v-model="manager" :items="staff"></v-select>
    </template>
  </Field>
</template>

<script>
import Field from "../../Field"

export default {
  name: "JobFieldManager",
  permissions: ["Modify Job", "Read Staff"],
  props: ["job"],
  components: {
    Field
  },
  methods: {
    save: function () {
      const data = {
        Job: {
          ID: this.job2.ID,
          "add-manager": {
            "@uuid": this.manager // @ means convert to xml attribute
          }
        }
      }
      this.$WfmApi.put("job.api/assign", data).then((response) => this.processResponse(response))
    },
    processStaffResponse: function (response) {
      const staff = this.$ensureArray(response.StaffList.Staff)
      this.staff = []
      for (const member of staff) {
        this.staff.push({text: member.Name, value: member.UUID})
      }
    },
    process() {
      this.manager = this.job2.Manager ? this.job2.Manager.UUID : ""
    },
    processResponse(response) {
      this.job2 = response.Job
      this.process()
      this.$emit("updated")
    }
  },
  mounted() {
    this.job2 = this.$clone(this.job)
    this.$WfmApi.get("staff.api/list").then((response) => this.processStaffResponse(response))
    this.process()
  },
  data() {
    return {
      staff: [],
      manager: "",
      job2: {}
    }
  }
}
</script>


