<template>
  <v-container fluid>
    <h1>Weekly workload planning</h1>
    <StaffQuestion></StaffQuestion>
    <WeeklyPlan :showStaffNavigator="false"/>
  </v-container>
</template>

<script>


import WeeklyPlan from "@/components/capacity/WeeklyPlan.vue"
import StaffQuestion from "@/components/capacity/StaffQuestion.vue"

export default {
  name: "StaffPlanSchedulePage",
  permissions: [...StaffQuestion.permissions, ...WeeklyPlan.permissions],
  components: {StaffQuestion, WeeklyPlan},
  data() {
    return {}
  },
}

</script>

<style scoped>

</style>