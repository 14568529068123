<template>
  <BfwComponent :isReady="isReady" :title="title" :showTitle="showTitle" :editable="editable">
    <v-form ref="form" v-model="valid" lazy-validation :isReady="isReady">
      <v-row>
        <v-col>
          <v-data-table class="jobTaskTable" dense hide-default-footer :headers="headers" :items="tableData" :items-per-page="200">

            <template v-slot:item.name="{item}">
              <span :class="item.class">{{ item.name }}</span>
            </template>
            <template v-slot:item.budget="{item}">
              <span :class="item.class">{{ item.budget | formatMinutes }}</span>
            </template>
            <template v-slot:item.used="{item}">
              <span :class="item.class">{{ item.used | formatMinutes }}</span>
            </template>
            <template v-slot:item.remaining="{item}">
              <span :class="item.class">{{ item.remaining | formatMinutes }}</span>
            </template>

            <template v-slot:item.graph="{item}">
              <OneLineGraph :used="item.used" :budget="item.budget" v-if="item.budget > 0"/>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-form>
  </BfwComponent>
</template>

<script>
import BfwComponent from "../BwfComponent"
import OneLineGraph from "../toolkit/OneLineGraph"

export default {
  name: "JobTaskSummary",
  permissions: ["Read Job"],
  props: ["jobId"],
  components: {
    BfwComponent, OneLineGraph
  },
  methods: {
    processResponse(response) {
      this.tasks = (response.Job.Tasks) ? this.$ensureArray(response.Job.Tasks.Task) : []
      this.isReady = true
    },
  },
  mounted() {

  },
  computed: {
    tableData: function () {
      let tableData = []
      let totalBudget = 0
      let totalUsed = 0
      let totalRemaining = 0

      for (const item of this.tasks) {
        const budget = parseInt(item.EstimatedMinutes)
        const used = parseInt(item.ActualMinutes)
        const remaining = budget - used
        const tableItem = {
          "id": item.ID,
          "name": item.Name,
          "budget": budget,
          "used": used,
          "remaining": remaining,
          "graph": "",
        }

        tableData.push(tableItem)
        totalBudget += budget
        totalUsed += used
        totalRemaining += remaining
      }


      if (tableData.length > 0) {
        tableData.push({
          "name": "Total",
          "description": "",
          "budget": totalBudget,
          "used": totalUsed,
          "remaining": totalRemaining,
          "graph": "",
          "class": "total"
        })
      }

      tableData.push({
        "name": "Grand Total",
        "description": "",
        "budget": totalBudget,
        "used": totalUsed,
        "remaining": totalRemaining,
        "graph": "",
        "class": "total"
      })

      return tableData
    }

  },
  watch: {
    jobId: function () {
      if (this.jobId) {
        this.isReady = false
        this.$WfmApi.get("job.api/get/" + this.jobId).then(response => this.processResponse(response))
      } else {
        this.isReady = false
      }
    }
  },
  data() {
    return {
      title: "Tasks",
      showTitle: true,
      editable: false,
      isReady: false,
      valid: true,

      headers: [
        {text: "Task", value: "name"},
        {text: "Budget", value: "budget"},
        {text: "Used", value: "used"},
        {text: "Remaining", value: "remaining"},
        {text: "", value: "graph"},
      ],
      tasks: [],
    }
  },
}

</script>

<style scoped>
.jobTaskTable tbody tr:last-child {
  font-weight: bold;
}

.total {
  font-weight: bold;
  line-height: 2rem;
}
</style>