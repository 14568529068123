<template>
  <v-container fluid>
    <h1>Contacts List</h1>
    <v-row>
      <v-col cols="12">
        <ClientsContactList></ClientsContactList>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import ClientsContactList from "../components/clients/ClientsContactList"


export default {
  name: "ContactList",
  permissions: [...ClientsContactList.permissions],
  components: {
    ClientsContactList
  },
  methods: {},
  mounted() {
  },
  data() {
    return {}
  },
}

</script>

<style scoped>

</style>