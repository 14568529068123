<script>
import {defineComponent} from "vue"
import {toLocalString} from "@/lib/dateTimeUtilities"
import {leadProbabilities} from "@/lib/constants"

export default defineComponent({
  name: "LeadsDashboard",
  permissions: ["Read Leads", "Read Quotes"],
  methods: {
    loadLeads() {
      return this.$BwfApi.get("leads").then(response => {
        if (response) {
          this.leads = response
        }
      })
    },
    loadQuotes() {
      const endDate = new Date()
      const startDate = new Date()
      startDate.setFullYear(startDate.getFullYear() - this.$WfmApi.lookback)
      const start = toLocalString(startDate).substr(0, 10).replace(/-/g, "")
      const end = toLocalString(endDate).substr(0, 10).replace(/-/g, "")
      const query = {"from": start, "to": end, "detailed": true}
      this.$WfmApi.get("quote.api/list", query).then(response => {
        if (response.Quotes) {
          this.quotes = this.$ensureArray(response.Quotes.Quote)
        }
      })
    },
    findQuote(quoteUuid) {
      return this.quotes.find(quote => quote.UUID === quoteUuid)
    },
  },
  computed: {
    leadProbabilityWeights() {
      return {
        low: leadProbabilities.find(p => p.value === "low").weight / 100,
        medium: leadProbabilities.find(p => p.value === "medium").weight / 100,
        high: leadProbabilities.find(p => p.value === "high").weight / 100,
      }
    },
    initialLeads() {
      const leads = this.leads.filter(lead => lead.stage === "initial")
      return {
        number: leads.length,
      }
    },
    preQuoteLeads() {
      const leads = this.leads.filter(lead => lead.stage === "pre-quote")
      return {
        number: leads.length,
      }
    },
    quotedLeads() {
      const data = {
        low: {
          number: 0,
          hours: 0,
          value: 0,
        },
        medium: {
          number: 0,
          hours: 0,
          value: 0,
        },
        high: {
          number: 0,
          hours: 0,
          value: 0,
        },
        weighted: {
          number: 0,
          hours: 0,
          value: 0,
        },
      }

      const quoted = this.leads.filter(lead => lead.stage === "quoted")
      const low = quoted.filter(lead => lead.probability === "low")
      const medium = quoted.filter(lead => lead.probability === "medium")
      const high = quoted.filter(lead => lead.probability === "high")

      const levels = {low, medium, high}
      for (const level in levels) {
        for (const lead of levels[level]) {
          const quote = this.findQuote(lead.quote_uuid)
          if (quote) {
            data[level].value += parseFloat(quote.Amount)
            data[level].number += 1

            const tasks = this.$ensureArray(quote.Tasks.Task)
            for (const task of tasks) {
              data[level].hours += parseInt(task.EstimatedMinutes) / 60
            }
          }
        }
      }

      data.weighted.number = data.low.number * this.leadProbabilityWeights.low +
          data.medium.number * this.leadProbabilityWeights.medium +
          data.high.number * this.leadProbabilityWeights.high

      data.weighted.hours = data.low.hours * this.leadProbabilityWeights.low +
          data.medium.hours * this.leadProbabilityWeights.medium +
          data.high.hours * this.leadProbabilityWeights.high

      data.weighted.value = data.low.value * this.leadProbabilityWeights.low +
          data.medium.value * this.leadProbabilityWeights.medium +
          data.high.value * this.leadProbabilityWeights.high

      return data
    },
  },
  mounted() {
    this.loadLeads()
    this.loadQuotes()
  },
  data: () => ({
    quotes: [],
    leads: [],
  }),
})
</script>

<template>
  <v-simple-table>
    <template v-slot:default>
      <tbody>
      <tr>
        <th class="text-center"></th>
        <th class="text-right">Initial Contact</th>
        <th class="text-right">Pre-quote</th>
        <th class="text-center" colspan="4">Quoted</th>
      </tr>

      <tr>
        <th class="text-right"></th>
        <th class="text-right"></th>
        <th class="text-right"></th>
        <th class="text-right">Low</th>
        <th class="text-right">Medium</th>
        <th class="text-right">High</th>
        <th class="text-right">Weighted Total</th>
      </tr>
      <tr>
        <th class="text-right">Number</th>
        <td class="text-right">{{ initialLeads.number }}</td>
        <td class="text-right">{{ preQuoteLeads.number }}</td>
        <td class="text-right">{{ quotedLeads.low.number }}</td>
        <td class="text-right">{{ quotedLeads.medium.number }}</td>
        <td class="text-right">{{ quotedLeads.high.number }}</td>
        <td class="text-right">{{ quotedLeads.weighted.number }}</td>
      </tr>
      <tr>
        <th class="text-right">Hours</th>
        <td class="text-right"></td>
        <td class="text-right"></td>
        <td class="text-right">{{ quotedLeads.low.hours }}</td>
        <td class="text-right">{{ quotedLeads.medium.hours }}</td>
        <td class="text-right">{{ quotedLeads.high.hours }}</td>
        <td class="text-right">{{ quotedLeads.weighted.hours }}</td>
      </tr>

      <tr>
        <th class="text-right">Value</th>
        <td class="text-right"></td>
        <td class="text-right"></td>
        <td class="text-right">{{ quotedLeads.low.value | formatCurrency }}</td>
        <td class="text-right">{{ quotedLeads.medium.value | formatCurrency }}</td>
        <td class="text-right">{{ quotedLeads.high.value | formatCurrency }}</td>
        <td class="text-right">{{ quotedLeads.weighted.value | formatCurrency }}</td>
      </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<style scoped>

</style>