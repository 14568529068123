<template>
  <v-container fluid>
    <h1>Workload review</h1>
    <WeeklyPlan :showStaffNavigator="true"/>
  </v-container>
</template>

<script>


import WeeklyPlan from "@/components/capacity/WeeklyPlan.vue"

export default {
  name: "StaffPlanScheduleReviewPage",
  permissions: [...WeeklyPlan.permissions],
  components: {WeeklyPlan},
  data() {
    return {}
  },
}

</script>

<style scoped>

</style>