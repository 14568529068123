import axios from "axios"

const WfmApi = {
  install(Vue) {
    // API function
    Vue.prototype.$WfmApi = {
      lookback: 5,// number of years to look back when doing range queries for 'all'
      get(path, queryParameters = {}) {
        return new Promise((dispatch) => {
          //console.log("-> GET "+ path, queryParameters)
          axios.get("workflowmax/" + path, {params: queryParameters, withCredentials: true})
            .then(function (response) {
              //console.log(response)
              dispatch(response.data.Response)
            })
            .catch(function (error) {
              if (error.response.status === 401) {
                window.location.href = error.response.data
              } else {
                Vue.prototype.$notify.show({type: "error", text: error.response.data})
              }
            })
        })
      },

      put(path, data = {}) {
        return new Promise((dispatch) => {
          //console.log("-> PUT "+ path)
          //console.log(data)
          axios.put("workflowmax/" + path, data, {withCredentials: true})
            .then(function (response) {
              dispatch(response.data.Response)
            })
            .catch(function (error) {
              if (error.response.status === 401) {
                window.location.href = error.response.data
              } else {
                Vue.prototype.$notify.show({type: "error", text: error.response.data})
              }
            })
        })
      },

      post(path, data = {}) {
        return new Promise((dispatch) => {
          //console.log("-> POST "+ path)
          //console.log(data)
          axios.post("workflowmax/" + path, data, {withCredentials: true})
            .then(function (response) {
              dispatch(response.data.Response)
            })
            .catch(function (error) {
              if (error.response.status === 401) {
                window.location.href = error.response.data
              } else {
                Vue.prototype.$notify.show({type: "error", text: error.response.data})
              }
            })
        })
      },

      delete(path) {
        return new Promise((dispatch) => {
          //console.log("-> DELETE "+ path)
          axios.delete("workflowmax/" + path, {withCredentials: true})
            .then(function (response) {
              dispatch(response.data.Response)
            })
            .catch(function (error) {
              if (error.response.status === 401) {
                window.location.href = error.response.data
              } else {
                Vue.prototype.$notify.show({type: "error", text: error.response.data})
              }
            })
        })
      },
    }
  }
}

export default WfmApi