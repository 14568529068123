<template>
  <v-row>
    <v-col cols="4" class="description">
      <v-text-field label="Description" v-model="cost.description" dense @change="update" :rules="[isNotEmpty]"></v-text-field>
    </v-col>

    <v-col cols="2" class="amount" v-if="cost.is_project_cost">
      <v-text-field label="On-chargeable amount" v-model.number="cost.amount" prefix="$" dense :rules="[isNotEmpty]"
                    @blur="calculatePrice"/>

      <v-text-field label="Markup" v-model.number="cost.markup" suffix="%" dense @blur="calculatePrice" :rules="[isNotEmpty]"/>
      <v-text-field label="Price to customer" v-model.number="cost.price" prefix="$" dense @blur="calculateMarkup" :rules="[isNotEmpty]" />

      <div>Profit: {{ cost.profit | formatCurrency(2) }}</div>
    </v-col>

    <v-col cols="2" class="inputs">
      <v-checkbox label="Project Cost" v-model="cost.is_project_cost" dense @change="update"></v-checkbox>
      <v-autocomplete label="Job" v-if="cost.is_project_cost" :items="jobs" v-model="cost.job" dense @change="update" :rules="[isNotEmpty]"></v-autocomplete>
    </v-col>

    <v-col cols="2" class="inputs">
      <v-autocomplete label="WFM Cost Centre" v-if="cost.is_project_cost" :items="wfmCostOptions" v-model="cost.wfm_cost_code" dense @change="update" :rules="[isNotEmpty]"></v-autocomplete>
      <v-autocomplete label="Xero Cost Centre" v-else :items="xeroCostOptions" v-model="cost.xero_cost_code" dense @change="update" :rules="[isNotEmpty]"></v-autocomplete>
    </v-col>

  </v-row>
</template>

<script>
import {isNotEmpty} from "@/lib/validationRules"
export default {
  name: "CostTransactionReconcilingForm",
  permissions: ["Read Job", "Read Supplier", "Read Cost", "Modify Job Cost"],
  props: ["value", "statementLine",  "wfmCostOptions", "xeroCostOptions", "jobs"],
  components: {},
    watch:{
    "statementLine.includes_gst": function(){
      if (this.statementLine.costs.length === 1){
        this.cost.amount = this.statementLine.chargeable_amount
        this.calculatePrice()
      }
    }
  },
  methods: {
    update() {
      this.$emit("input", this.cost)
    },
    calculateMarkup() {
      this.cost.markup = Math.round(10000 * (this.cost.price - this.cost.amount) / this.cost.amount) / 100
      this.cost.profit = Math.round(this.cost.price - this.cost.amount)
      this.update()
    },
    calculatePrice() {
      this.cost.price = Math.round((1 + this.cost.markup / 100) * this.cost.amount)// no decimals
      this.cost.profit = Math.round(100 * (this.cost.price - this.cost.amount)) / 100
      this.cost.markup = Math.round(10000 * (this.cost.price - this.cost.amount) / this.cost.amount) / 100
      this.update()
    },
  },
  mounted() {
    this.cost = {...this.value}
    this.cost.amount = Math.round(100 * this.cost.amount) / 100
    this.cost.markup = Math.round(100 * this.cost.markup) / 100 || this.defaultMarkup
    this.cost.price = Math.round(100 * this.cost.price) / 100 || 0
    this.cost.profit = Math.round(100 * this.cost.profit) / 100 || 0
    this.calculatePrice()
  },
  data() {
    return {
      isNotEmpty,
      cost: {},
      defaultMarkup: 20,
    }
  },
}

</script>

<style scoped>

</style>