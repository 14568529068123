// These are defined on the WFM api and are not available via the API
export const jobStates = ["On going", "In Progress", "On Hold", "Quoting", "Completed"]
export const defaultJobStates = ["On going", "In Progress", "On Hold", "Quoting"]
export const jobStatesQuickAccess = ["On going", "In Progress", "On Hold", "Completed"]
export const jobTypes = ["None", "Consulting", "Production"]
export const defaultJobTypes = ["None", "Consulting", "Production"]
export const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
export const leadProbabilities = [
  {text: "Low", value: "low", weight: 30},
  {text: "Medium", value: "medium", weight: 50},
  {text: "High", value: "high", weight: 70},
]

export const leadStages = [
  {text: "Initial", value: "initial"},
  {text: "Pre Quote", value: "pre-quote"},
  {text: "Quoted", value: "quoted"},
  {text: "Won", value: "won"},
  {text: "Lost", value: "lost"},
]

export const leadRegions = [
  "Northland",
  "Auckland",
  "Waikato",
  "Bay of Plenty",
  "Gisborne",
  "Hawkes Bay",
  "Taranaki",
  "Manawatu/Whanganui",
  "Wellington",
  "Tasman",
  "Nelson",
  "Marlborough",
  "West Coast",
  "Canterbury",
  "Otago",
  "Southland",
  "Australia",
  "Other"
]
export const leadBusinessTypes = [
  "Est Biz: EBM/EPO",
  "Est Biz: EBM/NPO",
  "Est Biz: NBM/NPO",
  "Est Biz: Research Institute",
  "New Biz: Est Startup",
  "New Biz: New Startup",
  "Consultant/Contractor",
]
export const colors = [
  "#E278C0",
  "#1FBECD",
  "#CD8403",
  "#30A039",
  "#9367BA",
  "#FE802A",
  "#8C564C",
  "#2177B1",
  "#7F7F7F",
  "#BCBD3B",
  "#852A2D",
]