<template>
  <Field @save="save">
    <template v-slot:view>
      {{ name }}
    </template>
    <template v-slot:edit>
      <v-text-field v-model="name"></v-text-field>
    </template>
  </Field>
</template>

<script>
import Field from "../../Field"

export default {
  name: "ContactFieldName",
  permissions: ["Modify Contact"],
  props: ["contact", "clientId"],
  components: {
    Field
  },
  methods: {
    save: function () {
      const data = {
        Contact: this.contact,
      }
      data.Contact.Name = this.name
      data.Contact.ClientID = this.clientId
      this.$WfmApi.put("client.api/contact/" + this.contact.UUID, data).then((response) => this.processResponse(response))
    },
    processResponse() {
      this.$emit("updated")
    }
  },
  mounted() {
    this.name = this.contact.Name
  },
  data() {
    return {
      name: "",
    }
  }
}
</script>


