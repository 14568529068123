<template>
  <BfwComponent :isReady="isReady" :title="title" :showTitle="showTitle" :editable="editable">
    <v-data-table class="service-table" dense hide-default-footer :headers="headers" :items="summary" :items-per-page="200">

      <template v-slot:item.service="{item}">
        <span :class="{'total':item.service==='Total'}">{{ item.service }}</span>
      </template>
      <template v-slot:item.budget="{item}">
        <span :class="{'total':item.service==='Total'}">{{ item.budget | formatMinutes }}</span>
      </template>
      <template v-slot:item.actual="{item}">
        <span :class="itemClass(item.forecastOver,item.service)">{{ item.actual | formatMinutes }}</span>
      </template>
      <template v-slot:item.forecast="{item}">
        <span :class="itemClass(item.forecastOver, item.service)">{{ item.forecast | formatMinutes }}</span>
      </template>

    </v-data-table>

    <!-- used by the compiler -->
    <div class="text-red text-green"></div>
  </BfwComponent>
</template>

<script>
import BfwComponent from "../BwfComponent"
import {formatDate} from "@/lib/dateTimeUtilities"

export default {
  name: "JobServiceSummary",
  permissions: ["Read Job"],
  props: ["jobId"],
  components: {
    BfwComponent
  },
  methods: {
    itemClass(overUnder, name = "") {
      let classStr = ""
      if (overUnder) classStr += "text-red "
      else classStr += "text-green "

      if (name.endsWith("Total")) classStr += "total "
      return classStr
    },
    loadAll() {
      Promise.all([
        this.loadJob(),
        this.loadPlanned(),
        this.loadServices(),
        this.loadJobTimeUsedPreviousMonths(),
      ]).then(() => {
        this.loadQuote().then(() => {
          this.calculate()
        })
      })
    },
    loadJob() {
      this.job = null
      return this.$WfmApi.get("job.api/get/" + this.jobId, {detailed: true}).then(response => {
        this.job = response.Job
      })
    },
    loadQuote() {
      this.quote = null
      return this.$WfmApi.get("quote.api/get/" + this.job.ApprovedQuoteUUID).then(response => {
        this.quote = response.Quote
      })
    },
    loadPlanned() {
      this.planned = null
      return this.$BwfApi.get("capacity-plan/job", {job_id: this.jobId}).then(response => {
        this.planned = response.labour
      })
    },
    loadServices() {
      return this.$BwfApi.get("services", {enabled: true}).then(response => {
        this.services = response
      })
    },
    loadJobTimeUsedPreviousMonths() {
      // Get Actual time for each task for the previous months
      const now = new Date()
      const to = formatDate(now)
      const startOfMonth = new Date()
      startOfMonth.setDate(1)
      startOfMonth.getTime()

      const taskTimes = {}

      now.setYear(now.getFullYear() - this.$WfmApi.lookback) // only look back 1 year for time entries
      const from = formatDate(now)
      return this.$WfmApi.get("time.api/job/" + this.jobId, {from: from, to: to}).then(response => {
        if (response.Times) {
          const timeEntries = this.$ensureArray(response.Times.Time)
          for (const item of timeEntries) {
            const time = parseInt(item.Minutes)
            const entryDate = new Date(item.Date)
            if (entryDate < startOfMonth) {
              taskTimes[item.Task.UUID] = taskTimes[item.Task.UUID] || 0
              taskTimes[item.Task.UUID] += time
            }
          }
        }

        this.taskTimes = taskTimes
      })
    },
    calculate() {
      const summary = []
      const budgetSummary = this.summarizeBudgetByServiceId()
      const [actualSummary, historicalSummary] = this.summarizeActualByServiceId()
      const plannedSummary = this.summarizePlannedByServiceId()
      const totals = {
        budget: 0,
        actual: 0,
        forecast: 0,
      }
      for (const service of this.services) {
        const budget = budgetSummary[service.service_id] || 0
        const actual = actualSummary[service.service_id] || 0
        const forecast = historicalSummary[service.service_id] + plannedSummary[service.service_id] || 0
        if (budget === 0 && actual === 0 && forecast === 0) continue
        totals.budget += budget
        totals.actual += actual
        totals.forecast += forecast
        summary.push({
          service: service.name,
          budget: budget,
          actual: actual,
          forecast: forecast,
          actualOver: actual > budget,
          forecastOver: forecast > budget,
        })
      }

      summary.push({
        service: "Total",
        budget: totals.budget,
        actual: totals.actual,
        forecast: totals.forecast,
        actualOver: totals.actual > totals.budget,
        forecastOver: totals.forecast > totals.budget,
      })
      this.summary = summary
      this.isReady = true
    },
    summarizeActualByServiceId() {
      if (!this.job.Tasks) return {}
      const summaryActual = {}
      const summaryHistorical = {}
      const tasks = this.$ensureArray(this.job.Tasks.Task)
      for (const task of tasks) {
        const serviceId = this.getServiceIdByTaskUUID(task.TaskUUID)
        if (serviceId) {
          const minutes = parseInt(task.ActualMinutes)
          summaryActual[serviceId] = summaryActual[serviceId] || 0
          summaryHistorical[serviceId] = summaryHistorical[serviceId] || 0
          summaryActual[serviceId] += minutes
          summaryHistorical[serviceId] += this.taskTimes[task.UUID]
        }
      }
      return [summaryActual, summaryHistorical]
    },
    summarizePlannedByServiceId() {
      const now = new Date()
      const currentMonthStr = now.getFullYear() + "-" + (now.getMonth() + 1).toString().padStart(2, "0")

      const planned = {}
      for (const service of this.services) {
        planned[service.service_id] = 0
      }
      for (const monthString in this.planned) {
        let percentageOfMonthRemaining = 1
        if (monthString >= currentMonthStr) {
          percentageOfMonthRemaining = 1
        } else {
          percentageOfMonthRemaining = 0
        }

        for (const serviceId in this.planned[monthString]) {
          planned[serviceId] = planned[serviceId] || 0
          planned[serviceId] += this.planned[monthString][serviceId] * percentageOfMonthRemaining * 60 // prorate and convert to minutes
        }
      }

      return planned
    },
    summarizeBudgetByServiceId() {
      if (!this.job.Tasks) return {}
      const summary = {}
      const tasks = this.$ensureArray(this.job.Tasks.Task)
      for (const task of tasks) {
        const serviceId = this.getServiceIdByTaskUUID(task.TaskUUID)
        if (serviceId) {
          const minutes = parseInt(task.EstimatedMinutes)
          summary[serviceId] = summary[serviceId] || 0
          summary[serviceId] += minutes
        }
      }
      return summary
    },
    summarizeBudgetByServiceIdFutureUseOnly() {
      // at this point, the quote does not have the task type, but may do in the future. Leaving here for my future self
      if (!this.quote || !this.quote.Tasks) return {}
      const summary = {}
      const tasks = this.$ensureArray(this.quote.Tasks.Task)
      for (const task of tasks) {
        const serviceId = this.getServiceIdByTaskUUID(task.TaskUUID)
        if (serviceId) {
          const minutes = parseInt(task.Minutes)
          summary[serviceId] = summary[serviceId] || 0
          summary[serviceId] += minutes
        }
      }
      return summary
    },
    getServiceIdByTaskUUID(taskUUID) {
      for (const service of this.services) {
        if (service.uuid === taskUUID) return service.service_id
      }
      return null
    }
  },
  mounted() {
  },
  computed: {
    tableData: function () {
      return []
    }
  },
  watch: {
    jobId: function () {
      if (this.jobId) {
        this.isReady = false
        this.loadAll()
      } else {
        this.isReady = false
      }
    }
  },
  data() {
    return {
      title: "Services",
      showTitle: true,
      editable: false,
      isReady: false,
      headers: [
        {text: "Service", value: "service"},
        {text: "Budget", value: "budget"},
        {text: "Actual", value: "actual"},
        {text: "Forecast", value: "forecast"}
      ],
      job: null,
      quote: null,
      services: null,
      planned: null,
      summary: [],
      taskTimes: {}
    }
  }
}

</script>

<style scoped>
.jobTaskTable tbody tr:last-child {
  font-weight: bold;
}

.total {
  font-weight: bold;
  line-height: 2rem;
}

.text-red {
  color: red;
}

.text-green {
  color: green;
}
</style>