<template>
  <v-form ref="form" v-model="valid" lazy-validation :isReady="isReady">
    <div v-for="field in customFieldDefinitions" v-bind:key="field.UUID">
      <v-checkbox v-if="field.Type === 'Checkbox'" v-model="field.vModel" :label="field.Name" @change="update"
                  true-value="true" false-value="false" dense/>
      <v-text-field v-if="field.Type === 'Number'" v-model="field.vModel" :label="field.Name" @change="update" dense/>
      <v-text-field v-if="field.Type === 'Text'" v-model="field.vModel" :label="field.Name" @change="update" dense/>
      <v-textarea v-if="field.Type === 'Multi-line Text'" v-model="field.vModel" :label="field.Name" @change="update" auto-grow dense       clearable
                  clear-icon="mdi-close-circle" rows="1"/>
      <v-text-field v-if="field.Type === 'Decimal'" v-model="field.vModel" :label="field.Name" @change="update" dense/>
      <div v-if="field.Type === 'Date'">
        <div>{{ field.Name }}:</div>
        <v-date-picker v-model="field.vModel" @change="update"></v-date-picker>
      </div>
      <v-select v-if="field.Type === 'Dropdown List'" v-model="field.vModel" :label="field.Name" :items="field.Options" @change="update"/>
      <a v-if="field.Type === 'Link'" :href="field.linkUrl">{{ linkUrl }}</a>
    </div>
  </v-form>
</template>

<script>

import {toLocalString} from "@/lib/dateTimeUtilities"

export default {
  name: "CustomFields",
  permissions: ["Read Custom Field"],
  props: ["type", "typeUUID", "exclude", "restrict"], //eg. type='client' and typeUUID = the clientUUID
  // restrict is an array of uuids to ONLY show. exclude is the array of UUIDs to exclude
  components: {},
  methods: {
    update() {
      const customFields = []
      for (let i = 0; i < this.customFieldDefinitions.length; i++) {
        const field = this.customFieldDefinitions[i]
        customFields.push({
          UUID: field.UUID,
          ValueElement: field.ValueElement,
          Value: field.vModel
        })
      }
      this.$emit("change", customFields)
    },
    processDefinitionResponse(res) {
      if (res.Status === "OK") {
        this.customFieldDefinitions = []
        const definitions = this.$ensureArray(res.CustomFieldDefinitions.CustomFieldDefinition)
        for (let i = 0; i < definitions.length; i++) {
          const field = definitions[i]

          // hide any excluded fields
          if (this.exclude && this.exclude.includes(field.UUID)) {
            continue
          }

          // hide anything but restrict fields
          if (this.restrict && !this.restrict.includes(field.UUID)) {
            continue
          }

          if (field.Type === "Dropdown List") {
              field.Options = field.Options.Option
          }
          if ((this.type === "client" && field.UseClient === "true") ||
              ((this.type === "contact" || this.type === "supplier-contact") && field.UseContact === "true") ||
              (this.type === "job" && field.UseJob === "true") ||
              (this.type === "cost" && field.UseCost === "true") ||
              (this.type === "task" && field.UseTask === "true") ||
              (this.type === "time" && field.UseTime === "true") ||
              (this.type === "lead" && field.UseLead === "true") ||
              (this.type === "supplier" && field.UseSupplier === "true")) {
            this.customFieldDefinitions.push(field)
          }
        }

        if (this.type === "client") {
          this.$WfmApi.get("client.api/get/" + this.typeUUID + "/customfield", {})
              .then((response) => this.processValuesResponse(response))
        } else if (this.type === "contact") {
          this.$WfmApi.get("client.api/contact/" + this.typeUUID + "/customfield", {})
              .then((response) => this.processValuesResponse(response))
        } else if (this.type === "supplier") {
          this.$WfmApi.get("supplier.api/get/" + this.typeUUID + "/customfield", {})
              .then((response) => this.processValuesResponse(response))
        } else if (this.type === "supplier-contact") {
          this.$WfmApi.get("supplier.api/contact/" + this.typeUUID + "/customfield", {})
              .then((response) => this.processValuesResponse(response))
        } else if (this.type === "job") {
          this.$WfmApi.get("job.api/get/" + this.typeUUID + "/customfield", {})
              .then((response) => this.processValuesResponse(response))
        } else if (this.type === "task") {
          this.$WfmApi.get("job.api/task/" + this.typeUUID + "/customfield", {})
              .then((response) => this.processValuesResponse(response))
        } else if (this.type === "cost") {
          this.$WfmApi.get("job.api/cost/" + this.typeUUID + "/customfield", {})
              .then((response) => this.processValuesResponse(response))
        } else if (this.type === "time") {
          this.$WfmApi.get("time.api/get/" + this.typeUUID + "/customfield", {})
              .then((response) => this.processValuesResponse(response))
        } else if (this.type === "lead") {
          this.$WfmApi.get("lead.api/get/" + this.typeUUID + "/customfield", {})
              .then((response) => this.processValuesResponse(response))
        } else {
          console.log("Unknown custom fields source type")
        }
      } else {
        //todo do something
      }
    },
    processValuesResponse(res) {
      res.CustomFields = res.CustomFields || {} // make sure there is a default
      const defaultSettings = this.$ensureArray(res.CustomFields.CustomField)
      for (let i = 0; i < defaultSettings.length; i++) {
        for (let j = 0; j < this.customFieldDefinitions.length; j++) {
          if (this.customFieldDefinitions[j].UUID === defaultSettings[i].UUID) {
            const defaultValue = defaultSettings[i].Text || defaultSettings[i].Decimal || defaultSettings[i].Number ||
                defaultSettings[i].Boolean ||
                toLocalString(new Date(defaultSettings[i].Date)).substr(0, 10) || ""
            this.$set(this.customFieldDefinitions[j], "vModel", defaultValue)
          }
        }
      }
    },
    save(parentUUID) {
      const typeUUID = (parentUUID) ? parentUUID : this.typeUUID
      const customFields = {CustomFields: {CustomField: []}}
      for (let i = 0; i < this.customFieldDefinitions.length; i++) {
        const field = this.customFieldDefinitions[i]

        if (field.vModel) {
          const customField = {}
          customField["UUID"] = field.UUID

          if (field.Type === "Date") {
            customField[field.ValueElement] = field.vModel.replace(/-/g, "") || ""
          } else {
            customField[field.ValueElement] = field.vModel || ""
          }

          customFields.CustomFields.CustomField.push(customField)
        }
      }

      this.$emit("saved", customFields.CustomFields.CustomField)
      if (this.type === "client") {
        this.$WfmApi.put("client.api/update/" + typeUUID + "/customfield", customFields)
            .then((response) => this.processValuesResponse(response))
      } else if (this.type === "contact") {
        this.$WfmApi.put("client.api/contact/" + typeUUID + "/customfield", customFields)
            .then((response) => this.processValuesResponse(response))
      } else if (this.type === "supplier") {
        this.$WfmApi.put("supplier.api/update/" + typeUUID + "/customfield", customFields)
            .then((response) => this.processValuesResponse(response))
      } else if (this.type === "supplier-contact") {
        this.$WfmApi.put("supplier.api/contact/" + typeUUID + "/customfield", customFields)
            .then((response) => this.processValuesResponse(response))
      } else if (this.type === "job") {
        this.$WfmApi.put("job.api/update/" + typeUUID + "/customfield", customFields)
            .then((response) => this.processValuesResponse(response))
      } else if (this.type === "task") {
        this.$WfmApi.put("job.api/task/" + typeUUID + "/customfield", customFields)
            .then((response) => this.processValuesResponse(response))
      } else if (this.type === "cost") {
        this.$WfmApi.put("job.api/cost/" + typeUUID + "/customfield", customFields)
            .then((response) => this.processValuesResponse(response))
      } else if (this.type === "time") {
        this.$WfmApi.put("time.api/update/" + typeUUID + "/customfield", customFields)
            .then((response) => this.processValuesResponse(response))
      } else if (this.type === "lead") {
        this.$WfmApi.put("lead.api/update/" + typeUUID + "/customfield", customFields)
            .then((response) => this.processValuesResponse(response))
      } else {
        console.log("Unknown custom fields source type")
      }
    }
  },
  mounted() {
    this.$WfmApi.get("customfield.api/definition", {}).then((response) => this.processDefinitionResponse(response))
  },
  computed: {},
  data() {
    return {
      title: "Custom fields",
      showTitle: false,
      editable: false,
      isReady: true,
      valid: true,
      settings: {},
      customFieldDefinitions: [],
      customFields: [],
    }
  },
}

</script>

<style scoped>

</style>