<template>
  <BfwComponent :isReady="isReady" :title="title" :showTitle="showTitle" :editable="editable" :hideCard="true" loaderType="list-item">
    <div class="import-wrapper pa-2">
      <v-file-input v-model="excelFile" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    label="Click here to select the bank statement to import" dark filled dense></v-file-input>
    </div>
    <xlsx-read :file="excelFile" :options="{cellDates:false}">
      <xlsx-json @parsed="processExcel" sheet="Bank Statement" :options="{range:firstExcelDataRow-1, header:excelColumns}"></xlsx-json>
    </xlsx-read>

    <v-data-table :headers="headers" :items="importedData" :items-per-page="-1" hide-default-footer
                  v-model="selected" :single-select="false" item-key="id" show-select v-if="importedData.length > 0">
      <template v-slot:item.amount="{ item }">
        <div :class="includedClass(item)">{{ item.amount|formatCurrency(2) }}</div>
      </template>

      <template v-slot:item.description="{ item }">
        <div :class="includedClass(item)">{{ item.description }}</div>
      </template>

      <template v-slot:item.date="{ item }">
        <div :class="includedClass(item)">{{ item.date|formatDate }}</div>
      </template>
    </v-data-table>
    <v-btn @click="importItems" color="primary" class="mt-3" v-if="importedData.length>0">Import transactions</v-btn>

  </BfwComponent>
</template>

<script>
import BfwComponent from "../BwfComponent"
import {XlsxRead, XlsxJson} from "vue-xlsx"
import {formatDate, getJsDateFromExcel} from "@/lib/dateTimeUtilities"

export default {
  name: "CostImporterBankStatementImport",
  props: [],
  permissions: ["Modify Job Cost"],
  components: {
    BfwComponent, XlsxRead, XlsxJson
  },
  watch: {},
  computed: {},
  methods: {
    includedClass(item) {
      return item.included ? "" : "strikethrough"
    },
    processExcel(data) {
      const importedData = []

      for (let i = 0; i < data.length; i++) {// last row is the closing balance
        const item = data[i]

        // stop when the data lines are exhausted
        if (typeof item.date === "string" && item.date.startsWith("Closing Balance")) {
          break
        }
        if (item.reconciled === "No") {
          const reference = item.reference.replace(/\s+/g, " ") // remove multiple spaces
          const description = item.description ? item.description.replace(/\s+/g, " ") : ""// remove multiple spaces, description column is not alwys presesnt

          const item2 = {
            id: i,
            date: formatDate(getJsDateFromExcel(item.date), "-"),
            amount: item.amount * -1,
            description: description ? `${description}: ${reference}` : reference,
          }
          importedData.push(item2)
        }
      }

      this.importedData = this.mergeWiseFees(importedData)
      this.selected = [...this.importedData]

      if (this.importedData.length === 0) {
        this.$notify.show({type: "error", text: "No transactions found"})
      }
    },
    mergeWiseFees(data) {
      const regex = /CARD-(\d*)/
      for (const item of data) {
        const m = regex.exec(item.description)
        item.card = undefined
        if (m) {
          item.card = m[1]
        }
      }
      for (const idx1 in data) {
        const item1 = data[idx1]
        if (item1.description.includes("Wise Charges for:")) {
          // find matching line
          for (const idx2 in data) {
            const item2 = data[idx2]
            if (item1 && item2 && item1.card && item2.card && item1.card === item2.card && idx2 !== idx1) {
              // merge and mark the wise transaction for removal
              data[idx2].amount += item1.amount
              data[idx2].description += " + Wise charges"
              data[idx1] = undefined
            }
          }
        }
      }

      // remove items marked for removal
      return data.filter(item => {
        return !!item
      })
    },
    importItems() {
      this.$BwfApi.post("costs/statement/import", this.selected).then(response => {
        if (response) {
          this.$notify.show({type: "error", text: response})
        } else {
          this.$notify.toast("Successfully Imported")
          this.importedData = []
          this.selected = []
          this.$emit("imported")
        }
      })
    }
  },
  mounted() {

  },
  data() {
    return {
      title: "Bank Statement Importer",
      showTitle: false,
      editable: false,
      isReady: true,
      valid: true,
      settings: {},
      firstExcelDataRow: 12,
      excelColumns: ["date", "description", "imported", "reference", "reconciled", "source", "amount", "balance"],
      headers: [
        {text: "Date", value: "date"},
        {text: "Description", value: "description"},
        {text: "Amount", value: "amount"},

      ],
      excelFile: null,
      selected: [],
      importedData: [],
    }
  },
}

</script>

<style scoped>
.import-wrapper {
  background-color: #77aa00;
  border-radius: 5px;
  min-width: 500px;
}
</style>