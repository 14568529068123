<template>

  <div v-if="isHistorical" class="disabled" :style="{color:progressColor}">
    {{ used }}
  </div>

  <div v-else-if="disabled" class="disabled" :style="{color:progressColor}">
    {{ planned }}
  </div>

  <v-progress-circular v-else :rotate="120" :size="50" :width="10" :value="percent" :color="progressColor"
                       @wheel.prevent="scroll" :class="{'changed':isChanged}">
    {{ planned }}
  </v-progress-circular>
</template>

<script>
export default {
  name: "ScrollControl",
  props: {
    min: {type: Number, required: false, default: 0},
    max: {type: Number, required: true},
    color: {type: String, required: false},
    value: {type: Object, required: true}, // must have 'planned', 'used', and 'historical' keys
    disabled: {type: Boolean, required: false, default: false}
  },
  computed: {
    percent: function () {
      return 100 * this.planned / this.max
    },
    progressColor: function () {
      return this.color ? this.color : "primary"
    },
    isChanged() {
      return this.planned !== this.plannedOrig
    }
  },
  methods: {
    scroll: function (event) {
      if (event.deltaY < 0) {
        this.planned += 1
      } else {
        this.planned -= 1
      }

      if (this.planned < this.min) {
        this.planned = this.min
      } else if (this.planned > this.max) {
        this.planned = this.max
      }

      this.$emit("input", {used: this.used, planned: this.planned, isHistorical: this.isHistorical})
    },
    reset() {
      this.planned = this.plannedOrig
      this.$emit("input", {used: this.used, planned: this.planned, isHistorical: this.isHistorical})
    },
    verify() {
      // Force the value to be within the min and max
      if (this.value.planned < this.min) {
        this.planned = this.min
      } else if (this.value.planned > this.max) {
        this.planned = this.max
      }
    },
  },

  mounted() {
    this.verify()
  },
  data() {
    return {
      planned: this.value.planned,
      plannedOrig: this.value.planned,
      used: this.value.used,
      isHistorical: this.value.isHistorical
    }
  },
  watch: {
    value: {
      handler: function (newVal) {
        this.planned = newVal.planned
        this.used = newVal.used
        this.isHistorical = newVal.isHistorical
        this.verify()
      },
      deep: true
    }
  }
}

</script>

<style scoped>
.disabled {
  width: 100%;
  height: 50px;
  vertical-align: center;
  text-align: center;
  padding: 5px 0 5px 0;
  font-weight: bold;
}

.changed {
  border: 3px solid lime;
}
</style>