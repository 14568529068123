import { render, staticRenderFns } from "./ReportPipelinePage.vue?vue&type=template&id=23c8a6e0&scoped=true&"
import script from "./ReportPipelinePage.vue?vue&type=script&lang=js&"
export * from "./ReportPipelinePage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23c8a6e0",
  null
  
)

export default component.exports