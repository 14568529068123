<template>
  <div>
    <div v-if="isPrimaryReady">
      <WeekNavigator v-model="period" @input="loadWeek"></WeekNavigator>
    </div>

    <div v-if="isReady">
      <div v-for="item in services" v-bind:key="item.service_id">
        <h2>{{ item.name }}</h2>
        <div class="chart-area">
          <v-chart class="chart" :option="chartOptions(item)"/>
        </div>
      </div>
    </div>
    <div v-else class="text-center pa-4">
      <v-progress-circular indeterminate size="150" width="10" color="primary">Loading</v-progress-circular>
    </div>
  </div>
</template>

<script>
import {use} from "echarts/core"
import {CanvasRenderer} from "echarts/renderers"
import {BarChart} from "echarts/charts"
import {
  LegendComponent,
  GridComponent,
} from "echarts/components"
import VChart from "vue-echarts"
import {defineComponent} from "vue"
import WeekNavigator from "@/components/toolkit/WeekNavigator.vue"
import {formatMonth, getMonthFraction, getMonthsInWeek, getWeekNumber, toLocalString} from "@/lib/dateTimeUtilities"
import {getAllTimeEntries} from "@/lib/lookup"

use([
  CanvasRenderer,
  BarChart,
  LegendComponent,
  GridComponent,
])
export default defineComponent({
  name: "WeeklyCapacityPlanGraphByService",
  props: [],
  permissions: ["Read Jobs"],
  components: {
    WeekNavigator,
    VChart,
  },
  computed: {
    monthStr() {
      return this.period.year.toString() + "-" + this.period.month.toString().padStart(2, "0")
    }
  },
  methods: {
    loadAll() {
      const endDate = new Date()
      const startDate = new Date()
      startDate.setFullYear(startDate.getFullYear() - this.$WfmApi.lookback)
      const start = toLocalString(startDate).substr(0, 10).replace(/-/g, "")
      const end = toLocalString(endDate).substr(0, 10).replace(/-/g, "")

      Promise.all([
        this.$WfmApi.get("job.api/list", {"from": start, "to": end, "detailed": "true"}).then(response => this.processJobsResponse(response)),
        this.$BwfApi.get("services", {enabled: true}).then(response => this.processServices(response)),
      ]).then(() => {
        this.isPrimaryReady = true
      })
    },
    loadWeek() {
      this.isReady = false

      const monthsInWeek = getMonthsInWeek(this.period.week, this.period.year)
      let bwfQuery
      if (monthsInWeek.length === 1) {
        bwfQuery = {
          startMonth: monthsInWeek[0].month,
          startYear: monthsInWeek[0].year,
          endMonth: monthsInWeek[0].month,
          endYear: monthsInWeek[0].year
        }
      } else {
        bwfQuery = {
          startMonth: monthsInWeek[0].month,
          startYear: monthsInWeek[0].year,
          endMonth: monthsInWeek[1].month,
          endYear: monthsInWeek[1].year
        }
      }

      const timeStartDate = new Date(monthsInWeek[0].year, monthsInWeek[0].month - 1, 1)
      const timeEndDate = new Date() // time looking is by last modified not the actual record time so have to look forward always
      Promise.all([
        this.$BwfApi.get("capacity-plan/jobs", bwfQuery).then((response) => this.processCapacityResponse(response)),
        this.$BwfApi.get("staff-plan", {staff_uuid: "all", periods: this.monthStr}).then(response => this.processPlanLoad(response)),
        getAllTimeEntries(this, timeStartDate, timeEndDate).then(response => this.processTimeResponse(response)),
      ]).then(() => {
        this.isReady = true
      })
    },
    processServices(response) {
      this.services = response
    },
    processJobsResponse(response) {
      const results = {}
      const jobs = this.$ensureArray(response.Jobs.Job)
      for (const job of jobs) {
        results[job.ID] = job
      }
      this.jobs = results
    },
    processTimeResponse(timeEntries) {
      this.timeEntries = timeEntries
    },
    processCapacityResponse(response) {
      // reorganise to be by service not by project
      const results = {}
      for (const jobId in response) {
        for (const monthStr in response[jobId]) { // only ever 1
          const [year, month] = monthStr.split("-")
          const fraction = getMonthFraction(parseInt(month), this.period.week, parseInt(year)) // prorate the month(s) for just this week
          for (const serviceId in response[jobId][monthStr]) {
            results[serviceId] = results[serviceId] || {}
            if (response[jobId][monthStr][serviceId] > 0) {
              results[serviceId][jobId] = response[jobId][monthStr][serviceId] * fraction
            }
          }
        }
      }
      this.capacityData = results
    },
    processPlanLoad(response) {
      const result = {}
      for (const item of response) {
        if (!(result[item.service_id] in result)) {
          result[item.service_id] = {}
        }
        result[item.service_id][item.job_id] = item

      }
      this.plan = result
    },
    calculateUsedTime(serviceUUID) {
      let total = {}
      for (const entry of this.timeEntries) {
        const taskUuid = this.findTaskTypeUuid(entry.Job.ID, entry.Task.UUID)
        const service = this.findService(taskUuid)
        if (service.uuid === serviceUUID) {
          const monthStr = entry.Date.substr(0, 7)
          if (monthStr === this.monthStr) {
            if (!(entry.Job.ID in total)) {
              total[entry.Job.ID] = 0
            }
            total[entry.Job.ID] += parseInt(entry.Minutes) / 60
          }
        }
      }
      return total
    },
    findTaskTypeUuid(jobId, taskUUID) {
      if (Object.keys(this.jobs).includes(jobId)) {
        const job = this.jobs[jobId]
        const tasks = this.$ensureArray(job.Tasks.Task)
        for (const task of tasks) {
          if (task.UUID === taskUUID) {
            return task.TaskUUID
          }
        }
      }
      return false
    },
    findService(taskTypeUUID) {
      for (const service of this.services) {
        if (service.uuid === taskTypeUUID) {
          return service
        }
      }
      return false
    },
    formatWeekString() {
      const tmp = new Date()//this.weekStartDate.getTime()
      const fromMonth = formatMonth(tmp.getMonth())
      const fromDay = tmp.getDate()
      tmp.setDate(tmp.getDate() + 6)
      const toMonth = formatMonth(tmp.getMonth())
      const toDay = tmp.getDate()
      return `${fromDay} ${fromMonth} - ${toDay} ${toMonth}`
    },
    chartOptions(service) {
      const usedTime = this.calculateUsedTime(service.uuid)
      const capacityData = service.service_id in this.capacityData ? this.capacityData[service.service_id] : {}
      const scheduledTime = service.service_id in this.plan ? this.plan[service.service_id] : {}
      const commonJobIds = Array.from(new Set([...Object.keys(usedTime), ...Object.keys(capacityData), ...Object.keys(scheduledTime)]))

      const labels = []
      const plannedData = []
      const usedData = []
      const scheduledData = []
      // Get common job names for each service
      for (const jobId of commonJobIds) {
        const name = jobId in this.jobs ? `${this.jobs[jobId].ID}\n${this.jobs[jobId].Client.Name}\n${this.jobs[jobId].Name}` : "Unknown"
        labels.push(name)

        const planned = jobId in capacityData ? Math.round(capacityData[jobId]) : 0
        plannedData.push(planned)

        const used = jobId in usedTime ? Math.round(usedTime[jobId]) : 0
        usedData.push(used)

        const scheduled = jobId in scheduledTime ? Math.round(scheduledTime[jobId].allocated_hours) : 0
        scheduledData.push(scheduled)
      }

      return {
        legend: {},
        grid: {
          containLabel: true
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow"
          }
        },
        xAxis: {
          type: "category",
          data: labels,
          axisLabel: {
            interval: 0,
            rotate: 60
          },
          splitLine: {
            show: true,
          },
          splitArea: {show: true},
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            name: "Planned",
            type: "bar",
            data: plannedData,
            itemStyle: {
              color: "#FF8138"
            },
          },
          {
            name: "Scheduled",
            type: "bar",
            data: scheduledData,
            itemStyle: {
              color: "#99ee99"
            }
          },
          {
            name: "Used",
            type: "bar",
            data: usedData,
            itemStyle: {
              color: "#92C6FF"
            }

          },
        ]
      }
    },
  },
  mounted() {
    this.loadAll()
  },
  data() {
    return {
      title: "Weekly Capacity Plan By Service",
      showTitle: false,
      editable: false,
      isReady: false,
      isPrimaryReady: false,
      valid: true,
      period: getWeekNumber(new Date()),
      services: [],
      jobs: {},
      data: []
    }
  },
})

</script>

<style scoped>
.chart-area {
  display: flex;
}

.chart {
  height: 1000px;
}
</style>