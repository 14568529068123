<template>
  <v-container fluid>
    <h1>Invoice summary</h1>
    <v-container>
      <v-row>
        <v-col cols="6">
          <JobsNavigator v-model="selectedJob" @jobChanged="selectedJob=$event"></JobsNavigator>
        </v-col>
        <v-col cols="6">
          <v-card>
            <v-card-text>
              <div>
                <span>Invoice data: {{ dateRangeText }}</span>
                <v-btn @click="showInvoiceDataRange = !showInvoiceDataRange" icon color="primary">
                  <v-icon>mdi-menu-down</v-icon>
                </v-btn>
                <v-date-picker v-model="dates" range :show-current="false" v-if="showInvoiceDataRange"/>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <JobBasicInfo :jobId="this.selectedJob"></JobBasicInfo>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <JobsInvoiceReview :jobId="selectedJob" :startDate="dates[0]" :endDate="dates[1]" :key="renderKey1"
                             :invoicePeriod="invoicePeriod" @changed="updateInvoiceData"></JobsInvoiceReview>
          <p>Changes below will populate once the save button is pressed</p>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <InvoicedCheckbox :jobId="selectedJob" :invoicePeriod="invoicePeriod" :key="renderKey1"></InvoicedCheckbox>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-card class="mt-5">
            <v-card-text>
              <v-text-field v-model.number="toInvoiceOverallDollarsOverride" label="Invoice Amount Override ($)" prefix="$" class="complete" @change="validateInput"></v-text-field>
              <v-btn color="primary" @click="updateOverride">Update</v-btn>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <JobInvoiceBudgetSummary :invoiceData="invoiceData" v-if="invoiceData" :key="renderKey2"/>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <JobInvoiceTimeSummary :invoiceData="invoiceData" v-if="invoiceData" :key="renderKey2"/>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <JobInvoiceCostsSummary :invoiceData="invoiceData" v-if="invoiceData" :key="renderKey2"/>
        </v-col>
      </v-row>

    </v-container>
  </v-container>
</template>

<script>
import JobInvoiceTimeSummary from "../components/job/JobInvoiceTimeSummary"
import JobInvoiceCostsSummary from "../components/job/JobInvoiceCostsSummary"
import JobInvoiceBudgetSummary from "../components/job/JobInvoiceBudgetSummary"
import JobBasicInfo from "@/components/job/JobBasicInfo.vue"
import JobsInvoiceReview from "@/components/job/JobInvoiceReview.vue"
import JobsNavigator from "@/components/jobs/JobsNavigator.vue"
import {toLocalString} from "@/lib/dateTimeUtilities"
import InvoicedCheckbox from "@/components/invoicing/InvoicedCheckbox.vue"
import {extractNumber} from "@/lib/validationRules"

export default {
  name: "InvoicingPage",
  permissions: [...JobInvoiceTimeSummary.permissions, ...JobInvoiceCostsSummary.permissions,
    ...JobInvoiceBudgetSummary.permissions, ...JobsNavigator.permissions, ...JobsInvoiceReview.permissions],
  components: {
    InvoicedCheckbox,
    JobsNavigator,
    JobsInvoiceReview,
    JobBasicInfo,
    JobInvoiceTimeSummary,
    JobInvoiceCostsSummary,
    JobInvoiceBudgetSummary,
  },
  watch: {},
  methods: {
    validateInput() {
      const clean = extractNumber(this.toInvoiceOverallDollarsOverride)
      if (clean !== this.toInvoiceOverallDollarsOverride) {
        this.toInvoiceOverallDollarsOverride = clean
      }
    },
    updateInvoiceData(invoiceData) {
      this.invoiceData = invoiceData
      this.toInvoiceOverallDollarsOverride = this.invoiceData.toInvoiceOverallDollars
      this.saveInvoiceAmount()
    },
    updateOverride() {
      this.invoiceData.toInvoiceOverallDollars = this.toInvoiceOverallDollarsOverride
      this.invoiceData.afterInvoicingOverallDollars = this.invoiceData.previouslyInvoicedOverallDollars + this.invoiceData.toInvoiceOverallDollars
      this.saveInvoiceAmount()
    },
    saveInvoiceAmount() {
      const data = {
        job_id: this.selectedJob,
        month_str: this.invoicePeriod,
        invoiced_amount: this.invoiceData.toInvoiceOverallDollars,
      }
      this.$BwfApi.post("invoice/task-review", data).then(() => {
        // this.$notify.toast("Saved")
      })
    },
  },
  computed: {
    renderKey1: function () {
      if (this.selectedJob && this.dates[0] && this.dates[1]) {
        return Math.abs(this.$hash(`${this.selectedJob}${this.dates[0]}${this.dates[1]}`)).toString()
      } else {
        return Math.random().toString()
      }
    },
    renderKey2: function () {
      const invoiceDataHash = this.$hash(JSON.stringify(this.invoiceData))
      if (this.selectedJob && this.dates[0] && this.dates[1]) {
        return Math.abs(this.$hash(`${this.selectedJob}${this.dates[0]}${this.dates[1]}${invoiceDataHash}`)).toString()
      } else {
        return Math.random().toString()
      }
    },
    invoicePeriod() {
      if (this.dates[1]) {
        return this.dates[1].substring(0, 7) //yyyy-mm
      } else {
        return "0000-00"
      }
    },
    dateRangeText() {
      return this.dates.join(" - ")
    },
  },
  mounted() {
  },
  data() {
    const now = new Date()
    const startOfLastMonth = new Date(now.getFullYear(), now.getMonth() - 1, 1)
    const endOfLastMonth = new Date(now.getFullYear(), now.getMonth(), 0)
    const start = toLocalString(startOfLastMonth).substr(0, 10)
    const end = toLocalString(endOfLastMonth).substr(0, 10)
    return {
      selectedJob: undefined,
      dates: [start, end],
      showInvoiceDataRange: false,
      invoiceData: {},
      toInvoiceOverallDollarsOverride: 0
    }
  },
}

</script>

<style scoped>
.complete {
  width: 150px
}
</style>