<template>
  <BfwComponent :isReady="isReady" :title="title" :showTitle="showTitle" :editable="editable" :hideCard="true"
                loaderType="list-item">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-row>
        <v-col cols="12">
          <v-select dense :items="filteredCosts" label="Cost" v-model="selectedCostUUID" @change="costSelected"></v-select>
        </v-col>
      </v-row>
    </v-form>
  </BfwComponent>
</template>

<script>
import BfwComponent from "../BwfComponent"

export default {
  name: "CostSelector",
  permissions: ["Modify Job Cost"],
  props: ["jobId", "value"],
  components: {
    BfwComponent
  },
  watch: {
    jobId: function () {
      this.loadCosts()
    },
    value: function () {
      this.selectedCostUUID = this.value
    }
  },
  computed: {
    filteredCosts: function () {
      const filteredCosts = []
      for (const i in this.costs) {
        const cost = this.costs[i]
        const d = cost.Date.substr(0, 10)
        const date = new Date(d)
        const note = cost.Note ? cost.Note : ""
        const description = d + ": " + cost.Description + " - " + note + " (" + cost.Quantity + " @ $" + cost.UnitCost + " = $" + cost.Quantity * cost.UnitCost + ")"
        filteredCosts.push({text: description, value: cost.UUID, date: date})
      }
      filteredCosts.sort((a, b) => {
        return b.date.getTime() - a.date.getTime()
      })
      return filteredCosts
    }
  },
  methods: {
    loadCosts() {
      if (this.jobId) {
        this.$WfmApi.get("job.api/costs/" + this.jobId).then(response => this.processResponse(response))
      }
    },
    processResponse(response) {
      if (response.Costs) {
        this.costs = this.$ensureArray(response.Costs.Cost)
      } else {
        this.costs = []
      }
      this.isReady = true
    },
    costSelected() {
      const _this = this
      this.costs.forEach(cost => {
        if (cost.UUID === this.selectedCostUUID) {
          _this.$emit("input", cost.UUID)
          _this.$emit("costChanged", cost)
        }
      })
    }
  },
  mounted() {
    this.selectedCostUUID = this.value
    this.loadCosts()
  },
  data() {
    return {
      title: "Cost selector",
      showTitle: false,
      editable: false,
      isReady: false,
      valid: true,
      disabled: false,
      settings: {},

      selectedCostUUID: "",
      costs: []
    }
  },
}

</script>

<style scoped>

</style>