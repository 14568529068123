<template>
  <v-container grid-list-md fluid>
    <h1 class="display-inline">Job Review</h1>
    <div class="display-inline ml-3">
      <WeeklyPlanAddItemPopup :jobId="selectedJobId"></WeeklyPlanAddItemPopup>
    </div>

    <div class="my-layout">
      <v-row>
        <v-col>
          <JobSetupCheck :jobId="selectedJobId"></JobSetupCheck>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <JobsNavigator @jobChanged="jobChanged"></JobsNavigator>
          <JobBasicInfo :jobId="selectedJobId"></JobBasicInfo>
          <JobServiceSummary :jobId="selectedJobId"></JobServiceSummary>
          <JobTaskSummary :jobId="selectedJobId"></JobTaskSummary>
        </v-col>

        <v-col cols="6">
          <JobBurnUp :jobId="selectedJobId"></JobBurnUp>
          <JobMaterialsBudgetSummary :jobId="selectedJobId"></JobMaterialsBudgetSummary>
          <JobScheduleSummary :jobId="selectedJobId"></JobScheduleSummary>
          <JobRecentTime :jobId="selectedJobId"></JobRecentTime>
          <JobMonthProgress :jobId="selectedJobId" :monthStr="monthStr"></JobMonthProgress>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <CapacityPlannerStandalone :jobId="selectedJobId"></CapacityPlannerStandalone>
        </v-col>
      </v-row>

    </div>
  </v-container>
</template>

<script>
import JobsNavigator from "../components/jobs/JobsNavigator"
import JobBasicInfo from "../components/job/JobBasicInfo"
import JobTaskSummary from "../components/job/JobTaskSummary"
import JobMaterialsBudgetSummary from "../components/job/JobMaterialsBudgetSummary"
import JobScheduleSummary from "../components/job/JobScheduleSummary"
import JobBurnUp from "../components/job/JobBurnUp"
import JobRecentTime from "../components/job/JobRecentTime"
import JobMonthProgress from "@/components/capacity/JobMonthProgress.vue"
import JobSetupCheck from "@/components/job/JobSetupCheck.vue"
import WeeklyPlanAddItemPopup from "@/components/capacity/WeeklyPlanAddItemPopup.vue"
import CapacityPlannerStandalone from "@/components/capacity/CapacityPlannerStandalone.vue"
import JobServiceSummary from "@/components/job/JobServiceSummary.vue"

export default {
  name: "JobsReview",
  permissions: [...JobsNavigator.permissions, ...JobBasicInfo.permissions, ...JobTaskSummary.permissions,
    ...JobMaterialsBudgetSummary.permissions, ...JobScheduleSummary.permissions, ...JobBurnUp.permissions,
    ...JobRecentTime.permissions, ...JobMonthProgress.permissions, ...CapacityPlannerStandalone.permissions,
      ...JobServiceSummary.permissions],
  components: {
    JobServiceSummary,
    CapacityPlannerStandalone,
    WeeklyPlanAddItemPopup,
    JobSetupCheck,
    JobMonthProgress, JobsNavigator, JobBasicInfo, JobTaskSummary, JobMaterialsBudgetSummary,
    JobScheduleSummary, JobBurnUp, JobRecentTime
  },
  computed: {
    monthStr() {
      const d = new Date()
      return d.getFullYear().toString() + "-" + (d.getMonth() + 1).toString().padStart(2, "0")
    }
  },
  methods: {
    jobChanged: function (jobId) {
      this.selectedJobId = jobId
    }
  },
  mounted() {
  },
  data() {
    return {
      selectedJobId: ""
    }
  },
}

</script>

<style scoped>
.v-card {
  margin-bottom: 10px !important;
}

.display-inline {
  display: inline-block;
}
</style>