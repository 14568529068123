<template>
  <BfwComponent :isReady="isReady" :title="title" :showTitle="showTitle" :editable="editable">
    <v-form ref="form" v-model="valid" lazy-validation :isReady="isReady">
      <v-row>
        <v-col>
          <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
          <v-data-table :headers="headers" :items="clients" :items-per-page="1000" :search="search" :custom-filter="filter" hide-default-footer>

            <template v-slot:item.Contacts="{item}">
              <div v-for="contact in item.Contacts.Contact" v-bind:key="contact.UUID">
                <a @click="selectContact(contact, item)">{{ contact.Name }}</a>
              </div>
            </template>

          </v-data-table>
        </v-col>
      </v-row>

     <ClientPopup :selected-client="selectedClient" :selected-contact="selectedContact"/>
    </v-form>
  </BfwComponent>
</template>

<script>
import BfwComponent from "../BwfComponent"
import ClientPopup from "@/components/clients/ClientPopup.vue"

export default {
  name: "ClientsContactList",
  permissions: ["Read Client", ...ClientPopup.permissions],
  components: {
    ClientPopup,
    BfwComponent,
  },
  methods: {
    processResponse(response) {
      this.clients = this.$ensureArray(response.Clients.Client)
      for (let i = 0; i < this.clients.length; i++) {
        if (!this.clients[i].Contacts) {
          this.clients[i].Contacts = {Contact: []}
        }
        this.clients[i].Contacts.Contact = this.$ensureArray(this.clients[i].Contacts.Contact)
      }
      this.isReady = true
    },
    selectContact(contact, client) {
      this.selectedContact = contact
      this.selectedClient = client
    },
    filter: function (value, searchStr, item) {
      const searchRegExp = new RegExp(searchStr, "i")
      if (item.Address && item.Address.search(searchRegExp) !== -1) {
        return true
      }
      if (item.Name && item.Name.search(searchRegExp) !== -1) {
        return true
      }
      if (item.Phone && item.Phone.search(searchRegExp) !== -1) {
        return true
      } //todo regex this
      for (let i = 0; i < item.Contacts.Contact.length; i++) {
        const contact = item.Contacts.Contact[i]
        if (contact.Name && contact.Name.search(searchRegExp) !== -1) {
          return true
        }
        if (contact.Email && contact.Email.search(searchRegExp) !== -1) {
          return true
        }
        if (contact.Mobile && contact.Mobile.search(searchRegExp) !== -1) {
          return true
        } //todo regex this
        if (contact.Phone && contact.Phone.search(searchRegExp) !== -1) {
          return true
        } //todo regex this
      }
      return false
    }
  },
  mounted() {
    this.$WfmApi.get("client.api/list").then(response => this.processResponse(response))
  },
  data() {
    return {
      title: "Contacts List",
      showTitle: false,
      editable: false,
      isReady: false,
      valid: true,
      search: "",
      settings: {},
      clients: [],
      selectedContact: {},
      selectedClient: {},
      headers: [
        {text: "Client", value: "Name"},
        {text: "Contact", value: "Contacts"},
      ],
    }
  },
}

</script>

<style scoped>

</style>