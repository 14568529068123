<template>
  <div v-if="loaded">
    <h3>Overall Planned Time</h3>
    <v-chart class="chart" :option="chartOptions()" autoresize/>
  </div>
</template>

<script>
import {use} from "echarts/core"
import {CanvasRenderer} from "echarts/renderers"
import {LineChart} from "echarts/charts"
import {
  LegendComponent,
  GridComponent,
  MarkLineComponent,
  TooltipComponent,
} from "echarts/components"
import VChart from "vue-echarts"


use([
  CanvasRenderer,
  LineChart,
  LegendComponent,
  GridComponent,
  MarkLineComponent,
  TooltipComponent,
])

export default {
  name: "PipelineGraph",
  components: {VChart},
  permissions: ["Read Timeseries"],
  methods: {
    loadTimeseries() {
      const query = {
        series_name: 'Planned Hours',
        item_name: '',
      }

      return this.$BwfApi.get("timeseries", query).then(response => {
        this.data = response
        this.loaded=true
      })
    },
    chartOptions() {
      const chartData = []
      for (const row of this.data) {
        const d = new Date(row.record_time)
        chartData.push([d, row.value])
      }

      const min = new Date()
      min.setMonth(min.getMonth() - 18)
      const max = new Date()
      return {
        xAxis: {
          type: "time",
          min: min,
          max: max,
        },
        yAxis: {
          type: "value",
          name: "Planned time (hours)",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow"
          }
        },
        series: [
          {
            name: "Value",
            type: "line",
            smooth: true,
            data: chartData,
          },
        ]
      }
    },
  },
  mounted() {
    this.loadTimeseries()
  },
  data() {
    return {
      loaded: false,
      data: {}
    }
  }
}
</script>

<style scoped>
.chart {
  height: 600px;
}

</style>