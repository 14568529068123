<template>
  <BfwComponent :isReady="isReady" :title="title" :showTitle="showTitle" :editable="editable" :hideCard="true"
                loaderType="list-item">
    <v-form ref="form" v-model="valid" lazy-validation>
      <div class="row">
        <div class="col pad-heading bold">
          WorkFlowMax Task
        </div>
        <div class="col bold">
          Service Name
        </div>
      </div>
      <draggable tag="ul" :list="taskTypes" class="list-group" handle=".handle">
        <li class="list-group-item row" v-for="task in taskTypes" :key="task.uuid">
          <v-icon class="handle">mdi-format-align-justify</v-icon>
          <div class="col">
            <v-checkbox :label="task.Name" v-model="task.enabled"></v-checkbox>
          </div>
          <div class="col">
            <v-text-field label="Service Name" v-model="task.serviceName"/>
          </div>
        </li>
      </draggable>
      <v-btn color="primary" @click="save">Save</v-btn>
    </v-form>

  </BfwComponent>
</template>

<script>
import BfwComponent from "../BwfComponent"
import draggable from "vuedraggable"

export default {
  name: "ServiceAdmin",
  permissions: ["Administer Betaworkflow"],
  components: {
    BfwComponent,
    draggable
  },
  computed: {},
  mounted() {
    this.loadWFMTasks()
  },
  data() {
    return {
      title: "Service Setup",
      showTitle: false,
      editable: false,
      isReady: true,
      valid: true,
      taskTypes: []
    }
  },
  methods: {
    loadWFMTasks() {
      this.$WfmApi.get("task.api/list").then(response => {
        this.taskTypes = this.$ensureArray(response.TaskList.Task)
        this.loadBwfServices()
      })
    },
    loadBwfServices() {
      this.$BwfApi.get("services").then(response => {
        // initialize new values
        for (const taskTypeIdx in this.taskTypes) {
          this.taskTypes[taskTypeIdx].serviceId = -1
          this.taskTypes[taskTypeIdx].initialSequence = -1
          this.taskTypes[taskTypeIdx].enabled = false
          this.taskTypes[taskTypeIdx].serviceName = ""
        }

        // update values
        let maxSequence = 0
        for (const service of response) {
          const taskTypeIdx = this.findService(service.uuid)
          if (taskTypeIdx) {
            this.taskTypes[taskTypeIdx].serviceId = service.service_id
            this.taskTypes[taskTypeIdx].initialSequence = service.sequence
            this.taskTypes[taskTypeIdx].enabled = service.enabled
            this.taskTypes[taskTypeIdx].serviceName = service.name ? service.name : ""
            maxSequence = Math.max(maxSequence, service.sequence)
          }
        }

        // add sequence information where it does not exist
        maxSequence += 1
        for (const taskTypeIdx in this.taskTypes) {
          if (this.taskTypes[taskTypeIdx].initialSequence === -1) {
            this.taskTypes[taskTypeIdx].initialSequence = maxSequence
            maxSequence += 1
          }
        }
        // put into order with enabled at the top then by sequence number
        this.taskTypes.sort((a, b) => {
          return a.initialSequence - b.initialSequence || a.enabled > b.enabled
        })
      })
    },
    findService(uuid) {
      for (const idx in this.taskTypes) {
        if (this.taskTypes[idx].UUID === uuid) {
          return idx
        }
      }
      return undefined
    },
    save() {
      const services = []
      this.taskTypes.forEach((item, idx) => {
        services.push({
          service_id: item.serviceId,
          name: item.serviceName ? item.serviceName : "",
          enabled: item.enabled,
          sequence: idx, // new order is determined by th array order
          uuid: item.UUID
        })
      })
      this.$BwfApi.post("services", services).then(() => {
        this.$notify.toast('Saved')
        this.loadWFMTasks() // reload all
      })
    }
  }
}

</script>

<style scoped>
.handle {
  float: left;
  padding-top: 8px;
  padding-bottom: 8px;
  cursor: move;
}

.list-group {
  padding: 0
}

.list-group-item {
  list-style-type: none;
  border: 1px solid lightgrey;
  margin-bottom: 5px;
  padding: 5px;
  line-height: 30px;
}

.bold {
  font-weight: bold;
}

.pad-heading {
  padding-left: 50px
}
</style>