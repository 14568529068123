<template>
  <v-container fluid>
    <h1>Capacity Report</h1>
    <div v-if="isReady">
      <v-row>
        <v-col>
          <h2 class="text-center">Overall</h2>
          <CapacitySummaryGraph :plannedOverall="chartData" :services="services" :scheduledOverall="scheduled"
                                :capacity="capacity" :timeUsedOverall="timeUsedOverall"/>
        </v-col>
      </v-row>

      <v-row v-for="service in services" v-bind:key="service.service_id">
        <v-col>
          <h2 class="text-center">{{ service.name }}</h2>
          <CapacitySummaryGraph :plannedOverall="chartData" :services="[service]" :scheduledOverall="scheduled"
                                :capacity="capacity" :timeUsedOverall="timeUsedOverall"/>
        </v-col>
      </v-row>
    </div>
    <div v-else class="text-center pa-4">
      <v-progress-circular indeterminate size="150" width="10" color="primary">Loading</v-progress-circular>
    </div>
  </v-container>

</template>

<script>
import CapacitySummaryGraph from "@/components/capacity/CapacitySummaryGraph.vue"
import {getDateOfISOWeek, getWeekFraction, getWeekNumber, toLocalString} from "@/lib/dateTimeUtilities"
import {getAllTimeEntries} from "@/lib/lookup"

export default {
  name: "ReportLongTermCapacityPage",
  permissions: [...CapacitySummaryGraph.permissions, "Read Services", "Read Job", "Read Capacity Plan", "Read Time", "Read Capacity"],
  components: {CapacitySummaryGraph},
  mounted() {
    const d = new Date()
    d.setMonth(d.getMonth() - 3) // start 3 months in history
    this.startYear = d.getFullYear()
    this.startMonth = d.getMonth() + 1
    this.endYear = this.startYear + 1 // end one year in the future
    this.endMonth = this.startMonth

    this.loadServices()
    this.loadJobs()
    this.loadCapacity()
  },
  computed: {
    isReady: function () {
      return this.servicesLoaded && this.jobsLoaded && this.capacityLoaded && this.timeUsedLoaded && this.plannedOverallLoaded
    }
  },
  methods: {
    loadServices() {
      this.servicesLoaded = false
      this.$BwfApi.get("services", {enabled: true}).then(response => {
        this.services = response
        this.initializeChartData()
        this.servicesLoaded = true
      })
    },
    loadJobs() {
      this.jobsLoaded = false
      const endDate = new Date()
      const startDate = new Date()
      startDate.setFullYear(startDate.getFullYear() - this.$WfmApi.lookback)
      const start = toLocalString(startDate).substr(0, 10).replace(/-/g, "")
      const end = toLocalString(endDate).substr(0, 10).replace(/-/g, "")
      const query = {"from": start, "to": end, "detailed": "true"}

      this.$WfmApi.get("job.api/list", query).then(response => {
        this.jobs = this.$ensureArray(response.Jobs.Job)
        this.jobsLoaded = true
        this.loadTimeUsed()
        this.loadPlannedOverall()
        this.loadAllScheduledData()
      })
    },
    initializeChartData() {
      // services template
      const servicesTemplate = {}
      for (const service of this.services) {
        servicesTemplate[service.service_id] = 0
      }

      // build obj of months
      const start = this.startYear * 12 + this.startMonth
      const end = this.endYear * 12 + this.endMonth

      const months = {}
      let month = this.startMonth
      let year = this.startYear
      for (let i = start; i <= end; i++) {
        if (month === 13) {
          month = 1
          year += 1
        }
        const monthStr = year.toString() + "-" + month.toString().padStart(2, "0")
        months[monthStr] = servicesTemplate
        month += 1
      }

      this.chartData = months
    },
    loadPlannedOverall() {
      this.plannedOverallLoaded = false
      this.$BwfApi.get("capacity-plan/all", {startMonth: this.startMonth, startYear: this.startYear}).then(response => {
        this.plannedOverallLoaded = true
        for (const monthString in response) {
          for (const serviceId in response[monthString]) {
            const month = {...this.chartData[monthString]}
            month[serviceId] = response[monthString][serviceId]
            this.$set(this.chartData, monthString, month)
          }
        }
      })
    },
    loadAllScheduledData() {
      this.allScheduledDataLoaded = false
      const periods = []
      const d = new Date(this.startYear, this.startMonth - 1, 1)
      const end = new Date(this.endYear, this.endMonth, 1).getTime()
      while (d.getTime() < end) {
        const {week, year} = getWeekNumber(d)
        const period = year + "-" + week.toString().padStart(2, "0")
        periods.push(period)
        d.setDate(d.getDate() + 7)
      }

      this.$BwfApi.get("staff-plan", {staff_uuid: "all", periods: periods.join(",")}).then(response => {
        this.allScheduledDataLoaded = true
        const result = {}
        for (const task of response) {
          if (task.service_id === -1) { // Other jobs
            continue
          }
          const date = getDateOfISOWeek(task.week, task.year)
          const month = date.getMonth() + 1
          const fraction = getWeekFraction(month, task.week, task.year)
          const monthStr = task.year + "-" + month.toString().padStart(2, "0")
          if (!result[monthStr]) {
            result[monthStr] = {}
          }
          if (!result[monthStr][task.service_id]) {
            result[monthStr][task.service_id] = 0
          }
          result[monthStr][task.service_id] += task.allocated_hours * fraction

          // if the week crosses a month boundary, add the fraction to the next month as well
          date.setDate(date.getDate() + 6)
          const eowMonth = date.getMonth() + 1
          if (eowMonth !== month) {
            const fraction = getWeekFraction(eowMonth, task.week, task.year)
            const monthStr = task.year + "-" + eowMonth.toString().padStart(2, "0")
            if (!result[monthStr]) {
              result[monthStr] = {}
            }
            if (!result[monthStr][task.service_id]) {
              result[monthStr][task.service_id] = 0
            }
            result[monthStr][task.service_id] += task.allocated_hours * fraction
          }

        }

        this.scheduled = result
        this.scheduledLoaded = true
      })
    },
    loadTimeUsed() { // load all time entries
      this.timeUsedLoaded = false
      const from = new Date()
      const to = new Date()
      from.setYear(from.getFullYear() - 1) // only look back 1 year for time entries
      getAllTimeEntries(this, from, to).then(timeEntries => {
        this.timeUsedLoaded = true
        const timeUsedOverall = {}
        const timeUsedThisJob = {}
          for (const item of timeEntries) {
            const hours = parseInt(item.Minutes) / 60
            const monthStr = this.shortenDate(item.Date)
            const taskUuid = this.findTaskTypeUuid(item.Job.ID, item.Task.UUID)
            if (!taskUuid) continue

            const service = this.findService(taskUuid)
            if (!service) continue

            if (!(monthStr in timeUsedOverall)) {
              timeUsedOverall[monthStr] = {}
              timeUsedThisJob[monthStr] = {}
            }
            if (!(service.service_id in timeUsedOverall[monthStr])) {
              timeUsedOverall[monthStr][service.service_id] = 0
              timeUsedThisJob[monthStr][service.service_id] = 0
            }
            timeUsedOverall[monthStr][service.service_id] += hours
            if (item.Job.ID === this.jobId) {
              timeUsedThisJob[monthStr][service.service_id] += hours
            }
          }
        this.timeUsedOverall = timeUsedOverall
        this.timeUsedThisJob = timeUsedThisJob
      })
    },
    findService(taskTypeUUID) {
      for (const service of this.services) {
        if (service.uuid === taskTypeUUID) {
          return service
        }
      }
      return false
    },
    findTaskTypeUuid(jobId, taskUUID) {
      const job = this.findJob(jobId)
      if (!job) return false
      if (!("Tasks" in job)) return false

      const tasks = this.$ensureArray(job.Tasks.Task)
      for (const task of tasks) {
        if (task.UUID === taskUUID) {
          return task.TaskUUID
        }
      }
      return false
    },
    findJob(JobId) {
      for (const job of this.jobs) {
        if (job.ID === JobId) {
          return job
        }
      }
      return false
    },
    shortenDate(dateStr) {
      return dateStr.substring(0, 7)
    },
    loadCapacity() {
      this.capacityLoaded = false
      this.$BwfApi.get("capacity").then(response => {
        this.capacityLoaded = true
        const capacity = {}

        response.forEach((row) => {
          const monthStr = row.year.toString() + "-" + row.month.toString().padStart(2, "0")
          const cap = {}
          for (const service_id in row.capacity) {
            const item = row.capacity[service_id]
            cap[item.service_id] = item.capacity
          }
          capacity[monthStr] = {
            reserved: row.reserved_capacity,
            capacity: cap
          }
        })

        this.capacity = capacity
      })
    },
  },
  data() {
    return {
      services: [],
      chartData: {},
      capacity: {},
      timeUsedOverall: {},
      scheduled: {},
      startYear: 0,
      endYear: 0,
      startMonth: 0,
      endMonth: 0,
      jobs: {},
      servicesLoaded: false,
      jobsLoaded: false,
      capacityLoaded: false,
      timeUsedLoaded: false,
      plannedOverallLoaded: false,
      scheduledLoaded: false,
    }
  },
}

</script>

<style scoped>

</style>