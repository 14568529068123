<template>
  <v-card v-if="!hideCard">
    <BwfComponentCore v-bind="$props" v-on="$listeners">
       <slot v-for="(_, name) in $slots" :name="name" :slot="name" />
       <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData">
         <slot :name="name" v-bind="slotData" />
       </template>
    </BwfComponentCore>
  </v-card>
  <div v-else>
     <BwfComponentCore v-bind="$props" v-on="$listeners">
       <slot v-for="(_, name) in $slots" :name="name" :slot="name" />
       <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData">
         <slot :name="name" v-bind="slotData" />
       </template>
     </BwfComponentCore>
  </div>
</template>

<script>
  import BwfComponentCore from './toolkit/BwfComponentCore'

  export default {
    name: "BwfComponent",
    props: {"isReady": {type: Boolean, required: true},
            "showTitle": {type: Boolean, default: true},
            'title': {type: String, required: true},
            'editable': {type: Boolean, default: false},
            'hideCard' : {type:Boolean, default: false},
            'loaderType' : {type:String, default: 'card'},
    },
    components: {
      BwfComponentCore
    },
    methods: {

    },
    mounted() {
    },
    data() {
      return {

      }
    },
  }

</script>

<style scoped>

</style>