aer yo<template>
  <v-card>
    <v-card-title class="text-h5 grey lighten-2">
      Split
    </v-card-title>

    <v-card-text>
      <v-row>
        <v-col class="mt-2 mb-0 pt-0 pb-0 input-label" cols="5">
          What currency is the invoice in?
        </v-col>
        <v-col class="mt-2 mb-0 pt-0 pb-0" cols="4">
          <v-switch v-model="foreignCurrency" :label="foreignCurrency === true ? 'Foreign Currency' : 'Local Currency (NZD)'" @click="recalculateAllSplits" dense></v-switch>
        </v-col>

        <v-col class="mt-2 mb-0 pt-0 pb-0" cols="3">
          <v-text-field label="Foreign Total" v-model="foreignCurrencyTotal" @change="recalculateAllSplits()" class="foreign-currency-input"
                        v-if="foreignCurrency===true"></v-text-field>
        </v-col>
      </v-row>
      <hr class="mt-2 mb-4"/>
      <table class="cost-split-table">
        <thead>
        <tr>
          <th></th>
          <th>NZD</th>
          <th v-if="foreignCurrency===true">Foreign Currency</th>
          <th>%</th>
          <th>Job</th>
        </tr>
        </thead>
        <tr v-for="(item, idx) in costSplits" v-bind:key="item.id">
          <td style="width: 30px">
            {{ idx + 1 }}
          </td>
          <td style="width: 80px">
            <v-text-field :disabled="foreignCurrency===true" v-model="item.amount" prefix="$"
                          class="dollar mr-4" @change="recalculateSplit(item, 'amount')"></v-text-field>
          </td>
          <td style="width: 80px" v-if="foreignCurrency===true">
            <v-text-field v-model="item.foreignAmount"
                          class="dollar mr-4" @change="recalculateSplit(item, 'foreign')"></v-text-field>
          </td>
          <td style="width: 60px">
            <v-text-field v-model="item.percent" suffix="%"
                          class="percent mr-4" @change="recalculateSplit(item, 'percent')"></v-text-field>
          </td>
          <td>
            <v-checkbox v-model="item.is_project_cost" color="primary" class="ma-1" label="Project Cost"></v-checkbox>
            <v-autocomplete label="Job" :items="jobs" v-model="item.job" dense v-if="item.is_project_cost"></v-autocomplete>
          </td>
          <td>
            <v-btn @click="costSplits.splice(idx, 1)" fab x-small color="red" class="ma-1">
              <v-icon>
                mdi-delete-forever
              </v-icon>
            </v-btn>
          </td>
        </tr>

        <tr class="total-row">
          <td>Total:</td>
          <td class="text-right  pr-4">
            {{ costSplitTotalLocal | formatCurrency(2) }}
          </td>
          <td v-if="foreignCurrency===true" class="text-right pr-4">
            {{ costSplitTotalForeign | formatCurrency(2) }}
          </td>
          <td class="text-right  pr-4">
            {{ percentSplitTotal }} %
          </td>
          <td class="text-right pr-4" :class="{'text-red': Math.abs(costSplitDifference) >= 0.01}">
            Difference: {{ costSplitDifference |formatCurrency(2) }}
          </td>
          <td>
            <v-btn color="primary" dense @click="more" fab x-small class="ma-1">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </td>
        </tr>
      </table>

    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn :disabled="Math.abs(costSplitDifference) > 0.01" color="primary" @click="split">
        Done
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "CostSplitter",
  props: ["statementLine", "costs", "jobs"],
  methods: {
    more() {
      this.costSplits.push({amount: 0, percent: 0, job: "", foreignAmount: 0})
    },
    recalculateAllSplits() {
      for (const item of this.costSplits) {
        item.amount = Math.round(item.percent * this.statementLine.chargeable_amount) / 100
        item.foreignAmount = Math.round(item.percent * this.foreignCurrencyTotal) / 100
      }
    },
    recalculateSplit(item, type) {
      if (type === "percent") {
        item.amount = Math.round(item.percent * this.statementLine.chargeable_amount) / 100
        item.foreignAmount = Math.round(item.percent * this.foreignCurrencyTotal) / 100
      } else if (type === "amount") {
        item.percent = Math.round(1000000 * item.amount / this.statementLine.chargeable_amount) / 10000
        item.amount = Math.round(item.percent * this.statementLine.chargeable_amount) / 100
      } else if (type === "foreign") {
        item.percent = Math.round(1000000 * item.foreignAmount / this.foreignCurrencyTotal) / 10000
        item.amount = Math.round(item.percent * this.statementLine.chargeable_amount) / 100
      }
    },
    split() {
      const newCosts = []
      for (const cost of this.costSplits) {
        newCosts.push({
          description: cost.description,
          amount: cost.amount,
          job: cost.job,
          staff: cost.staff,
          wfm_cost_code: cost.wfm_cost_code,
          xero_cost_code: cost.xero_cost_code,
          is_project_cost: cost.is_project_cost || false,
        })
      }

      this.$emit("split", this.statementLine.id, newCosts)
    }
  },
  computed: {
    costSplitTotalLocal: function () {
      let total = 0
      for (const cost of this.costSplits) {
        total += parseFloat(cost.amount)
      }
      return Math.round(100*total)/100
    },
    costSplitTotalForeign: function () {
      let total = 0
      for (const cost of this.costSplits) {
        total += parseFloat(cost.foreignAmount)
      }
      return Math.round(100*total)/100
    },
    percentSplitTotal: function () {
      let total = 0
      for (const cost of this.costSplits) {
        total += parseFloat(cost.percent)
      }
      return Math.round(10000 * total) / 10000
    },
    costSplitDifference: function () {
      if (this.foreignCurrency === true) {
        return this.foreignCurrencyTotal - this.costSplitTotalForeign
      } else {
        return this.statementLine.chargeable_amount - this.costSplitTotalLocal
      }
    },
  },
  data() {
    return {
      foreignCurrency: false,
      foreignCurrencyTotal: 0,
      costSplits: [],
      deletedCosts: []
    }
  },
  mounted() {
    const splits = []
    for (const cost of this.statementLine.costs) {
      const percent = Math.round(100 * cost.amount / this.statementLine.chargeable_amount)
      splits.push({
        description: cost.description,
        amount: cost.amount,
        percent: percent,
        job: cost.job,
        staff: cost.staff,
        foreignAmount: 0,
        wfm_cost_code: cost.wfm_cost_code,
        xero_cost_code: cost.xero_cost_code,
        is_project_cost: cost.is_project_cost || false,
      })
    }
    this.costSplits = splits
  }
}
</script>

<style scoped>
.costs-split-table {
  width: 100%
}

.total-row {
  font-size: 16px;
}

.dollar >>> input {
  width: 80px;
  text-align: right;
}

.percent {
  width: 60px;
}

.percent >>> input {
  text-align: right;
}

.inline-block div {
  display: inline-block;
  padding-right: 10px;
}

.foreign-currency-input {
  width: 80px;
}

.input-label {
  font-size: 15px;
  line-height: 40px;
}

.text-red {
  color: red;
}
</style>