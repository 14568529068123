<script>
import {defineComponent} from "vue"
import ContactFieldEmail from "@/components/contacts/fields/ContactFieldEmail.vue"
import ContactFieldMobile from "@/components/contacts/fields/ContactFieldMobile.vue"
import ContactFieldName from "@/components/contacts/fields/ContactFieldName.vue"
import ContactFieldPhone from "@/components/contacts/fields/ContactFieldPhone.vue"
import Vcard from "vue-vcard"

export default defineComponent({
  name: "ClientPopup",
  components: {Vcard, ContactFieldPhone, ContactFieldName, ContactFieldMobile, ContactFieldEmail},
  permissions: ["Read Client", ...ContactFieldName.permissions, ...ContactFieldPhone.permissions, ...ContactFieldMobile.permissions, ...ContactFieldEmail.permissions],
  props: {
    value: {
      type: Boolean,
    },
    selectedContact: {
      type: Object,
      required: true,
    },
    selectedClient: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    dialog: false,
  }),
  computed: {
    firstName() {
      const name = this.$splitName(this.selectedContact.Name)
      return name.first
    },
    lastName() {
      const name = this.$splitName(this.selectedContact.Name)
      return name.last
    }
  },
  methods:{
    close(){
      this.$emit("input", false)
    }
  },
  watch: {
    selectedContact: {
      handler: function () {
        this.dialog = true
      },
      deep: true,
    },
    value: {
      handler: function () {
        this.dialog = this.value
      },
      deep: true,
    },
  },
})
</script>

<template>
  <v-dialog v-model="dialog" max-width="500" :key="selectedContact.UUID">
    <v-card>
      <v-card-title class="headline">{{ selectedContact.Name }}</v-card-title>
      <v-card-text>
        <v-simple-table>
          <template v-slot:default>
            <tbody>
            <tr>
              <th class="text-left">Name</th>
              <td>
                <ContactFieldName :contact="selectedContact" :clientId="selectedClient.UUID"/>
              </td>
            </tr>
            <tr>
              <th class="text-left">Phone</th>
              <td>
                <ContactFieldPhone :contact="selectedContact" :clientId="selectedClient.UUID"/>
              </td>
            </tr>
            <tr>
              <th class="text-left">Mobile</th>
              <td>
                <ContactFieldMobile :contact="selectedContact" :clientId="selectedClient.UUID"/>
              </td>
            </tr>
            <tr>
              <th class="text-left">Email</th>
              <td>
                <ContactFieldEmail :contact="selectedContact" :clientId="selectedClient.UUID"/>
              </td>
            </tr>
            <tr>
              <th class="text-left">QR Code</th>
              <td>
                <Vcard :key="selectedContact.UUID"
                       :firstName="firstName"
                       :lastName="lastName"
                       :workEmail="selectedContact.Email ? selectedContact.Email : ''"
                       :workPhone="selectedContact.Phone ? selectedContact.Phone : ''"
                       :homePhone="selectedContact.Mobile ? selectedContact.Mobile : ''"
                       :orgName="selectedClient.Name ? selectedClient.Name : ''"
                />
              </td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="green darken-1" text @click="close">
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

</template>

<style scoped>

</style>