<template>
  <BfwComponent :isReady="isReady" :title="title" :showTitle="showTitle" :editable="editable" :hideCard="false"
                loaderType="list-item">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-row>
        <v-col cols="10">
          <v-autocomplete v-model="selectedJob" :items="filteredJobs" dense label="Job" @change="jobChanged"></v-autocomplete>

        </v-col>
        <v-col cols="1">
          <v-btn icon @click="more = !more">
            <v-icon>mdi-dots-horizontal</v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-row v-if="more">
        <v-col cols="12" class="mt-0">
          <v-select label="Sort by" v-model="sort" :items="sortOptions" @change="saveFavourite"></v-select>
          <label class="v-label theme--light">Filter</label>
          <v-select dense :items="jobStates" label="Job State" v-model="selectedJobState" multiple @change="saveFavourite"></v-select>
        </v-col>
      </v-row>

    </v-form>
  </BfwComponent>
</template>

<script>
import BfwComponent from "../BwfComponent"
import {jobStates, defaultJobStates} from "@/lib/constants"
import {toLocalString} from "@/lib/dateTimeUtilities"


export default {
  name: "JobsSelector",
  permissions: ["Read Job"],
  props: {
    value: String,
    defaultSort: {default: "Job Name"},  // 'Manager' is also valid
    urlNavigation: {default: true}
  },
  components: {
    BfwComponent
  },
  computed: {
    filteredJobs: function () {
      const filteredJobs = []
      for (const i in this.jobs) {
        const job = this.jobs[i]
        if (this.settings.jobTypes.includes(job.Type)) {
          if (this.selectedJobState.length === 0 || this.selectedJobState.includes(job.State)) {
            const managerName = (job.Manager) ? job.Manager.Name.toUpperCase() : ""
            filteredJobs.push({text: job.ID + " - " + job.Client.Name + " - " + job.Name, value: job.ID, manager: managerName})
          }
        }
      }

      if (this.sort === "Manager") {
        filteredJobs.sort(function (a, b) {
          if (a.manager < b.manager) return -1
          if (a.manager > b.manager) return 1
          return 0
        })
      } else if (this.sort === "Job Name") {
        filteredJobs.sort(function (a, b) {
          if (a.text < b.text) return -1
          if (a.text > b.text) return 1
          return 0
        })

      }

      return filteredJobs
    }
  },
  methods: {
    loadFavourite() {
      this.$BwfApi.get("user-settings").then(settings => {
        for (const settingName in settings) {
          if (settingName.startsWith("job-selector:")) {
            const parts = settingName.split(":")
            if (parts.length === 2 && parts[1] === this.$route.path) {
              const settingValue = settings[settingName]
              this.sort = settingValue.sort
              this.selectedJobState = settingValue.selectedJobState
            }
          }
        }
      })
    },
    saveFavourite() {
      const setting = {
        sort: this.sort,
        selectedJobState: this.selectedJobState
      }
      this.$BwfApi.post("user-settings", {"setting_name": `job-selector:${this.$route.path}`, "setting_value": setting})
    },
    processResponse(response) {
      this.jobs = this.$ensureArray(response.Jobs.Job)
      this.isReady = true

      if (this.value) {
        this.selectedJob = this.value
      } else if (this.$route.query.JobId) {
        this.selectedJob = this.$route.query.JobId
      } else if (this.filteredJobs.length > 0) {
        this.selectedJob = this.filteredJobs[0].value
      }
      this.jobChanged()
    },
    findJob(jobId) {
      for (const job of this.jobs) {
        if (jobId === job.ID) return job
      }
    },
    jobChanged() {
      this.$emit("input", this.selectedJob)
      this.$emit("jobChanged", this.findJob(this.selectedJob))
      if (this.urlNavigation) {
        if (!(this.$route.query.JobId && this.$route.query.JobId === this.selectedJob)) {
          this.$router.replace(this.$route.path + "?JobId=" + this.selectedJob)
        }
      }
    },

  },
  mounted() {
    this.sort = this.defaultSort
    this.loadFavourite()
    const endDate = new Date()
    const startDate = new Date()
    startDate.setFullYear(startDate.getFullYear() - this.$WfmApi.lookback)
    const start = toLocalString(startDate).substr(0, 10).replace(/-/g, "")
    const end = toLocalString(endDate).substr(0, 10).replace(/-/g, "")
    const query = {"from": start, "to": end}

    this.$WfmApi.get("job.api/list", query).then(response => this.processResponse(response))

  },
  data() {
    return {
      title: "Job selector",
      showTitle: false,
      editable: false,
      isReady: false,
      valid: true,
      disabled: false,
      sort: "Job Name", // job name
      more: false,
      sortOptions: ["Job Name", "Manager"],
      settings: {
        jobTypes: ["Consulting", "Production"],
      },

      selectedJob: 0,
      jobs: [],
      jobStates: jobStates,
      selectedJobState: defaultJobStates,
    }
  },
}

</script>

<style scoped>

</style>