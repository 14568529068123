<template>
  <BfwComponent :isReady="isReady" :title="title" :showTitle="showTitle" :editable="editable" loaderType="list-item-avatar-three-line">
    <v-form ref="form" v-model="valid" lazy-validation :isReady="isReady">
      <v-row>
        <v-col>
          <v-data-table dense hide-default-footer :headers="headers" :items="tableData">
            <template v-slot:item.budget="{item}">
              {{ item.budget | formatCurrency }}
            </template>

            <template v-slot:item.used="{item}">
              {{ item.used | formatCurrency }}
            </template>

            <template v-slot:item.remaining="{item}">
              {{ item.remaining | formatCurrency }}
            </template>

            <template v-slot:item.graph="{item}">
              <OneLineGraph :used="item.used" :budget="item.budget"/>
            </template>
          </v-data-table>

        </v-col>
      </v-row>
    </v-form>
  </BfwComponent>
</template>

<script>
import BfwComponent from "../BwfComponent"
import OneLineGraph from "../toolkit/OneLineGraph"

export default {
  name: "JobBudgetSummary",
  permissions: ["Read Job", "Read Job Cost", "Read Quote"],
  props: ["jobId"],
  components: {
    BfwComponent, OneLineGraph
  },
  methods: {
    loadCostCodes() {
      this.$WfmApi.get("cost.api/list", {page: 1}).then(response => {
        this.costCodes = this.$ensureArray(response.Costs.Cost)
      })
    },
    processJobResponse(response) {
      this.job = response.Job
      this.$WfmApi.get("job.api/costs/" + this.jobId).then(response => this.processCostsResponse(response))
    },

    processCostsResponse(response) {
      let total = {}
      if (response.Costs) {
        const costs = this.$ensureArray(response.Costs.Cost)
        costs.forEach(function (cost) {
          const code = cost.Code || "Unspecified"
          const amount = parseFloat(cost.Quantity) * parseFloat(cost.UnitCost)
          total[code] = total[code] || 0
          total[code] += amount
        })
      }

      this.usedAmount = total

      // Get quote
      this.$WfmApi.get("quote.api/get/" + this.job.ApprovedQuoteUUID).then(response => this.processQuotesResponse(response))
    },
    processQuotesResponse(response) {
      const quote = response.Quote
      let total = {}
      if (quote && quote.Costs) {
        const costs = this.$ensureArray(quote.Costs.Cost)
        costs.forEach(function (cost) {
          const code = cost.Code || "Unspecified"
          const amount = parseFloat(cost.Quantity) * parseFloat(cost.UnitCost)
          total[code] = total[code] || 0
          total[code] += amount
        })
      }
      this.quoteAmount = total
      this.isReady = true
    }
  },
  mounted() {
    this.loadCostCodes()
  },
  computed: {
    tableData: function () {
      const tableData = []
      const allCodes1 = Object.keys(this.quoteAmount).concat(Object.keys(this.usedAmount))
      const allCodes = [...new Set(allCodes1)] // unique values only
      for (const code of allCodes) {
        const used = this.usedAmount[code] || 0
        const budget = this.quoteAmount[code] || 0
        const remaining = budget - used
        const name = this.costCodes.find((c) => c.Code === code)?.Description || code

        tableData.push({
          "name": name,
          "budget": budget,
          "used": used,
          "remaining": remaining,
          "graph": "",
        })
      }
      return tableData
    }
  },
  watch: {
    jobId: function () {
      if (this.jobId) {
        this.isReady = false
        this.quoteFound = false
        this.$WfmApi.get("job.api/get/" + this.jobId).then(response => this.processJobResponse(response))
      } else {
        this.isReady = false
      }
    }
  },
  data() {
    return {
      title: "Materials",
      showTitle: true,
      editable: false,
      isReady: false,
      valid: true,

      job: null,
      quoteAmount: {},
      usedAmount: {},
      quoteFound: false,
      costCodes: [],

      headers: [
        {text: "Name", value: "name"},
        {text: "Budget", value: "budget"},
        {text: "Used", value: "used"},
        {text: "Remaining", value: "remaining"},
        {text: "", value: "graph"},
      ],
    }
  },
}

</script>

<style scoped>

.validation-error {
  padding: 3px;
  color: red;
  font-size: smaller;
}


</style>