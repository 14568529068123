<template>
  <div v-if="loaded">
    <h3>Jobs</h3>
    <v-chart class="chart" :option="chartOptions('Charge Rates - Job')" autoresize/>

    <h3>Services</h3>
    <v-chart class="chart" :option="chartOptions('Charge Rates - Service')" autoresize/>

    <h3>Staff</h3>
    <v-chart class="chart" :option="chartOptions('Charge Rates - Staff')" autoresize/>

    <h3>Project Managers</h3>
    <v-chart class="chart" :option="chartOptions('Charge Rates - Project Manager')" autoresize/>

  </div>
</template>

<script>
import {use} from "echarts/core"
import {CanvasRenderer} from "echarts/renderers"
import {LineChart} from "echarts/charts"
import {
  LegendComponent,
  GridComponent,
  MarkLineComponent,
  TooltipComponent,
} from "echarts/components"
import VChart from "vue-echarts"
import {getStaffListAll} from "@/lib/lookup"


use([
  CanvasRenderer,
  LineChart,
  LegendComponent,
  GridComponent,
  MarkLineComponent,
  TooltipComponent,
])

export default {
  name: "ReportChargeRatesGraph",
  components: {VChart},
  permissions: ["Read Timeseries"],
  methods: {
    loadStaff() {
      getStaffListAll(this, (staff) => {
        const list = {}
        for (const item of staff) {
          list[item.wfm_userid] = item.name
        }
        this.staff = list
      })
    },
    loadServices() {
      this.$BwfApi.get("services", {enabled: true}).then(response => {
        const list = {}
        for (const item of response) {
          list[item.service_id] = item.name
        }
        this.services = list
      })
    },
    async loadAll() {
      const timeSeriesLoaders = []
      this.services = await this.$BwfApi.get("services", {enabled: true})
      for (const service of this.services) {
        timeSeriesLoaders.push(this.loadTimeseries("Charge Rates - Service", service.service_id))
      }

      this.staff = await this.$BwfApi.get("staff")
      for (const staffMember of this.staff) {
        timeSeriesLoaders.push(this.loadTimeseries("Charge Rates - Staff", staffMember.wfm_userid))
        timeSeriesLoaders.push(this.loadTimeseries("Charge Rates - Project Manager", staffMember.wfm_userid))
      }

      timeSeriesLoaders.push(this.loadTimeseries("Charge Rates - Job", ""))

      Promise.all(timeSeriesLoaders).then(() => {
        this.loaded = true
        console.log(this.staff)
      })
    },
    loadTimeseries(datasetName, itemName) {
      this.data[datasetName] = {}

      const query = {
        series_name: datasetName,
        item_name: itemName,
      }
      return this.$BwfApi.get("timeseries", query).then(response => {
        this.data[datasetName][itemName] = response
      })
    },
    chartOptions(dataset) {
      const data = this.data[dataset]

      const series = []
      for (const seriesIdentifier in data) {
        const values = []
        for (const row of data[seriesIdentifier]) {
          const d = new Date(row.record_time)
          values.push([d, Math.round(10 * row.value) / 10])
        }

        let seriesName = ""
        if (dataset === "Charge Rates - Service") {
          seriesName = this.services.find(item => item.service_id.toString() === seriesIdentifier).name
        } else if (dataset === "Charge Rates - Staff") {
          seriesName = this.staff.find(item => item.wfm_userid === seriesIdentifier).name
        } else if (dataset === "Charge Rates - Project Manager") {
          seriesName = this.staff.find(item => item.wfm_userid === seriesIdentifier).name
        }

        // hide emtpy series
        if (data[seriesIdentifier].length > 0) {
          series.push({
            name: seriesName,
            type: "line",
            smooth: true,
            data: values,
          })
        }
      }

      const min = new Date()
      min.setMonth(min.getMonth() - 12)
      const max = new Date()

      return {
        xAxis: {
          type: "time",
          min: min,
          max: max,
        },
        yAxis: {
          type: "value",
          name: "Rate",
        },
        legend: {
          top: "30"
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow"
          }
        },
        series: series
      }
    },
  },
  mounted() {
    this.loadAll()
  },
  data() {
    return {
      loaded: false,
      staff: {},
      services: {},
      data: {}
    }
  }
}
</script>

<style scoped>
.chart {
  height: 600px;
}

</style>