<template>
  <v-container fluid>
    <h1>Costs Transaction Matching</h1>
    <p>Use this tool to match bank transactions with invoices.</p>

    <v-row>
      <v-col cols="12">
        <CostTransactionMatching/>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import CostTransactionMatching from "@/components/costs/CostTransactionMatching.vue"
export default {
  name: "CostsImportStaff",
  permissions:[...CostTransactionMatching.permissions],
  components: {
    CostTransactionMatching,
  },
  methods: {
    data() {
      return {
      }
    },
  }
}

</script>

<style scoped>

</style>