<template>
  <BfwComponent :isReady="isReady" :title="title" :showTitle="showTitle" :editable="editable">
    <v-form ref="form" v-model="valid" lazy-validation :isReady="isReady">
      <v-row>
        <v-col>
          <span>{{ recentTimeTotal | formatMinutes }} in the last {{ settings.lookBack }} days</span>

          <div v-if="!expanded">
            <div class="hand" @click="expanded = !expanded"> More...</div>
          </div>
          <div v-else>
            <v-list three-line>
              <template v-for="item in recentTimeEntries">
                <v-divider :key="item.ID"></v-divider>
                <v-list-item :key="item.ID">
                  <v-list-item-content>
                    <v-list-item-title class="bold">{{ item.minutes | formatMinutes }} {{ item.staff }} - {{ item.task }}</v-list-item-title>
                    <v-list-item-subtitle>{{ item.note }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list>
            <div class="hand" @click="expanded = !expanded"> Less...</div>
          </div>
        </v-col>
      </v-row>
    </v-form>
  </BfwComponent>
</template>

<script>
import BfwComponent from "../BwfComponent"

export default {
  name: "JobRecentTime",
  permissions: ["Read Time"],
  props: ["jobId"],
  components: {
    BfwComponent
  },
  methods: {
    processTimeResponse(response) {
      this.timeEntries = []
      if (response.Times) {
        const timeEntries = this.$ensureArray(response.Times.Time)
        const startDate = new Date()
        startDate.setDate(startDate.getDate() - this.settings.lookBack)
        for (const item of timeEntries) {
          const d = new Date(item.Date)
          if (d > startDate) {
            this.timeEntries.push(item)
          }
        }
      }
      this.isReady = true
    },
  },
  mounted() {

  },
  computed: {
    recentTimeEntries: function () {
      const recentEntries = []
      for (const item of this.timeEntries) {
        recentEntries.push({
          ID: item.ID,
          minutes: parseInt(item.Minutes),
          task: item.Task.Name,
          staff: item.Staff.Name,
          note: item.Note,
        })
      }
      return recentEntries
    },
    recentTimeTotal: function () {
      let total = 0
      for (const item of this.timeEntries) {
        const minutes = parseInt(item.Minutes)
        total += minutes
      }
      return total
    }

  },
  watch: {
    jobId: function () {
      if (this.jobId) {
        this.isReady = false
        this.$WfmApi.get("time.api/job/" + this.jobId).then(response => this.processTimeResponse(response))
      } else {
        this.isReady = false
      }
    }
  },
  data() {
    return {
      title: "Recent Time",
      showTitle: true,
      editable: false,
      isReady: false,
      valid: true,
      timeEntries: [],
      expanded: false,
      settings: {
        lookBack: 14 // days history
      }
    }
  },
}

</script>

<style scoped>

</style>