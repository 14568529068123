<template>
  <div>
    <span>Invoice Period: {{ formatMonth(invoicePeriod) }}</span>
    <v-btn @click="showInvoicePeriod = !showInvoicePeriod" icon color="primary">
      <v-icon>mdi-menu-down</v-icon>
    </v-btn>
    <div>
      <v-date-picker v-model="invoicePeriod" :show-current="false" v-if="showInvoicePeriod" type="month"
                     @input="$emit('input', invoicePeriod);showInvoicePeriod=false"></v-date-picker>
    </div>
  </div>
</template>

<script>

import {formatMonth, toLocalString} from "../../lib/dateTimeUtilities"

export default {
  name: "InvoicePeriod",
  permissions: ["Read Job"],
  props: ["value"],
  components: {},
  computed: {},
  methods: {formatMonth},
  mounted() {
    if (this.value) {
      this.invoicePeriod = this.value
    } else {
      this.$emit("input", this.invoicePeriod)
    }
  },
  data() {
    const now = new Date()
    const endOfLastMonth = new Date(now.getFullYear(), now.getMonth(), 0)
    return {
      showInvoicePeriod: false,
      invoicePeriod: toLocalString(endOfLastMonth).substr(0, 7),
    }
  }
}

</script>


<style scoped>
</style>