<template>
  <v-container fluid>
    <h1>Capacity Setup</h1>
    <p>This page is used to configure how much capacity is available each month. This will vary from month to month because of leave, public holidays etc.
      It is expected that the capacity be set with a 12 month horizon, although this can be adjusted at any time.</p>
    <CapacitySetup/>
  </v-container>
</template>

<script>

import CapacitySetup from "@/components/capacity/CapacitySetup.vue"

export default {
  name: "AdminCapacityPage",
  permissions: [...CapacitySetup.permissions],
  components: {CapacitySetup},
  data() {
    return {}
  },
}

</script>

<style scoped>

</style>