<template>
  <v-container fluid>
    <h1>{{ role }} role access</h1>
    <v-data-table v-model='selectedPages' :headers="pageHeaders" :items="pages" item-key='name' hide-default-footer disable-pagination show-select>
      <template v-slot:item.name="{ item }">
        <v-icon>{{ item.icon }}</v-icon>
        {{ item.title }}
      </template>

      <template v-slot:item.permissions="{ item }">
        {{ item.permissions.join(", ") }}
      </template>
    </v-data-table>

    <div>Permissions: {{ permissions.join(", ") }}</div>

    <v-btn @click="save" color="primary" class="ma-1">
      <v-icon>
        mdi-content-save
      </v-icon>
      Save
    </v-btn>


  </v-container>
</template>

<script>
import {routes} from "@/routes"


export default {
  name: "AdminRolePermissions",
  permissions: ["Administer Betaworkflow"],
  props: [],
  components: {},
  data() {
    return {
      title: "Page Access",
      role: "",
      pageHeaders: [
        {text: "Page", value: "name"},
      ],
      selectedPages: [],
    }
  },
  computed: {
    pages: function () {
      return routes.filter(route => {
        return route.menuGroup !== "Internal"
      }).map(route => {
        return {
          name: route.name,
          icon: (route.icon) ? route.icon : "file-document",
          title: `${route.menuGroup} / ${route.title}`,
          permissions: [...new Set(route.component.permissions)],
        }
      }).sort((a, b) => {
        if ( a.title < b.title) return -1
        if ( a.title > b.title) return 1
        return 0
      })
    },
    permissions: function () {
      let allPermissions = []
      this.selectedPages.forEach((page) => {
        allPermissions = allPermissions.concat(page.permissions)
      })
      return [...new Set(allPermissions)].sort()
    }
  },
  methods: {
    save() {
      const data = {
        pages: this.selectedPages.map(page => {
          return page.name
        }), permissions: this.permissions
      }

      this.$BwfApi.post(`admin/role/${this.$route.params.id}/permissions`, data).then(response => {
        this.$notify.toast("Saved")
        this.selectedPages = this.pages.filter(page => {
          return response.pages.includes(page.name)
        })
      })
    }
  },
  mounted() {
    this.$BwfApi.get("admin/roles").then(response => {
      for (const idx in response) {
        if (response[idx].role_id === parseInt(this.$route.params.id)) {
          this.role = response[idx].name
        }
      }

      this.$BwfApi.get(`admin/role/${this.$route.params.id}/permissions`).then(response => {
        this.selectedPages = this.pages.filter(page => {
          return response.pages.includes(page.name)
        })
      })
    })
  },
}

</script>

<style scoped>

</style>