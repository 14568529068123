<template>
  <v-container fluid>
    <div v-if="isReady">
      <h1>Xero Contact - WorkFlowMax Supplier Matching</h1>
      <p>The following table shows all the Suppliers in WorkFlowMax that are not matched to Xero as a Contact.</p>
      <v-data-table :headers="headers" :items="suppliers" dense disable-pagination hide-default-footer>

        <template v-slot:item.delete="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn fab x-small color="red" @click="deleteSupplier(item.UUID)" v-bind="attrs" v-on="on">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
            <span>Delete supplier from WorkFlowMax</span>
          </v-tooltip>
        </template>

        <template v-slot:item._xero_contact="{ item }">
          <v-autocomplete v-model="item._xero_contact" :items="contacts" item-text="name" item-value="contact_id"></v-autocomplete>
        </template>

        <template v-slot:item.action="{ item }">

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn fab x-small color="primary" class="mr-2" @click="saveMatch(item, null)" v-bind="attrs" v-on="on">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>Create New Xero Contact</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn fab x-small color="accent" @click="saveMatch(item, item._xero_contact)" v-bind="attrs" v-on="on">
                <v-icon>mdi-content-save</v-icon>
              </v-btn>
            </template>
            <span>Save Linkage</span>
          </v-tooltip>

        </template>
      </v-data-table>
    </div>

    <div v-else>
      <v-progress-linear indeterminate color="primary"></v-progress-linear>
    </div>
  </v-container>
</template>

<script>

export default {
  name: "AdminXeroContactSupplierMatchingPage",
  permissions: ["Administer Betaworkflow"],
  components: {},

  data() {
    return {
      title: "Admin - Contact - Supplier Matching",
      isReady: false,
      contacts: [],
      headers: [
        {text: "Delete", value: "delete"},
        {text: "WorkFlowMax Supplier", value: "Name"},
        {text: "Xero Contact", value: "_xero_contact"},
        {text: "Action", value: "action"},
      ],
      suppliers: [],
    }
  },
  methods: {
    deleteSupplier(supplierUuid) {
      this.$WfmApi.post("supplier.api/delete", {Supplier: {UUID: supplierUuid}}).then(response => {
        if (response.Status === 'OK') {
          this.$notify.toast("Deleted")
          // remove from list
          const idx = this.findSupplierIndex(supplierUuid)
          if (idx !== false) {
            this.suppliers.splice(idx, 1)
          }
        }
      })
    },
    saveMatch(supplier, contactUuid) { // contactUuid can be null to create a new contact and then match to it
      this.$BwfApi.post("match-supplier", {supplier: supplier, contact: contactUuid}).then(() => {
        this.$notify.toast("Saved")
        // remove from list
        const idx = this.findSupplierIndex(supplier.UUID)
        this.suppliers.splice(idx, 1)
      })
    },
    loadUnmatched() {
      // load the unmatched suppliers from WFM
      // behind the scenes this will load up the WFM suppliers
      // and return the matched suppliers
      return this.$BwfApi.get("unmatched-suppliers").then(response => {
        this.suppliers = response
        for (const idx in this.suppliers) {
          this.suppliers[idx]._xero_contact = this.findContactByName(this.suppliers[idx].Name)
        }
      })
    },
    loadContacts() {
      // load the Xero contacts
      return this.$BwfApi.get("xero/contacts").then(response => {
        this.contacts = response
      })
    },
    findSupplierIndex(supplierUuid) {
      // find the index of the supplier in the suppliers array
      for (const idx in this.suppliers) {
        const supplier = this.suppliers[idx]
        if (supplier.UUID === supplierUuid) {
          return idx
        }
      }
      return false
    },
    findContactByName(name) {
      // find the contact UUID by name
      for (const contact of this.contacts) {
        if (contact.name === name) {
          return contact.contact_id
        }
      }
      return null
    },
  },
  async mounted() {
    await this.loadContacts()
    await this.loadUnmatched()
    this.isReady = true
  },
}

</script>

<style scoped>

</style>