<template>
  <Field @save="save">
    <template v-slot:view>
      <a :href="'tel:'+mobile">{{ mobile }}</a>
    </template>
    <template v-slot:edit>
      <v-text-field v-model="mobile"></v-text-field>
    </template>
  </Field>
</template>

<script>
import Field from "../../Field"

export default {
  name: "ContactFieldMobile",
  permissions: ["Modify Contact"],
  props: ["contact", "clientId"],
  components: {
    Field
  },
  methods: {
    save: function () {
      const data = {
        Contact: this.contact,
      }
      data.Contact.Mobile = this.mobile
      data.Contact.ClientID = this.clientId
      this.$WfmApi.put("client.api/contact/" + this.contact.UUID, data).then((response) => this.processResponse(response))
    },
    processResponse() {
      this.$emit("updated")
    }
  },
  mounted() {
    this.mobile = this.contact.Mobile
  },
  data() {
    return {
      mobile: "",
    }
  }
}
</script>


