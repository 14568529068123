<template>
  <v-container fluid>
    <h1>Services Setup</h1>
    <p>This page is used to configure which WorkflowMax tasks are available for the capacity planner.</p>
    <p>Please enable the relevant tasks and give a "friendly" name to the task type as it should be presented in the capacity planner and
      reorder them to be in the order that they should show on interfaces.</p>

    <ServiceAdmin/>
  </v-container>
</template>

<script>

import ServiceAdmin from "@/components/capacity/ServiceAdmin.vue"

export default {
  name: "AdminServices",
  permissions: [...ServiceAdmin.permissions],
  components: {ServiceAdmin},
  data() {
    return {}
  },
}

</script>

<style scoped>

</style>