<template>
  <v-row class="match-row">
    <v-col cols="4" class="description">
      <v-text-field label="Description" v-model="cost.description" dense @change="update" :rules="[isNotEmpty]"></v-text-field>
    </v-col>

    <v-col cols="2" class="amount">
      <v-text-field label="Amount" v-model.number="cost.amount" dense prefix="NZ$" @change="update" :rules="[isNotEmpty]"></v-text-field>
    </v-col>

    <v-col cols="2" class="inputs">
      <v-checkbox label="Project Cost" v-model="cost.is_project_cost" dense @change="update"></v-checkbox>
      <v-autocomplete label="Job" v-if="cost.is_project_cost" :items="jobs" v-model="cost.job" dense @change="update" :rules="[isNotEmpty]"></v-autocomplete>
    </v-col>

    <v-col cols="2" class="inputs">
      <v-autocomplete label="WFM Cost Centre" v-if="cost.is_project_cost" :items="wfmCostOptions" v-model="cost.wfm_cost_code" dense @change="update" :rules="[isNotEmpty]"></v-autocomplete>
      <v-autocomplete label="Xero Cost Centre" v-else :items="xeroCostOptions" v-model="cost.xero_cost_code" dense @change="update" :rules="[isNotEmpty]"></v-autocomplete>
    </v-col>
  </v-row>
</template>

<script>

import {isNotEmpty} from "@/lib/validationRules"

export default {
  name: "CostTransactionMatchingForm",
  permissions: ["Read Job", "Read Supplier", "Read Cost", "Modify Job Cost"],
  props: ["value", "statementLine", "wfmCostOptions", "xeroCostOptions", "jobs"],
  components: {},
  watch: {
    "statementLine.includes_gst": function () {
      if (this.statementLine.costs.length === 1) {
        this.cost.amount = this.statementLine.chargeable_amount
        this.update()
      }
    }
  },
  methods: {
    isNotEmpty,
    update() {
      this.$emit("input", this.cost)
    },
  },
  mounted() {
    this.cost = {...this.value}
    this.cost.amount = Math.round(100 * this.cost.amount) / 100
  },
  data() {
    return {
      cost: {},
      amount: 0
    }
  },
}

</script>

<style scoped>


</style>