import Vue from "vue"
import VueRouter from "vue-router"
import VueFilterDateFormat from "vue-filter-date-format"
import Cookies from "js-cookie"
import jwt_decode from "jwt-decode"
import vuetify from "./plugins/vuetify"
import App from "./App.vue"
import WfmApi from "./plugins/WfmApi"
import BwfApi from "./plugins/BwfApi"
import FiltersUtilities from "./plugins/FiltersUtilities"
import {routes} from "./routes"
import notify from "vuetify-notify"

Vue.config.productionTip = false
Vue.use(VueRouter)
Vue.use(VueFilterDateFormat)
Vue.use(WfmApi)
Vue.use(BwfApi)
Vue.use(FiltersUtilities)

Vue.use(notify, {
  vuetify,
  options: {
    toast: {
      x: "center",
      y: "bottom",
      color: "green",
      timeout: 3000,
    },
    dialog: {
      width: 400
    }
  }
})

const APP_VERSION = require("../package.json").version

const token = Cookies.get("bwf")
let decoded
if (token) {
  decoded = jwt_decode(token)
} else {
  decoded = {permissions: [], pages: [], expiry: 0}
}

const router = new VueRouter({routes: routes})
router.beforeEach((to, from, next) => {
  const savedNext = localStorage.getItem("next")
  for (const idx in routes) {
    const route = routes[idx]
    if (route.name === to.name) {
      if (to.name === "AccessDeniedPage" || to.name === "LoginPage") {
        next() // Don't do access control on these pages
      } else if (decoded.expiry < Date.now() / 1000) {
        localStorage.setItem("next", to.fullPath)
        next("/login")
      } else if (savedNext) {
        localStorage.removeItem("next")
        next(savedNext)
      } else if (to.name === "Home") {
        next() // Don't do access control on these pages
      } else if (!decoded.pages.includes(route.name)) {
        next("/403")
      } else {
        break // normal case
      }
    }
  }
  next()
})


Vue.prototype.$version = APP_VERSION
Vue.prototype.$access = decoded
new Vue({
  router,
  vuetify,
  render: h => h(App),
  data: {}
}).$mount("#app")
