<template>
  <Field @save="save" :editable="false">
    <template v-slot:view>
      {{ startDate | dateFormat("D MMM YYYY") }}
    </template>
    <template v-slot:editModal>
      <v-date-picker v-model="startDateString"></v-date-picker>
    </template>
  </Field>
</template>

<script>
import Field from "../../Field"
import {toLocalString} from "@/lib/dateTimeUtilities"

export default {
  name: "JobFieldStartDate",
  permissions: ["Modify Job"],
  props: ["job"],
  components: {
    Field
  },
  methods: {
    save: function () {
      const data = {
        Job: {
          ID: this.job2.ID,
          Name: this.job2.Name,
          StartDate: this.startDateString.replace(/-/g, ""),
          DueDate: this.job.DueDate.substr(0, 10).replace(/-/g, ""),
          ClientNumber:this.job.ClientOrderNumber
        }
      }
      this.$WfmApi.put("job.api/update", data).then((response) => this.processResponse(response))
    },
    process() {
      this.startDate = new Date(this.job2.StartDate)
      this.startDateString = toLocalString(this.startDate).substr(0, 10)
    },
    processResponse(response) {
      this.job2 = response.Job
      this.process()
      this.$emit("updated", new Date(this.job2.StartDate))
    }
  },
  mounted() {
    this.job2 = this.$clone(this.job)
    this.process()
  },
  data() {
    return {
      startDate: new Date(),
    }
  }
}
</script>


