<template>
  <v-container grid-list-md fluid>
    <h1 class="display-inline">Job Review</h1>
    <div class="display-inline ml-3">
      <WeeklyPlanAddItemPopup :jobId="selectedJobId"></WeeklyPlanAddItemPopup>
    </div>

    <div class="my-layout">
      <v-row>
        <v-col>
          <JobSetupCheck :jobId="selectedJobId"></JobSetupCheck>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <JobsNavigator @jobChanged="jobChanged"></JobsNavigator>
          <JobBasicInfo :jobId="selectedJobId"></JobBasicInfo>
        </v-col>

        <v-col cols="4">
          <JobMaterialsBudgetSummary :jobId="selectedJobId"></JobMaterialsBudgetSummary>
          <JobScheduleSummary :jobId="selectedJobId"></JobScheduleSummary>
          <JobChargeRates :jobId="selectedJobId"></JobChargeRates>
        </v-col>
        <v-col cols="4">
          <JobBurnUp :jobId="selectedJobId"></JobBurnUp>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6">
          <JobProjectCostVariance :jobId="selectedJobId"></JobProjectCostVariance>
        </v-col>
        <v-col cols="6" class="wrapper-outer">
          <CapacityPlannerStandalone :jobId="selectedJobId"></CapacityPlannerStandalone>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import JobsNavigator from "../components/jobs/JobsNavigator"
import JobBasicInfo from "../components/job/JobBasicInfo"
import JobMaterialsBudgetSummary from "../components/job/JobMaterialsBudgetSummary"
import JobScheduleSummary from "../components/job/JobScheduleSummary"
import JobBurnUp from "../components/job/JobBurnUp"
import JobSetupCheck from "@/components/job/JobSetupCheck.vue"
import WeeklyPlanAddItemPopup from "@/components/capacity/WeeklyPlanAddItemPopup.vue"
import CapacityPlannerStandalone from "@/components/capacity/CapacityPlannerStandalone.vue"
import JobProjectCostVariance from "@/components/job/JobProjectCostVariance.vue"
import JobChargeRates from "@/components/job/JobChargeRates.vue"

export default {
  name: "JobsReviewProjectManagers",
  permissions: [...JobsNavigator.permissions, ...JobBasicInfo.permissions,
    ...JobMaterialsBudgetSummary.permissions, ...JobScheduleSummary.permissions, ...JobBurnUp.permissions,
    ...CapacityPlannerStandalone.permissions, ...JobChargeRates.permissions, ...JobProjectCostVariance.permissions,
  ],
  components: {
    JobChargeRates,
    JobProjectCostVariance,
    CapacityPlannerStandalone,
    WeeklyPlanAddItemPopup,
    JobSetupCheck,
    JobsNavigator, JobBasicInfo, JobMaterialsBudgetSummary,
    JobScheduleSummary, JobBurnUp
  },
  computed: {
    monthStr() {
      const d = new Date()
      return d.getFullYear().toString() + "-" + (d.getMonth() + 1).toString().padStart(2, "0")
    }
  },
  methods: {
    jobChanged: function (jobId) {
      this.selectedJobId = jobId
    }
  },
  mounted() {
  },
  data() {
    return {
      selectedJobId: ""
    }
  },
}

</script>

<style scoped>
.v-card {
  margin-bottom: 10px !important;
}

.display-inline {
  display: inline-block;
}


.wrapper-outer {
  flex-grow: 0 !important;
}

</style>