<template>
  <BfwComponent :isReady="isReady" :title="title" :showTitle="showTitle" :editable="editable" :hideCard="true"
                loaderType="list-item">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-row>
        <v-col cols="1">
          <v-btn absolute dark fab small color="primary" @click="previous" :disabled="disabled">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
        </v-col>

        <v-col cols="9">
          <v-autocomplete dense :items="filteredJobs" label="Job" v-model="selectedValue" @change="changeJob"></v-autocomplete>
        </v-col>

        <v-col cols="1">
          <v-btn @click="more = !more" color="accent" dense small fab>
            <v-icon v-if="!more">mdi-dots-horizontal</v-icon>
            <v-icon v-else>mdi-chevron-up</v-icon>
          </v-btn>
        </v-col>

        <v-col cols="1">
          <v-btn absolute dark fab small color="primary" @click="next" :disabled="disabled">
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-row v-if="more">
        <v-col cols="12" class="mt-0 ma-0">
          <div class="frameset"><label class="v-label theme--light">Sort by</label>
            <v-select v-model="sort" :items="sortOptions" @change="repopulate"></v-select>
          </div>

          <div class="frameset"><label class="v-label theme--light">Filter</label>
            <v-select dense :items="jobStates" label="Job State" v-model="selectedJobState" multiple :disabled="!jobStatesFilter"
                      class="mt-3" @change="repopulate"></v-select>
            <v-select dense :items="jobTypes" label="Job Type" v-model="selectedJobType" multiple :disabled="!jobTypesFilter"
                      @change="repopulate"></v-select>
            <v-select dense :items="managers" label="Manager" v-model="selectedManagers" multiple :disabled="!jobManagersFilter"
                      @change="repopulate"></v-select>
            <v-checkbox v-model="myJobs" label="Only my jobs" dense @change="repopulate" class="inline-block"></v-checkbox>
            <v-checkbox v-model="hasWip" :label='`Has WIP (updated ${updated})`' v-if="accessWIP" dense @change="repopulate" class="inline-block"></v-checkbox>
            <v-checkbox v-model="showJobCompletedDate" label='Filter by job completed date' dense @change="repopulate" class="inline-block"></v-checkbox>
            <div v-if="showJobCompletedDate">
              Job completed in between:
              <v-date-picker v-model="closeDateRange" range :show-current="false" @change="repopulate"/>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-form>
  </BfwComponent>
</template>

<script>
import BfwComponent from "../BwfComponent"
import {defaultJobStates, jobStates, defaultJobTypes, jobTypes} from "@/lib/constants"
import {toLocalString} from "@/lib/dateTimeUtilities"
import {getStaffList} from "@/lib/lookup"

export default {
  name: "JobsNavigator",
  permissions: ["Read Job"],
  components: {
    BfwComponent
  },
  props: ["value"],
  computed: {
    jobStatesFilter() {
      return !this.hasWip
    },
    jobTypesFilter() {
      return !this.hasWip
    },
    jobManagersFilter() {
      return !this.myJobs
    },
    jobCloseDateFilter() {
      return this.showJobCompletedDate
    },
    filteredJobs: function () {
      const filteredJobs = []
      for (const i in this.jobs) {
        const job = this.jobs[i]
        job.exclude = false
        const selectedJobType = [...this.selectedJobType]
        const index = selectedJobType.indexOf("None")
        if (index !== -1) {
          selectedJobType.splice(index, 1, undefined)
        }

        if (this.jobTypesFilter) {
          // include if there are no selected job types or the job type is one of those selected, otherwise exclude
          if (!(selectedJobType.length === 0 || selectedJobType.includes(job.Type))) {
            job.exclude = true
          }
        }

        if (this.jobManagersFilter) {
          // include if there are no selected managers or the manager is one of those selected, otherwise exclude
          if (!(this.selectedManagers.length === 0 || (job.Manager && this.selectedManagers.includes(job.Manager.UUID)))) {
            job.exclude = true
          }
        }

        if (this.myJobs) {
          if (!(job.Manager && job.Manager.UUID === this.$access.wfm_uuid)) {
            job.exclude = true
          }
        }

        if (this.jobStatesFilter) {
          if (!(this.selectedJobState.length === 0 || this.selectedJobState.includes(job.State))) {
            job.exclude = true
          }
        }

        if (this.hasWip) {
          if (!this.includesWip(job.ID)) {
            job.exclude = true
          }
        }

        if (this.jobCloseDateFilter) {
          if (!job.CompletedDate) {
            job.exclude = true
          }
          const closeDate = new Date(job.CompletedDate)
          const startDate = new Date(this.closeDateRange[0])
          const endDate = new Date(this.closeDateRange[1])
          if (!(closeDate >= startDate && closeDate <= endDate)) {
            job.exclude = true
          }
        }
      }

      for (const i in this.jobs) {
        const job = this.jobs[i]
        if (job.exclude === false) {
          const managerName = (job.Manager) ? job.Manager.Name.toUpperCase() : ""
          filteredJobs.push({
            text: `${job.ID} - ${job.Client.Name} - ${job.Name}`,
            value: job.ID,
            manager: managerName,
            name: `${job.Client.Name} - ${job.Name}`
          })
        }
      }

      if (this.sort === "Job Name") {
        filteredJobs.sort((a, b) => a.name.localeCompare(b.name) || a.manager.localeCompare(b.manager))
      } else if (this.sort === "Manager") {
        filteredJobs.sort((a, b) => a.manager.localeCompare(b.manager) || a.name.localeCompare(b.name))
      }

      return filteredJobs
    },
  },
  methods: {
    loadFavourite() {
      this.$BwfApi.get("user-settings").then(settings => {
        for (const settingName in settings) {
          if (settingName.startsWith("job-navigator:")) {
            const parts = settingName.split(":")
            if (parts.length === 2 && parts[1] === this.$route.path) {
              const settingValue = settings[settingName]
              this.selectedJobState = settingValue.selectedJobState
              this.selectedJobType = settingValue.selectedJobType
              this.selectedManagers = settingValue.selectedManagers
              this.sort = settingValue.sort
              this.myJobs = settingValue.myJobs
              this.hasWip = settingValue.hasWip
              this.showJobCompletedDate = settingValue.showJobCompletedDate
              this.more = false // hide the filter options
            }
          }
        }
      })
    },
    saveFavourite() {
      const setting = {
        selectedJobState: this.selectedJobState,
        selectedJobType: this.selectedJobType,
        selectedManagers: this.selectedManagers,
        sort: this.sort,
        myJobs: this.myJobs,
        hasWip: this.hasWip,
        showJobCompletedDate: this.showJobCompletedDate,
      }
      this.$BwfApi.post("user-settings", {"setting_name": `job-navigator:${this.$route.path}`, "setting_value": setting})
    },
    includesWip(jobId) {
      for (const item of this.wip) {
        if (item.jobId === jobId) return true
      }
      return false
    },
    loadStaff() {
      getStaffList(this, (res) => {
        const staff = []
        for (const idx in res) {
          const member = res[idx]
          staff.push({
            text: member.name,
            value: member.wfm_userid
          })
        }
        this.managers = staff
      })
    },
    next() {
      for (let idx = 0; idx < this.filteredJobs.length; idx++) {
        if (this.selectedValue === this.filteredJobs[idx].value) {
          const newIdx = idx + 1
          if (newIdx < this.filteredJobs.length) {
            this.selectedValue = this.filteredJobs[newIdx].value
            this.changeJob()
            break
          }
        }
      }
    },
    previous() {
      for (let idx = 0; idx < this.filteredJobs.length; idx++) {
        if (this.selectedValue === this.filteredJobs[idx].value) {
          const newIdx = idx - 1
          if (newIdx >= 0) {
            this.selectedValue = this.filteredJobs[newIdx].value
            this.changeJob()
            break
          }
        }
      }
    },
    changeJob() {
      this.delay()
      this.$emit("jobChanged", this.selectedValue)
      if (!(this.$route.query.JobId && this.selectedValue && this.$route.query.JobId === this.selectedValue)) {
        this.$router.replace(this.$route.path + "?JobId=" + this.selectedValue)
      }
    },
    processResponse(response) {
      this.jobs = this.$ensureArray(response.Jobs.Job)

      if (this.value) {
        this.selectedValue = this.value
      } else if (this.$route.query.JobId) {
        this.selectedValue = this.$route.query.JobId
      } else if (this.filteredJobs.length > 0) {
        this.selectedValue = this.filteredJobs[0].value
      }
      this.$emit("jobChanged", this.selectedValue)
      this.isReady = true
    },
    repopulate() {
      if (this.filteredJobs.length > 0) {
        this.selectedValue = this.filteredJobs[0].value
      }
      this.$emit("jobChanged", this.selectedValue)
      this.saveFavourite()
    },
    delay() {
      // delay the next and previous buttons being reenabled
      this.disabled = true
      setTimeout(() => this.disabled = false, 1000)
    },
  },
  mounted() {
    const endDate = new Date()
    const startDate = new Date()
    startDate.setFullYear(startDate.getFullYear() - this.$WfmApi.lookback)
    const start = toLocalString(startDate).substr(0, 10).replace(/-/g, "")
    const end = toLocalString(endDate).substr(0, 10).replace(/-/g, "")
    const query = {"from": start, "to": end}
    this.loadFavourite()
    this.loadStaff()
    this.$WfmApi.get("job.api/list", query).then(response => this.processResponse(response))

    if (this.accessWIP) {
      this.$BwfApi.get("wip").then(response => {
        this.updated = response.updated.substring(0, 19)
        this.wip = response.data
      })
    }
  },
  data() {
    const now = new Date()
    const startOfLastMonth = new Date(now.getFullYear(), now.getMonth() - 1, 1)
    const endOfLastMonth = new Date(now.getFullYear(), now.getMonth(), 0)
    const start = toLocalString(startOfLastMonth).substr(0, 10)
    const end = toLocalString(endOfLastMonth).substr(0, 10)

    return {
      title: "Job selector",
      showTitle: false,
      editable: false,
      isReady: false,
      valid: true,
      disabled: false,
      selectedValue: 0,
      selectedJobState: defaultJobStates,
      selectedJobType: defaultJobTypes,
      managers: [],
      selectedManagers: [],
      jobStates: jobStates,
      jobTypes: jobTypes,
      jobs: [],
      sort: "Manager",
      more: true,
      sortOptions: ["Job Name", "Manager"],
      myJobs: false,
      accessWIP: this.$access.permissions.includes("Read WIP"),
      hasWip: false,
      wip: [],
      updated: "",
      showJobCompletedDate: false,
      closeDateRange: [start, end],
    }
  },
}

</script>

<style scoped>
.frameset {
  border: 1px solid lightgrey;
  border-radius: 10px;
  padding: 20px 10px 10px 10px;
  position: relative;
  margin-bottom: 10px;
}

.frameset label {
  top: -10px;
  left: 10px;
  background: white;
  padding: 5px;
  position: absolute;
}

.inline-block {
  display: inline-block;
  margin-right: 20px;
}
</style>
