<template>
  <v-container fluid>
    <h1>Cost mover</h1>
    <p>This tool will move costs from one job to another</p>
    <v-container>
      <v-row>
        <v-col cols="6">
          <h2>Source</h2>
          <JobsSelector v-model="sourceJob"></JobsSelector>
          <CostSelector v-model="selectedCostUUID" :jobId="sourceJob" @costChanged="selectedCost=$event" ref="costSelector"></CostSelector>
        </v-col>
        <v-col cols="6">
          <h2>Destination</h2>
          <JobsSelector v-model="destinationJob"></JobsSelector>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-btn @click="moveCost">Move</v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar v-model="snackbar" :timeout="2000">
      {{ snackbarText }}
    </v-snackbar>
  </v-container>
</template>

<script>
import CostSelector from "../components/costs/CostSelector"
import JobsSelector from "../components/jobs/JobsSelector"

export default {
  name: "CostMover",
  permissions: [...CostSelector.permissions, ...JobsSelector.permissions],
  components: {
    CostSelector, JobsSelector
  },
  methods: {
    moveCost() {
      //new cost
      const newCost = {...this.selectedCost}
      newCost.Job = this.destinationJob

      if (newCost.Supplier) {
        newCost.SupplierUUID = newCost.Supplier.UUID
      }

      newCost.Date = newCost.Date.substr(0, 10).replace(/-/g, "")
      delete newCost.Supplier
      delete newCost.UUID
      this.$WfmApi.post("job.api/cost", {Cost: newCost}).then(response => this.processCreateResponse(response))

      // updated cost
      const updatedCost = {...this.selectedCost}
      updatedCost.Date = updatedCost.Date.substr(0, 10).replace(/-/g, "")
      delete newCost.Supplier
      updatedCost.Description = "DELETE ME"
      updatedCost.Note = "DELETE ME"
      updatedCost.UnitCost = 0
      updatedCost.UnitPrice = 0
      updatedCost.Quantity = 0
      updatedCost.Billable = "false"
      updatedCost.Job = this.sourceJob
      this.$WfmApi.put("job.api/cost", {Cost: updatedCost}).then(response => this.processUpdateResponse(response))
    },
    processCreateResponse(res) {
      console.log(res)
    },
    processUpdateResponse(res) {
      if (res.Status === "OK") {
        this.snackbar = true
        this.snackbarText = "Successfully moved cost"
        this.selectedCost = null
        this.selectedCostUUID = null
        this.$refs.costSelector.loadCosts() // force update
      } else {
        this.snackbar = true
        this.snackbarText = "Failed to move cost"
      }
    }
  },
  computed: {},
  mounted() {
  },
  data() {
    return {
      sourceJob: undefined,
      selectedCost: undefined,
      selectedCostUUID: undefined,
      destinationJob: undefined,
      snackbar: false,
      snackbarText: ""
    }
  },
}

</script>

<style scoped>

</style>