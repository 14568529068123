<template>
  <BfwComponent :isReady="isReady" :title="title" :showTitle="showTitle" :editable="editable" loaderType="list-item-avatar-three-line">

    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-if="accessStaffRates" icon color="primary" class="q-mb-md run-button" v-bind="attrs"
               v-on="on" @click="calculate">
          <v-icon>mdi-calculator-variant</v-icon>
        </v-btn>
      </template>
      <span>Calculate the charge rates for this job</span>
    </v-tooltip>

    <div><span class="strong">Project:</span>
      {{ jobRate | formatCurrency }}/hr
    </div>

    <div v-for="serviceRatesDisplayItem in serviceRatesDisplay" :key="serviceRatesDisplayItem.uuid">
      <div>
        <span class="strong">{{ serviceRatesDisplayItem.name }}:</span>
        {{ serviceRatesDisplayItem.rate | formatCurrency }}/hr
      </div>
    </div>

    <v-expansion-panels flat accordion v-if="accessStaffRates">
      <v-expansion-panel>
        <v-expansion-panel-header>
          <div class="font-weight-bold">Staff</div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div v-for="staffRatesDisplayItem in staffRatesDisplay" :key="staffRatesDisplayItem.uuid">
            <div>
              <span class="strong">{{ staffRatesDisplayItem.name }}:</span>
              {{ staffRatesDisplayItem.rate | formatCurrency }}
            </div>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

  </BfwComponent>
</template>

<script>
import BfwComponent from "../BwfComponent"
import {getStaffListAll} from "@/lib/lookup"


export default {
  name: "JobChargeRates",
  permissions: [],
  props: ["jobId"],
  components: {BfwComponent},
  computed: {
    accessStaffRates() {
      return this.$access.permissions.includes("Read Staff Rates") || this.$access.permissions.includes("Administer Betaworkflow")
    },
    staffRatesDisplay() {
      const staffRates = []
      for (const staffUuid in this.staffRates) {
        const name = this.staff[staffUuid] ? this.staff[staffUuid] : "Unknown"
        staffRates.push({name: name, rate: this.staffRates[staffUuid], uuid: staffUuid})
      }
      return staffRates
    },
    serviceRatesDisplay() {
      const serviceRates = []
      for (const serviceId in this.serviceRates) {
        const name = this.services[serviceId] ? this.services[serviceId] : "Unknown"
        serviceRates.push({name: name, rate: this.serviceRates[serviceId], uuid: serviceId})
      }
      return serviceRates
    }
  },
  methods: {
    calculate(){
      this.$BwfApi.post("job/" + this.jobId + "/chargeable-rates").then(() => this.loadChargeRates())
    },
    processResponse(response) {
      const serviceRates = {}
      const staffRates = {}
      this.jobRate = 0
      for (let i = 0; i < response.length; i++) {
        const item = response[i]
        if (item.entity === "job") {
          this.jobRate = item.hourly_rate
        }

        if (item.entity === "service") {
          serviceRates[item.entity_id] = item.hourly_rate
        }

        if (item.entity === "staff") {
          staffRates[item.entity_id] = item.hourly_rate
        }
      }
      this.isReady = true
      this.serviceRates = serviceRates
      this.staffRates = staffRates
    },
    loadStaff() {
      getStaffListAll(this, (staff) => {
        const list = {}
        for (const item of staff) {
          list[item.wfm_userid] = item.name
        }
        this.staff = list
      })
    },
    loadServices() {
      this.servicesLoaded = false
      this.$BwfApi.get("services", {enabled: true}).then(response => {
        const list = {}
        for (const item of response) {
          list[item.service_id] = item.name
        }
        this.services = list
      })
    },
    loadChargeRates() {
      this.isReady = false
      this.$BwfApi.get("job/" + this.jobId + "/chargeable-rates").then(response => this.processResponse(response))
    }
  },
  mounted() {
    this.loadStaff()
    this.loadServices()
  },
  watch: {
    jobId: function () {
      if (this.jobId) {
        this.isReady = false
        this.loadChargeRates()
      } else {
        this.isReady = false
      }
    }
  },
  data() {
    return {
      title: "Chargeable Rates",
      showTitle: true,
      editable: false,
      isReady: false,
      jobRate: 0,
      serviceRates: {},
      staffRates: {},
      staff: {}, // staff id to name
      services: {}, // service id to name
    }
  },
}

</script>

<style scoped>
.strong {
  font-weight: bold;
}

.v-expansion-panel-header {
  padding-left: 0 !important;
}

.run-button {
  position: absolute;
  right: 0;
  top: 0;
}
</style>