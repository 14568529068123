<template>
  <v-card>
    <v-card-title>{{ formatMonth(this.monthStr) }}</v-card-title>
    <v-card-text v-if="isPlannedReady && isScheduledReady && isUsedReady" class="card-text">
      <div class="headings">
        <div class="job-name font-weight-bold text-left">Job</div>
        <div class="job-hours font-weight-bold text-right">Planned</div>
        <div class="job-hours font-weight-bold text-right">Scheduled</div>
        <div class="job-hours-used font-weight-bold text-right">Used</div>
      </div>

      <div v-for="job in jobsOfInterest" v-bind:key="job.ID" :class="{highlight:job.ID === highlightedJobId}" class="data-row">
        <div class="job-name text-left">[{{ job.ID }}] {{ job.Client.Name }} - {{ job.Name }}</div>
        <div class="job-hours text-right">{{ job.planned > 0 ? Math.round(job.planned) : "" }}</div>
        <div class="job-hours text-right">{{ job.scheduled > 0 ? Math.round(job.scheduled) : "" }}</div>
        <div class="job-hours-used text-right">{{ job.used > 0 ? Math.round(job.used) : "" }}</div>
      </div>

      <div class="footer">
        <div>
          <div class="job-name text-right font-weight-bold">Total</div>
          <div class="job-hours text-right font-weight-bold">{{ totalPlanned }}</div>
          <div class="job-hours text-right font-weight-bold">{{ totalScheduled }}</div>
          <div class="job-hours-used text-right font-weight-bold">{{ totalUsed }}</div>
        </div>

        <div>
          <div class="job-name text-right font-weight-bold">Capacity</div>
          <div class="job-hours text-right font-weight-bold">{{ maxCapacity }}</div>
          <div class="job-hours text-right font-weight-bold">{{ maxCapacity }}</div>
          <div class="job-hours-used text-right font-weight-bold">{{ maxCapacity }}</div>
          <div></div>
        </div>

        <div>
          <div class="job-name text-right font-weight-bold">Difference</div>
          <div class="job-hours text-right font-weight-bold">
            <span :style="{color:totalsColor(totalPlanned, capacity)}">{{ Math.round(maxCapacity - totalPlanned) }}</span>
          </div>
          <div class="job-hours text-right font-weight-bold">
            <span :style="{color:totalsColor(totalScheduled, capacity)}">{{ Math.round(maxCapacity - totalScheduled) }}</span>
          </div>
          <div class="job-hours-used text-right font-weight-bold">
            <span :style="{color:totalsColor(totalUsed, capacity)}">{{ Math.round(maxCapacity - totalUsed) }}</span>
          </div>
        </div>
      </div>
    </v-card-text>

    <v-card-text v-else class="text-center">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
      <p>Loading</p>
    </v-card-text>
    <v-card-actions>
      <v-btn color="primary" block @click="$emit('close')">Close</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>


import {formatMonth, getDateOfISOWeek, getWeekFraction, getWeeksInMonth, toLocalString} from "@/lib/dateTimeUtilities"
import {getAllTimeEntries} from "@/lib/lookup"

export default {
  name: "CapacityMonthPopup",
  permissions: [],
  props: ["monthStr", "serviceIds", "highlightedJobId", "maxCapacity"],
  components: {},
  computed: {
    jobsOfInterest() {
      return Object.values(this.jobs).filter(item => {
        return item.planned > 0 || item.scheduled > 0 || item.used > 0
      }).sort((a, b) => {
        const a1 = b.planned || 0
        const b1 = a.planned || 0
        return a1 - b1
      })
    },
    totalPlanned() {
      let total = 0
      for (const job of this.jobs) {
        if (job.planned) {
          total += job.planned
        }
      }
      return Math.round(total)
    },
    totalScheduled() {
      let total = 0
      for (const job of this.jobs) {
        if (job.scheduled) {
          total += job.scheduled
        }
      }
      return Math.round(total)
    },
    totalUsed() {
      let total = 0
      for (const job of this.jobs) {
        if (job.used) {
          total += Math.round(job.used)
        }
      }
      return Math.round(total)
    }
  },
  mounted() {
    this.loadJobs()
    this.loadServices()
  },
  data() {
    return {
      jobs: {},
      capacity: {},
      isPlannedReady: false,
      isScheduledReady: false,
      isUsedReady: false,
      services: {},
      servicesLoaded: false
    }
  },
  methods: {
    formatMonth,
    totalsColor(planned, maxCapacity) {
      return planned > maxCapacity ? "red" : "green"
    },
    loadServices() {
      this.servicesLoaded = false
      this.$BwfApi.get("services", {enabled: true}).then(response => {
        this.services = response
        this.servicesLoaded = true
      })
    },
    loadJobs() {
      const endDate = new Date()
      const startDate = new Date()
      startDate.setFullYear(startDate.getFullYear() - this.$WfmApi.lookback)
      const start = toLocalString(startDate).substr(0, 10).replace(/-/g, "")
      const end = toLocalString(endDate).substr(0, 10).replace(/-/g, "")
      const query = {"from": start, "to": end, detailed: true}

      this.$WfmApi.get("job.api/list", query).then(response => {
        this.jobs = this.$ensureArray(response.Jobs.Job)
        this.loadAllPlannedCapacityData() // Call next stage
        this.loadAllScheduledData()
        this.loadUsedData()
      })
    },
    loadAllPlannedCapacityData() {
      const parts = this.monthStr.split("-")
      const year = parts[0]
      const month = parts[1]
      const query = {startMonth: month, startYear: year, endMonth: month, endYear: year}
      this.$BwfApi.get("capacity-plan/jobs", query).then(response => {
        for (const jobId in response) {
          const jobIdx = this.findJobIdx(jobId)

          for (const serviceId of this.serviceIds) {
            if (serviceId in response[jobId][this.monthStr]) {
              this.jobs[jobIdx].planned = this.jobs[jobIdx].planned || 0
              this.jobs[jobIdx].planned += response[jobId][this.monthStr][serviceId]
            }
          }
        }

        this.isPlannedReady = true
      })
    },
    loadAllScheduledData() {
      const parts = this.monthStr.split("-")
      const year = parseInt(parts[0])
      const month = parseInt(parts[1])
      const periods = getWeeksInMonth(month, year)
      const periodStr = periods.map(p => {
        return p.period
      }).join(",")

      this.$BwfApi.get("staff-plan", {staff_uuid: "all", periods: periodStr}).then(response => {
        this.allScheduledDataLoaded = true
        for (const task of response) {
          if (task.service_id === -1) { // Other jobs
            continue
          }

          const date = getDateOfISOWeek(task.week, task.year)
          const month = date.getMonth() + 1
          const fraction = getWeekFraction(month, task.week, task.year)
          const jobIdx = this.findJobIdx(task.job_id)
          if (this.serviceIds.includes(task.service_id)) {
            this.jobs[jobIdx].scheduled = this.jobs[jobIdx].scheduled || 0
            this.jobs[jobIdx].scheduled += task.allocated_hours * fraction
          }
        }

        this.isScheduledReady = true
      })

    },
    loadUsedData() {
      const parts = this.monthStr.split("-")
      const start = new Date(parseInt(parts[0]), parseInt(parts[1]) - 1, 1)
      const end = new Date() // look all the way to the future as the time date is the last modified date of the time entry
      getAllTimeEntries(this, start, end, (timeEntries) => {
        for (const item of timeEntries) {
          const hours = parseInt(item.Minutes) / 60
          const taskUuid = this.findTaskTypeUuid(item.Job.ID, item.Task.UUID)
          if (!taskUuid) continue

          const service = this.findService(taskUuid)
          if (!service) continue

          const jobIdx = this.findJobIdx(item.Job.ID)
          if (this.serviceIds.includes(service.service_id)) {
            this.jobs[jobIdx].used = this.jobs[jobIdx].used || 0
            this.jobs[jobIdx].used += hours
          }
        }
      })

      this.isUsedReady = true
    },
    findService(taskTypeUUID) {
      for (const service of this.services) {
        if (service.uuid === taskTypeUUID) {
          return service
        }
      }
      return false
    },
    findTaskTypeUuid(jobId, taskUUID) {
      const jobIdx = this.findJobIdx(jobId)
      if (!jobIdx) return false
      if (!("Tasks" in this.jobs[jobIdx])) return false

      const tasks = this.$ensureArray(this.jobs[jobIdx].Tasks.Task)
      for (const task of tasks) {
        if (task.UUID === taskUUID) {
          return task.TaskUUID
        }
      }
      return false
    },
    findJobIdx(JobId) {
      for (const jobIdx in this.jobs) {
        if (this.jobs[jobIdx].ID === JobId) {
          return jobIdx
        }
      }
      return false
    }
  }
}

</script>

<style scoped>

.job-name {
  width: 600px;
  display: inline-block;
}

.job-hours {
  width: 80px;
  display: inline-block;
}

.job-hours-used {
  width: 50px;
  display: inline-block;
}


.highlight {
  font-weight: bold;
  color: green;
}

.data-row {
  border-bottom: 1px solid #ccc;
}

.footer {
  margin-top: 15px
}

.card-text {
  padding-right: 0

}
</style>