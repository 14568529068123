<template>
  <v-container fluid>
    <h1>Weekly Capacity Report - By Service</h1>
    <WeeklyCapacityPlanGraphByService/>
  </v-container>
</template>

<script>


import WeeklyCapacityPlanGraphByService from "@/components/capacity/WeeklyCapacityPlanGraphByService.vue"

export default {
  name: "ReportCapacityWeeklyByServicePagePage",
  permissions: [],
  components: {WeeklyCapacityPlanGraphByService},
  data() {
    return {}
  },
}

</script>

<style scoped>

</style>