<template>
  <v-container>
    <h1>Leads Page</h1>
    <LeadsCard></LeadsCard>
  </v-container>
</template>

<script>

import LeadsCard from "@/components/leads/LeadsCard.vue"
export default {
  name: "TestPage",
  permissions: ["Administer Betaworkflow"],
  components: {
    LeadsCard,
  },
  methods: {
  },
  computed: {},
  mounted() {
  },
  data() {
    return {}
  },
}

</script>

<style scoped>

</style>