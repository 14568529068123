<template>
  <v-container fluid>
    <h1>KPI Project Cost Variance</h1>
    <AggregateProjectCostVariance></AggregateProjectCostVariance>
    <PCVGraph></PCVGraph>
  </v-container>
</template>

<script>
import PCVGraph from "@/components/capacity/PCVGraph.vue"
import AggregateProjectCostVariance from "@/components/jobs/AggregateProjectCostVairiance.vue"

export default {
  name: "ReportProjectCostVariancePage",
  permissions: [
      ...PCVGraph.permissions
  ],
  components: {AggregateProjectCostVariance,
    PCVGraph
  },
  data() {
    return {
      selectedJobId: null
    }
  },
  mounted() {

  },
}

</script>

<style scoped>

</style>