<template>
  <v-container>

    <v-btn @click="collapsed = !collapsed" color="accent" dense x-small fab class="inline-block mr-2">
      <v-icon v-if="collapsed">mdi-chevron-down</v-icon>
      <v-icon v-else>mdi-chevron-up</v-icon>
    </v-btn>
    <h2 class="mb-4 inline-block">{{ question }}</h2>
    <div v-if="!collapsed">

      <div class="row">

        <div v-for="option in options" v-bind:key="option.value"
             :style="{'background': option.color}"
             :class="['button' ,'row',isSelected(option.value)]"
             @click="select(option.value)">
          <v-icon size='50px' dark class="icon">{{ option.icon }}</v-icon>
          <div class="button-group">
            {{ option.name }}
          </div>
        </div>

      </div>
    </div>
  </v-container>
</template>

<script>


export default {
  name: "StaffQuestion",
  permissions: ["Modify Staff Feedback"],
  components: {},
  computed: {},
  mounted() {
    this.$BwfApi.get("staff-feedback").then((response) => {
      if (response) {
        this.rating = response.response
        this.collapsed = true
      }
    })
  },
  data() {
    return {
      title: "Staff Question",
      showTitle: false,
      editable: false,
      isReady: true,
      valid: true,
      question: "How are you feeling about your workload?",
      rating: 0,
      collapsed: false,
      options: [
        {name: "Extremely under worked", value: 1, icon: "mdi-emoticon-angry-outline", color: "red"},
        {name: "Very under worked", value: 2, icon: "mdi-emoticon-sad-outline", color: "orange"},
        {name: "A little under worked", value: 3, icon: "mdi-emoticon-neutral-outline", color: "#8cc42b"},
        {name: "Just right", value: 4, icon: "mdi-emoticon-happy-outline", color: "green"},
        {name: "Stretched", value: 5, icon: "mdi-emoticon-neutral-outline", color: "#8cc42b"},
        {name: "Overwhelmed", value: 6, icon: "mdi-emoticon-sad-outline", color: "orange"},
        {name: "Extremely Overwhelmed", value: 7, icon: "mdi-emoticon-angry-outline", color: "red"}
      ],
    }
  },
  methods: {
    select(val) {
      this.rating = val
      this.$BwfApi.post("staff-feedback", {question: 1, response: val}).then(() => {
        this.$notify.toast("Saved")
        this.collapsed=true
      })
    },
    isSelected(val) {
      return val === this.rating ? "selected" : ""
    }
  }
}

</script>

<style scoped>
.button {
  width: 150px;
  height: 140px;
  display: inline-block;
  color: white;
  padding: 10px;
  text-align: center;
  border-radius: 10px;
  margin: 5px;
  opacity: 0.4;
  cursor: pointer;
  font-size: larger;
}

.icon {
  padding-bottom: 10px;
}

.selected {
  box-shadow: 10px 10px 20px grey;
  opacity: 1;
}

.inline-block {
  display: inline-block;
}
</style>