<template>
  <BfwComponent :isReady="isReady" :title="title" :showTitle="showTitle" :editable="editable" :hideCard="true" loaderType="list-item">

    <v-data-table :headers="headers" :items="costs" :items-per-page="100" :sort-by="['date']">
      <template v-slot:item.amount="{ item }">
        <div>{{ item.amount|formatCurrency(2) }}</div>
      </template>

      <template v-slot:item.description="{ item }">
        <div>{{ item.description }}</div>
      </template>

      <template v-slot:item.date="{ item }">
        <div class="text-no-wrap">{{ (new Date(item.date)) |formatDate }}</div>
      </template>

      <template v-slot:item.actions="{ item }">

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn @click="selectedCostId=item.id;removeDialog=true" fab v-bind="attrs" v-on="on" x-small color="red" class="ma-1">
              <v-icon>
                mdi-delete-forever
              </v-icon>
            </v-btn>
          </template>
          <span>Delete Bank Statement Line</span>
        </v-tooltip>


      </template>
    </v-data-table>
    <v-dialog v-model="removeDialog" width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Confirm
        </v-card-title>

        <v-card-text>
          <p>Are you sure you want to delete this entry forever?</p>
        </v-card-text>

        <v-card-actions>
          <v-btn color="primary" text @click="removeDialog=false">
            No
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="removeCost()">
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </BfwComponent>
</template>

<script>
import BfwComponent from "../BwfComponent"

export default {
  name: "CostImporterBankStatementList",
  props: [],
  permissions: ["Modify Job Cost"],
  components: {
    BfwComponent,
  },
  watch: {},
  computed: {},
  methods: {
    loadCosts() {
      // Request unmatched costs from the database
      this.$BwfApi.get("costs/statement/load", {status: 0}).then(response => this.processLoadCostsResponse(response))
    },
    processLoadCostsResponse(response) {
      this.costs = response
    },
    removeCost() {
      this.$BwfApi.delete(`cost/statement/${this.selectedCostId}`).then(() => {
        this.$notify.toast("Successfully removed")
        this.removeDialog = false
        let {idx} = this.findCost(this.selectedCostId)
        this.costs.splice(idx, 1)
        this.selectedCostId = null
      })
    },
    findCost(costId) {
      let cost = null
      let idx = 0
      for (idx = 0; this.costs.length; idx++) {
        const c = this.costs[idx]
        if (c.id === costId) {
          cost = c
          break
        }
      }
      return {cost, idx}
    },
  },
  mounted() {
    this.loadCosts()
  },
  data() {
    return {
      title: "Cost Importer Bank Statement List",
      showTitle: false,
      editable: false,
      isReady: true,
      valid: true,
      settings: {},
      headers: [
        {text: "Date", value: "date"},
        {text: "Bank Statement ID", value: "id"},
        {text: "Description", value: "description"},
        {text: "Amount", value: "amount"},
        {text: "Actions", value: "actions"},
      ],
      costs: [],
      removeDialog: false
    }
  },
}

</script>
