<template>
  <v-container fluid>
    <v-container class="text-xs-center">
      <v-card class="mx-auto" max-width="344" outlined>

        <v-img src="../assets/logo.png" height="100%" width="300px" contain></v-img>

        <v-card-subtitle class="text-center">
          <div class="text-h6"> BetaWorkflow</div>
          <div>Making WorkflowMax Beta</div>
        </v-card-subtitle>

        <v-card-actions class="ce">
          <v-btn @click="login" x-large color="primary" class="full-width">Login</v-btn>
        </v-card-actions>

        <div class="version">Version: {{ $version }}</div>
      </v-card>

    </v-container>
  </v-container>
</template>

<script>

export default {
  name: "LoginPage",
  methods: {
    login() {
      window.location.href = "/login"
    }
  }
}

</script>

<style scoped>
.full-width {
  width: 100%;
}

.version{
  color: grey;
  font-size: 12px;
}
</style>