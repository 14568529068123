<template>
  <div class="chart-area">
    <p>This report shows the summary of the number of hours scheduled for each staff member for a given month.</p>
    <p>As scheduling happens on a per-week basis, where a week crosses a month boundary, the week apportioned on a pro-rata basis</p>
    <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition"
            offset-y max-width="290px" min-width="auto">

      <template v-slot:activator="{ on, attrs }">
        <v-text-field v-model="monthStr" label="Month" prepend-icon="mdi-calendar"
                      readonly v-bind="attrs" v-on="on"></v-text-field>
      </template>

      <v-date-picker v-model="monthStr" type="month" no-title scrollable>
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="menu = false"> Cancel</v-btn>
        <v-btn text color="primary" @click="$refs.menu.save(monthStr)"> OK</v-btn>
      </v-date-picker>
    </v-menu>

    <v-data-table :headers="headers" :items="tableData" :items-per-page="100" hide-default-footer></v-data-table>

  </div>
</template>

<script>
import {defineComponent} from "vue"
import {getWeeksInMonth} from "@/lib/dateTimeUtilities"

export default defineComponent({
  name: "ReportServiceScheduleSummary",
  props: [],
  permissions: [],
  components: {},
  watch: {
    monthStr() {
      this.load()
    }
  },
  computed: {
    headers() {
      const headers = [{text: "Staff Member", value: "UUID"}]
      for (const service of this.services) {
        headers.push({text: service.name, value: service.service_id.toString()})
      }
      headers.push({text: "Total", value: "total"})
      return headers
    },
  },
  methods: {
    load() {
      const parts = this.monthStr.split("-")
      const periods = getWeeksInMonth(parseInt(parts[1]), parseInt(parts[0]))
      this.weekFractions = {}
      for (const p of periods) {
        this.weekFractions[p.week] = p.fraction
      }

      const periodString = periods.map((p) => {
        return p.period
      }).join(",")

      Promise.all([
        this.$BwfApi.get("services", {enabled: true}).then(response => this.services = response),
        this.$WfmApi.get("staff.api/list").then(response => {
          this.staff = this.$ensureArray(response.StaffList.Staff)
        }),
        this.$BwfApi.get("staff-plan", {staff_uuid: 'all', periods: periodString}).then(response => this.taskList = response)

      ]).then(() => {
        this.services.push({service_id: -1, name: "Other"})
        this.createSummary()
        this.createTableData()
      })
    },
    createSummary() {
      const data = {}
      const serviceTemplate = {}
      for (const service of this.services) {
        serviceTemplate[service.service_id] = 0
      }

      for (const staffMember of this.staff) {
        data[staffMember.UUID] = {...serviceTemplate}
      }

      for (const task of this.taskList) {
        if (task.staff_uuid in data && task.service_id in data[task.staff_uuid]) {
          data[task.staff_uuid][task.service_id] += task.allocated_hours * this.weekFractions[task.week]
        }
      }

      // Totals
      for (const uuid in data) {
        const item1 = data[uuid]
        let total = 0
        for (const item2 in item1) {
          total += item1[item2]
        }
        data[uuid]["total"] = total
      }

      this.hoursSummary = data
    },
    createTableData() {
      const tableData = []
      for (const staffMember of this.staff) {
        const row = {UUID: staffMember.Name}
        for (const service of this.services) {
          row[service.service_id.toString()] = Math.round(this.hoursSummary[staffMember.UUID][service.service_id])
        }
        row["total"] = Math.round(this.hoursSummary[staffMember.UUID]["total"])
        tableData.push(row)
      }
      this.tableData = tableData
    }
  },
  mounted() {
    this.load()
  },
  data() {
    const now = new Date()
    return {
      monthStr: now.getFullYear() + "-" + (now.getMonth() + 1).toString().padStart(2, "0"),
      menu: false,
      hoursSummary: {},
      tableData: [],
      services: [],
      staff: [],
      weekFractions: {}
    }
  },
})

</script>

<style scoped>

</style>