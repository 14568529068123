export function isNotEmpty(value) {
  return !!value;
}

export function isNotNull(value) {
  return value !== null
}

export function extractNumber(value) {
  return Number(value.replace(/[^0-9.]+/g, ""))
}