<template>
  <div v-if="loaded">
    <h3>Consulting Project Speed KPI</h3>
    <p>On average, it currently takes
      <span class="highlight">{{ latest("KPI Consulting", "speed") }} weeks </span> complete one billable week of work for a project.</p>
    <v-chart class="chart" :option="chartOptions('KPI Consulting', 'speed')" autoresize/>

    <h3>Consulting Focus</h3>
    <p>On average, each project currently receives
      <span class="highlight">{{ latest("KPI Consulting", "focus") }}</span> hours of billable time each week</p>

    <v-chart class="chart" :option="chartOptions('KPI Consulting', 'focus')" autoresize/>

    <h3>Production Project Speed KPI</h3>
    <p>On average, it currently takes
      <span class="highlight">{{ latest("KPI Production", "speed") }} weeks </span> to complete one billable week of work for a project</p>
    <v-chart class="chart" :option="chartOptions('KPI Production', 'speed')" autoresize/>

    <h3>Production Focus</h3>
      <p>Projects currently receive On average, each project receives
      <span class="highlight">{{ latest("KPI Production", "focus") }}</span> hours of billable time each week</p>

    <v-chart class="chart" :option="chartOptions('KPI Production', 'focus')" autoresize/>
  </div>
</template>

<script>
import {use} from "echarts/core"
import {CanvasRenderer} from "echarts/renderers"
import {LineChart} from "echarts/charts"
import {
  LegendComponent,
  GridComponent,
  MarkLineComponent,
  TooltipComponent,
} from "echarts/components"
import VChart from "vue-echarts"


use([
  CanvasRenderer,
  LineChart,
  LegendComponent,
  GridComponent,
  MarkLineComponent,
  TooltipComponent,
])

export default {
  name: "SpeedFocusKPIGraph",
  components: {VChart},
  permissions: ["Read Timeseries"],
  methods: {
    latest(seriesName, itemName) {
      const key = seriesName + "|" + itemName
      const data = this.data[key]
      if (data.length > 0) {
        const row = data[data.length - 1]
        return Math.round(10 * row.value) / 10
      }
      return 0
    },
    loadAll() {
      Promise.all([
        this.loadTimeseries("KPI Consulting", "focus"),
        this.loadTimeseries("KPI Consulting", "speed"),
        this.loadTimeseries("KPI Production", "focus"),
        this.loadTimeseries("KPI Production", "speed"),
      ]).then(() => {
        this.loaded = true
      })
    },
    loadTimeseries(seriesName, itemName) {
      const query = {
        series_name: seriesName,
        item_name: itemName,
      }
      const key = seriesName + "|" + itemName
      return this.$BwfApi.get("timeseries", query).then(response => {
        this.data[key] = response
      })
    },
    chartOptions(seriesName, itemName) {
      const key = seriesName + "|" + itemName
      const data = this.data[key]

      const kpi = []
      for (const row of data) {
        const d = new Date(row.record_time)
        kpi.push([d, Math.round(10 * row.value) / 10])
      }

      const min = new Date()
      min.setMonth(min.getMonth() - 18)
      const max = new Date()
      return {
        xAxis: {
          type: "time",
          min: min,
          max: max,
        },
        yAxis: {
          type: "value",
          name: "Value",
        },
        legend: {
          top: "30"
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow"
          }
        },
        series: [
          {
            name: "Value",
            type: "line",
            smooth: true,
            data: kpi,
          },
        ]
      }
    },
  },
  mounted() {
    this.loadAll()
  },
  data() {
    return {
      loaded: false,
      data: {}
    }
  }
}
</script>

<style scoped>
.chart {
  height: 600px;
}

.highlight {
  font-weight: bold;
  font-size: 1.2em;
  color: #77aa00;
}
</style>