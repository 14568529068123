<template>
  <v-container fluid>
    <h1>Charge Rates</h1>
    <ReportChargeRatesGraph></ReportChargeRatesGraph>
  </v-container>
</template>

<script>
import ReportChargeRatesGraph from "@/components/reports/ReportChargeRatesGraph.vue"

export default {
  name: "ReportChargeRatesPage",
  permissions: [...ReportChargeRatesGraph.permissions],
  components: {ReportChargeRatesGraph},
  data() {
    return {}
  },
  mounted() {

  },
}

</script>

<style scoped>

</style>