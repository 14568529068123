<template>
  <BfwComponent :isReady="isReady" :title="title" :showTitle="showTitle" :editable="editable" :hideCard="true">
    <v-form ref="form" v-model="valid" lazy-validation :isReady="isReady">
      <v-text-field v-model="name" label="Name"></v-text-field>
      <v-text-field v-model="phone" label="Phone"></v-text-field>
      <v-text-field v-model="fax" label="Fax"></v-text-field>
      <v-text-field v-model="website" label="Website"></v-text-field>

      <div class="address-section">
        <h3>Address</h3>
        <v-text-field v-model="address" label="Address"></v-text-field>
        <v-text-field v-model="city" label="City"></v-text-field>
        <v-text-field v-model="region" label="Region"></v-text-field>
        <v-text-field v-model="postCode" label="Post code"></v-text-field>
        <v-text-field v-model="country" label="Country"></v-text-field>
      </div>
      <div class="address-section">
        <h3>Postal Address</h3>
        <v-text-field v-model="postalAddress" label="Address"></v-text-field>
        <v-text-field v-model="postalCity" label="City"></v-text-field>
        <v-text-field v-model="postalRegion" label="Region"></v-text-field>
        <v-text-field v-model="postalPostCode" label="Post Code"></v-text-field>
        <v-text-field v-model="postalCountry" label="Country"></v-text-field>
      </div>
    </v-form>
  </BfwComponent>
</template>

<script>
import BfwComponent from "../BwfComponent"

export default {
  name: "SupplierEditor",
  permissions: ["Modify Supplier"],
  props: {
    "supplierId": {type: String},
  },
  components: {
    BfwComponent,
  },
  computed: {
    supplier: function () {
      return {
        UUID: this.UUID,
        Name: this.name,
        Website: this.website,
        Phone: this.phone,
        Fax: this.fax,
        Address: this.address,
        City: this.city,
        Region: this.region,
        PostCode: this.postCode,
        Country: this.country,
        PostalAddress: this.postalAddress,
        PostalCity: this.postalCity,
        PostalRegion: this.postalRegion,
        PostalPostCode: this.postalPostCode,
        PostalCountry: this.postalCountry,
      }
    }
  },
  methods: {
    save() {
      if (!this.UUID) { // create supplier
        this.$WfmApi.post("supplier.api/add", {Supplier: this.supplier}).then((response) => this.processContactSaveResponse(response))
      } else { // update supplier
        this.$WfmApi.put("supplier.api/update/", {Supplier: this.supplier}).then((response) => this.processContactSaveResponse(response))
      }
    },
    processContactSaveResponse(response) {
      const supplier = response.Supplier
      this.$emit("saved", supplier)
    }
  },
  data() {
    return {
      title: "Supplier",
      showTitle: false,
      editable: false,
      isReady: true,
      valid: true,
      settings: {},
      UUID: 0,
      name: "",
      website: "",
      phone: "",
      fax: "",
      address: "",
      city: "",
      region: "",
      postCode: "",
      country: "",
      postalAddress: "",
      postalCity: "",
      postalRegion: "",
      postalPostCode: "",
      postalCountry: "",
    }
  },
}

</script>

<style scoped>
.address-section {
  border: 1px solid lightgray;
  padding: 5px;
  margin-bottom: 10px;
}
</style>