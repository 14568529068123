<template>
  <BfwComponent :isReady="isReady" :title="title" :showTitle="showTitle" :editable="editable" v-if="invoiceData.job">
    <div v-if="invoiceData.quote">
      Based on quote {{ invoiceData.quote.ID }} - {{ invoiceData.quote.Name }}<br/>
    </div>
    <div v-else>
      No Quote available
    </div>

    Based on job: {{ invoiceData.job.ID }} - {{ invoiceData.job.Name }}<br/>
    <CopyButton :key="invoiceData.job.ID">
    <pre>
===================== Project Status =====================

PROJECT SCOPE: As per proposal {{ proposal }}

PROJECT:
• Total Quoted/Estimated...{{ parseFloat(invoiceData.quotedOverallDollars) | formatCurrency(0, 7) }}
• Previously invoiced...........{{ invoiceData.previouslyInvoicedOverallDollars | formatCurrency(0, 7) }}
• This invoice.......................{{ invoiceData.toInvoiceOverallDollars | formatCurrency(0, 7) }}
• Total invoiced to date.......{{ afterInvoicingOverallDollars | formatCurrency(0, 7) }}

• Budget Burn Percent..{{ toInvoiceOverallPercent }}%

All amounts are EXCLUSIVE of GST

• Status : {{ invoiceData.job.State }}
==========================================================
    </pre>
    </CopyButton>
  </BfwComponent>
</template>

<script>
import BfwComponent from "../BwfComponent"
import CopyButton from "@/components/toolkit/CopyButton"

export default {
  name: "JobsInvoiceBudgetSummary",
  permissions: [],
  props: ["invoiceData"],
  components: {
    CopyButton,
    BfwComponent
  },
  methods: {},
  mounted() {

  },
  computed: {
    toInvoiceOverallPercent() {
      const percent =  Math.round(100*this.afterInvoicingOverallDollars / this.invoiceData.quotedOverallDollars)
      return isFinite(percent) ? percent : '--'
    },
    afterInvoicingOverallDollars(){
      return this.invoiceData.previouslyInvoicedOverallDollars + this.invoiceData.toInvoiceOverallDollars
    },
    proposal(){
      return this.invoiceData.quote ? this.invoiceData.quote.ID : 'N/A'
    }
  },
  data() {
    return {
      title: "Job Invoice Summary",
      showTitle: false,
      editable: false,
      previouslyInvoiced: 0,
      job: {},
      quote: {},
      isReady: true,
    }
  }
}

</script>

<style scoped>

.name {
  font-weight: bold
}

.hours {

}

.items {

}
</style>

