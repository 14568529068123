<template>
  <div v-if="loaded">
    <h3>Summary</h3>
    <v-select label="Snapshot" :items="snapshotDates" v-model="selectedSnapshot" @change="updateSnapshot"></v-select>
    <LeadsKpiSnapshot :snapshot="snapshot" :key="selectedSnapshot" v-if="selectedSnapshot"/>
    <p v-else>Please select a snapshot to view</p>

    <h3>New Enquiries</h3>
    <v-chart class="chart" :option="chartOptions2('Leads New Enquiries', 'number')" autoresize/>

    <h3>Open Quotes</h3>
    <h4 class="text-center">Number of Open Quotes</h4>
    <v-chart class="chart" :option="chartOptions('Leads Open Quotes', 'number')" autoresize/>
    <h4 class="text-center">Value of Open Quotes</h4>
    <v-chart class="chart" :option="chartOptions('Leads Open Quotes', 'value')" autoresize/>
    <h4 class="text-center">Hours in Open Quotes</h4>
    <v-chart class="chart" :option="chartOptions('Leads Open Quotes', 'hours')" autoresize/>

    <h3>Conversions</h3>
    <h4 class="text-center">Number of Won</h4>
    <v-chart class="chart" :option="chartOptions('Leads Conversions', 'number')" autoresize/>
    <h4 class="text-center">Value of Won</h4>
    <v-chart class="chart" :option="chartOptions('Leads Conversions', 'value')" autoresize/>
    <h4 class="text-center">Hours in Won</h4>
    <v-chart class="chart" :option="chartOptions('Leads Conversions', 'hours')" autoresize/>
    <h4 class="text-center">Ratio of Won</h4>
    <v-chart class="chart" :option="chartOptions('Leads Conversions', 'ratio')" autoresize/>

  </div>
</template>

<script>
import {use} from "echarts/core"
import {CanvasRenderer} from "echarts/renderers"
import {LineChart, ScatterChart} from "echarts/charts"
import {
  LegendComponent,
  GridComponent,
  MarkLineComponent,
  TooltipComponent,
} from "echarts/components"
import VChart from "vue-echarts"
import LeadsKpiSnapshot from "@/components/leads/LeadsKpiSnapshot.vue"
import {formatDate} from "@/lib/dateTimeUtilities"


use([
  CanvasRenderer,
  LineChart,
  ScatterChart,
  LegendComponent,
  GridComponent,
  MarkLineComponent,
  TooltipComponent,
])

export default {
  name: "LeadsKpiGraphs",
  components: {LeadsKpiSnapshot, VChart},
  permissions: ["Read Timeseries"],
  computed: {
    snapshotDates() {
      const key = "Leads New Enquiries|raw-number" // just pick one
      const data = this.data[key]
      const dates = []
      for (const row of data) {
        dates.push({text: formatDate(new Date(row.record_time), "-"), value: row.record_time})
      }
      return dates
    },
  },
  methods: {
    loadAll() {
      Promise.all([
        this.loadTimeseries("Leads New Enquiries", "raw-number"),
        this.loadTimeseries("Leads New Enquiries", "average-number"),

        this.loadTimeseries("Leads Open Quotes", "new-number"),
        this.loadTimeseries("Leads Open Quotes", "new-value"),
        this.loadTimeseries("Leads Open Quotes", "new-hours"),
        this.loadTimeseries("Leads Open Quotes", "existing-number"),
        this.loadTimeseries("Leads Open Quotes", "existing-value"),
        this.loadTimeseries("Leads Open Quotes", "existing-hours"),
        this.loadTimeseries("Leads Open Quotes", "total-number"),
        this.loadTimeseries("Leads Open Quotes", "total-value"),
        this.loadTimeseries("Leads Open Quotes", "total-hours"),

        this.loadTimeseries("Leads Conversions", "new-number"),
        this.loadTimeseries("Leads Conversions", "new-value"),
        this.loadTimeseries("Leads Conversions", "new-hours"),
        this.loadTimeseries("Leads Conversions", "new-ratio"),
        this.loadTimeseries("Leads Conversions", "existing-number"),
        this.loadTimeseries("Leads Conversions", "existing-value"),
        this.loadTimeseries("Leads Conversions", "existing-hours"),
        this.loadTimeseries("Leads Conversions", "existing-ratio"),
        this.loadTimeseries("Leads Conversions", "total-number"),
        this.loadTimeseries("Leads Conversions", "total-value"),
        this.loadTimeseries("Leads Conversions", "total-hours"),
        this.loadTimeseries("Leads Conversions", "total-ratio"),
      ]).then(() => {
        this.loaded = true
      })
    },
    loadTimeseries(seriesName, itemName) {
      const query = {
        series_name: seriesName,
        item_name: itemName,
      }
      const key = seriesName + "|" + itemName
      return this.$BwfApi.get("timeseries", query).then(response => {
        this.data[key] = response
      })
    },
    chartOptions2(datasetName, itemName) { // eg. datasetName = "Leads Open Quotes", itemName = "number"
      const seriesNames = ["raw", "average"]
      const seriesData = {
        raw: [],
        average: [],
      }
      for (const seriesName of seriesNames) {
        const key = datasetName + "|" + seriesName + "-" + itemName
        if (!this.data[key]) {
          console.log("Missing data for", key)
          return {}
        }
        const data = this.data[key]
        for (const row of data) {
          const d = new Date(row.record_time)
          seriesData[seriesName].push([d, Math.round(10 * row.value) / 10])
        }
      }

      const min = new Date()
      min.setMonth(min.getMonth() - 18)
      const max = new Date()
      return {
        xAxis: {
          type: "time",
          min: min,
          max: max,
        },
        yAxis: {
          type: "value",
        },
        legend: {
          top: "30"
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow"
          }
        },
        series: [
          {
            name: "Raw",
            type: "scatter",
            data: seriesData.raw,
          },
          {
            name: "Average",
            type: "line",
            smooth: true,
            data: seriesData.average,
          },
        ]
      }
    },
    chartOptions(datasetName, itemName) { // eg. datasetName = "Leads Open Quotes", itemName = "number"
      const seriesNames = ["new", "existing", "total"]
      const seriesData = {
        new: [],
        existing: [],
        total: []
      }
      for (const seriesName of seriesNames) {
        const key = datasetName + "|" + seriesName + "-" + itemName
        if (!this.data[key]) {
          console.log("Missing data for", key)
          return {}
        }
        const data = this.data[key]
        for (const row of data) {
          const d = new Date(row.record_time)
          seriesData[seriesName].push([d, Math.round(10 * row.value) / 10])
        }
      }

      const min = new Date()
      min.setMonth(min.getMonth() - 18)
      const max = new Date()
      return {
        xAxis: {
          type: "time",
          min: min,
          max: max,
        },
        yAxis: {
          type: "value",
        },
        legend: {
          top: "30"
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow"
          }
        },
        series: [
          {
            name: "New",
            type: "line",
            smooth: true,
            data: seriesData.new,
          },
          {
            name: "Existing",
            type: "line",
            smooth: true,
            data: seriesData.existing,
          },
          {
            name: "Total",
            type: "line",
            smooth: true,
            data: seriesData.total,
          },
        ]
      }
    },
    updateSnapshot() {
      const snapshotDate = this.selectedSnapshot
      this.snapshot = {
        newLeads: {
          number: this.data["Leads New Enquiries|average-number"].find(row => row.record_time === snapshotDate).value,
        },
        quoted: {
          number: this.data["Leads Open Quotes|total-number"].find(row => row.record_time === snapshotDate).value,
          hours: this.data["Leads Open Quotes|total-hours"].find(row => row.record_time === snapshotDate).value,
          value: this.data["Leads Open Quotes|total-value"].find(row => row.record_time === snapshotDate).value,
        },
        wonNew: {
          number: this.data["Leads Conversions|new-number"].find(row => row.record_time === snapshotDate).value,
          hours: this.data["Leads Conversions|new-hours"].find(row => row.record_time === snapshotDate).value,
          value: this.data["Leads Conversions|new-value"].find(row => row.record_time === snapshotDate).value,
          ratio: this.data["Leads Conversions|new-ratio"].find(row => row.record_time === snapshotDate).value,
        },
        wonExisting: {
          number: this.data["Leads Conversions|existing-number"].find(row => row.record_time === snapshotDate).value,
          hours: this.data["Leads Conversions|existing-hours"].find(row => row.record_time === snapshotDate).value,
          value: this.data["Leads Conversions|existing-value"].find(row => row.record_time === snapshotDate).value,
          ratio: this.data["Leads Conversions|existing-ratio"].find(row => row.record_time === snapshotDate).value,
        }
      }
    }
  },
  mounted() {
    this.loadAll()
  },
  data() {
    return {
      loaded: false,
      data: {},
      snapshot: {},
      selectedSnapshot: undefined,
    }
  }
}
</script>

<style scoped>
.chart {
  height: 600px;
}

</style>