<template>
  <BfwComponent :isReady="isReady" :title="title" :showTitle="showTitle" :editable="editable" :hideCard="true"
                loaderType="list-item">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-row>
        <v-col cols="2" md="1">
          <v-btn absolute dark fab small color="primary" @click="previous">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
        </v-col>

        <v-col cols="4" md="5">
          <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="monthYear" transition="scale-transition"
                  offset-y max-width="290px" min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="monthYear" label="Month & Year" prepend-icon="mdi-calendar" readonly
                            v-bind="attrs" v-on="on"></v-text-field>
            </template>
            <v-date-picker v-model="monthYear" type="month" no-title scrollable @change="updateMonth"  @click="$refs.menu.save(monthYear)">
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="4" md="5">
          <v-text-field v-model="weekStr" label="Week" readonly></v-text-field>
        </v-col>

        <v-col cols="2" md="1">
          <v-btn absolute dark fab small color="primary" @click="next">
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-col>
      </v-row>

    </v-form>
  </BfwComponent>
</template>

<script>
import BfwComponent from "../BwfComponent"
import {monthNames} from "@/lib/constants"
import {getWeekNumber} from "@/lib/dateTimeUtilities"

export default {
  name: "WeekNavigator",
  permissions: [],
  components: {
    BfwComponent
  },
  props: ["value"],
  computed: {},
  methods: {
    next() {
      this.weekStartDate.setDate(this.weekStartDate.getDate() + 7)
      this.monthYear = this.weekStartDate.toISOString().substr(0, 7)
      this.updateWeekStr()
      this.emitValue()
    },
    previous() {
      this.weekStartDate.setDate(this.weekStartDate.getDate() - 7)
      this.monthYear = this.weekStartDate.toISOString().substr(0, 7)
      this.updateWeekStr()
      this.emitValue()
    },
    updateMonth() {
      const a = new Date(`${this.monthYear}-01`)
      a.setDate(a.getDate() - (a.getDay()) + 1)
      this.weekStartDate = a
      this.updateWeekStr()
      this.emitValue()
    },

    formatMonth(monthNumber) {
      return monthNames[monthNumber]
    },
    updateWeekStr() {
      const tmp = new Date(this.weekStartDate.getTime())
      const fromMonth = this.formatMonth(tmp.getMonth())
      const fromDay = tmp.getDate()
      tmp.setDate(tmp.getDate() + 6)
      const toMonth = this.formatMonth(tmp.getMonth())
      const toDay = tmp.getDate()
      this.weekStr = `${fromDay} ${fromMonth} - ${toDay} ${toMonth}`
    },
    emitValue() {
      const period = getWeekNumber(this.weekStartDate)
      this.$emit("input", {
        year: period.year,
        month:this.weekStartDate.getMonth()+1, // @todo refcator this out
        week: period.week,
        period:period.year.toString() + "-" + period.week.toString().padStart(2, "0"),
      })
    }
  },
  mounted() {
    this.updateWeekStr()
    this.emitValue()
  },
  data() {
    const weekStartDate = new Date()
    weekStartDate.setDate(weekStartDate.getDate() - weekStartDate.getDay() + 1)

    return {
      title: "Week Navigator",
      showTitle: false,
      editable: false,
      isReady: true,
      valid: true,
      monthYear: new Date().toISOString().substr(0, 7),
      weekStartDate: weekStartDate,
      weekStr: "",
      menu: false
    }
  },
}

</script>

<style scoped>

</style>