<template>
  <BfwComponent :isReady="isReady" :title="title" :showTitle="showTitle" :editable="editable">
    <v-form ref="form" v-model="valid" lazy-validation :isReady="isReady">
      <v-row>
        <v-col>
          <v-chart class="chart" :option="chartOptions"/>
        </v-col>
      </v-row>
    </v-form>
  </BfwComponent>
</template>

<script>
import BfwComponent from "../BwfComponent"
import {use} from "echarts/core"
import {CanvasRenderer} from "echarts/renderers"
import {LineChart} from "echarts/charts"
import {
  LegendComponent,
  GridComponent,
  MarkLineComponent
} from "echarts/components"
import VChart from "vue-echarts"
import {defineComponent} from "vue"

use([
  CanvasRenderer,
  LineChart,
  LegendComponent,
  GridComponent,
  MarkLineComponent
])
export default defineComponent({
  name: "JobBurnUp",
  permissions: ["Read Job", "Read Time", "Read Capacity Plan"],
  props: ["jobId"],
  components: {
    BfwComponent,
    VChart,
  },
  methods: {
    processJobResponse(response) {
      this.job = response.Job

      let estimated = 0
      const tasks = (response.Job.Tasks) ? this.$ensureArray(response.Job.Tasks.Task) : []
      tasks.forEach(function (task) {
        estimated += parseInt(task.EstimatedMinutes)
      })

      this.hoursBudget = estimated / 60
      this.$WfmApi.get("time.api/job/" + this.jobId).then(response => this.processTimeResponse(response))
    },
    processTimeResponse(response) {
      if (response.Times) {
        this.timeEntries = this.$ensureArray(response.Times.Time)
      } else {
        this.timeEntries = []
      }
      this.isReady = true
    },
    loadPlannedCapacity() {
      this.$BwfApi.get("capacity-plan/job", {job_id: this.jobId}).then((response) => {
        this.plannedCapacity = response.labour
      })
    },
    loadOriginalDueDate() {
      this.originalDueDate = null
      this.$WfmApi.get("job.api/get/" + this.jobId + "/customfield", {}).then((response) => {
        if (response.CustomFields) {
          const customFields = this.$ensureArray(response.CustomFields.CustomField)
          customFields.forEach(field => {
            if (field.UUID === this.settings.originalDueDateUUID) {
              this.originalDueDate = new Date(field.Date)
            }
          })
        }
      })
    },
    proRataMultiplier(monthStr) { // amount of month left, 0 if month has gone, 1 if month is future, or a fraction for the current month
      const parts = monthStr.split("-")
      const dataYear = parseInt(parts[0])
      const dataMonth = parseInt(parts[1])
      const now = new Date()
      const currentYear = now.getFullYear()
      const currentMonth = now.getMonth() + 1
      const c = currentYear * 12 + currentMonth
      const d = dataYear * 12 + dataMonth
      if (c === d) { // current month, pro rata
        const daysUsed = now.getDate()
        const daysInMonth = new Date(currentYear, currentMonth, 0).getDate()
        return 1 - (daysUsed / daysInMonth)
      } else if (d < c) {
        return 0
      } else {
        return 1
      }
    },
  },
  computed: {
    chartOptions: function () {
      const usedChartData = {}
      const startDate = new Date(this.job.StartDate)
      const dueDate = new Date(this.job.DueDate)
      const now = new Date()
      const endDate = dueDate > now ? dueDate : now
      const dueWeek = Math.floor((dueDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24 * 7))

      const totalWeeks = Math.floor((endDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24 * 7))
      const nowIndex = Math.floor((now.getTime() - startDate.getTime()) / (1000 * 3600 * 24 * 7))

      for (let i = 0; i <= totalWeeks; i++) {
        usedChartData[i] = 0
      }
      // aggregate time entries into days
      for (const item of this.timeEntries) {
        const hours = parseInt(item.Minutes) / 60
        const date = new Date(item.Date)
        let DifferenceInSeconds = date.getTime() - startDate.getTime()
        let DifferenceInWeeks = Math.floor(DifferenceInSeconds / (1000 * 3600 * 24 * 7))
        DifferenceInWeeks = Math.max(0, DifferenceInWeeks) // negative values are possible
        usedChartData[DifferenceInWeeks] += hours
      }

      // convert to running totals
      let runningTotal = 0
      for (let i = 0; i <= totalWeeks; i++) {
        runningTotal += usedChartData[i]
        if (i <= nowIndex) {
          usedChartData[i] = runningTotal
        } else {
          usedChartData[i] = null
        }
      }

      // format into pairs
      const usedPairs = []
      let last = []
      for (const week in usedChartData) {
        usedPairs.push([week, usedChartData[week]])
        if (usedChartData[week]) {
          last = [week, usedChartData[week]]
        }
      }

      // Planned Capacity
      const plannedChartData = {}
      plannedChartData[last[0]] = last[1] // start same point as end of used

      for (const monthStr in this.plannedCapacity) {
        const parts = monthStr.split("-")
        const date = new Date(parseInt(parts[0]), parseInt(parts[1])) // this sets 1 month ahead
        date.setDate(0)// This sets to the last day of the previous month
        let DifferenceInSeconds = date.getTime() - startDate.getTime()
        let DifferenceInWeeks = Math.floor(DifferenceInSeconds / (1000 * 3600 * 24 * 7))
        DifferenceInWeeks = Math.max(0, DifferenceInWeeks) // negative values are possible

        let total = 0
        for (const serviceId in this.plannedCapacity[monthStr]) {
          total += this.plannedCapacity[monthStr][serviceId] * this.proRataMultiplier(monthStr)
        }

        if (! plannedChartData[DifferenceInWeeks] ){
          plannedChartData[DifferenceInWeeks] = total
        }
      }

      // format into pairs
      const plannedPairs = []
      runningTotal = 0
      for (const week in plannedChartData) {
        runningTotal += plannedChartData[week]
        if (runningTotal > 0) {
          plannedPairs.push([week, runningTotal])
        }
      }

      let originalDueWeek = -1 // off the screen unless set
      if (this.originalDueDate) {
        const DifferenceInSeconds = this.originalDueDate.getTime() - startDate.getTime()
        originalDueWeek = Math.floor(DifferenceInSeconds / (1000 * 3600 * 24 * 7))
      }

      return {
        animation: false,
        legend: {bottom: 0},
        grid: {
          left: 60,
          top: 20,
          right: 80,
          bottom: 50
        },
        xAxis: {
          type: "value",
        },
        yAxis: {
          type: "value",
          max: Math.round(Math.max(this.hoursBudget, runningTotal) + 10)
        },
        series: [
          {
            name: "Actual",
            type: "line",
            areaStyle: {},
            showSymbol: false,
            color: "#92C6FF",
            data: usedPairs,
            silent: true,
            markLine: {
              lineStyle: {width: 3, type: "solid"},
              symbol: "none",
              label: {show: false},
              data: [
                {name: "budget", yAxis: this.hoursBudget, lineStyle: {color: "#84bd00"}, label: {formatter: "Budget", show: true, position: "start"}},
                {name: "dueDate", xAxis: dueWeek, lineStyle: {color: "#0000ff"}, label: {formatter: "Expected Due Date", show: true}},
                {name: "originalDate", xAxis: originalDueWeek, lineStyle: {color: "#f87979"}, label: {formatter: "Original Due Date", show: dueWeek!==originalDueWeek}}
              ]
            }
          },
          {
            name: "Planned",
            silent: true,
            type: "line",
            showSymbol: false,
            color: "#FF8138",
            data: plannedPairs,
          },
        ]
      }
    },
  },
  watch: {
    jobId: function () {
      if (this.jobId) {
        this.isReady = false
        this.$WfmApi.get("job.api/get/" + this.jobId)
            .then(response => this.processJobResponse(response))
            .then(this.loadPlannedCapacity)
            .then(this.loadOriginalDueDate)
      } else {
        this.isReady = false
      }
    }
  },
  data() {
    return {
      title: "Time Burn Up",
      showTitle: true,
      editable: false,
      isReady: false,
      valid: true,
      hoursBudget: 0,
      job: {},
      timeEntries: [],
      plannedCapacity: {},
      originalDueDate: null,
      settings: {
        originalDueDateUUID: "9c483d6b-3c50-44a4-9bfe-904880c11677",
      },
    }
  },
})

</script>

<style scoped>
.chart {
  height: 300px;
}
</style>
