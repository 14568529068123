<template>
    <v-container fluid v-if="isReady">
        <h3 v-if="showTitle">{{title}}</h3>
         <v-btn absolute dark fab top right color="primary" v-if="editable">
            <v-icon>mdi-cog-outline</v-icon>
         </v-btn>

         <slot></slot>
    </v-container>

    <v-container v-else>
      <v-skeleton-loader class="mx-auto" max-width="300" :type="loaderType"></v-skeleton-loader>
    </v-container>
</template>

<script>
  export default {
    name: "BwfComponentCore",
    props: {"isReady": {type: Boolean, required: true},
            "showTitle": {type: Boolean, default: true},
            'title': {type: String, required: true},
            'editable': {type: Boolean, default: false},
            'hideCard' : {type:Boolean, default: false},
            'loaderType' : {type:String, default: 'card'},
    },
    components: {

    },
    methods: {

    },
    mounted() {
    },
    data() {
      return {

      }
    },
  }

</script>

<style scoped>

</style>