export function getWeekNumber(date) {
  const date2 = new Date(date.getTime())
  date2.setHours(0, 0, 0, 0)
  date2.setDate(date2.getDate() + 3 - (date2.getDay() + 6) % 7)   // Thursday in current week decides the year.
  const week1 = new Date(date2.getFullYear(), 0, 4)  // January 4 is always in week 1.
  const week = 1 + Math.round(((date2.getTime() - week1.getTime()) / 86400000  // Adjust to Thursday in week 1 and count number of weeks from date to week1.
    - 3 + (week1.getDay() + 6) % 7) / 7)
  const year = date2.getFullYear()
  return {week, year}
}

export function getDateOfISOWeek(weekNumber, year) {
  const simple = new Date(year, 0, 1 + (weekNumber - 1) * 7)
  const dow = simple.getDay()
  const IsoWeekStart = simple
  if (dow <= 4)
    IsoWeekStart.setDate(simple.getDate() - simple.getDay() + 1)
  else
    IsoWeekStart.setDate(simple.getDate() + 8 - simple.getDay())
  return IsoWeekStart
}

export function formatMonth(monthStr) {
  const parts = monthStr.split("-")
  const year = parseInt(parts[0])
  const monthNumber = parseInt(parts[1])
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
  return months[monthNumber - 1] + " " + year
}

// Format a date as yyyymmdd
export function formatDate(date, sep = "") {
  if (!date) {
    return ""
  }

  let month = "" + (date.getMonth() + 1)
  let day = "" + date.getDate()
  let year = date.getFullYear()

  if (month.length < 2)
    month = "0" + month
  if (day.length < 2)
    day = "0" + day

  return [year, month, day].join(sep)
}

// like Date.toISOString but for local time
export function toLocalString(date) {
  var tzo = -date.getTimezoneOffset(),
    dif = tzo >= 0 ? "+" : "-",
    pad = function (num) {
      var norm = Math.floor(Math.abs(num))
      return (norm < 10 ? "0" : "") + norm
    }
  return date.getFullYear() +
    "-" + pad(date.getMonth() + 1) +
    "-" + pad(date.getDate()) +
    "T" + pad(date.getHours()) +
    ":" + pad(date.getMinutes()) +
    ":" + pad(date.getSeconds()) +
    dif + pad(tzo / 60) +
    ":" + pad(tzo % 60)
}

/**
 * Given a months and year, return the weeks that have at least one day in the month
 * @param month
 * @param year
 * @returns {*[]}
 */
export function getWeeksInMonth(month, year) {
  const startDate = new Date(year, month - 1, 1)
  const endDate = new Date(year, month, 0)
  const start = getWeekNumber(startDate)
  const end = getWeekNumber(endDate)
  const periods = []
  for (let w = start.week; w <= end.week; w++) {
    periods.push({
      week: w,
      year: year,
      period: `${year}-${w.toString().padStart(2, "0")}`,
      fraction: getWeekFraction(month, w, year)
    })
  }
  return periods
}

/**
 * Given a month, week and year, return the fraction of the week that is in the month
 * @param month
 * @param weekNumber
 * @param year
 * @returns {number}
 */
export function getWeekFraction(month, weekNumber, year) {
  // Fraction of the working week that is contained in the specified month
  const startOfWeek = getDateOfISOWeek(weekNumber, year)
  let count = 0
  for (let d = 0; d < 7; d++) {
    const date = new Date(startOfWeek)
    date.setDate(date.getDate() + d)
    if (date.getMonth() + 1 === month && // date is in the right month
      date.getDay() !== 0 && date.getDay() !== 6) { // date is not in the weekend
      count++
    }
  }
  return count / 5 // % of the week in the selected month
}


/**
 * Given a week and year, return the month(s) that have at least one day in the week
 * @param week
 * @param year
 * @returns {*[]}
 */
export function getMonthsInWeek(week, year) {
  const startDate = getDateOfISOWeek(week, year)
  const startMonth = startDate.getMonth() + 1
  const endDate = getDateOfISOWeek(week, year)
  endDate.setDate(endDate.getDate() + 6)
  const endMonth = endDate.getMonth() + 1

  const periods = []
  periods.push({
    week: week,
    year: year,
    month: startMonth,
    period: `${year}-${week.toString().padStart(2, "0")}`,
    fraction: getMonthFraction(startMonth, week, year)
  })

  if (startMonth !== endMonth) {
    periods.push({
      week: week,
      year: year,
      month: endMonth,
      period: `${year}-${week.toString().padStart(2, "0")}`,
      fraction: getMonthFraction(endMonth, week, year)
    })
  }
  return periods
}

/**
 * Given a month, week and year, return the fraction of the month that the week is in
 * This should be ~0.2 for a regular week but might be less if the week crosses a month boundary.
 *
 * @param month
 * @param weekNumber
 * @param year
 * @returns {number}
 */
export function getMonthFraction(month, weekNumber, year) {
  const workDaysInMonth = getWeekdaysInMonth(month, year)
  // Fraction of the working week that is contained in the specified month
  const startOfWeek = getDateOfISOWeek(weekNumber, year)
  let count = 0
  for (let d = 0; d < 7; d++) {
    const date = new Date(startOfWeek)
    date.setDate(date.getDate() + d)
    if (date.getMonth() + 1 === month && // date is in the right month
      date.getDay() !== 0 && date.getDay() !== 6) { // date is not in the weekend
      count++
    }
  }
  return count / workDaysInMonth // % of the month that is in the selected month
}

/**
 * Given a year, month, and date, return true if the day is a work day
 * @param year
 * @param month
 * @param date
 * @returns {boolean}
 */
function isWeekday(year, month, date) {
  const day = new Date(year, month, date).getDay()
  return day !== 0 && day !== 6
}

/**
 * Given a month and year, return number of work days in the month
 * @param month
 * @param year
 * @returns {number}
 */
function getWeekdaysInMonth(month, year) {
  const daysInMonth = new Date(year, month, 0).getDate()
  let weekdays = 0
  for (let i = 0; i < daysInMonth; i++) {
    if (isWeekday(year, month, i + 1))
      weekdays++
  }
  return weekdays
}

/**
 * Convert a Javscript date into a new timezone
 * @param date
 * @param tzString
 * @returns {Date}
 */
export function convertTZ(date, tzString) {
  return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {timeZone: tzString}))
}

export function getJsDateFromExcel(excelDate) {
	return new Date((excelDate - (25569))*86400*1000);
}