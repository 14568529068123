<template>
  <v-container fluid>
    <v-container class="text-xs-center">
    <h1>Access Denied</h1>
      <p>You are not authorized to view this page</p>
      <p>If you are a new user, you may not have been given permissions to view any pages yet.</p>
    </v-container>
  </v-container>
</template>

<script>

export default {
  name: "AccessDenied",
}

</script>

<style scoped>

</style>