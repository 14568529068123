<script>
export default {
  name: "ReportCashflow",
  methods: {
    generateCashflow() {
      this.$BwfApi.get(`capacity-plan/cashflow`, {}, {responseType: "blob"})
          .then(response => {
            const objectUrl = window.URL.createObjectURL(response)
            const anchor = document.createElement("a")
            document.body.appendChild(anchor)
            anchor.target = "_blank"
            anchor.href = objectUrl
            anchor.click()
          })
    }
  }
}
</script>

<template>
  <v-btn @click="generateCashflow" color="primary" class="mb-5">Generate Cashflow Report</v-btn>
</template>

<style scoped>

</style>