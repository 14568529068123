<script>
import {defineComponent} from "vue"

export default defineComponent({
  props: {
    text: {
      type: String,
      default: "New"
    }
  },
  name: "CornerRibbon"
})
</script>

<template>
  <div class="ribbon-wrapper">
    <div class="ribbon">
      {{text}}
    </div>
  </div>
</template>

<style scoped>
.ribbon-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  width: 120px;
  overflow: hidden;
  height: 80px
}

.ribbon {
  transform: rotate(45deg) translate(20px, -20px);
  background: gold;
  padding: 0 0 0 25px;
  font-size: 12px;
  text-align: center;
}
</style>