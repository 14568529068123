<template>
  <BfwComponent :isReady="isReady" :title="title" :showTitle="showTitle" :editable="editable" :hideCard="true"
                loaderType="list-item">
    <v-form ref="form" v-model="valid" lazy-validation>

      <v-btn @click="importFromSheet" color="primary">Import from Google Sheet</v-btn>


      <v-simple-table>
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-left">
              Year
            </th>
            <th class="text-left">
              Month
            </th>
            <th class="text-left">
              Reserved Capacity
            </th>
            <th v-for="item in services" v-bind:key="item.uuid" class="text-left">
              {{ item.name }}
            </th>
          </tr>
          </thead>

          <tbody>
          <tr v-for="row in data" v-bind:key="row.year.toString()+row.month.toString()" :class="{changed:row.changed}">
            <td>
              {{ row.year }}
            </td>
            <td>
              {{ formatMonth(row.month) }}
            </td>
            <td>
              <v-text-field v-model="row.reserved_capacity" suffix="%"/>
            </td>
            <td v-for="item in services" v-bind:key="item.uuid">
              <v-text-field v-model="row.capacity[item.service_id].capacity"/>
            </td>

          </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-btn color="primary" @click="addMonth" class="mt-3">Add Month</v-btn>
      <div class="pt-4">
        <v-btn color="primary" @click="save">Save</v-btn>
      </div>
    </v-form>

    <v-dialog v-model="showImportDialog" width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Import from Google Sheet
        </v-card-title>

        <v-card-text v-if="importDataLoaded">
          <p>Please select the months to import from the google sheet. Once imported please check the results then save.</p>
          <div v-for="( record, monthStr) in importData" v-bind:key="monthStr">
            <v-checkbox :label="formatMonth(record.month)+' '+record.year" :value="monthStr" dense v-model="importList"></v-checkbox>
          </div>
        </v-card-text>

        <v-card-text v-else class="text-center pa-4">
          <v-progress-circular indeterminate size="150" width="10" color="primary">Loading</v-progress-circular>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="primary" dark text href="https://docs.google.com/spreadsheets/d/138687WnJ-frbOl6m_1qamwyzLdB4v18rSLy-4Zd0FdE/edit" target="_blank"> Open sheet</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="doImport"> Import</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </BfwComponent>
</template>

<script>
import BfwComponent from "../BwfComponent"
import {monthNames} from "@/lib/constants"

export default {
  name: "CapacitySetup",
  permissions: ['Read Capacity', 'Modify Capacity','Read Services'],
  components: {
    BfwComponent
  },
  computed: {},
  mounted() {
    this.loadServices()
  },
  methods: {
    importFromSheet() {
      this.showImportDialog = true
      this.importDataLoaded = false
      this.$BwfApi.get("admin/capacity/google-sheet").then(response => {
        this.importData = response
        this.importDataLoaded = true
      })
    },
    doImport() {
      this.showImportDialog = false

      for (const monthStr of this.importList) {
        const idx = this.findDataRow(this.importData[monthStr].month_number)
        let row
        if (idx === false) {
          row = {
            capacity: {},
            month: this.importData[monthStr]['month'],
            year: this.importData[monthStr]['year'],
            reserved_capacity: this.importData[monthStr]['reserved_capacity'],
            month_number: this.importData[monthStr]['month_number']
          }
        } else {
          row = this.data[idx]
          row.reserved_capacity= this.importData[monthStr]['reserved_capacity']
        }
        row.changed=true

        for (const serviceId in this.importData[monthStr].capacity) {
          if (serviceId in row.capacity) {
            row["capacity"][serviceId].capacity = this.importData[monthStr].capacity[serviceId]
          } else {
            row["capacity"][serviceId] = {
                capacity: this.importData[monthStr].capacity[serviceId],
                service_id: serviceId
            }
          }
        }
        if (idx) {
          this.data[idx] = row
        }
        else {
          this.data.push(row)
        }
      }
    },
    findDataRow(monthNumber) {
      for (const idx in this.data) {
        if (this.data[idx].month_number === monthNumber) {
          return idx
        }
      }
      return false
    },
    loadServices() {
      this.$BwfApi.get("services", {enabled: true}).then(response => {
        this.services = response
        this.loadCapacity()
      })
    },
    loadCapacity() {
      this.$BwfApi.get("capacity").then(response => {
        const _this = this
        this.data = response
        // ensure there are default values for all services
        this.data.forEach((row) => {
          row.capacity = row.capacity || {}
          _this.services.forEach((service) => {
            if (!row.capacity[service.service_id]) {
              row.capacity[service.service_id] = {
                capacity: 0,
                service_id: service.service_id
              }
            }
          })
        })
      })
    },
    formatMonth(monthNumber) {
      return monthNames[monthNumber - 1]
    },
    addMonth() {
      const d = new Date()
      const capacity_template = {}
      for (const item of this.services) {
        capacity_template[item.service_id] = {
          service_id: item.service_id,
          capacity: 0
        }
      }

      let month = this.data.length > 0 ? parseInt(this.data[this.data.length - 1].month) : d.getMonth()
      let year = this.data.length > 0 ? this.data[this.data.length - 1].year : d.getFullYear()
      let reserved_capacity = this.data.length > 0 ? this.data[this.data.length - 1].reserved_capacity : 0
      let capacity = this.data.length > 0 ? JSON.parse(JSON.stringify(this.data[this.data.length - 1].capacity)) : capacity_template
      if (month === 12) {
        year += 1
      }
      month += 1
      if (month === 13) {
        month = 1
      }
      this.data.push({
        year: year,
        month: month,
        reserved_capacity: reserved_capacity,
        capacity: capacity
      })

    },
    save() {
      this.$BwfApi.post("capacity", this.data).then(response => {
        this.data = response
        this.loadServices()
        this.$notify.toast("Save")
      })
    }
  },
  data() {
    return {
      title: "Capacity Setup",
      showTitle: false,
      editable: false,
      isReady: true,
      valid: true,
      services: [],
      data: [],
      importData: {},
      showImportDialog: false,
      importDataLoaded: false,
      importList: []
    }
  },
}

</script>

<style scoped>
.changed {
  background: lightgreen;
}
</style>