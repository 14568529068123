<template>
  <v-container fluid>
    <h1>KPI: Project Speed & Focus</h1>
    <SpeedFocusKPIGraph></SpeedFocusKPIGraph>
    <DeliveryOnTimeKPIGraph></DeliveryOnTimeKPIGraph>
  </v-container>
</template>

<script>


import SpeedFocusKPIGraph from "@/components/capacity/SpeedFocusKPIGraph.vue"
import DeliveryOnTimeKPIGraph from "@/components/capacity/DeliveryOnTimeKPIGraph.vue"

export default {
  name: "ReportKPIPage",
  permissions: [...SpeedFocusKPIGraph.permissions, ...DeliveryOnTimeKPIGraph.permissions],
  components: {SpeedFocusKPIGraph, DeliveryOnTimeKPIGraph},
  data() {
    return {}
  },
  mounted() {

  },
}

</script>

<style scoped>

</style>