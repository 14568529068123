<template>
  <v-container fluid>
    <h1>Roles</h1>
    <v-btn @click="add" color="primary" class="ma-1">
      <v-icon>
        mdi-plus
      </v-icon>
      Add
    </v-btn>

    <v-data-table :headers="headers" :items="roles" dense disable-pagination hide-default-footer>
      <template v-slot:item.actions="{ item }">
        <v-btn @click="del(item.role_id)" fab x-small color="red" class="ma-1" :disabled="item.name==='Administrator'">
          <v-icon>
            mdi-delete
          </v-icon>
        </v-btn>
        <v-btn :to="`/admin/role/${item.role_id}/permissions`" fab x-small color="primary" class="ma-1">
          <v-icon>
            mdi-account-cog
          </v-icon>
        </v-btn>
      </template>

      <template v-slot:item.role="{ item }">
        <v-text-field v-model="item.name"/>
      </template>
    </v-data-table>

    <v-btn @click="save" color="primary" class="ma-1">
      <v-icon>
        mdi-content-save
      </v-icon>
      Save
    </v-btn>
  </v-container>
</template>

<script>

export default {
  name: "AdminRolesPage",
  permissions: ["Administer Betaworkflow"],
  components: {},
  data() {
    return {
      roles: [],
      headers: [
        {text: "Role", value: "role"},
        {text: "Actions", value: "actions"},
      ],
    }
  },
  methods: {
    add() {
      this.roles.push({role_id: Math.random(), role: "", is_new: true})
    },
    del(role_id) {
      for (const i in this.roles) {
        const role = this.roles[i]
        if (role.role_id === role_id) {
          this.roles.splice(i, 1)
        }
      }
    },
    save() {
      this.$BwfApi.post("admin/roles", this.roles).then(response => {
        this.roles = response
        this.$notify.toast("Saved")
      })
    },
    loadRoles() {
      this.$BwfApi.get("admin/roles").then(response => {
        this.roles = response
      })
    }
  },
  mounted() {
    this.loadRoles()
  },
}

</script>

<style scoped>

</style>