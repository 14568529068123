<template>
  <BfwComponent :isReady="isReady" :title="title" :showTitle="showTitle" :editable="editable" loaderType="list-item-avatar-three-line">
    <v-form ref="form" v-model="valid" lazy-validation v-if="isReady">
      Start Date:
      <JobFieldStartDate :job="job"/>
      <br/>
      End Date:
      <JobFieldDueDate :job="job"/>
      <br/>
      <div ref="gantt" class="left-container"></div>
    </v-form>
  </BfwComponent>
</template>

<script>
import BfwComponent from "../BwfComponent"
import JobFieldDueDate from "./fields/JobFieldDueDate"
import JobFieldStartDate from "./fields/JobFieldStartDate"
import {gantt} from "dhtmlx-gantt"
import {formatDate} from "@/lib/dateTimeUtilities"

export default {
  name: "JobTimePlanner",
  permissions: ["Read Job", "Modify Job Task", ...JobFieldDueDate.permissions, ...JobFieldStartDate.permissions],
  props: ["jobId"],
  components: {BfwComponent, JobFieldDueDate, JobFieldStartDate},
  methods: {
    loadJob() {
      if (this.jobId) {
        this.isReady = false
        this.$WfmApi.get("job.api/get/" + this.jobId).then(response => this.processResponse(response))
      } else {
        this.isReady = false
      }
    },
    processResponse(response) {
      this.job = response.Job
      this.isReady = true
      const _this = this

      const d1 = new Date(this.job.StartDate)
      const d2 = new Date(this.job.DueDate)
      let defaultDuration = this.dateDiff(d1, d2)

      const tasks = (response.Job.Tasks) ? this.$ensureArray(response.Job.Tasks.Task) : []
      _this.tasks.data = []
      tasks.forEach(function (task, idx) {
        let startDate = formatDate(d1, '-')
        let duration = defaultDuration

        if (task.StartDate) {
          startDate = task.StartDate
          const d1 = new Date(task.StartDate)
          const d2 = new Date(task.DueDate)
          duration = _this.dateDiff(d1, d2)
        }
        const progress = task.EstimatedMinutes > 0 ? Math.max(1, (task.ActualMinutes / task.EstimatedMinutes)) : 0
        const remaining = Math.floor(Math.max(0, (task.EstimatedMinutes - task.ActualMinutes) / 60))
        const name = "(" + remaining + ") " + task.Name
        _this.tasks.data.push({
          id: idx + 1,
          uuid: task.UUID,
          estimated_minutes: task.EstimatedMinutes,
          text: name,
          start_date: startDate,
          duration: duration,
          remaining: remaining,
          progress: progress,
          task: task
        })
      })

      this.$nextTick(function () {
        this.drawGantt()
        this.processChartChange()
      })
    },
    drawGantt() {
      const endDate = new Date(this.job.DueDate)
      endDate.setMonth(endDate.getMonth() + 3) // add 3 months past the last date

      gantt.clearAll()
      gantt.plugins({marker: true})
      gantt.config.autosize = "y"
      gantt.config.date_format = "%Y-%m-%d"
      gantt.config.start_date = new Date(this.job.StartDate)
      gantt.config.end_date = endDate
      gantt.config.scale_unit = "week"
      gantt.config.drag_links = false
      gantt.config.drag_progress = false
      gantt.config.correct_work_time = true
      gantt.config.columns = [
        //{name:"text", label:"Task name", width:"*", tree:true }
      ]

      gantt.showLightbox = function () {
      }
      if (!this.handler) {
        this.handler = gantt.attachEvent("onAfterTaskDrag", this.processChartEvent)
      }

      const dateToStr = gantt.date.date_to_str(gantt.config.task_date)
      gantt.addMarker({
        start_date: new Date(),
        css: "today",
        text: "Today",
        title: dateToStr(new Date())
      })
      gantt.addMarker({
        start_date: new Date(this.job.StartDate),
        css: "project-marker",
        text: "Project Start",
        title: dateToStr(new Date(this.job.StartDate))
      })
      gantt.addMarker({
        start_date: new Date(this.job.DueDate),
        css: "project-marker",
        text: "Project Due",
        title: dateToStr(new Date(this.job.DueDate))
      })

      this.addMilestones()
      gantt.init(this.$refs.gantt)
      gantt.parse(this.tasks)
    },
    addMilestones() {
      if (this.job.Milestones) {
        const dateToStr = gantt.date.date_to_str(gantt.config.task_date)
        const milestones = this.$ensureArray(this.job.Milestones.Milestone)
        milestones.forEach(function (milestone) {
          gantt.addMarker({
            start_date: new Date(milestone.Date),
            css: "milestone-marker",
            text: milestone.Description,
            title: dateToStr(new Date(milestone.Date))
          })
        })
      }
    },
    saveTask(task) {
      const _this = this
      const label = this.$nameToLabel(task.task.Name) // GET API provides 'name' only and PUT uses 'label'
      const data = {
        Task: {
          JobID: _this.jobId,
          UUID: task.uuid,
          Label: label,
          Description: task.task.Description,
          EstimatedMinutes: task.estimated_minutes,
          StartDate: task.start_date.toISOString().substr(0, 10).replace(/-/g, ""),
          DueDate: task.end_date.toISOString().substr(0, 10).replace(/-/g, ""),
        }
      }

      _this.$WfmApi.put("job.api/task", data).then(response => _this.processTaskResponse(response))
    },
    processChartEvent(id) {
      const task = gantt.getTask(id)
      this.saveTask(task)
      this.processChartChange()
    },
    processChartChange() {
      const tasks = []
      this.tasks.data.forEach(function (task) {
        tasks.push({
          hours: task.remaining,
          startDate: task.start_date,
          dueDate: task.end_date,
          name: task.text
        })
      })
      this.$emit("updatedTasks", tasks)
    },
    processTaskResponse(response) {
      console.log(response)
    },
    dateDiff(date1, date2) {
      const t2 = date2.getTime()
      const t1 = date1.getTime()

      return parseInt((t2 - t1) / (24 * 3600 * 1000))
    },
  },
  mounted() {
    this.loadJob()
  },
  watch: {
    jobId: function () {
      this.loadJob()
    }
  },
  data() {
    return {
      title: "Job Title",
      showTitle: false,
      editable: false,
      isReady: true,
      valid: true,
      handler: false,
      job: {},
      tasks: {
        data: [],
        links: []
      },
    }
  }
}

</script>

<style>
@import "~dhtmlx-gantt/codebase/dhtmlxgantt.css";
@import "~dhtmlx-gantt/codebase/skins/dhtmlxgantt_material.css";

.cont {
  width: 100%;
}

.left-container {
  overflow: hidden;
  position: relative;
}

.project-marker {
  background-color: rgb(255, 0, 0, 0.7) !important;
}

.milestone-marker {
  background-color: rgb(132, 189, 0, 0.9) !important;
}
</style>