<template>
  <BfwComponent :isReady="isReady" :title="title" :showTitle="showTitle" :editable="editable" loaderType="list-item-avatar-three-line">
    <v-form ref="form" v-model="valid" lazy-validation v-if="isReady">
      <div>
        <h2 class="jobHeading">{{ jobId }} - {{ job.Name }} </h2>
        <div>
          <v-btn :href="job.WebURL" target="_blank" small color="primary" class="ml-2">
            <v-icon small>mdi-open-in-new</v-icon>
            Job
          </v-btn>
          <v-btn :href="job.WebURL.replace('/information', '/financials')" target="_blank" small color="primary" class="ml-2">
            <v-icon small>mdi-open-in-new</v-icon>
            Quote
          </v-btn>
        </div>
      </div>

      <div class="client">
        <strong>Client:</strong> {{ job.Client.Name }}
      </div>

      <div class="manager">
        <strong>Manager: </strong>
        <JobFieldManager :job="job"/>
      </div>

      <div class="state">
        <strong>State: </strong>
        <JobFieldState :job="job"/>
      </div>

      <div class="jobType">
        <strong>Job type: </strong>
        <JobFieldType :job="job"/>
      </div>


      <div class="billingType">
        <strong>Billing type: </strong>
        <CustomField type="job" :typeUUID="job.ID" fieldUUID="9c483d6b-411b-4633-8edc-43e0535a2350" :view="true" :edit="true"/>
      </div>

      <div class="excludeFromKpi">
        <strong>Exclude From KPI: </strong>
        <CustomField type="job" :typeUUID="job.ID" fieldUUID="9c483d6b-44ba-42a0-85ac-24c3a5d6bcab" :view="true" :edit="true"/>
      </div>

      <div class="clientOrderNumber">
        <strong>Purchase Order: </strong> {{ job.ClientOrderNumber }}
      </div>

      <div class="notes">
        <strong>Notes: </strong>
        <CustomFields type="job" :typeUUID="job.ID" restrict="9c483d6b-43d9-4201-9eed-01908dd712c2" ref="jobNotes"/>
        <v-btn dense color="primary" @click="$refs.jobNotes.save()" x-small class="mb-5">Save</v-btn>
      </div>

      <div v-if="!!job.Description" class="jobDescription">
        <strong>Job description: </strong>
        <div v-if="!descriptionExpanded" class="pre-formatted">
          {{ job.Description | truncateString }}
          <div class="hand" @click="descriptionExpanded = !descriptionExpanded"> More...</div>
        </div>
        <div v-else class="pre-formatted">
          {{ job.Description }}
          <div class="hand" @click="descriptionExpanded = !descriptionExpanded"> Less...</div>
        </div>

      </div>
    </v-form>
  </BfwComponent>
</template>

<script>
import BfwComponent from "../BwfComponent"
import JobFieldManager from "../job/fields/JobFieldManager"
import JobFieldType from "../job/fields/JobFieldType"
import JobFieldState from "../job/fields/JobFieldState"
import CustomField from "../toolkit/CustomField"
import CustomFields from "@/components/toolkit/CustomFields"

export default {
  name: "JobBasicInfo",
  permissions: ["Read Job", ...CustomFields.permissions, ...JobFieldManager.permissions, ...JobFieldState.permissions, ...CustomField.permissions, ...JobFieldType.permissions],
  props: ["jobId"],
  components: {CustomFields, BfwComponent, JobFieldManager, JobFieldState, CustomField, JobFieldType},
  methods: {
    processResponse(response) {
      this.job = response.Job
      this.isReady = true
    },
  },
  mounted() {
  },
  watch: {
    jobId: function () {
      if (this.jobId) {
        this.isReady = false
        this.$WfmApi.get("job.api/get/" + this.jobId).then(response => this.processResponse(response))
      } else {
        this.isReady = false
      }
    }
  },
  data() {
    return {
      title: "Job Basic Information",
      showTitle: false,
      editable: false,
      isReady: false,
      valid: true,
      job: {},
      descriptionExpanded: false,
    }
  },
}

</script>

<style scoped>
.pre-formatted {
  white-space: pre-wrap;
}

.hand {
  cursor: pointer;
}

.jobHeading {
  display: inline-block;
}

.jobLink {
  text-decoration: none;
}

.notes >>> label {
  display: none;
}
</style>