const FiltersUtilities = {
  install(Vue) {
    // Format minutes filter (turns minutes into h:mm format)
    Vue.filter("formatMinutes", function (value) {
      if (typeof value !== "number") return value
      const prefix = value >= 0 ? "" : "-"
      const hours = Math.floor(Math.abs(value) / 60)
      const minutes = Math.abs(value) % 60
      return prefix + hours + ":" + String(minutes).padStart(2, "0")
    })

    // Format a number into currency format
    Vue.filter("formatCurrency", function (value, dp = 0, pad = 0) {
      if (typeof value !== "number") return value

      const formatted = value.toFixed(dp).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")
      const padMultiplier = 1.5 // dots per character (on average)
      const newPad = Math.floor((pad - formatted.length) * padMultiplier) + pad
      return "$" + formatted.padStart(newPad, ".")

    })

    Vue.filter("formatDate", function (value) {
      if (value instanceof Date) {
        const options = {month: "short", year: "numeric", day: "numeric"}
        return value.toLocaleString("en-GB", options)
      } else {
        return value
      }
    })


    Vue.filter("truncateString", function (str, n = 50) {
      str = (typeof str == "string") ? str : "" // ensure str is a string
      if (str.length <= n) {
        return str
      }
      const subString = str.substr(0, n - 1) // the original check
      return subString.substr(0, subString.lastIndexOf(" "))
    })


    // Utility clone method
    Vue.prototype.$clone = function (obj) {
      if (Array.isArray(obj)) {
        return [...obj]
      } else {
        return Object.assign({}, obj)
      }
    }

    // Ensure things which should be arrays actually are
    Vue.prototype.$ensureArray = function (obj) {
      if (obj) {
        return (Array.isArray(obj)) ? obj : [obj]
      } else {
        return []
      }
    }


    Vue.prototype.$emailValidation = function (v) {
      return !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w+)+$/.test(v) || "E-mail must be valid"
    }


    Vue.prototype.$nameToLabel = function (name) {
      const NameToLabelRegex = /^.* - (.*)$/gm
      const parsed = NameToLabelRegex.exec(name)
      if (parsed.length === 2) {
        return parsed[1]
      } else {
        return ""
      }
    }

    Vue.prototype.$splitName = function (name) {
      const parts = name.split(" ")
      return {
        first: parts.length >= 1 ? parts[0] : "",
        last: parts.length >= 2 ? parts[1] : "",
      }
    }

    Vue.prototype.$hash = function (str) {
      let hash = 0, i, chr
      if (str.length === 0) return hash
      for (i = 0; i < str.length; i++) {
        chr = str.charCodeAt(i)
        hash = ((hash << 5) - hash) + chr
        hash |= 0 // Convert to 32bit integer
      }
      return hash
    }

    Vue.prototype.$formatCurrency = function (value, dp = 0, pad = 0) {
      if (!value) return "$0"
      if (typeof value !== "number") return value

      return "$" + value.toFixed(dp).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,").padStart(pad, ".")
    }

  }
}
export default FiltersUtilities