<template>
  <BfwComponent :isReady="isReady" :title="title" :showTitle="showTitle" :editable="editable" :hideCard="true"
                loaderType="list-item">
    <div v-for="person in staff" v-bind:key="person.wfm_userid">
      <h2 class="pa-3">{{ person.name }}</h2>
      <v-chart class="chart" :option="chartOptions(person.wfm_userid)"/>
    </div>
  </BfwComponent>
</template>

<script>
import BfwComponent from "../BwfComponent"
import {use} from "echarts/core"
import {CanvasRenderer} from "echarts/renderers"
import {LineChart} from "echarts/charts"
import {
  LegendComponent,
  GridComponent,
  MarkLineComponent,
  VisualMapComponent
} from "echarts/components"
import VChart from "vue-echarts"
import {defineComponent} from "vue"
import {getStaffList} from "@/lib/lookup"

use([
  CanvasRenderer,
  LineChart,
  LegendComponent,
  GridComponent,
  MarkLineComponent,
  VisualMapComponent
])
export default defineComponent({
  name: "WorkloadReport",
  permissions: ["Read Staff Feedback"],
  props: ["months"],
  components: {
    VChart,
    BfwComponent
  },
  computed: {},
  mounted() {
    this.loadStaff()
  },
  methods: {
    loadStaff() {
      getStaffList(this, (res) =>{
        this.staff = res
        for (const staff of res) {
          this.loadStaffFeedback(staff.wfm_userid)
          console.log(staff.wfm_userid)
        }
        this.isReady = true
      })
    },
    loadStaffFeedback(staffUuid) {
      this.$BwfApi.get("admin/staff-feedback", {staff: staffUuid, question: 1}).then(response => {
        this.$set(this.workload, staffUuid, response)
      })
    },
    chartOptions(staffUuid) {
      const months = [...this.months]
      months.sort() // ensure ascending order. String compare but that is OK
      const data = []
      if (staffUuid in this.workload) {
        this.workload[staffUuid].forEach((item) => {
          const d = new Date(item.feedback_date * 1000)
          data.push([d, item.response])
        })
      }

      return {
        grid: {
          left: 150,
          top: 50,
          right: 20,
          bottom: 20
        },
        visualMap: {
          show: false,
          dimension: 1,
          type: "piecewise",
          opacity: 0.3,
          pieces: [
            {
              max: 1,
              color: "red"
            },
            {
              min: 1,
              max: 2,
              color: "orange"
            },
            {
              min: 2,
              max: 3,
              color: "green"
            },
            {
              min: 3,
              max: 4,
              color: "blue"
            },
            {
              min: 4,
              max: 5,
              color: "green"
            },
            {
              min: 5,
              max: 6,
              color: "orange"
            },
            {
              min: 6,
              color: "red"
            }
          ]
        },
        xAxis: {
          type: "time",
          min: months[0], // uses string comparison but that should be OK
          max: months[1],
        },
        yAxis: {
          type: "category",
          splitLine: {
            show: true,
          },
          max: 7,
          min: 1,
          data: [
            "",
            "Extremely under worked",
            "Very under worked",
            "A little under worked",
            "Just right",
            "Stretched",
            "Overwhelmed",
            "Extremely Overwhelmed",
          ],
        },
        series: [
          {
            name: "workload",
            type: "line",
            smooth: true,
            data: data,
          },
        ]
      }
    },
  },
  data() {
    return {
      title: "Workload Report",
      showTitle: false,
      editable: false,
      isReady: false,
      valid: true,
      staff: [],
      workload: {},
    }
  },
})

</script>

<style scoped>
.chart {
  height: 300px;
}
</style>