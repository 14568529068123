<template>
  <Field @save="save">
    <template v-slot:view>
      {{ getText(type) }}
    </template>
    <template v-slot:edit>
      <v-select v-model="type" :items="jobTypes" item-value="UUID" item-text="Name"></v-select>
    </template>
  </Field>
</template>

<script>
import Field from "../../Field"
import {getCategoriesList} from "@/lib/lookup"

export default {
  name: "JobFieldType",
  permissions: ["Modify Job", "Read Setting"],
  props: ["job"],
  components: {
    Field
  },
  data() {
    return {
      type: "",
      jobTypes: []
    }
  },
  methods: {
    save: function () {
      const data = {
        Job: {
          ID: this.job.ID,
          Name: this.job.Name,
          CategoryUUID: this.type,
          StartDate:this.job.StartDate.substr(0, 10).replace(/-/g, ""),
          DueDate: this.job.DueDate.substr(0, 10).replace(/-/g, ""),
        }
      }

      this.$WfmApi.put("job.api/update", data).then((response) => this.processResponse(response))
    },
    processResponse() {
      this.$emit("updated")
    },
    getText(uuid) {
      const found = this.jobTypes.filter((item) => {
        return item.UUID === uuid
      })[0]
      return found ? found.Name : ""
    }

  },
  mounted() {
    getCategoriesList(this, (categories) => {
      this.jobTypes = categories

      // The Category name comes through the API but the UUID needs to be saved so map the job type field to the UUID on mount
      for (const item of this.jobTypes){
        if (item.Name === this.job.Type){
         this.type = item.UUID
        }
      }

    })
  },
}
</script>


