<template>
  <v-container fluid>
    <h1>WIP Setup</h1>
    <p>This is a summary of the WIP in WorkflowMax. This is not automatically updated, instead the WIP needs to be imported.</p>
    <WIPSetup></WIPSetup>
  </v-container>
</template>

<script>

import WIPSetup from "@/components/invoicing/WIPSetup.vue"

export default {
  name: "WIPSetupPage",
  permissions: [...WIPSetup.permissions],
  components: {WIPSetup},
  methods: {},
  computed: {},
  mounted() {

  },
  data() {
    return {}
  },
}

</script>

<style scoped>

</style>