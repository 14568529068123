<template>
  <div v-if="!editMode" class="field-wrapper">
      <div>
        <slot name="view"></slot>
        <v-btn v-if="editable" @click="editMode=true" x-small icon class="show">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </div>
  </div>
  <div v-else class="field-wrapper">
    <v-dialog v-model="editMode" max-width="400px" v-if="$slots.editModal">
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text>
          <slot name="editModal"></slot>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
           <v-btn @click="save">
             Save
             <v-icon>mdi-content-save</v-icon>
           </v-btn>
        </v-card-actions>
        </v-card>
    </v-dialog>

    <div v-if="$slots.edit">
     <div class="elements">
      <slot name="edit" :save="save"></slot>
     </div>
      <v-btn class="save" @click="save" x-small icon>
        <v-icon>mdi-content-save</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
  export default {
    name: "FieldWrapper",
    props:{
      editable:{default: true}
    },
    methods: {
      save: function(){
        this.editMode=false
        this.$emit('save')
      }
    },
    data() {
      return {
        editMode:false,
      }
    },
  }

</script>

<style scoped>

.elements {
  display: inline-block;
}

.save {
  display: inline-block;
}

.show {
  display:inline-block
}

.hide {
  display: none
}

.field-wrapper {
  display: inline-block;
}
</style>