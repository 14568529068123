<template>
  <BfwComponent :isReady="isReady" :title="title" :showTitle="showTitle" :editable="editable" :hideCard="true"
                loaderType="list-item">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-row>
        <v-col cols="2" md="1">
          <v-btn absolute dark fab small color="primary" @click="previous">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
        </v-col>

        <v-col>
          <v-autocomplete dense :items="staff" label="Staff Member" v-model="selectedValue" @change="changeStaff"></v-autocomplete>
        </v-col>

        <v-col cols="2" md="1">
          <v-btn absolute dark fab small color="primary" @click="next">
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </BfwComponent>
</template>

<script>
import BfwComponent from "../BwfComponent"
import {getStaffList} from "@/lib/lookup"

export default {
  name: "StaffNavigator",
  permissions: ["Read Staff"],
  components: {
    BfwComponent
  },
  computed: {
  },
  methods: {
    previous() {
      const idx = this.findIdx(this.selectedValue)
      const newIdx = (idx > 1) ?  parseInt(idx) - 1 : 0
      const newItem = this.staff[newIdx]
      this.selectedValue = newItem.value
      this.$emit("staffMemberChanged", this.selectedValue)
    },
    next() {
      const idx = this.findIdx(this.selectedValue)
      const newIdx = (idx < this.staff.length-1) ? parseInt(idx) +1 :  this.staff.length-1
      const newItem = this.staff[newIdx]
      this.selectedValue = newItem.value
      this.$emit("staffMemberChanged", this.selectedValue)
    },
    findIdx(uuid) {
      for (const idx in this.staff) {
        if (this.staff[idx].value === uuid) {
          return idx
        }
      }
    },
    changeStaff() {
      this.$emit("staffMemberChanged", this.selectedValue)
    },
    loadStaff() {
      getStaffList(this, (res)=> {
        this.staff = []
        for (const staff of res) {
          this.staff.push({"text": staff.name, "value": staff.wfm_userid})
        }
        this.selectedValue = this.staff[0].value
        this.$emit("staffMemberChanged", this.selectedValue)
        this.isReady = true
      })
    },
  },
  mounted() {
    this.loadStaff()
  },
  data() {
    return {
      title: "Staff selector",
      showTitle: false,
      editable: false,
      isReady: false,
      valid: true,
      disabled: false,
      settings: {},
      selectedValue: 0,
      staff: [],
    }
  },
}

</script>

<style scoped>

</style>