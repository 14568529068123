<template>
  <div v-if="loaded">
    <h3>Consulting Project Cost Variance (%)</h3>
    <p>Note: Positive value indicate a profitable variance.</p>
    <v-chart class="chart" :option="percentageChartOptions('Consulting')" autoresize/>

    <h3>Production Project Cost Variance (%)</h3>
    <p>Note: Positive value indicate a profitable variance.</p>
    <v-chart class="chart" :option="percentageChartOptions('Production')" autoresize/>

    <h3>Total Project Cost Variance ($)</h3>
    <p>Note: Positive value indicate a profitable variance.</p>
    <v-chart class="chart" :option="moneyChartOptions(['Consulting', 'Production'])" autoresize/>

  </div>
</template>

<script>
import {use} from "echarts/core"
import {CanvasRenderer} from "echarts/renderers"
import {LineChart} from "echarts/charts"
import {
  LegendComponent,
  GridComponent,
  MarkLineComponent,
  TooltipComponent,
} from "echarts/components"
import VChart from "vue-echarts"
import {colors} from "@/lib/constants"


use([
  CanvasRenderer,
  LineChart,
  LegendComponent,
  GridComponent,
  MarkLineComponent,
  TooltipComponent,
])

export default {
  name: "PCVGraph",
  components: {VChart},
  permissions: ["Read Timeseries"],
  methods: {
    loadAll() {
      this.$BwfApi.get("services", {enabled: true}).then(response => {
        this.services = response
        this.load().then(() => {
          this.loaded = true
        })
      })
    },
    load() {
      return this.$BwfApi.get("project-cost-variance/all").then(response => {
        const result = {}
        for (const row of response) {
          const jobType = row.job_type ? row.job_type : "Unknown"
          if (!result[jobType]) {
            result[jobType] = []
          }
          if (result[jobType][row.service_name] === undefined) {
            result[jobType][row.service_name] = []
          }
          result[jobType][row.service_name].push(row)
        }
        this.data = result
      })
    },
    percentageChartOptions(jobType) {
      const data = this.data[jobType]
      const chartSeries = []
      let minValue = 0
      let maxValue = 0

      const legend = [...this.services]
      legend.push({name: "Total", sequence: -1})
      for (const service of legend) {
        const seriesData = []
        if (data[service.name]) {
          for (const row of data[service.name]) {
            const d = new Date(row.calculated_at)
            const value = row.pcv
            seriesData.push([d, value])
            maxValue = Math.max(maxValue, value)
            minValue = Math.min(minValue, value)
          }
        }

        if (seriesData.length > 0) {
          const color = (service.sequence === -1) ? "#000000" : colors[service.sequence]
          const width = (service.sequence === -1) ? 4 : 2

          chartSeries.push({
            name: service.name,
            type: "line",
            smooth: true,
            data: seriesData,
            itemStyle: {color: color, width: width},
            lineStyle: {color: color, borderWidth: width},
          })
        }
      }

      // background colors
      if (chartSeries.length > 1) {
        chartSeries[0].markArea = {
          itemStyle: {
            color: "rgba(173, 255, 177, 0.4)"
          },
          data: [
            [
              {yAxis: maxValue * 1.2},
              {yAxis: 0}
            ],
          ]
        }
        chartSeries[1].markArea = {
          itemStyle: {
            color: "rgba(255, 173, 177, 0.4)"
          },
          data: [
            [
              {yAxis: 0},
              {yAxis: minValue * 1.2}
            ],
          ]
        }
      }

      const min = new Date()
      min.setMonth(min.getMonth() - 18)
      const max = new Date()
      return {
        xAxis: {
          type: "time",
          min: min,
          max: max,
        },
        yAxis: {
          type: "value",
          name: "%",
        },
        legend: {
          top: "30"
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow"
          }
        },
        series: chartSeries
      }
    },
    moneyChartOptions(seriesNames) {
      const chartSeries = []
      let minValue = 0
      let maxValue = 0

      for (const seriesName of seriesNames) {
        const seriesData = []
        for (const serviceName in this.data[seriesName]) {
          const series = this.data[seriesName][serviceName]
          for (const row of series) {
            if (row.job_type === seriesName && row.service_name === "Total") {
              const d = new Date(row.calculated_at)
              const value = row.invoiced_money - row.actual_money
              seriesData.push([d, value])
              maxValue = Math.max(maxValue, value)
              minValue = Math.min(minValue, value)
            }
          }
        }

        if (seriesData.length > 0) {
          chartSeries.push({
            name: seriesName,
            type: "line",
            smooth: true,
            data: seriesData,
          })
        }
      }

      // background colors
      if (chartSeries.length > 1) {
        chartSeries[0].markArea = {
          itemStyle: {
            color: "rgba(173, 255, 177, 0.4)"
          },
          data: [
            [
              {yAxis: maxValue},
              {yAxis: 0}
            ],
          ]
        }
        chartSeries[1].markArea = {
          itemStyle: {
            color: "rgba(255, 173, 177, 0.4)"
          },
          data: [
            [
              {yAxis: 0},
              {yAxis: minValue}
            ],
          ]
        }
      }


      const min = new Date()
      min.setMonth(min.getMonth() - 18)
      const max = new Date()
      return {
        xAxis: {
          type: "time",
          min: min,
          max: max,
        },
        yAxis: {
          type: "value",
          name: "$",
        },
        legend: {
          top: "30"
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow"
          }
        },
        series: chartSeries
      }
    },
  },
  mounted() {
    this.loadAll()
  },
  data() {
    return {
      loaded: false,
      data: {}
    }
  }
}
</script>

<style scoped>
.chart {
  height: 600px;
}
</style>