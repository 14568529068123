<template>
  <BfwComponent :isReady="isReady" :title="title" :showTitle="showTitle" :editable="editable">
    <p class="hint">{{ subtitle }}</p>
    <v-container fluid>
      <v-layout row wrap>
        <v-card :min-width="400" class="ma-2" v-for="(contact, idx) in contactsArray" v-bind:key="contact.UUID">
          <v-card-text>
            <ContactEditor :contact="contact" :clientName="clientName" @change="updateContact(idx, $event)"/>
          </v-card-text>
        </v-card>
      </v-layout>
    </v-container>

    <v-btn icon @click="addContact" color="primary" top right absolute>
      <v-icon>mdi-plus</v-icon>
    </v-btn>
  </BfwComponent>
</template>

<script>
import BfwComponent from "../BwfComponent"
import ContactEditor from "./ContactEditor"

export default {
  name: "CreatesContact",
  permissions: [...ContactEditor.permissions],
  props: {
    "contacts": {type: Array},
    "clientName": {type: String},
  },
  components: {
    BfwComponent, ContactEditor
  },
  watch: {
    contacts: function () {
      this.contactsArray = (this.contacts) ? this.$clone(this.contacts) : []
    }
  },
  methods: {
    update() {
      this.$emit("change", this.contactsArray)
    },
    addContact() {
      this.contactsArray.push({})
    },
    updateContact(idx, contact) {
      this.$set(this.contactsArray, idx, contact)
      this.update()
    }
  },
  mounted() {
    this.contactsArray = (this.contacts) ? this.$clone(this.contacts) : []
    if (this.contactsArray.length === 0) {
      this.addContact()
    }
  },
  computed: {
    subtitle: function () {
      return this.contactsArray.length === 1 ? "The person" : "The people"
    },
    title: function () {
      return this.contactsArray.length === 1 ? "Contact" : "Contacts"
    },
  },
  data() {
    return {
      showTitle: true,
      editable: false,
      isReady: true,
      valid: true,
      settings: {},
      contactsArray: []
    }
  },
}

</script>

<style scoped>
.hint {
  font-size: smaller;
  font-style: italic;
}
</style>