<template>
  <div v-if="loaded">
    <h3>Consulting Delivery On Time KPI</h3>
    <p>On average, projects are delivered
      <span class="highlight">{{ Math.abs(latest("KPI Delivery On Time Consulting", "days")) }} days {{ isAhead(latest("KPI Delivery On Time Consulting", "days")) }}</span>
      the originally agreed schedule.</p>
    <v-chart class="chart" :option="chartOptions('KPI Delivery On Time Consulting', 'days', 'Days')" autoresize/>
    <v-chart class="chart" :option="chartOptions('KPI Delivery On Time Consulting', 'percent', '% of projects')" autoresize/>

    <h3>Production Delivery On Time KPI</h3>
    <p>On average, projects are delivered
      <span class="highlight">{{ Math.abs(latest("KPI Delivery On Time Production", "days")) }} days {{ isAhead(latest("KPI Delivery On Time Production", "days")) }}</span>
      the originally agreed schedule.</p>
    <v-chart class="chart" :option="chartOptions('KPI Delivery On Time Production', 'days', 'Days')" autoresize/>
    <v-chart class="chart" :option="chartOptions('KPI Delivery On Time Production', 'percent', '% of projects')" autoresize/>

  </div>
</template>

<script>
import {use} from "echarts/core"
import {CanvasRenderer} from "echarts/renderers"
import {LineChart} from "echarts/charts"
import {
  LegendComponent,
  GridComponent,
  MarkLineComponent,
  TooltipComponent,
} from "echarts/components"
import VChart from "vue-echarts"


use([
  CanvasRenderer,
  LineChart,
  LegendComponent,
  GridComponent,
  MarkLineComponent,
  TooltipComponent,
])

export default {
  name: "SpeedFocusKPIGraph",
  components: {VChart},
  permissions: ["Read Timeseries"],
  methods: {
    isAhead(days) {
      if (days < 0) {
        return "behind"
      }
      return "ahead of"
    },
    latest(seriesName, itemName) {
      const key = seriesName + "|" + itemName
      const data = this.data[key]
      if (data.length > 0) {
        const row = data[data.length - 1]
        return Math.round(10 * row.value) / 10
      }
      return 0
    },
    loadAll() {
      Promise.all([
        this.loadTimeseries("KPI Delivery On Time Consulting", "days"),
        this.loadTimeseries("KPI Delivery On Time Consulting", "percent"),
        this.loadTimeseries("KPI Delivery On Time Production", "days"),
        this.loadTimeseries("KPI Delivery On Time Production", "percent"),
      ]).then(() => {
        this.loaded = true
      })
    },
    loadTimeseries(seriesName, itemName) {
      const query = {
        series_name: seriesName,
        item_name: itemName,
      }
      const key = seriesName + "|" + itemName
      return this.$BwfApi.get("timeseries", query).then(response => {
        this.data[key] = response
      })
    },
    chartOptions(seriesName, itemName, yLabel) {
      const key = seriesName + "|" + itemName
      const data = this.data[key]

      const kpi = []
      for (const row of data) {
        const d = new Date(row.record_time)
        kpi.push([d, Math.round(10 * row.value) / 10])
      }

      const min = new Date()
      min.setMonth(min.getMonth() - 18)
      const max = new Date()
      return {
        xAxis: {
          type: "time",
          min: min,
          max: max,
        },
        yAxis: {
          type: "value",
          name: yLabel,
        },
        legend: {
          top: "30"
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow"
          }
        },
        series: [
          {
            name: yLabel,
            type: "line",
            smooth: true,
            data: kpi,
          },
        ]
      }
    },
  },
  mounted() {
    this.loadAll()
  },
  data() {
    return {
      loaded: false,
      data: {}
    }
  }
}
</script>

<style scoped>
.chart {
  height: 600px;
}

.highlight {
  font-weight: bold;
  font-size: 1.2em;
  color: #77aa00;
}
</style>