<template>
  <BfwComponent :isReady="isReady" :title="title" :showTitle="showTitle" :editable="editable">
    <v-switch v-model="showDetail" label="Show Detail" class="mb-2"></v-switch>

    <!--Detail table -->
    <v-data-table v-if="showDetail" class="detail-table" item-class="task-table" dense hide-default-footer :headers="detailHeaders" :items="tasksSummary" :items-per-page="200">
      <template v-slot:item.name="{item}">
        <span :class="item._displayClass">{{ item.name }}</span>
      </template>
      <template v-slot:item.budgetTime="{item}">
        <div :class="{'total':item.name.endsWith('Total')}">{{ item.budgetTime | formatMinutes }}</div>
      </template>
      <template v-slot:item.actualTime="{item}">
        <div :class="itemClass(null, item.name)">{{ item.actualTime | formatMinutes }}</div>
        <div :class="itemClass(item.actualOver, item.name)">{{ item.actualTimeDifference | formatMinutes }}</div>
      </template>
      <template v-slot:item.forecastTime="{item}">
        <div v-if="item.name.endsWith('Total')"> <!-- Can only show forecast for total -->
          <div :class="itemClass(null, item.name)">{{ item.forecastTime | formatMinutes }}</div>
          <div :class="itemClass(item.forecastOver, item.name)">{{ item.forecastTimeDifference | formatMinutes }}</div>
        </div>
      </template>

      <template v-slot:item.budgetMoney="{item}">
        <div :class="{'total':item.name.endsWith('Total')}">{{ item.budgetMoney | formatCurrency }}</div>
      </template>
      <template v-slot:item.actualMoney="{item}">
        <div :class="itemClass(null, item.name)">{{ item.actualMoney | formatCurrency }}</div>
        <div :class="itemClass(item.actualOver, item.name)">{{ item.actualMoneyDifference | formatCurrency }}</div>
      </template>
      <template v-slot:item.forecastMoney="{item}">
        <div v-if="item.name.endsWith('Total')"> <!-- Can only show forecast for total -->
          <div :class="itemClass(null, item.name)">{{ item.forecastMoney | formatCurrency }}</div>
          <div :class="itemClass(item.forecastOver, item.name)">{{ item.forecastMoneyDifference | formatCurrency }}</div>
        </div>
      </template>
    </v-data-table>

    <!--Service table -->
    <v-data-table v-else class="service-table" dense hide-default-footer :headers="summaryHeaders" :items="serviceSummary" :items-per-page="200">
      <template v-slot:item.name="{item}">
        <span :class="item._displayClass">{{ item.name }}</span>
      </template>
      <template v-slot:item.budgetTime="{item}">
        <div>{{ item.budgetTime | formatMinutes }}</div>
      </template>
      <template v-slot:item.actualTime="{item}">
        <div>{{ item.actualTime | formatMinutes }}</div>
        <div :class="itemClass(item.actualOver)">{{ item.actualTimeDifference | formatMinutes }}</div>
      </template>
      <template v-slot:item.forecastTime="{item}">
        <div>{{ item.forecastTime | formatMinutes }}</div>
        <div :class="itemClass(item.forecastOver)">{{ item.forecastTimeDifference | formatMinutes }}</div>
      </template>

      <template v-slot:item.budgetMoney="{item}">
        <div>{{ item.budgetMoney | formatCurrency }}</div>
      </template>
      <template v-slot:item.actualMoney="{item}">
        <div>{{ item.actualMoney | formatCurrency }}</div>
        <div :class="itemClass(item.actualOver)">{{ item.actualMoneyDifference | formatCurrency }}</div>
      </template>
      <template v-slot:item.forecastMoney="{item}">
        <div>{{ item.forecastMoney | formatCurrency }}</div>
        <div :class="itemClass(item.forecastOver)">{{ item.forecastMoneyDifference | formatCurrency }}</div>
      </template>

    </v-data-table>
    <p class="mt-3"><span class="green--text">Green</span> figures indicates under budget (good) &
      <span class="red--text">Red</span> figures indicates over budget (bad)</p>

    <ul>
      <li v-for="(error, idx) in errors" class="text-error" v-bind:key="idx">
        {{ error }}
      </li>
    </ul>
    <!-- used by the compiler -->
    <div class="heading text-red text-green display-none"></div>
  </BfwComponent>
</template>

<script>
import BfwComponent from "../BwfComponent"
import {formatDate} from "@/lib/dateTimeUtilities"

export default {
  name: "JobProjectCostVariance",
  permissions: ["Read Job"],
  props: ["jobId"],
  components: {
    BfwComponent
  },
  methods: {
    itemClass(overUnder, name = "") {
      let classStr = ""
      if (overUnder !== null) {
        if (overUnder) classStr += "text-red "
        else classStr += "text-green "
      }

      if (name.endsWith("Total")) classStr += "total "
      if (name.endsWith("Grand Total")) classStr += "grand-total "
      return classStr
    },
    loadAll() {
      Promise.all([
        this.loadDefaultRate(),
        this.loadJob(),
        this.loadPlanned(),
        this.loadServices(),
        this.loadJobTimeUsedPreviousMonths()
      ]).then(() => {
        this.loadQuote().then(() => {
          this.calculate()
        })
      })
    },
    loadJob() {
      this.job = null
      return this.$WfmApi.get("job.api/get/" + this.jobId, {detailed: true}).then(response => {
        this.job = response.Job
      })
    },
    loadQuote() {
      this.quote = null
      return this.$WfmApi.get("quote.api/get/" + this.job.ApprovedQuoteUUID).then(response => {
        this.quote = response.Quote
      })
    },
    loadPlanned() {
      this.planned = null
      return this.$BwfApi.get("capacity-plan/job", {job_id: this.jobId}).then(response => {
        this.planned = response.labour
      })
    },
    loadJobTimeUsedPreviousMonths() {
      // Get Actual time for each task for the previous months
      const now = new Date()
      const to = formatDate(now)
      const startOfMonth = new Date()
      startOfMonth.setDate(1)
      startOfMonth.getTime()

      const taskTimes = {}

      now.setYear(now.getFullYear() - this.$WfmApi.lookback) // only look back 1 year for time entries
      const from = formatDate(now)
      return this.$WfmApi.get("time.api/job/" + this.jobId, {from: from, to: to}).then(response => {
        if (response.Times) {
          const timeEntries = this.$ensureArray(response.Times.Time)
          for (const item of timeEntries) {
            const time = parseInt(item.Minutes)
            const entryDate = new Date(item.Date)
            if (entryDate < startOfMonth) {
              taskTimes[item.Task.UUID] = taskTimes[item.Task.UUID] || 0
              taskTimes[item.Task.UUID] += time
            }
          }
        }
        this.taskTimes = taskTimes
      })
    },
    loadServices() {
      return this.$BwfApi.get("services", {enabled: true}).then(response => {
        this.services = response
      })
    },
    loadDefaultRate() {
      return this.$BwfApi.get("setting/default_billable_rate").then(response => {
        this.default_rate = response["default_billable_rate"]
      })
    },
    calculate() {
      const isJobComplete = this.job.Status === "Complete"
      const serviceSummary = []
      let tasksSummary = []

      const [budgetServiceTime, budgetServiceMoney, budgetTaskTime, budgetTaskMoney, taskRates, serviceRates, errors1] = this.summarizeBudget()
      const [actualServiceTime, actualServiceMoney, actualTaskTime, actualTaskMoney, errors2] = this.summarizeActual(taskRates)
      const [historicalServiceTime, historicalServiceMoney, errors3] = this.summarizeHistoricalActual(taskRates)
      const [plannedTime, plannedMoney, errors4] = this.summarizePlanned(serviceRates)

      const serviceTotals = {
        budgetTime: 0,
        budgetMoney: 0,
        actualTime: 0,
        actualMoney: 0,
        forecastTime: 0,
        forecastMoney: 0,
      }
      const errors5 = []
      for (const service of this.services) {
        const [serviceTaskSummary, errors] = this.createTasksSummary(service, actualTaskTime, actualTaskMoney, budgetTaskTime, budgetTaskMoney)
        if (errors.length> 0) errors5.push(...errors)

        if (serviceTaskSummary.length === 0) continue

        tasksSummary.push({
          _displayClass: "heading",
          name: service.name,
        })

        tasksSummary = tasksSummary.concat(serviceTaskSummary)
        const budgetTime = budgetServiceTime[service.service_id] || 0
        const actualTime = actualServiceTime[service.service_id] || 0
        let forecastTime = 60 * Math.round(historicalServiceTime[service.service_id] / 60) + plannedTime[service.service_id] || 0

        const budgetMoney = budgetServiceMoney[service.service_id] || 0
        const actualMoney = actualServiceMoney[service.service_id] || 0
        let forecastMoney = historicalServiceMoney[service.service_id] + plannedMoney[service.service_id] || 0

        if (isJobComplete) {
          // if the job is complete, then the forecast is the actual
          forecastTime = actualTime
          forecastMoney = actualMoney
        }

        if (budgetTime === 0 && actualTime === 0 && forecastTime === 0) continue
        serviceTotals.budgetTime += budgetTime
        serviceTotals.actualTime += actualTime
        serviceTotals.forecastTime += forecastTime
        serviceTotals.budgetMoney += budgetMoney
        serviceTotals.actualMoney += actualMoney
        serviceTotals.forecastMoney += forecastMoney

        const summaryLine = {
          _displayClass: "total",
          name: service.name + " Total",
          budgetTime: budgetTime,
          actualTime: actualTime,
          forecastTime: forecastTime,
          budgetMoney: budgetMoney,
          actualMoney: actualMoney,
          forecastMoney: forecastMoney,
          actualOver: actualTime > budgetTime,
          forecastOver: forecastTime > budgetTime,
          actualTimeDifference: Math.abs(budgetTime - actualTime),
          actualMoneyDifference: Math.abs(budgetMoney - actualMoney),
          forecastTimeDifference: Math.abs(budgetTime - forecastTime),
          forecastMoneyDifference: Math.abs(budgetMoney - forecastMoney),
        }
        serviceSummary.push(summaryLine)
        tasksSummary.push(summaryLine)
      }

      const grandTotal = {
        _displayClass: "grand-total",
        name: "Grand Total",
        budgetTime: serviceTotals.budgetTime,
        actualTime: serviceTotals.actualTime,
        forecastTime: serviceTotals.forecastTime,
        budgetMoney: serviceTotals.budgetMoney,
        actualMoney: serviceTotals.actualMoney,
        forecastMoney: serviceTotals.forecastMoney,
        actualOver: serviceTotals.actualTime > serviceTotals.budgetTime,
        forecastOver: serviceTotals.forecastTime > serviceTotals.budgetTime,
        actualTimeDifference: Math.abs(serviceTotals.budgetTime - serviceTotals.actualTime),
        actualMoneyDifference: Math.abs(serviceTotals.budgetMoney - serviceTotals.actualMoney),
        forecastTimeDifference: Math.abs(serviceTotals.budgetTime - serviceTotals.forecastTime),
        forecastMoneyDifference: Math.abs(serviceTotals.budgetMoney - serviceTotals.forecastMoney),
      }
      serviceSummary.push(grandTotal)
      tasksSummary.push(grandTotal)
      this.serviceSummary = serviceSummary
      this.tasksSummary = tasksSummary

      this.errors = errors1.concat(errors2, errors3, errors4, errors5)
      this.isReady = true
    },
    createTasksSummary(service, tasksActualTime, tasksActualMoney, tasksEstimateTime, tasksEstimateMoney) {
      const tasks = this.$ensureArray(this.job.Tasks.Task)
      const errors = []
      const taskSummary = []
      for (const task of tasks) {
        const serviceId = this.getServiceIdByTaskUUID(task.TaskUUID)
        if (serviceId) {
          if (serviceId === service.service_id) {
            const budgetTime = tasksEstimateTime[task.UUID] || 0
            const actualTime = tasksActualTime[task.UUID] || 0
            const forecastTime = 0
            const budgetMoney = tasksEstimateMoney[task.UUID] || 0
            const actualMoney = tasksActualMoney[task.UUID] || 0
            const forecastMoney = 0
            taskSummary.push({
              _displayClass: "task",
              name: task.Name,
              budgetTime: budgetTime,
              actualTime: actualTime,
              forecastTime: forecastTime,
              budgetMoney: budgetMoney,
              actualMoney: actualMoney,
              forecastMoney: forecastMoney,
              actualOver: actualTime > budgetTime,
              forecastOver: forecastTime > budgetTime,
              actualTimeDifference: Math.abs(budgetTime - actualTime),
              actualMoneyDifference: Math.abs(budgetMoney - actualMoney),
              forecastTimeDifference: Math.abs(budgetTime - forecastTime),
              forecastMoneyDifference: Math.abs(budgetMoney - forecastMoney),
            })
          }
        } else {
          errors.push(`No service found for task "${task.Name}"`)
        }
      }
      return [taskSummary, errors]
    },
    summarizeActual(billableRates) {
      if (!this.job.Tasks) return [0, 0, 0, 0, []]
      const errors = []
      const taskTime = {}
      const serviceTime = {}
      const taskMoney = {}
      const serviceMoney = {}

      const tasks = this.$ensureArray(this.job.Tasks.Task)
      for (const task of tasks) {
        const serviceId = this.getServiceIdByTaskUUID(task.TaskUUID)
        if (serviceId) {
          serviceTime[serviceId] = serviceTime[serviceId] || 0
          serviceMoney[serviceId] = serviceMoney[serviceId] || 0
          taskTime[task.UUID] = taskTime[task.UUID] || 0
          taskMoney[task.UUID] = taskMoney[task.UUID] || 0

          const minutes = parseInt(task.ActualMinutes)
          if (!billableRates[task.UUID]) {
            errors.push(`No billable rate found for job task "${task.Name}" using default rate`)
          }
          const rate = billableRates[task.UUID] || this.default_rate
          serviceTime[serviceId] += minutes
          serviceMoney[serviceId] += minutes / 60 * rate
          taskTime[task.UUID] = minutes
          taskMoney[task.UUID] = minutes / 60 * rate
        } else {
          errors.push(`No service found for job task "${task.Name}"`)
        }
      }

      return [serviceTime, serviceMoney, taskTime, taskMoney, errors]
    },
    summarizeHistoricalActual(billableRates) {
      // sumarise the actual, but based on Time entries, and only for time entries older than the current month
      if (!this.job.Tasks) return [0, 0, []]
      const errors = []
      const serviceTime = {}
      const serviceMoney = {}

      const tasks = this.$ensureArray(this.job.Tasks.Task)
      for (const task of tasks) {
        const serviceId = this.getServiceIdByTaskUUID(task.TaskUUID)
        if (serviceId) {
          serviceTime[serviceId] = serviceTime[serviceId] || 0
          serviceMoney[serviceId] = serviceMoney[serviceId] || 0

          const minutes = this.taskTimes[task.UUID] || 0
          serviceTime[serviceId] += minutes
          serviceMoney[serviceId] += minutes / 60 * billableRates[task.UUID]
        } else {
          errors.push(`No service found for job task "${task.Name}"`)
        }
      }

      return [serviceTime, serviceMoney, errors]
    },
    summarizePlanned(serviceRates) {
      const errors = []
      const now = new Date()
      const currentMonthStr = now.getFullYear() + "-" + (now.getMonth() + 1).toString().padStart(2, "0")

      const plannedTime = {}
      const plannedMoney = {}
      for (const service of this.services) {
        plannedTime[service.service_id] = 0
        plannedMoney[service.service_id] = 0
      }

      for (const monthString in this.planned) {
        let percentageOfMonthRemaining = 1
        if (monthString >= currentMonthStr) {
          percentageOfMonthRemaining = 1
        } else {
          percentageOfMonthRemaining = 0
        }

        for (const serviceId in this.planned[monthString]) {
          const planned = this.planned[monthString][serviceId] * percentageOfMonthRemaining * 60 // prorate and convert to minutes
          plannedTime[serviceId] += planned
          plannedMoney[serviceId] += planned / 60 * serviceRates[serviceId]
        }
      }
      return [plannedTime, plannedMoney, errors]
    },
    summarizeBudget() {
      // at this point, the quote does not have the task type, but may do in the future. Leaving here for my future self
      if (!this.quote || !this.quote.Tasks) return [0, 0, 0, 0, 0, []]
      const errors = []
      const serviceTime = {}
      const taskTime = {}
      const serviceMoney = {}
      const taskMoney = {}
      const taskRates = {}
      const serviceRates = {}

      const quoteTasks = this.$ensureArray(this.quote.Tasks.Task)
      for (const quoteTask of quoteTasks) {
        const jobTask = this.getTaskByName(quoteTask.Name)
        const serviceId = this.getServiceIdByTaskUUID(jobTask.TaskUUID)
        if (serviceId) {
          const minutes = parseInt(quoteTask.EstimatedMinutes)
          const billableRate = parseFloat(quoteTask.BillableRate)
          serviceTime[serviceId] = serviceTime[serviceId] || 0
          taskTime[jobTask.UUID] = taskTime[jobTask.UUID] || 0
          serviceMoney[serviceId] = serviceMoney[serviceId] || 0
          taskMoney[jobTask.UUID] = taskMoney[jobTask.UUID] || 0

          serviceTime[serviceId] += minutes
          serviceMoney[serviceId] += minutes / 60 * billableRate
          taskTime[jobTask.UUID] = minutes
          taskMoney[jobTask.UUID] = minutes / 60 * billableRate

          taskRates[jobTask.UUID] = billableRate
          serviceRates[serviceId] = billableRate
        } else {
          errors.push(`No service found for quote task "${quoteTask.Name}`)
        }
      }
      return [serviceTime, serviceMoney, taskTime, taskMoney, taskRates, serviceRates, errors]
    },
    getServiceIdByTaskUUID(taskUUID) {
      for (const service of this.services) {
        if (service.uuid === taskUUID) return service.service_id
      }
      return null
    },
    getTaskByName(taskName) {
      const tasks = this.$ensureArray(this.job.Tasks.Task)
      for (const task of tasks) {
        if (task.Name === taskName) {
          return task
        }
      }
    }
  },
  mounted() {
  },
  computed: {
    tableData: function () {
      return []
    }
  },
  watch: {
    jobId: function () {
      if (this.jobId) {
        this.isReady = false
        this.loadAll()
      } else {
        this.isReady = false
      }
    }
  },
  data() {
    return {
      title: "Project Cost Variance",
      showTitle: true,
      editable: false,
      isReady: false,
      showDetail: false,
      summaryHeaders: [
        {text: "Service", value: "name"},
        {text: "Budget (hrs)", value: "budgetTime", align: "right"},
        {text: "Actual (hrs)", value: "actualTime", align: "right"},
        {text: "Forecast (hrs)", value: "forecastTime", align: "right"},
        {text: "Budget ($)", value: "budgetMoney", align: "right"},
        {text: "Actual ($)", value: "actualMoney", align: "right"},
        {text: "Forecast ($)", value: "forecastMoney", align: "right"},
      ],
      detailHeaders: [
        {text: "Task", value: "name"},
        {text: "Budget (hrs)", value: "budgetTime", align: "right"},
        {text: "Actual (hrs)", value: "actualTime", align: "right"},
        {text: "Forecast (hrs)", value: "forecastTime", align: "right"},
        {text: "Budget ($)", value: "budgetMoney", align: "right"},
        {text: "Actual ($)", value: "actualMoney", align: "right"},
        {text: "Forecast ($)", value: "forecastMoney", align: "right"},
      ],
      job: null,
      quote: null,
      services: null,
      planned: null,
      taskTimes: null,
      serviceSummary: [],
      tasksSummary: [],
      default_rate: 0,
      errors: []
    }
  }
}

</script>

<style scoped>
.total {
  font-weight: bold;
}

.text-red {
  color: red;
  font-weight: normal;
  font-style: italic;
}

.text-red:before {
  content: '(';
}

.text-red:after {
  content: ')';
}


.text-green {
  color: green;
  font-weight: normal;
  font-style: italic;
}

.heading, .grand-total {
  font-weight: bold;
  font-size: 1.2rem;
}

.service-table >>> td {
  vertical-align: top;
  font-weight: bold;
}

.detail-table >>> td {
  vertical-align: top;
}

.service-table >>> tbody tr:last-child td,
.detail-table >>> tbody tr:last-child td {
  border-top: 1px solid #000;
  font-size: large;
}

.display-none {
  display: none;
}

.text-error {
  color: red;
}

</style>