import axios from "axios"

const BwfApi = {
  install(Vue) {
    // API function
    Vue.prototype.$BwfApi = {
      lookback: 5,// number of years to look back when doing range queries for 'all'
      get(path, queryParameters = {}, config = {}) {
        return new Promise((dispatch) => {
          //console.log("-> GET "+ path, queryParameters)
          const baseConfig = {params: queryParameters, withCredentials: true}
          axios.get("betaworkflow/" + path, {...baseConfig, ...config})
            .then(function (response) {
              dispatch(response.data)
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                  window.location.href = error.response.data
                } else {
                  Vue.prototype.$notify.show({type: "error", text: error.response.data})
                }
              }
            )
        })
      },

      put(path, data = {}, config = {}) {
        return new Promise((dispatch) => {
          //console.log("-> PUT "+ path)
          //console.log(data)
          const baseConfig = {withCredentials: true}
          axios.put("betaworkflow/" + path, data, {...baseConfig, ...config})
            .then(function (response) {
              dispatch(response.data)
            })
            .catch(function (error) {
              if (error.response.status === 401) {
                window.location.href = error.response.data
              } else {
                Vue.prototype.$notify.show({type: "error", text: error.response.data})
              }
            })
        })
      },

      post(path, data = {}, config = {}) {
        return new Promise((dispatch) => {
          //console.log("-> POST "+ path)
          //console.log(data)
          const baseConfig = {withCredentials: true}
          axios.post("betaworkflow/" + path, data, {...baseConfig, ...config})
            .then(function (response) {
              dispatch(response.data)
            })
            .catch(function (error) {
              if (error.response.status === 401) {
                window.location.href = error.response.data
              } else {
                Vue.prototype.$notify.show({type: "error", text: error.response.data})
              }
            })
        })
      },

      delete(path, config = {}) {
        return new Promise((dispatch) => {
          //console.log("-> DELETE "+ path)
          const baseConfig = {withCredentials: true}
          axios.delete("betaworkflow/" + path, {...baseConfig, ...config})
            .then(function (response) {
              dispatch(response.data)
            })
            .catch(function (error) {
              if (error.response.status === 401) {
                window.location.href = error.response.data
              } else {
                Vue.prototype.$notify.show({type: "error", text: error.response.data})
              }
            })
        })
      },
    }
  }
}

export default BwfApi