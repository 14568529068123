<script>
import {defineComponent} from "vue"

export default defineComponent({
  name: "LeadsKpiSnapshot",
  permissions: ["Read Leads", "Read Quotes"],
  props: ["snapshot"],
  methods: {},
  data: () => ({}),
})
</script>

<template>

  <v-simple-table>
    <template v-slot:default>
      <tbody>
      <tr>
        <th class="text-center"></th>
        <th class="text-right">New Leads</th>
        <th class="text-right">Quoted</th>
        <th class="text-right">Won (new clients)</th>
        <th class="text-right">Won (existing clients)</th>
      </tr>
      <tr>
        <th class="text-right">Number</th>
        <td class="text-right">{{ snapshot.newLeads.number }}</td>
        <td class="text-right">{{ snapshot.quoted.number }}</td>
        <td class="text-right">{{ snapshot.wonNew.number }}</td>
        <td class="text-right">{{ snapshot.wonExisting.number }}</td>
      </tr>
      <tr>
        <th class="text-right">Hours</th>
        <td class="text-right"></td>
        <td class="text-right">{{ snapshot.quoted.hours }}</td>
        <td class="text-right">{{ snapshot.wonNew.hours }}</td>
        <td class="text-right">{{ snapshot.wonExisting.hours }}</td>
      </tr>
      <tr>
        <th class="text-right">Value</th>
        <td class="text-right"></td>
        <td class="text-right">{{ snapshot.quoted.value | formatCurrency}}</td>
        <td class="text-right">{{ snapshot.wonNew.value | formatCurrency }}</td>
        <td class="text-right">{{ snapshot.wonExisting.value | formatCurrency}}</td>
      </tr>
      <tr>
        <th class="text-right">Ratio</th>
        <td class="text-right"></td>
        <td class="text-right"></td>
        <td class="text-right">{{ snapshot.wonNew.ratio }}%</td>
        <td class="text-right">{{ snapshot.wonExisting.ratio }}%</td>
      </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<style scoped>

</style>