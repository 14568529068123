export default class Labour {
    tasks = []
    quotedDollars = 0
    quotedMinutes = 0
    previouslyInvoicedMinutes = 0
    previouslyInvoicedDollars = 0
    previouslyInvoicedPercent = 0
    currentMinutes = 0
    currentToDateMinutes = 0
    toInvoiceDollars = 0
    afterInvoicingDollars = 0
    currentToDatePercent = 0
    toInvoicePercent = 0
    fallbackHourlyRate = 0


    constructor(vue, invoicePeriod, startDate, endDate) {
        this.vue = vue
        this.invoicePeriod = invoicePeriod
        this.startDate = startDate
        this.endDate = endDate
    }

    processWfmJob(job) {
        let tasks = []
        if ("Tasks" in job && job.Tasks) {
            tasks = this.vue.$ensureArray(job.Tasks.Task)
        }

        for (const task of tasks) {
            this.tasks.push({
                name: task.Name, // From the Job task
                UUID: task.UUID, // From the Job task
                jobEstimatedMinutes: parseInt(task.EstimatedMinutes), // used for checking against the quote
                quotedDollars: 0,// Sum the Amount field of each task on the quote
                quotedTime: 0,// Sum the EstimatedMinutes of each task on the quote
                previousTimeToDate: 0, // Pulled from Job Time entries where Date < first of last month
                previousInvoicedToDateDollars: 0,// Sum the Amount field of each task on the invoice
                previouslyInvoicedPercent: 0, // Percent of task previously invoiced
                actualCompletionPercent: 0, // Percent of task actually complete
                thisMonthNewTime: 0,// Pulled from Job Time entries where Date > first of last month
                totalTimeToDate: 0, // previousTimeToDate + thisMonthNewTime
                toInvoicePercent: 0, // Amount to invoice (% completion) and is user modifiable
                toInvoiceAmount: 0, // Either entered directly by the user or calculated based on the toInvoicePercent, or InvoiceAll
                newInvoicedTotal: 0, // The total amount the project will be invoiced including the current invoice
                notes: [], // used in some cases to add additional information about a task
                summary: "", // Text summary of task changes
                entries: new Set(), // text summary of all time entries
            })
        }
        // Other is for tasks which cannot be matched to quoted tasks or invoiced tasks
        this.tasks.push({
            name: "Other",
            UUID: "00000000-0000-0000-0000-000000000000",
            quotedDollars: 0,
            quotedTime: 0,
            invoiceAll: true,
            previousTimeToDate: 0,
            previousInvoicedToDateDollars: 0,
            previouslyInvoicedPercent: 0,
            actualCompletionPercent: 0,
            thisMonthNewTime: 0,
            totalTimeToDate: 0,
            toInvoicePercent: 100,
            toInvoiceAmount: 0,
            newInvoicedTotal: 0,
            notes: [],
            summary: "",
            entires: new Set()
        })

    }

    recalculateTasks() {
        // Designed to be called on initialization only
        const _this = this
        this.tasks.forEach(function (task) {
            const amount1 = Math.round(100 * task.previousInvoicedToDateDollars / task.quotedDollars)
            task.previouslyInvoicedPercent = Number.isFinite(amount1) ? amount1 : 100

            const amount2 = Math.round(100 * task.totalTimeToDate / task.quotedTime)
            task.actualCompletionPercent = Number.isFinite(amount2) ? amount2 : 100

            task.toInvoicePercent = task.previouslyInvoicedPercent
            task.newInvoicedTotal = task.previousInvoicedToDateDollars
            task.summary = _this.taskSummary(task)
            _this.recalculateTask("percent", task)
        })
    }

    recalculateTask(what, task) {
        if (what === "percent") {
            const amount = Math.round((100 * (task.toInvoiceAmount + task.previousInvoicedToDateDollars)) / task.quotedDollars)
            task.toInvoicePercent = Number.isFinite(amount) ? amount : 100
        } else if (what === "amount") {
            task.toInvoiceAmount = ((task.toInvoicePercent / 100) * task.quotedDollars) - task.previousInvoicedToDateDollars
            task.toInvoiceAmount = Math.max(0, Math.round(task.toInvoiceAmount))
        }
        task.newInvoicedTotal = task.toInvoiceAmount + task.previousInvoicedToDateDollars
        task.summary = this.taskSummary(task)
        this.recalculateOverall()
    }

    taskSummary(task) {
        const previousTime = this.vue.$options.filters.formatMinutes(task.previousTimeToDate)
        const thisMonthTime = this.vue.$options.filters.formatMinutes(task.thisMonthNewTime)
        const totalTime = this.vue.$options.filters.formatMinutes(task.previousTimeToDate + task.thisMonthNewTime)
        const quotedTime = this.vue.$options.filters.formatMinutes(task.quotedTime)

        const previousDollars = this.vue.$formatCurrency(task.previousInvoicedToDateDollars)
        const toInvoiceDollars = this.vue.$formatCurrency(task.toInvoiceAmount)
        const newInvoicedTotal = this.vue.$formatCurrency(task.newInvoicedTotal)
        const quotedDollars = this.vue.$formatCurrency(task.quotedDollars)

        const previousPercent = task.previouslyInvoicedPercent + "%"
        const thisMonthPercent = task.toInvoicePercent + "%"

        return `<strong>Hours:</strong> ${previousTime} + ${thisMonthTime} -> ${totalTime} of ${quotedTime}<br />
              <strong>Dollars:</strong> ${previousDollars} + ${toInvoiceDollars} -> ${newInvoicedTotal} of ${quotedDollars}<br />
              <strong>Percent:</strong> ${previousPercent} -> ${thisMonthPercent}`
    }

    recalculateOverall() {
        this.quotedDollars = this.getQuotedDollars()
        this.quotedMinutes = this.getQuotedMinutes()
        this.previouslyInvoicedMinutes = this.getPreviouslyInvoicedMinutes()
        this.previouslyInvoicedDollars = this.getPreviouslyInvoicedDollars()
        this.currentMinutes = this.getCurrentMinutes()
        this.currentToDateMinutes = this.getCurrentToDateMinutes()
        this.toInvoiceDollars = this.getToInvoiceDollars()
        this.afterInvoicingDollars = this.getAfterInvoicingDollars()
        this.previouslyInvoicedPercent = this.getPreviouslyInvoicedPercent()
        this.currentToDatePercent = this.getCurrentToDatePercent()
        this.toInvoicePercent = this.getToInvoicePercent()
    }

    getQuotedDollars() {
        return this.tasks.reduce((prevValue, task) => prevValue + task.quotedDollars, 0)
    }

    getQuotedMinutes() {
        return this.tasks.reduce((prevValue, task) => prevValue + task.quotedTime, 0)
    }

    getPreviouslyInvoicedMinutes() {
        return this.tasks.reduce((prevValue, task) => prevValue + task.previousTimeToDate, 0)
    }

    getPreviouslyInvoicedDollars() {
        return this.tasks.reduce((prevValue, task) => prevValue + task.previousInvoicedToDateDollars, 0)
    }

    getToInvoiceDollars() {
        return this.tasks.reduce((prevValue, task) => prevValue + task.toInvoiceAmount, 0)
    }

    getCurrentMinutes() {
        return this.tasks.reduce((prevValue, task) => prevValue + task.thisMonthNewTime, 0)
    }

    getCurrentToDateMinutes() {
        return this.tasks.reduce((prevValue, task) => prevValue + task.totalTimeToDate, 0)
    }

    getAfterInvoicingDollars() {
        return this.tasks.reduce((prevValue, task) => prevValue + task.newInvoicedTotal, 0)
    }

    getPreviouslyInvoicedPercent() {
        const value = Math.round(100 * this.previouslyInvoicedDollars / this.quotedDollars)
        return Number.isFinite(value) ? value : "--"
    }

    getCurrentToDatePercent() {
        const value = Math.round(1000 * this.currentToDateMinutes / this.quotedMinutes) / 10
        return Number.isFinite(value) ? value : "--"
    }

    getToInvoicePercent() {
        const value = Math.round(100 * this.afterInvoicingDollars / this.quotedDollars)
        return Number.isFinite(value) ? value : "--"
    }

    processWfmQuote(response) {
        const _this = this
        const quote = response.Quote

        // Tasks
        if (quote && quote.Tasks) {
            const quoteTasks = this.vue.$ensureArray(quote.Tasks.Task)
            quoteTasks.forEach(function (quoteTask) {
                let task = _this.getTaskByName(quoteTask.Name)
                if (!task) {
                    task = _this.getTaskByName("Other")
                    task.notes.push(`Includes quote for '${quoteTask.Name}' for which there is no job task`)
                }

                task.quoteFound = true
                task.quotedDollars += parseFloat(quoteTask.Amount)
                task.quotedTime += parseInt(quoteTask.EstimatedMinutes)
            })
        }

        this.tasks.forEach((task) => {
            if (task.name === "Other") return //skip this one

            if (!task.quoteFound) {
                task.notes.push(`No quote found for '${task.name}'`)
            }

            if (task.quotedTime !== task.jobEstimatedMinutes) {
                const quoted = this.vue.$options.filters.formatMinutes(task.quotedTime)
                const job = this.vue.$options.filters.formatMinutes(task.jobEstimatedMinutes)
                task.notes.push(`Quoted hours for '${task.name}' (${quoted}) does not match the actual job(${job})`)
            }
        })
    }

    processWfmInvoice(response) {
        const _this = this
        let invoices = []

        if (response.Invoices) {
            invoices = this.vue.$ensureArray(response.Invoices.Invoice)
        }

        invoices.forEach(function (invoice) {
            // skip if not paid or approved
            if (!['Paid', 'Approved'].includes(invoice.Status)) return

            const endOfMonth = (new Date(_this.endDate + "T23:59")).getTime()
            const invoiceDate = (new Date(invoice.Date)).getTime()
            if (invoiceDate >= endOfMonth) return // skip - future time

            // Tasks
            if (invoice.Jobs.Job.Tasks) {
                const invoiceTasks = _this.vue.$ensureArray(invoice.Jobs.Job.Tasks.Task)
                invoiceTasks.forEach(function (invoiceTask) {
                    let task = _this.getTaskByName(invoiceTask.Name)
                    if (!task) {
                        task = _this.getTaskByName("Other")
                        task.notes.push(`Includes invoiced amount for '${invoiceTask.Name}' for which there is no job task`)
                    }

                    task.previousInvoicedToDateDollars += parseFloat(invoiceTask.Amount)
                })
            }

        })
    }

    processWfmTime(response) {
        const _this = this
        const endOfMonth = (new Date(this.endDate + "T23:59")).getTime()
        const startOfMonth = (new Date(this.startDate + "T00:00")).getTime()


        if (response.Times) {
            const timeEntries = this.vue.$ensureArray(response.Times.Time)
            timeEntries.forEach(function (timeEntry) {
                const timeEntryTime = (new Date(timeEntry.Date)).getTime()
                if (timeEntryTime > endOfMonth) return // skip - future time

                let task = _this.getTask(timeEntry.Task.UUID)
                if (!task) {
                    task = _this.getTaskByName("Other")
                    task.notes.push(`Includes time for task for '${timeEntry.Task.Name}' for which there is no job task`)
                }

                if (timeEntry.InvoiceTaskUUID) {
                    task.previousTimeToDate += parseInt(timeEntry.Minutes)
                }
                if (timeEntryTime >= startOfMonth && timeEntryTime < endOfMonth) {
                    task.thisMonthNewTime += parseInt(timeEntry.Minutes)
                    task.entries.add(_this.cleanText(timeEntry.Note))
                }
                task.totalTimeToDate += parseInt(timeEntry.Minutes)
            })
        }

    }

    processBwf(response) {
        for (const idx in response.tasks) {
            const item = response.tasks[idx]
            const task = this.getTask(item.task_id)
            task.toInvoicePercent = item.percentage
            task.toInvoiceAmount = item.amount
        }
    }

    getTask(taskUUID) {
        for (const task of this.tasks) {
            if (task.UUID === taskUUID) {
                return task
            }
        }
    }

    getTaskByName(taskName) {
        for (const task of this.tasks) {
            if (task.name === taskName) {
                return task
            }
        }
    }

    cleanText(str) {
        if (str) {
            const A = str.replace(/([^.])$/, "$1.") // Add full stop if one does not exist
            return A.charAt(0).toUpperCase() + A.slice(1) // Capitalize first letter
        } else {
            return ""
        }
    }

    copyActuals(mode) {
        if (mode === "quote") {
            for (const task of this.tasks) {
                const amount2 = Math.round(1000 * task.totalTimeToDate / task.quotedTime) / 10 // 1 decimal places
                task.toInvoicePercent = Number.isFinite(amount2) ? amount2 : 100
                this.recalculateTask("amount", task)
            }
        } else if (mode === "adhoc") {
            for (const task of this.tasks) {
                task.toInvoiceAmount = Math.round(task.thisMonthNewTime / 60 * this.fallbackHourlyRate)
                this.recalculateTask("percent", task)
            }
        }
    }

}