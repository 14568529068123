<template>
  <Field @save="save">
    <template v-slot:view>
      <a :href="'mailto:'+email">{{email}}</a>
    </template>
    <template v-slot:edit>
      <v-text-field v-model="email" ></v-text-field>
    </template>
  </Field>
</template>

<script>
  import Field from '../../Field'

  export default {
    name: "ContactFieldEmail",
    permissions: ["Modify Contact"],
    props: ['contact', 'clientId'],
    components: {
      Field
    },
    methods: {
      save: function(){
        const data = {
          Contact: this.contact,
        }
        data.Contact.Email = this.email
        data.Contact.ClientID = this.clientId
        this.$WfmApi.put('client.api/contact/' + this.contact.UUID, data).then((response) => this.processResponse(response))
      },
      processResponse(){
        this.$emit("updated")
      }
    },
    mounted() {
      this.email = this.contact.Email
    },
    data() {
      return {
        email:'',
    }
  }
}
</script>


