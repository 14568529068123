<script>
import {defineComponent} from "vue"

export default defineComponent({
  name: "MoreText",
  props: {
    maxLines: {
      type: Number,
      default: 3,
    },
    minHeight: { // used when the text is shorter than maxLines
      type: String,
      default: "unset",
    },
  },
  computed: {
    showMoreText() {
      return this.showMore ? "less" : "more"
    },
    clip: function () {
      if (!this.showMoreEnabled) {
        return {"min-height": this.minHeight}
      } else if (this.showMore) {
        return {"-webkit-line-clamp": "unset",}
      } else {
        return {"-webkit-line-clamp": this.maxLines}
      }
    }
  },
  methods: {
    toggleShowMore() {
      this.showMore = !this.showMore
    },
    calculateShowMore() {
      let el = document.querySelector("#" + this.uniqueId)
      if (el) {
        this.showMoreEnabled = el.scrollHeight !== el.clientHeight
      }
    },
  },
  data: () => ({
    showMore: false,
    showMoreEnabled: true,
    uniqueId: "id" + Math.random().toString(36).substr(2, 9),
  }),
  mounted() {
    this.calculateShowMore()
  }
})
</script>

<template>
  <div>
    <div class="multiline" :style="clip" :id="uniqueId">
      <slot></slot>
    </div>
    <span class="more" v-if="showMoreEnabled" @click="toggleShowMore">...{{ showMoreText }}</span>
  </div>
</template>

<style scoped>
.multiline {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.more {
  cursor: pointer;
}
</style>