import { render, staticRenderFns } from "./ReportWorkloadPage.vue?vue&type=template&id=3325d3ef&scoped=true&"
import script from "./ReportWorkloadPage.vue?vue&type=script&lang=js&"
export * from "./ReportWorkloadPage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3325d3ef",
  null
  
)

export default component.exports