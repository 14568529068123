<template>
  <v-container fluid>
    <h1>Scheduled Tasks</h1>
    <p>This interface is used to manage background tasks. If a task is not enabled, it
      will not automatically run when it is next due.</p>
    <p>If the task is scheduled with "run soon", it will run once at the next available schedule time (hourly).</p>

    <v-data-table :headers="headers" :items="tasks" dense disable-pagination hide-default-footer>
      <template v-slot:item.enabled="{ item }">
        <v-switch v-model="item.enabled" @change="changeState(item.name, item.enabled)"></v-switch>
      </template>

      <template v-slot:item.run_soon="{ item }">
        <v-switch v-model="item.run_soon" @change="changeRunSoon(item.name, item.run_soon)"></v-switch>
      </template>

      <template v-slot:item.last_run="{ item }">
        {{ new Date(item.last_run) | formatDate }}
      </template>

      <template v-slot:item.next_due="{ item }">
        {{ new Date(item.next_due) | formatDate }}
      </template>
    </v-data-table>

  </v-container>
</template>

<script>

export default {
  name: "AdminBackgroundTasks",
  permissions: ["Administer Betaworkflow"],
  components: {},
  data() {
    return {
      tasks: [],
      headers: [
        {text: "Task", value: "name"},
        {text: "Last Run", value: "last_run"},
        {text: "Next Due", value: "next_due"},
        {text: "Run Soon", value: "run_soon"},
        {text: "Enabled", value: "enabled"},
      ],
    }
  },
  methods: {
    changeState(name, state) {
      const payload = {name: name, state: state ? 1 : 0}
      this.$BwfApi.post("admin/scheduled-tasks/change-state", payload).then(response => {
        this.roles = response
        this.$notify.toast("Saved")
      })
    },
    changeRunSoon(name, state) {
      const payload = {name: name, state: state ? 1 : 0}
      this.$BwfApi.post("admin/scheduled-tasks/run-soon", payload).then(response => {
        this.roles = response
        this.$notify.toast("Saved")
      })
    },
    loadSchedules() {
      this.$BwfApi.get("admin/scheduled-tasks").then(response => {
        this.tasks = response
      })
    }
  },
  mounted() {
    this.loadSchedules()
  },
}

</script>

<style scoped>

</style>