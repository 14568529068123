<template>
  <BfwComponent :isReady="isReady" :title="title" :showTitle="showTitle" :editable="editable" :hideCard="true">
    <v-form ref="form" v-model="valid" lazy-validation :isReady="isReady">
      <div>
        <v-text-field v-model="name" label="Name"></v-text-field>
        <v-text-field v-model="mobile" label="Mobile"></v-text-field>
        <v-text-field v-model="phone" label="Phone"></v-text-field>
        <v-text-field v-model="email" label="Email" :rules="[$emailValidation]"></v-text-field>
        <v-text-field v-model="position" label="Position"></v-text-field>
        <CustomFields type="contact" :typeUUID="UUID" v-if="hideCustomFields===false"/>
      </div>

      <v-dialog width="200">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on" color="primary">
            <v-icon>mdi-qrcode</v-icon>
          </v-btn>
        </template>

        <Vcard :key="vcardKey"
               :firstName="firstName"
               :lastName="lastName"
               :workEmail="email"
               :workPhone="phone"
               :homePhone="mobile"
               :orgName="clientName"
        />
      </v-dialog>
    </v-form>
  </BfwComponent>
</template>

<script>
import BfwComponent from "../BwfComponent"
import CustomFields from "../toolkit/CustomFields"
import Vcard from "vue-vcard"

export default {
  name: "ContactEditor",
  permissions: [...CustomFields.permissions],
  props: {
    "value": {type: Object},
    "clientName": {type: String},
    "hideCustomFields": {type: Boolean, default: true}
  },
  components: {
    BfwComponent, CustomFields, Vcard
  },
  methods: {
    getContact() {
      const contact = {
        uuid: this.UUID,
        name: this.name,
        phone: this.phone,
        mobile: this.mobile,
        email: this.email,
        position: this.position
      }
      this.$emit("input", contact)
      return contact
    },
  },
  computed: {
    firstName() {
      const name = this.$splitName(this.name)
      return name.first
    },
    lastName() {
      const name = this.$splitName(this.name)
      return name.last
    },
    vcardKey() {
      const str = this.name + this.phone + this.mobile + this.email + this.position
      return this.$hash(str)
    }
  },
  watch: {
    value: {
      handler: function (val) {
        this.UUID = val.uuid
        this.name = val.name
        this.phone = val.phone
        this.mobile = val.mobile
        this.email = val.email
        this.position = val.position
      },
      deep: true
    }
  },
  data() {
    return {
      title: "Contact",
      showTitle: false,
      editable: false,
      isReady: true,
      valid: true,
      settings: {},
      UUID: "",
      name: "",
      phone: "",
      mobile: "",
      email: "",
      position: "",
    }
  },
}

</script>

<style scoped>

</style>