<template>
  <Field @save="save">
    <template v-slot:view>
      {{ state }}
      <v-btn v-for="item in jobStatesQuickAccess" v-bind:key="item" @click="state=item; save()" x-small color="primary" class="mr-1">{{ item }}</v-btn>
    </template>
    <template v-slot:edit>
      <v-select v-model="state" :items="jobStates"></v-select>
    </template>
  </Field>
</template>

<script>
import Field from "../../Field"
import {jobStates, jobStatesQuickAccess} from "@/lib/constants"

export default {
  name: "JobFieldState",
  permissions: ["Modify Job"],
  props: ["job"],
  components: {
    Field
  },
  methods: {
    save: function () {
      const data = {
        Job: {
          ID: this.job.ID,
          State: this.state
        }
      }
      this.$WfmApi.put("job.api/state", data).then((response) => this.processResponse(response))
    },
    processResponse() {
      this.$emit("updated")
    }
  },
  mounted() {
    this.state = this.job.State
  },
  data() {
    return {
      state: "",
      jobStates: jobStates,
      jobStatesQuickAccess: jobStatesQuickAccess
    }
  }
}
</script>


